/* eslint-disable import/prefer-default-export */
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as constants from '../constants';

import {
  fetchRolesList,
  fetchFunctionalRolesList,
  fetchObjectRoles,
} from '../../services/clientAPI/roles.service';

const handleListError = error =>
  of({
    type: constants.GET_ROLES_LIST_FAILURE,
    error: error.message,
  });

export const getRolesListEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_ROLES_LIST),
    switchMap(() =>
      fetchRolesList().pipe(
        map(roleList => ({
          roles: roleList,
        })),
      ),
    ),
    switchMap(roleList =>
      fetchFunctionalRolesList().pipe(
        map(functionalRoleList => ({
          ...roleList.roles,
          functionRoles: functionalRoleList.map(r => r.role),
        })),
      ),
    ),
    switchMap(otherRoles =>
      fetchObjectRoles().pipe(map(objectRoles => ({ ...otherRoles, objectRoles }))),
    ),
    mergeMap(roles => [
      {
        type: constants.GET_ROLES_LIST_SUCCESS,
        payload: roles,
      },
      {
        type: constants.STAMP_INITIAL_USER_DETAILS_ROLES,
        payload: roles,
      },
    ]),
    catchError(handleListError),
  );
