/* eslint-disable import/prefer-default-export */
export const filter = {
  config: {
    identifier: 'groups',
    name: 'Log Group',
  },
  defaults: {},
  filters: [
    {
      key: 0,
      text: 'None',
      value: 0,
    },
    {
      key: 1,
      text: 'BusinessAuditLog',
      value: 1,
    },
    {
      key: 2,
      text: 'SecurityAuditLog',
      value: 2,
    },
    {
      key: 3,
      text: 'ActivityTraceLog',
      value: 3,
    },
    {
      key: 4,
      text: 'ExceptionLog',
      value: 4,
    },
  ],
};
