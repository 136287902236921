import _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Segment, Grid, GridRow, GridColumn, Button } from 'semantic-ui-react';

import { getPendingUsers, setUserApproval } from '../../../store/actions/user.actions';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import {
  selectPendingUsersLoading,
  selectPendingUsers,
  selectPendingUserError,
} from '../../../store/selectors/user.selector';

const PendingUsers = styled.div`
  padding: 15px;
`;
const PendingUsersError = styled.div`
  padding: 15px;
  p::before {
    content: 'Error: ';
    font-weight: 700;
  }
  p {
    color: red;
  }
`;

const SamlUserApproval = ({
  history,
  loading,
  requestPendingUsers,
  pendingUsers,
  pendingUsersCount,
  pendingUserError,
  submitApproveUser,
  submitRejectUser,
}) => {
  useEffect(() => {
    requestPendingUsers();
  }, [requestPendingUsers]);

  if (pendingUserError && pendingUserError.length) {
    return (
      <PendingUsersError className="dashboard-pending-users" style={{ minHeight: '125px' }}>
        <p>{pendingUserError}</p>
      </PendingUsersError>
    );
  }

  const approveUser = _id => () => {
    submitApproveUser(_id, history);
  };
  const rejectUser = _id => () => {
    submitRejectUser(_id, history);
  };

  const groupedByContractRole = _.groupBy(pendingUsers, user => _.get(user, 'contractRole'));
  const formattedUsers = Object.entries(groupedByContractRole).map(([key, users]) => {
    const role = key === 'undefined' ? 'Non-SAML' : key;
    return [role, users];
  });
  const users = formattedUsers.length ? (
    formattedUsers.map(([contractRole, userArray]) => (
      <Segment vertical key={`user-${contractRole}`}>
        <Grid>
          <h3>{contractRole}</h3>
          {userArray.map(({ _id, displayName, email }, key) => (
            <GridRow key={key}>
              <GridColumn width={8}>
                <span>
                  <a href={`/user/${_id}`}>{displayName || email}</a>
                </span>
              </GridColumn>
              <GridColumn width={8}>
                <Button
                  size="mini"
                  color="green"
                  title={`Approve ${email}`}
                  onClick={approveUser(_id)}
                >
                  Approve
                </Button>
                <Button size="mini" color="red" title={`Reject ${email}`} onClick={rejectUser(_id)}>
                  Reject
                </Button>
              </GridColumn>
            </GridRow>
          ))}
        </Grid>
      </Segment>
    ))
  ) : (
    <p>No users pending approval</p>
  );

  return (
    <LoadingBoundary isLoading={loading}>
      <PendingUsers className="dashboard-pending-users" style={{ minHeight: '125px' }}>
        <h2>Pending Users: {pendingUsersCount}</h2>
        <hr />
        {users}
      </PendingUsers>
    </LoadingBoundary>
  );
};

SamlUserApproval.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  pendingUsers: PropTypes.array.isRequired,
  pendingUsersCount: PropTypes.number,
  pendingUserError: PropTypes.string,
  requestPendingUsers: PropTypes.func.isRequired,
  submitApproveUser: PropTypes.func.isRequired,
  submitRejectUser: PropTypes.func.isRequired,
};

SamlUserApproval.defaultProps = {
  pendingUsersCount: 0,
  pendingUserError: null,
};

const mapStateToProps = createStructuredSelector({
  loading: state => selectPendingUsersLoading(state),
  pendingUsers: state => selectPendingUsers(state),
  pendingUsersCount: state => selectPendingUsers(state).length,
  pendingUserError: state => selectPendingUserError(state),
});

const mapDispatchToProps = dispatch => ({
  requestPendingUsers: () => dispatch(getPendingUsers()),
  submitApproveUser: (id, history) => dispatch(setUserApproval(id, true, history)),
  submitRejectUser: id => dispatch(setUserApproval(id, false)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SamlUserApproval));
