/* eslint-disable react/prop-types */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Modal, Button, Tab, Container } from 'semantic-ui-react';

import TEMPLATES from '../../../config/templateMapper';
import { dashboards } from '../../../config/appConfig';
import {
  makeSelectFluidLayout,
  makeSelectTabsLayout,
  makeSelectLinkPageLayout,
} from '../../../store/selectors/layout.selector';

import { DashboardHeader } from '../../molecules/DashboardHeader';
import WidgetDefaultCmp from '../../molecules/WidgetDefault';
import ToolbarDefault from '../../molecules/ToolbarDefault';
import { makeSelectCurrentTheme } from '../../../store/selectors/themes.selector';

const Dashboard = ({ name, title, subtitle, icon, tabs, fluid, history, match, linkPage }) => {
  const [openModal, setOpenModal] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const client = useSelector(makeSelectCurrentTheme);

  // Todo: Every dashboard will eventually have .default key, but until then, fallback to dashboards[name].
  const dashConfig = dashboards[name][client] || dashboards[name].default || dashboards[name];

  const handleOpenModal = action => {
    const ROUTE_MAPPER = [
      { path: 'monthly-performance', template: 'monthlyPerformanceWrapper' },
      { path: 'holdings', template: 'holdingsWidget' },
      { path: 'historical-allocation', template: 'historicalAllocation' },
      { path: 'export', template: 'export' },
      { path: 'drawdown', template: 'drawdownChart' },
      { path: 'volatility', template: 'volatilityChart' },
      { path: 'contribution', template: 'performanceContribution' },
      { path: 'historical', template: 'default' },
      { path: 'scenarios', template: 'scenariosWidget' },
      { path: 'compare', template: 'compare' },
      { fullPath: 'monitoring/logins', template: 'monitoringLogins' },
      { fullPath: 'monitoring/strategies', template: 'monitoringStrategies' },
    ];

    const url = ROUTE_MAPPER.find(route => route.template === action.template);
    if (linkPage && url) {
      if (url.fullPath) {
        history.push(`/${url.fullPath}/`);
      } else {
        history.push(`${match.url}/${url.path}`);
      }
    } else {
      setModalAction(action);
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const renderWidgetContent = widget => {
    const Content = TEMPLATES[widget.type].type;
    return <Content {...widget} />;
  };

  const renderWidget = widget => (
    <WidgetDefaultCmp
      key={widget.title}
      hideHeader={widget.hideHeader}
      title={widget.title}
      openModal={handleOpenModal}
      actions={widget.actions}
      noPadding={widget.noPadding}
      noBorder={widget.noBorder}
      background={widget.background}
      collapsed={widget.collapsed}
      hideToggleContent={widget.hideToggleContent}
    >
      {renderWidgetContent({ ...widget, fluid })}
    </WidgetDefaultCmp>
  );

  const renderTabs = () => {
    const panes = dashConfig.widgets.map(widget => ({
      menuItem: widget.title,
      // eslint-disable-next-line react/display-name
      render: () => <Tab.Pane attached={false}>{renderWidget(widget)}</Tab.Pane>,
    }));

    return (
      <Container>
        <Tab menu={{ pointing: true }} panes={panes} />
      </Container>
    );
  };

  const renderModalContent = template => {
    const Content = TEMPLATES[template] && TEMPLATES[template].type;
    return template ? <Content /> : null;
  };

  const renderOrHideWidget = widget => {
    if (widget.type === 'fundShareClassWidget') {
      return null;
    }
    return renderWidget(widget);
  };

  const renderDashColumn = ({ width, widgets }) =>
    width && widgets ? (
      <Grid.Column width={width}>
        {widgets.map((widget, index) => {
          if (widget && widget.column1) {
            // nested columns
            return (
              <Grid key={index}>
                <Grid.Row>
                  {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                  {renderColumns(widget)}
                </Grid.Row>
              </Grid>
            );
          }
          return renderOrHideWidget(widget);
        })}
      </Grid.Column>
    ) : null;

  function renderColumns(config) {
    return (
      <Fragment>
        {config.column1 && renderDashColumn(config.column1)}
        {config.column2 && renderDashColumn(config.column2)}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <DashboardHeader title={title} subtitle={subtitle} icon={icon} />
      {dashConfig.toolbar && (
        <ToolbarDefault
          back={dashConfig.toolbar.back}
          actions={dashConfig.toolbar.actions}
          openModal={handleOpenModal}
        />
      )}
      <Grid padded>
        {dashConfig.topRow && <Grid.Row>{renderDashColumn(dashConfig.topRow)}</Grid.Row>}
        <Grid.Row>
          {tabs && dashConfig.widgets && dashConfig.widgets.length
            ? renderTabs()
            : renderColumns(dashConfig)}
        </Grid.Row>
        {dashConfig.bottomRow && <Grid.Row>{renderDashColumn(dashConfig.bottomRow)}</Grid.Row>}
      </Grid>

      {modalAction && modalAction.template && (
        <Modal size="large" open={openModal} onClose={handleCloseModal}>
          <Modal.Header>{modalAction.label}</Modal.Header>
          <Modal.Content scrolling>{renderModalContent(modalAction.template)}</Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setOpenModal(false)} content="Close" />
          </Modal.Actions>
        </Modal>
      )}
    </Fragment>
  );
};

Dashboard.defaultProps = {
  subtitle: '',
  icon: { name: 'cog', size: 'big' },
  tabs: false,
  fluid: false,
  linkPage: false,
};

Dashboard.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.object,
  tabs: PropTypes.bool,
  fluid: PropTypes.bool,
  linkPage: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  fluid: state => makeSelectFluidLayout(state),
  tabs: state => makeSelectTabsLayout(state),
  linkPage: state => makeSelectLinkPageLayout(state),
});

export default withRouter(connect(mapStateToProps)(Dashboard));
