import React, { useState } from 'react';
import { storiesOf } from '@storybook/react';
import StoryRouter from 'storybook-react-router';

import TimeFilter from './index';

const stateStart = new Date().setDate(-3);
const stateEnd = new Date();
const defaultState = {
  start: stateStart,
  end: stateEnd,
  selection: 2020,
};

let setTimeFilter;
const Driver = story => {
  const [state, setTimeFilterState] = useState(defaultState);
  setTimeFilter = setTimeFilterState;
  const setFilterState = (start, end, selection) => {
    setTimeFilterState({ start, end, selection });
  };
  return <div style={{ margin: 50 }}>{story({ state, setFilterState })}</div>;
};

const customDateTimeOptions = [
  {
    id: 2019,
    label: '2019',
    func: id => () => {
      const start = new Date(2019, 10, 18).getTime();
      const end = new Date(2019, 11, 31, 23, 59, 59).getTime();
      setTimeFilter({ start, end, selection: id });
    },
  },
  {
    id: 2020,
    label: '2020',
    func: id => () => {
      const start = new Date(2020, 0, 1).getTime();
      const end = new Date(2020, 11, 31, 23, 59, 59).getTime();
      setTimeFilter({ start, end, selection: id });
    },
  },
];

const standardConfig = {};
const dateOnlyConfig = { timeInput: false };
const customConfig = { customDateTimeOptions };
const customDateOnlyConfig = { customDateTimeOptions, timeInput: false };
const noCustomOptionConfig = { customDateTimeOption: false };
const customDateOnlyWithoutCustomOptionConfig = {
  customDateTimeOptions,
  customDateTimeOption: false,
  timeInput: false,
};

storiesOf('TimeFilter', module)
  .addDecorator(StoryRouter())
  .addDecorator(Driver)
  .add('Default', driverProps => <TimeFilter {...standardConfig} {...driverProps} />)
  .add('Date Only', driverProps => <TimeFilter {...dateOnlyConfig} {...driverProps} />)
  .add('Custom', driverProps => <TimeFilter {...customConfig} {...driverProps} />)
  .add('Custom - Date Only', driverProps => (
    <TimeFilter {...customDateOnlyConfig} {...driverProps} />
  ))
  .add('Without Custom Option', driverProps => (
    <TimeFilter {...noCustomOptionConfig} {...driverProps} />
  ))
  .add('Custom - without Custom Option', driverProps => (
    <TimeFilter {...customDateOnlyWithoutCustomOptionConfig} {...driverProps} />
  ));
