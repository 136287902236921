import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Checkbox, Grid, GridColumn, GridRow, Loader } from 'semantic-ui-react';

import {
  clearExportData,
  requestInstrumentExport,
} from '../../../store/actions/instruments.actions';
import {
  selectInstrumentsExportData,
  selectInstrumentsExportDataLoading,
} from '../../../store/selectors/instruments.selector';
import Button from '../../atoms/Button';

const InstrumentExport = styled.div`
  padding: 15px;
`;

const InstrumentExportWidget = ({
  exportInstruments,
  clearExportInstruments,
  exportData,
  isFetching,
}) => {
  const downloadLink = useRef();
  const [downloadAsJson, toggleJsonFormat] = useState(false);
  const _filename = `InstrumentExport-${new Date().getTime()}.${downloadAsJson ? 'json' : 'csv'}`;

  useEffect(() => {
    if (exportData) {
      const URL = window.URL || window.webkitURL;
      const type = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) // is safari?
        ? `application/${downloadAsJson ? 'json' : 'csv'}`
        : `${downloadAsJson ? 'application/json' : 'text/csv'}`;
      const downloadData = downloadAsJson ? JSON.stringify(exportData, '', 2) : exportData;

      const downloadable =
        typeof URL.createObjectURL === 'undefined'
          ? `data:${type};charset=utf-8,${downloadData}`
          : URL.createObjectURL(new Blob(['', downloadData], { type }));

      const a = downloadLink.current;
      a.download = _filename;
      a.href = downloadable;
      a.click();
      a.href = '';
      clearExportInstruments();
    }
  }, [_filename, exportData, clearExportInstruments, downloadAsJson]);

  const downloadData = () => (
    <a download={_filename} ref={downloadLink} target="_self" href={exportData}>
      &nbsp;
    </a>
  );

  const handleClick = () => exportInstruments(downloadAsJson);

  return (
    <InstrumentExport style={{ minHeight: '125px' }}>
      <h2>
        Instrument Export <Loader inline size="tiny" active={isFetching} />
      </h2>
      <hr />
      <Grid>
        <GridRow>
          <GridColumn width={16} style={{ marginBottom: 10 }}>
            <Checkbox
              label="JSON format"
              checked={downloadAsJson || false}
              onChange={() => toggleJsonFormat(!downloadAsJson)}
            />
          </GridColumn>
          <GridColumn width={16}>
            <Button primary onClick={handleClick}>
              Export
            </Button>
            {downloadData()}
          </GridColumn>
        </GridRow>
      </Grid>
    </InstrumentExport>
  );
};

InstrumentExportWidget.propTypes = {
  exportInstruments: PropTypes.func.isRequired,
  clearExportInstruments: PropTypes.func.isRequired,
  exportData: PropTypes.any,
  isFetching: PropTypes.bool,
};

InstrumentExportWidget.defaultProps = {
  exportData: null,
  isFetching: false,
};

const mapStateToProps = createStructuredSelector({
  exportData: state => selectInstrumentsExportData(state),
  isFetching: state => selectInstrumentsExportDataLoading(state),
});

const mapDispatchToProps = dispatch => ({
  exportInstruments: downloadAsJson => dispatch(requestInstrumentExport(downloadAsJson)),
  clearExportInstruments: () => dispatch(clearExportData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentExportWidget);
