import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Button, Form, Segment } from 'semantic-ui-react';
import renderConfig from 'helpers/renderConfig';
import {
  selectLoginMessage,
  makeSelectInvalidRoles,
  makeSelectAuthenticated,
} from '../../../store/selectors/auth.selector';
import UIMessage from '../../atoms/UIMessage';

const LoginButton = styled(Button)`
  //background: #4f98d1 none !important;
  background: ${props => props.theme.variables.primary} none !important;
  color: #fff !important;
`;

const LoginForm = ({
  onLogin,
  onSSOLogin,
  loginMessage,
  roleElevationRequired,
  isAuthenticated,
}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    const credentials = {
      username,
      password,
    };
    onLogin(credentials);
    setUsername('');
    setPassword('');
  };

  const roleMessage = roleElevationRequired && (
    <UIMessage type="error" message="Only Admins and IT Service Operatives allowed." />
  );
  const uiMessage = loginMessage && loginMessage.length && (
    <UIMessage type="error" message={loginMessage} />
  );

  return (
    <Form size="large">
      {!isAuthenticated && roleMessage}
      {!isAuthenticated && uiMessage}
      <Segment stacked>
        <Form.Input
          fluid
          icon="user"
          iconPosition="left"
          placeholder="Username or Email Address"
          value={username}
          onChange={e => setUsername(e.target.value)}
        />
        <Form.Input
          fluid
          icon="lock"
          iconPosition="left"
          placeholder="Password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <LoginButton fluid size="large" onClick={handleLogin}>
          Login
        </LoginButton>
      </Segment>
      {renderConfig.useSaml && (
        <Segment stacked>
          <LoginButton fluid size="small" onClick={onSSOLogin}>
            <i className="fa fa-lock" style={{ paddingRight: 15 }} />
            {`Login with ${renderConfig.displayName} SAML / SSO`}
          </LoginButton>
        </Segment>
      )}
    </Form>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onSSOLogin: PropTypes.func.isRequired,
  loginMessage: PropTypes.string,
  roleElevationRequired: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};

LoginForm.defaultProps = {
  loginMessage: '',
  roleElevationRequired: '',
};

const mapStateToProps = createStructuredSelector({
  loginMessage: state => selectLoginMessage(state),
  roleElevationRequired: state => makeSelectInvalidRoles(state),
  isAuthenticated: state => makeSelectAuthenticated(state),
});

export default connect(mapStateToProps)(LoginForm);
