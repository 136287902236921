import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import renderThemeConfig from 'helpers/renderThemeConfig';
import PageWrapper from '../../../molecules/PageWrapper';
import AddNewDataRefreshTask from './NewTaskPage';
import ViewExistingTasks from './ViewExistingTasks';

const DataRefreshHeader = styled.div`
  background: ${props => props.theme.dashboardHeaderBackground};
  margin-top: 70px;
  margin-bottom: -90px;
  height: 60px;
  display: flex;
  padding-top: 5px;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderTitle = styled.h1`
  color: white;
  margin: 0;
  font-size: 1.2em;
  font-weight: normal;
`;

export default function DataRefreshPage() {
  const [creating, setCreating] = useState(false);
  return (
    <>
      <DataRefreshHeader theme={renderThemeConfig.default}>
        <Icon inverted name="refresh" style={{ margin: '0 10px 0 20px', lineHeight: '15px' }} />
        <HeaderTitle>Data Refresh</HeaderTitle>
      </DataRefreshHeader>
      <PageWrapper>
        <div style={{ width: '100%', minHeight: '88vh' }}>
          {creating && <AddNewDataRefreshTask onDone={() => setCreating(false)} />}
          {!creating && <ViewExistingTasks onCreateNew={() => setCreating(true)} />}
        </div>
      </PageWrapper>
    </>
  );
}
