/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import './style.css';

import FundTableRow from '../FundsTableRow';
import filters from '../../../config/appConfig/filters';
import { sortDataByColumn } from '../../../helpers/filter.helper';

const FirstHeadCell = styled(Table.Cell)`
  border-bottom: 1px solid #203a4c !important;
  padding: 2px !important;
  white-space: nowrap;
  font-weight: 600;
`;

const SecondHeadCell = styled(Table.HeaderCell)`
  border-bottom: 1px solid #203a4c !important;
  height: 47px;
  white-space: nowrap;
`;

const LastCell = styled(Table.HeaderCell)`
  max-width: 115px;
  border-bottom: 1px solid #203a4c !important;
`;

const CurrentSelect = styled.select`
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
`;

const UnofficialDataSpan = styled.span`
  border-left: 4px solid #a63860 !important;
  color: #a63860 !important;
  font-weight: 500;
`;

const FundsTable = ({ data, colored, onSelect, tab, cob }) => {
  const [currentMetric, setCurrentMetric] = useState(null);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const summary = tab === 'summary';
  const periods = summary
    ? [
        { label: '1D Return', name: 'one_day_actual' },
        { label: 'MTD Return', name: 'mtd' },
        { label: 'YTD Return', name: 'ytd_actual' },
      ]
    : [
        { label: '1 MONTH', name: 'one_month_actual' },
        { label: '3 MONTH', name: 'three_months_actual' },
        { label: '1Y', name: 'one_year_actual' },
        { label: '3Y', name: 'three_years_actual', sup: 'An.' },
        { label: '5Y', name: 'five_years_actual', sup: 'An.' },
        { label: 'SI', name: 'since_inception_annual', sup: 'An.' },
      ];

  const handleCurrentMetricChange = e => {
    setCurrentMetric(e.target.value);
  };

  const handleSort = clickedColumn => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      setDirection('ascending');
      setSortedData(sortDataByColumn(data, clickedColumn));
    } else {
      setSortedData(
        sortDataByColumn(data, clickedColumn, direction === 'ascending' ? 'desc' : 'asc'),
      );
      setDirection(direction === 'ascending' ? 'descending' : 'ascending');
    }
  };

  const renderHeader = () => (
    <Table.Header>
      <Table.Row>
        <FirstHeadCell no-sort="true" colSpan={cob ? 3 : 4}>
          <div style={{ float: 'left' }}>
            <UnofficialDataSpan>&nbsp; Estimated Data</UnofficialDataSpan>
          </div>

          <div style={{ textAlign: 'right' }}>{`Number of funds: ${data.length}`}</div>
        </FirstHeadCell>
        {periods.map(period => (
          <FirstHeadCell key={period.name} colSpan="3" textAlign="center">
            {period.label}
            {period.sup && <sup>{period.sup}</sup>}
          </FirstHeadCell>
        ))}
        {summary && (
          <FirstHeadCell key="current" textAlign="center">
            Current
          </FirstHeadCell>
        )}
      </Table.Row>
      <Table.Row>
        <SecondHeadCell style={{ width: '80px' }} textAlign="center">
          Watch
        </SecondHeadCell>
        <SecondHeadCell
          className="left-border-none"
          width="4"
          sorted={column === 'name' ? direction : null}
          onClick={handleSort('code')}
        >
          Name
        </SecondHeadCell>
        <SecondHeadCell
          className="left-border-none"
          style={{ width: '70px' }}
          sorted={column === 'currency' ? direction : null}
          onClick={handleSort('currency')}
        >
          Ccy
        </SecondHeadCell>
        {!cob && (
          <SecondHeadCell className="left-border-none" style={{ width: '70px' }}>
            As of Date (COB)
          </SecondHeadCell>
        )}
        {periods.map(period => (
          <Fragment key={period.name}>
            {['Fund', 'Bmk', 'Alpha'].map(item => {
              let fieldName = '';
              if (item === 'Fund') {
                fieldName = `performance_${period.name}`;
              }
              if (item === 'Bmk') {
                fieldName = `benchmark_${period.name}`;
              }
              if (item === 'Alpha') {
                fieldName = `alpha_${period.name}`;
              }

              return (
                <SecondHeadCell
                  className={item === 'Fund' ? 'left-border' : 'left-border-none'}
                  key={item}
                  textAlign="center"
                  sorted={column === fieldName ? direction : null}
                  onClick={handleSort(fieldName)}
                >
                  {item === 'Fund' && 'F (%)'}
                  {item === 'Bmk' && 'B (%)'}
                  {item === 'Alpha' && <span>&alpha; (bps)</span>}
                </SecondHeadCell>
              );
            })}
          </Fragment>
        ))}
        {summary && (
          <LastCell style={{ width: '180px' }} className="left-border" textAlign="left">
            <CurrentSelect name="metricKeys" onChange={handleCurrentMetricChange}>
              {filters.currentMetricsFilter.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.text}
                </option>
              ))}
            </CurrentSelect>
          </LastCell>
        )}
      </Table.Row>
    </Table.Header>
  );

  return (
    <Table striped sortable basic="very" compact>
      {renderHeader()}
      <Table.Body>
        {sortedData.map((row, i) => (
          <FundTableRow
            key={`${row.name}-${i}`}
            row={row}
            cob={cob}
            onSelect={onSelect}
            summary={summary}
            currentMetric={currentMetric}
            colored={colored}
          />
        ))}
      </Table.Body>
    </Table>
  );
};
FundsTable.defaultProps = {
  data: [],
  colored: false,
  tab: 'summary',
  cob: false,
};

FundsTable.propTypes = {
  data: PropTypes.array,
  colored: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  tab: PropTypes.string,
  cob: PropTypes.bool,
};

export default FundsTable;
