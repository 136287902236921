/* eslint-disable import/prefer-default-export */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, filter, catchError, map } from 'rxjs/operators';

import {
  fetchStrategyDetails,
  fetchStrategyList,
  performStrategyCSVExport,
  requestStrategyCalculation,
  setStrategyUpdate,
} from '../../services/clientAPI/strategy.service';

import * as constants from '../constants';

const handleError = type => error =>
  of({
    type,
    error: error.message,
  });

export const getStrategyExportEpic = actions$ =>
  actions$.pipe(
    ofType(constants.STRATEGY_EXPORT_REQUEST),
    mergeMap(action =>
      performStrategyCSVExport(action.json).pipe(
        mergeMap(response => [
          {
            type: constants.STRATEGY_EXPORT_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.STRATEGY_EXPORT_FAILURE)),
      ),
    ),
    catchError(handleError(constants.STRATEGY_EXPORT_FAILURE)),
  );

export const requestStrategyCalculationEpic = actions$ =>
  actions$.pipe(
    ofType(constants.STRATEGY_CALCULATION_REQUEST),
    mergeMap(action =>
      requestStrategyCalculation(action.name).pipe(
        mergeMap(response => [
          {
            type: constants.STRATEGY_CALCULATION_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.STRATEGY_CALCULATION_FAILURE)),
      ),
    ),
    catchError(handleError(constants.STRATEGY_CALCULATION_FAILURE)),
  );

export const getStrategyListEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_STRATEGY_LIST),
    mergeMap(() =>
      fetchStrategyList().pipe(
        mergeMap(response => [
          {
            type: constants.GET_STRATEGY_LIST_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.GET_STRATEGY_LIST_FAILURE)),
      ),
    ),
    catchError(handleError(constants.GET_STRATEGY_LIST_FAILURE)),
  );

export const getStrategyDetailsEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_STRATEGY_DETAILS),
    mergeMap(action =>
      fetchStrategyDetails(action.id).pipe(
        mergeMap(response => [
          {
            type: constants.GET_STRATEGY_DETAILS_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.GET_STRATEGY_DETAILS_FAILURE)),
      ),
    ),
    catchError(handleError(constants.GET_STRATEGY_DETAILS_FAILURE)),
  );

const getUpdatedStrategyDetails = (strategyDetails, update) =>
  Object.assign(strategyDetails, update);

export const updateStrategyDetailsEpic = actions$ =>
  actions$.pipe(
    filter(action => action.type === constants.UPDATE_STRATEGY_DETAILS),
    map(action => {
      const update = getUpdatedStrategyDetails(
        action.payload?.strategyDetails,
        action.payload?.update,
      );
      return {
        type: constants.UPDATE_STRATEGY_DETAILS_SUCCESS,
        payload: { data: update },
      };
    }),
  );

export const setStrategyDetailsEpic = actions$ =>
  actions$.pipe(
    ofType(constants.SET_STRATEGY_DETAILS),
    mergeMap(action =>
      setStrategyUpdate(action.payload).pipe(
        mergeMap(response => [
          {
            type: constants.GET_STRATEGY_DETAILS,
            _id: response,
          },
        ]),
        mergeMap(() => [
          {
            type: constants.SET_STRATEGY_DETAILS_SUCCESS,
          },
        ]),
        catchError(handleError(constants.SET_STRATEGY_DETAILS_FAILURE)),
      ),
    ),
    catchError(handleError(constants.SET_STRATEGY_DETAILS_FAILURE)),
  );
