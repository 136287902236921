import React from 'react';
import * as moment from 'moment';
import { storiesOf } from '@storybook/react';

import UserLoginsChart from './index';

const data = [
  {
    name: 'other',
    data: [
      ['Mon Oct 21 2019', 2],
      ['Tue Oct 22 2019', 2],
      ['Wed Oct 23 2019', 1],
      ['Thu Oct 24 2019', 0],
      ['Fri Oct 25 2019', 0],
      ['Sat Oct 26 2019', 0],
      ['Sun Oct 27 2019', 0],
      ['Mon Oct 28 2019', 0],
      ['Tue Oct 29 2019', 0],
      ['Wed Oct 30 2019', 2],
      ['Thu Oct 31 2019', 6],
      ['Fri Nov 01 2019', 12],
      ['Sat Nov 02 2019', 0],
      ['Sun Nov 03 2019', 0],
      ['Mon Nov 04 2019', 13],
      ['Tue Nov 05 2019', 1],
      ['Wed Nov 06 2019', 0],
      ['Thu Nov 07 2019', 2],
      ['Fri Nov 08 2019', 0],
      ['Sat Nov 09 2019', 0],
      ['Sun Nov 10 2019', 0],
      ['Mon Nov 11 2019', 1],
      ['Tue Nov 12 2019', 3],
      ['Wed Nov 13 2019', 1],
      ['Thu Nov 14 2019', 1],
      ['Fri Nov 15 2019', 0],
      ['Sat Nov 16 2019', 0],
      ['Sun Nov 17 2019', 0],
      ['Mon Nov 18 2019', 0],
    ],
  },
  {
    name: 'bjb-business-tech',
    data: [
      ['Mon Oct 21 2019', 1],
      ['Tue Oct 22 2019', 3],
      ['Wed Oct 23 2019', 0],
      ['Thu Oct 24 2019', 0],
      ['Fri Oct 25 2019', 0],
      ['Sat Oct 26 2019', 0],
      ['Sun Oct 27 2019', 0],
      ['Mon Oct 28 2019', 1],
      ['Tue Oct 29 2019', 0],
      ['Wed Oct 30 2019', 0],
      ['Thu Oct 31 2019', 0],
      ['Fri Nov 01 2019', 0],
      ['Sat Nov 02 2019', 0],
      ['Sun Nov 03 2019', 0],
      ['Mon Nov 04 2019', 0],
      ['Tue Nov 05 2019', 0],
      ['Wed Nov 06 2019', 0],
      ['Thu Nov 07 2019', 0],
      ['Fri Nov 08 2019', 0],
      ['Sat Nov 09 2019', 0],
      ['Sun Nov 10 2019', 0],
      ['Mon Nov 11 2019', 0],
      ['Tue Nov 12 2019', 0],
      ['Wed Nov 13 2019', 0],
      ['Thu Nov 14 2019', 0],
      ['Fri Nov 15 2019', 0],
      ['Sat Nov 16 2019', 0],
      ['Sun Nov 17 2019', 0],
      ['Mon Nov 18 2019', 0],
    ],
  },
  {
    name: 'bjb-bb-owner',
    data: [
      ['Mon Oct 21 2019', 0],
      ['Tue Oct 22 2019', 7],
      ['Wed Oct 23 2019', 6],
      ['Thu Oct 24 2019', 3],
      ['Fri Oct 25 2019', 5],
      ['Sat Oct 26 2019', 0],
      ['Sun Oct 27 2019', 0],
      ['Mon Oct 28 2019', 0],
      ['Tue Oct 29 2019', 9],
      ['Wed Oct 30 2019', 8],
      ['Thu Oct 31 2019', 8],
      ['Fri Nov 01 2019', 0],
      ['Sat Nov 02 2019', 0],
      ['Sun Nov 03 2019', 0],
      ['Mon Nov 04 2019', 6],
      ['Tue Nov 05 2019', 6],
      ['Wed Nov 06 2019', 4],
      ['Thu Nov 07 2019', 7],
      ['Fri Nov 08 2019', 2],
      ['Sat Nov 09 2019', 0],
      ['Sun Nov 10 2019', 0],
      ['Mon Nov 11 2019', 16],
      ['Tue Nov 12 2019', 7],
      ['Wed Nov 13 2019', 6],
      ['Thu Nov 14 2019', 7],
      ['Fri Nov 15 2019', 6],
      ['Sat Nov 16 2019', 0],
      ['Sun Nov 17 2019', 0],
      ['Mon Nov 18 2019', 4],
    ],
  },
];

const defaultProps = {
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      // don't display the dummy year
      month: '%e. %b',
      year: '%b',
    },
  },
  yAxis: {
    title: {
      text: 'Number of Logins',
    },
  },
};

const singleSeries = {
  ...defaultProps,
  data: {
    name: 'User Logins by Functional Role over Time',
    series: [
      {
        name: data[0].name,
        data: data[0].data.map(item => [
          moment(item[0], 'ddd MMM DD YYYY').utc().valueOf(),
          item[1],
        ]),
      },
    ],
  },
};
const multiSeries = {
  ...defaultProps,
  data: {
    name: 'User Logins by Functional Role over Time',
    series: data.map(series => ({
      name: series.name,
      data: series.data.map(item => [moment(item[0], 'ddd MMM DD YYYY').utc().valueOf(), item[1]]),
    })),
  },
};

storiesOf('UserLoginsChart', module)
  .add('single series', () => <UserLoginsChart {...singleSeries} />)
  .add('multi series', () => <UserLoginsChart {...multiSeries} />);
