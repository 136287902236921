export const capitalizeFirstLetter = (name: string) =>
  name?.[0]?.toUpperCase() + name?.substring(1);

export const choices: Record<string, number> = {
  '5s': 5_000,
  '30s': 30_000,
  '1m': 60_000,
  '5m': 5 * 60_000,
  '15m': 15 * 60_000,
};

export const roundToPreviousNearestEven = (time: number) => 2 * Math.floor(time / 2);
