import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import { clearExportData, requestStrategyExport } from '../../../store/actions/strategy.actions';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const csvPrefix = isSafari ? 'application' : 'text';

/* eslint-disable no-param-reassign */
function downloadAndClear(anchorElement, filename, dataURL) {
  anchorElement.download = filename;
  anchorElement.href = dataURL;
  anchorElement.click();
  anchorElement.href = '';
}

function getMIMEType(downloadAsJson) {
  const suffix = downloadAsJson ? 'json' : 'csv';
  const prefix = downloadAsJson ? 'application' : csvPrefix;
  return `${prefix}/${suffix}`;
}

function getDataURL(exportData, downloadAsJson) {
  const URL = window.URL || window.webkitURL;
  const mimeType = getMIMEType(downloadAsJson);
  const downloadData = downloadAsJson ? JSON.stringify(exportData, null, 2) : exportData;
  return typeof URL.createObjectURL === 'undefined'
    ? `data:${mimeType};charset=utf-8,${downloadData}`
    : URL.createObjectURL(new Blob(['', downloadData], { type: mimeType }));
}

export default function useExportStrategyData(dataForExport, asJSON, filename) {
  const dispatch = useDispatch();
  const linkRef = useRef();
  const exportData = () => dispatch(requestStrategyExport(asJSON));
  useEffect(() => {
    if (!dataForExport) return;
    const dataURL = getDataURL(dataForExport, asJSON);
    downloadAndClear(linkRef.current, filename, dataURL);
    dispatch(clearExportData());
  }, [filename, dataForExport, asJSON, dispatch]);
  return { exportData, linkRef };
}
