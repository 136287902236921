import React from 'react';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import { TextInput, Switch, Dropdown, DropdownSize } from '@alpima/picasso';
import { CountriesDropdown } from '../../../../../config/countriesList';
import { FormikValues, UserDetailsRequestType, UserDetailsResponseType } from './UserDetails.types';

export const UserSchema = yup.object().shape({
  id: yup.string(),
  firstName: yup.string().required('First name is required'),
  email: yup.string().email('Invalid email address').required('Email is required'),
  gbdauth: yup.string(),
  username: yup.string().required('Username is required'),
  lastName: yup.string().required('Last name is required'),
  existingClient: yup.boolean(),
  alternativeFunds: yup.boolean(),
  specificFunds: yup.string(),
  business: yup.string().required('Company name is required'),
  occupation: yup.string().required('Job title is required'),
  street1: yup.string().required('Business address is required'),
  countryCode: yup.string().required('Business country is required'),
  postcode: yup.string().required('Company postcode is required'),
  number: yup.string().required('Mobile number is required'),
});

export function getInitialValues(userDetails: UserDetailsResponseType | null): FormikValues {
  const userEmployment = userDetails?.employment?.at(-1);
  const userPhone = userDetails?.phones?.at(-1);

  return {
    id: userDetails?._id ?? '',
    enabled: userDetails?.enabled ?? false,
    email: userDetails?.email ?? '',
    username: userDetails?.username ?? '',
    firstName: userDetails?.firstName ?? '',
    lastName: userDetails?.lastName ?? '',
    gbdAuth: userDetails?.gbdAuth ?? '',
    authorisedBy: userDetails?.authorisedBy ?? '',
    existingClient: userDetails?.existingClient ?? false,
    alternativeFunds: userDetails?.alternativeFunds ?? false,
    specificFunds: userDetails?.specificFunds ?? '',
    business: userEmployment?.business ?? '',
    occupation: userEmployment?.occupation ?? '',
    street1: userEmployment?.address?.street1 ?? '',
    postcode: userEmployment?.address?.postCode ?? '',
    countryCode: userPhone?.countryCode ?? '',
    permissionCountryCode: userDetails?.permissionCountryCode || userPhone?.countryCode || '',
    number: userPhone?.number ?? '',
  };
}

export function genUserDetailsReqObject(values: FormikValues): UserDetailsRequestType {
  return {
    _id: values.id,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    business: values.business,
    occupation: values.occupation,
    street1: values.street1,
    postCode: values.postcode,
    countryCode: values.countryCode,
    number: values.number,
    enabled: values.enabled,
    existingClient: values.existingClient,
    alternativeFunds: values.alternativeFunds,
    specificFunds: values.specificFunds,
    gbdAuth: values.gbdAuth,
    authorisedBy: values.authorisedBy,
    permissionCountryCode: values?.permissionCountryCode,
  };
}

export const countriesList = CountriesDropdown();

export function FormField({ label, content }: { label: string; content: string }) {
  return (
    <div className="flex flex-row mb-6">
      <p className="w-2/6 text-gray-500">{label}</p>
      <div className="w-4/6 pr-10 text-black">{content}</div>
    </div>
  );
}

export function RadioInput({ name, value }: { name: string; value: string }) {
  const formik = useFormikContext<FormikValues>();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (value === 'approved' && e.target.checked) {
      formik.setFieldValue('enabled', true);
    }

    formik.handleChange(e);
  };

  return (
    <div className="flex flex-row items-baseline">
      <input
        value={value}
        name="gbdAuth"
        onChange={handleChange}
        type="radio"
        checked={formik.values.gbdAuth === value}
      />
      <span className="first:uppercase ml-1">{name}</span>
    </div>
  );
}

export function GBDAuth() {
  const formik = useFormikContext<FormikValues>();
  return (
    <div className="flex flex-col mb-6">
      <div className="flex flex-row">
        <p className="w-2/6 text-gray-500 p-0 m-0">GBD Authorisation?</p>
        <div className="w-4/6 pr-10 flex flex-row justify-between">
          <RadioInput value="approved" name="Approved" />
          <RadioInput value="rejected" name="Declined" />
          <RadioInput value="awaiting" name="Awaiting" />
          {formik.touched.gbdAuth && formik.errors.gbdAuth && (
            <div className="text-red-500">{formik.errors.gbdAuth}</div>
          )}
        </div>
      </div>
      <p className="text-warning-800 text-left mt-1">
        After you save your changes an email will be sent to the user.
      </p>
    </div>
  );
}

export function FormTextField({
  label,
  placeholder,
  name,
  type = 'text',
}: {
  label: string;
  placeholder?: string;
  name: keyof FormikValues;
  type?: string;
}) {
  const { values, handleChange, touched, errors } = useFormikContext<FormikValues>();
  return (
    <div className="flex flex-row mb-6">
      <p className="w-2/6 text-gray-500">{label}</p>
      <div className="w-4/6 pr-10">
        <TextInput
          name={name}
          type={type}
          onChange={handleChange}
          placeholder={placeholder}
          value={(values?.[name] || '') as string}
          wrapperClassName="w-full"
        />
        {touched?.[name] && errors?.[name] && <div className="text-red-500">{errors?.[name]}</div>}
      </div>
    </div>
  );
}

export function CountriesField({ label, name }: { label: string; name: keyof FormikValues }) {
  const formik = useFormikContext<FormikValues>();

  return (
    <div className="flex flex-row mb-6">
      <p className="w-2/6 text-gray-500">{label}</p>
      <div className="w-4/6 pr-10">
        <Dropdown
          options={countriesList}
          customSize={DropdownSize.SMALL}
          selectedOption={(formik.values?.[name] || '') as string}
          onChange={formik.handleChange}
          name={name}
          wrapperClassName="w-full"
          selectClassName="w-full"
        />
      </div>
    </div>
  );
}

export function FormSwitchField({
  label,
  name,
  data,
}: {
  label: string;
  name: keyof FormikValues;
  data?: FormikValues;
}) {
  const formik = useFormikContext<FormikValues>();
  const enabledFormSwitch = name === 'enabled';
  const showGbdAuthEnabledError = enabledFormSwitch && data?.gbdAuth !== 'approved';
  const disabled =
    enabledFormSwitch && (data?.gbdAuth !== 'approved' || formik.values?.gbdAuth !== 'approved');
  const checked = enabledFormSwitch
    ? formik.values?.gbdAuth === 'approved'
      ? formik.values?.[name]
      : false
    : formik.values?.[name];

  return (
    <div className="flex flex-row mb-6">
      <p className="w-5/6 text-gray-500">{label}</p>
      <div className="w-1/6 pr-10">
        <Switch
          name={name}
          id={name}
          onChange={formik.handleChange}
          checked={!!checked}
          disabled={disabled}
        />
      </div>
      {formik.touched?.[name] && formik.errors?.[name] && (
        <div className="text-red-500">{formik.errors?.[name]}</div>
      )}
      {showGbdAuthEnabledError && (
        <div className="text-red-500">Need to set GBD Authorisation to Approved first</div>
      )}
    </div>
  );
}

export const Profile = ({
  handleSubmit,
  values,
}: {
  handleSubmit: () => void;
  values: FormikValues;
}) => (
  <form className="w-full px-8 xl:px-40 pt-12" onSubmit={handleSubmit}>
    <div className="grid grid-cols-2 2xl:grid-cols-3">
      <FormSwitchField label="Enabled" name="enabled" data={values} />
      <FormField label="ID" content={values?.id} />
      <FormField label="Username" content={values?.username} />
      <FormTextField label="Company" name="business" placeholder="Doe Ltd" />
      <FormTextField label="First Name" name="firstName" placeholder="John" />
      <FormTextField label="Last Name" name="lastName" placeholder="Doe" />
      <FormTextField label="Job Title" name="occupation" placeholder="CEO" />
      <FormTextField label="Email" name="email" placeholder="john.doe@alpima.com" type="email" />
      <FormSwitchField label="Existing Client?" name="existingClient" />
      <FormTextField label="Business Address" name="street1" placeholder="33, Fake St" />
      <GBDAuth />
      <FormSwitchField label="Alternative Investment Interest?" name="alternativeFunds" />
      <CountriesField label="Business country" name="countryCode" />
      <FormTextField label="Authorised by" name="authorisedBy" placeholder="John Doe" />
      <FormTextField label="Specific funds" name="specificFunds" placeholder="Fund II..." />
      <CountriesField label="Permission country" name="permissionCountryCode" />
      <FormTextField label="Business postcode" name="postcode" placeholder="S23 6HB" />
      <FormTextField label="Mobile number" name="number" placeholder="+44 7432887654" />
    </div>
  </form>
);
