import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { getDailyErrorCount } from '../../../store/actions/log.actions';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import {
  selectDailyErrorCount,
  selectDailyErrorCountError,
  selectDailyErrorCountLoading,
} from '../../../store/selectors/log.selector';

const Errors = styled.div`
  padding: 15px;
  a {
    color: ${props => props.theme.variables.highlightedText};
  }
`;
const ErrorsError = styled.div`
  padding: 15px;
  p::before {
    content: 'Error: ';
    font-weight: 700;
  }
  p {
    color: red;
  }
`;

const LogsErrorMonitoring = ({ loading, requestErrors, errorsCount, errorsError }) => {
  useEffect(() => {
    requestErrors();
  }, [requestErrors]);

  if (errorsError && errorsError.length) {
    return (
      <ErrorsError className="dashboard-daily-errors" style={{ minHeight: '125px' }}>
        <p>{errorsError}</p>
      </ErrorsError>
    );
  }

  return (
    <LoadingBoundary isLoading={loading}>
      <Errors className="dashboard-daily-errors" style={{ minHeight: '75px' }}>
        <h2>
          Daily Errors: <Link to="/logs?submit=errors">{errorsCount}</Link>
        </h2>
      </Errors>
    </LoadingBoundary>
  );
};

LogsErrorMonitoring.propTypes = {
  loading: PropTypes.bool.isRequired,
  errorsCount: PropTypes.number,
  errorsError: PropTypes.string,
  requestErrors: PropTypes.func.isRequired,
};

LogsErrorMonitoring.defaultProps = {
  errorsCount: 0,
  errorsError: null,
};

const mapStateToProps = createStructuredSelector({
  loading: state => selectDailyErrorCountLoading(state),
  errorsCount: state => selectDailyErrorCount(state),
  errorsError: state => selectDailyErrorCountError(state),
});

const mapDispatchToProps = dispatch => ({
  requestErrors: () => dispatch(getDailyErrorCount()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogsErrorMonitoring));
