import React from 'react';

const PageOptions = ({ length, itemsPerPage }: { length: number; itemsPerPage: number }) => {
  const numberOfPages = Math.ceil(length / itemsPerPage);
  const pagesArray = new Array(numberOfPages).fill(0).map((_, i) => i + 1);

  return (
    <>
      {pagesArray.map(page => (
        <option key={page}>{page}</option>
      ))}
    </>
  );
};

const NumberOfItemsAndTotal = ({
  itemsPerPage,
  page,
  numberOfRows,
}: {
  itemsPerPage: number;
  page: number;
  numberOfRows: number;
}) => {
  if (numberOfRows === 0) return <div>0 items</div>;
  const numberOfFirstRow = itemsPerPage * (page - 1) + 1;
  const numberOfLastRow = Math.min(itemsPerPage * page, numberOfRows);

  if (numberOfFirstRow === numberOfLastRow)
    return (
      <div>
        {numberOfLastRow} of {numberOfLastRow} items
      </div>
    );

  return (
    <div>
      {numberOfFirstRow} - {numberOfLastRow} of {numberOfRows} items
    </div>
  );
};

const TablePagination = ({
  itemsPerPage,
  itemsPerPageHandler,
  page,
  pageHandler,
  numberOfRows,
}: {
  itemsPerPage: number;
  itemsPerPageHandler: (items: number) => void;
  page: number;
  pageHandler: (page: number) => void;
  numberOfRows: number;
}) => {
  const numberOfPages = Math.ceil(numberOfRows / itemsPerPage);
  return (
    <div className="w-full flex flex-row justify-between p-6 text-gray-500 border-solid border-1 border-gray-300">
      <div>
        <label htmlFor="itemsPerPage">
          Items per page:
          <select
            className="border-0"
            id="itemsPerPage"
            disabled={numberOfRows === 0}
            value={itemsPerPage}
            onChange={event => itemsPerPageHandler(parseInt(event.target.value, 10))}
            onBlur={() => {}}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="50">50</option>
          </select>
        </label>
      </div>
      <NumberOfItemsAndTotal itemsPerPage={itemsPerPage} page={page} numberOfRows={numberOfRows} />
      <div>
        <label htmlFor="page">
          Page:
          <select
            disabled={numberOfPages <= 1}
            className="border-0"
            id="page"
            value={page}
            onChange={event => pageHandler(parseInt(event.target.value, 10))}
            onBlur={() => {}}
          >
            <PageOptions length={numberOfRows} itemsPerPage={itemsPerPage} />
          </select>
        </label>
      </div>
    </div>
  );
};

export default TablePagination;
