import React from 'react';
import { storiesOf } from '@storybook/react';

import ChartDefault from './index';

const defaultProps = {};

storiesOf('ChartDefault', module)
  .addDecorator(story => <div style={{ margin: '50px' }}>{story()}</div>)
  .add('default', () => <ChartDefault {...defaultProps} />);
