import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { Checkbox } from 'semantic-ui-react';
import renderConfig from 'helpers/renderConfig';
import { getStrategyTableData } from '../../../store/actions/monitoring.actions';
import {
  selectLastRefreshTable as selectLastRefresh,
  selectCacheTimestampTable,
  selectStrategyDataTable,
  selectMonitoringTableLoading,
} from '../../../store/selectors/monitoring.selector';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';
import LoadingBoundary from '../../atoms/LoadingBoundary';

const ChartLink = styled.span`
  padding: 0 15px 0;
  border-right: solid 1px #999;
  color: black;
  cursor: pointer;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    border-right: none;
  }
  &.active {
    color: ${props => props.theme.variables.primary};
    font-weight: 700;
  }
`;

const Table = styled.table`
  border: solid 1px #333;
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  tr,
  th,
  td {
    border: solid 1px #333;
  }
  th {
    background: #ccc;
  }
  td {
    padding: 5px;
  }
`;

const compileTableContent = data => {
  const parsedData = {
    overall: {},
    types: {},
  };
  const initialiseMonthEntry = (type, month) => {
    parsedData.overall = parsedData.overall || {};
    parsedData.overall[month] = parsedData.overall[month] || { created: 0, updated: 0 };
    parsedData.types[type] = parsedData.types[type] || {};
    parsedData.types[type][month] = parsedData.types[type][month] || { created: 0, updated: 0 };
  };
  Object.entries(data).forEach(([parameter, strategyTypes]) => {
    if (parameter && parameter === 'months') {
      strategyTypes.forEach(({ type, counts }) => {
        const createdCounts = counts && counts[0];
        const updatedCounts = counts && counts[1];
        // eslint-disable-next-line no-unused-expressions
        createdCounts &&
          createdCounts.forEach(([month, count]) => {
            initialiseMonthEntry(type, month);
            parsedData.overall[month].created += count;
            parsedData.types[type][month].created += count;
          });
        // eslint-disable-next-line no-unused-expressions
        updatedCounts &&
          updatedCounts.forEach(([month, count]) => {
            initialiseMonthEntry(type, month);
            parsedData.overall[month].updated += count;
            parsedData.types[type][month].updated += count;
          });
      });
    } else {
      // eslint-disable-next-line no-unused-expressions
      strategyTypes &&
        strategyTypes.forEach(({ type, counts }) => {
          // Initialise
          parsedData.overall = parsedData.overall || {};
          parsedData.overall[parameter] = parsedData.overall[parameter] || {
            created: 0,
            updated: 0,
          };
          parsedData.types[type] = parsedData.types[type] || {};
          parsedData.types[type][parameter] = parsedData.types[type][parameter] || {
            created: 0,
            updated: 0,
          };

          // Increment
          parsedData.overall[parameter].created += counts[0];
          parsedData.overall[parameter].updated += counts[1];
          parsedData.types[type][parameter].created += counts[0];
          parsedData.types[type][parameter].updated += counts[1];
        });
    }
  });
  return parsedData;
};

const MonitorStrategiesTableWidget = ({
  loading,
  // lastRefresh,
  cacheTimestamp,
  data,
  getTableData,
}) => {
  const [activeYear, setYear] = useState(2022);
  const [includeDeleted, toggleIncludeDeleted] = useState(false);
  useEffect(() => {
    getTableData(activeYear, includeDeleted);
  }, [getTableData, activeYear, includeDeleted]);
  const { formatDateTime } = useDateTimeFormatter();

  const parsedData = compileTableContent(data);
  const layout = [{ identifier: 'overall', label: 'Overall', data: parsedData.overall }];
  const subLayout = ['created', 'updated'];
  const columns = [
    { identifier: 'today', label: 'Today' },
    { identifier: 'yesterday', label: 'Yesterday' },
    { identifier: 'ytd', label: 'YTD' },
    { identifier: '0', label: 'Jan' },
    { identifier: '1', label: 'Feb' },
    { identifier: '2', label: 'Mar' },
    { identifier: '3', label: 'Apr' },
    { identifier: '4', label: 'May' },
    { identifier: '5', label: 'Jun' },
    { identifier: '6', label: 'Jul' },
    { identifier: '7', label: 'Aug' },
    { identifier: '8', label: 'Sept' },
    { identifier: '9', label: 'Oct' },
    { identifier: '10', label: 'Nov' },
    { identifier: '11', label: 'Dec' },
    { identifier: 'si', label: 'S.I.' },
  ];

  const renderRowTitle = (type, operation) => {
    const matches = type.match(/^([A-z]+):?\s?(.+)?/);
    const portfolioType = matches && matches.length && matches[1];
    const formattedType = portfolioType !== 'Overall' ? portfolioType.toUpperCase() : type;
    const formattedSubType = (matches && matches[2] && `: ${matches[2]}`) || '';

    return (
      <Fragment>
        <span style={{ padding: '0 10px' }}>
          <strong>{formattedType}</strong> {formattedSubType}
        </span>
        <span style={{ marginLeft: 10, fontSize: '0.9rem' }}>{`[${operation}]`}</span>
      </Fragment>
    );
  };

  const renderTableContent = () => (
    <Fragment>
      {layout.map((entry, index) =>
        subLayout.map((subEntry, subIndex) => (
          <tr
            key={`login-layout-entry-${index}-${subIndex}`}
            style={{ background: index % 2 !== 0 ? '#e5e5e5' : '#FFF' }}
          >
            <td style={{ textAlign: 'left' }}>{renderRowTitle(entry.label, subEntry)}</td>
            {columns.map((column, colIndex) => (
              <td key={`login-layout-column-entry-${colIndex}`}>
                {entry.data &&
                  entry.data[column.identifier] &&
                  entry.data[column.identifier][subEntry]}
              </td>
            ))}
          </tr>
        )),
      )}
      {Object.entries(parsedData.types).map(([type, typeData], index) =>
        subLayout.map((subType, subIndex) => (
          <tr
            key={`login-type-entry-${index}-${subIndex}`}
            style={{ background: index % 2 === 0 ? '#e5e5e5' : '#FFF' }}
          >
            <td style={{ textAlign: 'left' }}>{renderRowTitle(type, subType)}</td>
            {columns.map((column, colIndex) => (
              <td key={`login-type-column-entry-${colIndex}`}>
                {typeData && typeData[column.identifier] && typeData[column.identifier][subType]}
              </td>
            ))}
          </tr>
        )),
      )}
    </Fragment>
  );

  return (
    <LoadingBoundary isLoading={loading}>
      <Fragment>
        <div style={{ float: 'left', marginBottom: 10 }}>
          <ChartLink className={activeYear === 2019 ? 'active' : ''} onClick={() => setYear(2019)}>
            2019
          </ChartLink>
          <ChartLink className={activeYear === 2020 ? 'active' : ''} onClick={() => setYear(2020)}>
            2020
          </ChartLink>
          <ChartLink className={activeYear === 2021 ? 'active' : ''} onClick={() => setYear(2021)}>
            2021
          </ChartLink>
          <ChartLink className={activeYear === 2022 ? 'active' : ''} onClick={() => setYear(2022)}>
            2022
          </ChartLink>
          <span style={{ margin: '0 15px', fontSize: '1rem', textAlign: 'right' }}>
            Cached since: {formatDateTime(cacheTimestamp)}
          </span>
        </div>
        <div style={{ float: 'right', marginBottom: 10 }}>
          {renderConfig.includeDeletedStrategies && (
            <Checkbox
              label="Include deleted"
              defaultChecked={includeDeleted}
              onClick={() => toggleIncludeDeleted(!includeDeleted)}
            />
          )}
        </div>
        <Table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              {columns.map(column => (
                <th key={`column-header-${column.label}`}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableContent()}</tbody>
        </Table>
      </Fragment>
    </LoadingBoundary>
  );
};

MonitorStrategiesTableWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  // lastRefresh: PropTypes.number.isRequired,
  cacheTimestamp: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  getTableData: PropTypes.func.isRequired,
};

MonitorStrategiesTableWidget.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  loading: state => selectMonitoringTableLoading(state),
  lastRefresh: state => selectLastRefresh(state),
  cacheTimestamp: state => selectCacheTimestampTable(state),
  data: state => selectStrategyDataTable(state),
});

const mapDispatchToProps = dispatch => ({
  getTableData: (year, includeDeleted) => dispatch(getStrategyTableData(year, includeDeleted)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitorStrategiesTableWidget);
