import { createSelector } from 'reselect';

const selectReferenceData = state => state.get('reference');

const selectLastRefresh = createSelector(selectReferenceData, reference =>
  reference.get('lastRefresh'),
);
const selectReferenceDataLoading = createSelector(selectReferenceData, reference =>
  reference.get('loading'),
);

const selectReferenceDataList = createSelector(
  selectReferenceData,
  reference => reference.get('list').toJS() || [],
);

const selectLastExportRequest = createSelector(selectReferenceData, reference =>
  reference.get('lastRequest'),
);

const selectReferenceDataExportData = createSelector(selectReferenceData, reference => {
  const data = reference.get('data');
  if (!data) return null;
  if (typeof data === 'string') return data;
  return data.toJS() || {};
});

const selectReferenceDataExportLoading = createSelector(selectReferenceData, data =>
  data.get('isExporting'),
);

const selectReferenceDataProductLines = createSelector(selectReferenceData, reference => {
  const data = reference.get('reference').toJS();
  return data.productLines || {};
});

const selectReferenceDataCurrencies = createSelector(selectReferenceData, reference => {
  const data = reference.get('reference').toJS();
  return data.currencies || [];
});

export {
  selectReferenceData,
  selectReferenceDataLoading,
  selectLastRefresh,
  selectReferenceDataList,
  selectLastExportRequest,
  selectReferenceDataExportData,
  selectReferenceDataExportLoading,
  selectReferenceDataProductLines,
  selectReferenceDataCurrencies,
};
