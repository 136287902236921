import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Form, Grid, GridRow, GridColumn, Label, Checkbox } from 'semantic-ui-react';
import renderThemeConfig from 'helpers/renderThemeConfig';
import {
  selectUserDetails,
  selectUserLoading,
  selectOnlineUsers,
  selectHasDetailsStateChanged,
} from '../../../store/selectors/user.selector';
import {
  selectCompaniesList,
  selectCompaniesLoader,
} from '../../../store/selectors/companies.selector';
import { selectRolesList, selectRolesLoader } from '../../../store/selectors/roles.selector';
import {
  fetchUserDetails,
  stampUserAttribute,
  stampEnableUser,
  stampDisableUser,
  setUserUpdate,
  cancelUserUpdate,
} from '../../../store/actions/user.actions';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import ButtonTwoChoice from '../../atoms/ButtonTwoChoice';

const resultRenderer = ({ displayName }) => <Label content={displayName} />;
resultRenderer.propTypes = {
  displayName: PropTypes.string.isRequired,
};

const WidgetActions = styled.div`
  display: flex;
  flex: 1 0 auto;
  & .widgetHeaderGroup {
    flex: 1 0 auto;
    justify-content: flex-start;

    & h1 {
      font-size: 2rem;
      margin: 10px 15px;
      border-bottom: solid 1px;
      padding-bottom: 15px;
    }
    & h2 {
      display: inline-flex;
      font-size: 1.2rem;
      margin: 5px 15px 10px;

      & div.ui.checkbox {
        margin: 3px 10px;
      }
      & i {
        margin: 3px 10px;
        &.fa-check-circle {
          color: green;
        }
        &.fa-times-circle {
          color: red;
        }
      }
    }
  }

  & .ui.buttons {
    flex: 0 0 auto;
    justify-content: flex-end;
    margin: 0 5px;
    padding: 10px;
    height: 60px;
  }
`;

const UserDetailsWidget = ({
  match,
  loading,
  getUserDetails,
  userDetails,
  onlineUsers,
  companyData,
  roleData,
  hasStateChanged,
  setUserAttribute,
  enableUser,
  disableUser,
  setUpdate,
  cancelUpdate,
}) => {
  const { userConfig } = renderThemeConfig.default;

  useEffect(() => {
    getUserDetails(match.params.id);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAttribute = attribute => {
    // eslint-disable-next-line
    const ComponentAtom = require(`../../atoms/${attribute.atom}`).default;
    const data = {
      user: userDetails || {},
      company: companyData || [],
      roles: roleData || [],
    };

    return (
      <ComponentAtom
        {...attribute}
        data={data}
        onChange={value => {
          // eslint-disable-next-line
          setUserAttribute(attribute.type, value, data, attribute.compiler);
        }}
        disabled={userDetails && !userDetails.enabled}
      />
    );
  };

  const handleEnableUser = () => enableUser();
  const handleDisableUser = () => disableUser();

  const handlePersistUpdate = () => setUpdate();
  const handleCancelUpdate = () => cancelUpdate();

  const isEnabled = userDetails && (
    <Checkbox
      checked={userDetails.enabled}
      onClick={() => (userDetails.enabled ? handleDisableUser() : handleEnableUser())}
    />
  );
  const isOnline = (onlineUsers.filter(user => user._id === (userDetails && userDetails._id)) || [])
    .length ? (
    <i className="fa fa-check-circle" />
  ) : (
    <i className="fa fa-times-circle" />
  );

  return (
    <LoadingBoundary isLoading={loading}>
      <div style={{ minHeight: '850px' }}>
        <WidgetActions>
          <div className="widgetHeaderGroup">
            <h1>{userDetails && userDetails.displayName}</h1>
            <h2>Enabled: {isEnabled}</h2>
            <h2>Online: {isOnline}</h2>
          </div>
          <ButtonTwoChoice
            disabled={!hasStateChanged}
            onClickLeft={handlePersistUpdate}
            onClickRight={handleCancelUpdate}
            left="Save"
            right="Cancel"
          />
        </WidgetActions>
        <Form>
          <Grid columns={2} divided>
            <GridRow>
              <GridColumn>
                {userConfig.attributes
                  .filter(attribute => attribute.column === 1)
                  .map(renderAttribute)}
              </GridColumn>
              <GridColumn>
                {userConfig.attributes
                  .filter(attribute => attribute.column === 2)
                  .map(renderAttribute)}
              </GridColumn>
            </GridRow>
          </Grid>
        </Form>
      </div>
    </LoadingBoundary>
  );
};

UserDetailsWidget.propTypes = {
  getUserDetails: PropTypes.func.isRequired,
  setUserAttribute: PropTypes.func.isRequired,
  setUpdate: PropTypes.func.isRequired,
  cancelUpdate: PropTypes.func.isRequired,
  enableUser: PropTypes.func.isRequired,
  disableUser: PropTypes.func.isRequired,
  userDetails: PropTypes.object.isRequired,
  onlineUsers: PropTypes.array.isRequired,
  companyData: PropTypes.array.isRequired,
  roleData: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  hasStateChanged: PropTypes.bool,
};

UserDetailsWidget.defaultProps = {
  hasStateChanged: false,
};

const mapStateToProps = createStructuredSelector({
  userDetails: state => selectUserDetails(state),
  onlineUsers: state => selectOnlineUsers(state),
  companyData: state => selectCompaniesList(state),
  roleData: state => selectRolesList(state),
  loading: state =>
    selectUserLoading(state) || selectCompaniesLoader(state) || selectRolesLoader(state),
  hasStateChanged: state => selectHasDetailsStateChanged(state),
});

const mapDispatchToProps = dispatch => ({
  getUserDetails: id => dispatch(fetchUserDetails(id)),
  setUserAttribute: (key, value, data, compiler) =>
    dispatch(stampUserAttribute(key, value, data, compiler)),
  enableUser: () => dispatch(stampEnableUser()),
  disableUser: () => dispatch(stampDisableUser()),
  setUpdate: () => dispatch(setUserUpdate()),
  cancelUpdate: () => dispatch(cancelUserUpdate()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetailsWidget));
