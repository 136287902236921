import React from 'react';
import styled from 'styled-components';

const DivWrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  padding-left: 30px;
  width: 100%;
  min-height: 45px;
  padding: 5px 5px 5px 15px;
  background-color: ${props => props.theme.variables.dashboardHeaderBackground || '#203A4C'};
  color: ${props => props.theme.variables.dashboardHeaderColor || '#fff'};
`;

// eslint-disable-next-line react/prop-types
const DashboardHeaderWrapper = ({ children }) => <DivWrapper>{children}</DivWrapper>;

export default DashboardHeaderWrapper;
