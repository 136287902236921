import { useCallback, useMemo, useState } from 'react';
import { DisplayColumnsProps } from './DisplayColumns';

type Column = {
  key: string;
  name: string;
};

/**
 * Manage which columns are visible and which aren't, providing a "toggle" function.
 */
export default function useDisplayColumns(columns: Column[]): [Column[], DisplayColumnsProps] {
  const [visibleColumns, setVisibleColumns] = useState(columns.map(c => c.key));

  const toggleHeaderVisible = useCallback((key: string) => {
    setVisibleColumns(visible => {
      if (visible.includes(key)) {
        return visible.filter(v => v !== key);
      }
      return [...visible, key];
    });
  }, []);

  const filteredColumns = useMemo(
    () => columns.filter(col => visibleColumns.includes(col.key)),
    [columns, visibleColumns],
  );

  return [filteredColumns, { toggleHeaderVisible, visibleColumns, columns }];
}
