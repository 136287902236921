import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Dashboard from '../../../organisms/Dashboard';
import PageWrapper from '../../../molecules/PageWrapper';
import { makeSelectCurrentTheme } from '../../../../store/selectors/themes.selector';

const UsersPage = ({ match }) => (
  <PageWrapper>
    <div style={{ minHeight: '850px', background: '#F6F8FA' }}>
      <Switch>
        <Route
          exact
          path="/user/list/:page"
          render={() => (
            <Dashboard title="Users List" icon={{ name: 'users', size: 'large' }} name="userList" />
          )}
        />
        <Route exact path="/user/list" render={() => <Redirect to={`${match.url}/1`} />} />
        <Route
          path="/user/:id"
          render={() => (
            <Dashboard title="User" icon={{ name: 'user', size: 'large' }} name="user" />
          )}
        />
        <Route
          exact
          path={`${match.url}`}
          render={() => (
            <Fragment>
              <p>Redirecting...</p>
              <Redirect to={`${match.url}/list`} />
            </Fragment>
          )}
        />
      </Switch>
    </div>
  </PageWrapper>
);
const mapStateToProps = createStructuredSelector({ theme: state => makeSelectCurrentTheme(state) });

UsersPage.defaultProps = {};
UsersPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(connect(mapStateToProps, null)(UsersPage));
