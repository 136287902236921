import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Grid, GridRow, GridColumn, Button } from 'semantic-ui-react';

import {
  getUpdatedContractRoleUsers,
  setUserContractRoleApproval,
} from '../../../store/actions/user.actions';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import {
  selectUpdatedContractLoading,
  selectUpdatedContractUsers,
  selectUpdatedContractUsersError,
} from '../../../store/selectors/user.selector';

const UpdatedUsers = styled.div`
  padding: 15px;
`;
const UpdatedUsersError = styled.div`
  padding: 15px;
  p::before {
    content: 'Error: ';
    font-weight: 700;
  }
  p {
    color: red;
  }
`;

const SamlContractRoleApproval = ({
  history,
  loading,
  requestUpdatedUsers,
  updatedUsers,
  updatedUsersCount,
  updatedUserError,
  submitApproveNewContractRole,
}) => {
  useEffect(() => {
    requestUpdatedUsers();
  }, [requestUpdatedUsers]);

  if (updatedUserError && updatedUserError.length) {
    return (
      <UpdatedUsersError className="dashboard-updated-users" style={{ minHeight: '125px' }}>
        <p>{updatedUserError}</p>
      </UpdatedUsersError>
    );
  }

  const approveUser = _id => () => {
    submitApproveNewContractRole(_id, history);
  };

  const users = updatedUsers.length ? (
    <Grid>
      {updatedUsers.map(({ _id, displayName, email, contractRole, contractRoleChanged }, key) => (
        <GridRow key={key}>
          <GridColumn width={4}>
            <span>
              <a href={`/user/${_id}`}>{displayName || email}</a>
            </span>
          </GridColumn>
          <GridColumn width={3}>{contractRole}</GridColumn>
          <GridColumn width={1}>
            <span>&rarr;</span>
          </GridColumn>
          <GridColumn width={4}>{contractRoleChanged}</GridColumn>
          <GridColumn width={3}>
            <Button size="mini" color="green" title={`Update ${email}`} onClick={approveUser(_id)}>
              Approve
            </Button>
          </GridColumn>
        </GridRow>
      ))}
    </Grid>
  ) : (
    <p>All contract roles are in sync</p>
  );

  return (
    <LoadingBoundary isLoading={loading}>
      <UpdatedUsers className="dashboard-updated-users" style={{ minHeight: '125px' }}>
        <h2>Updated Contract Roles: {updatedUsersCount}</h2>
        <hr />
        {users}
      </UpdatedUsers>
    </LoadingBoundary>
  );
};

SamlContractRoleApproval.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updatedUsers: PropTypes.array.isRequired,
  updatedUsersCount: PropTypes.number,
  updatedUserError: PropTypes.string,
  requestUpdatedUsers: PropTypes.func.isRequired,
  submitApproveNewContractRole: PropTypes.func.isRequired,
};

SamlContractRoleApproval.defaultProps = {
  updatedUsersCount: 0,
  updatedUserError: null,
};

const mapStateToProps = createStructuredSelector({
  loading: state => selectUpdatedContractLoading(state),
  updatedUsers: state => selectUpdatedContractUsers(state),
  updatedUsersCount: state => selectUpdatedContractUsers(state).length,
  updatedUserError: state => selectUpdatedContractUsersError(state),
});

const mapDispatchToProps = dispatch => ({
  requestUpdatedUsers: () => dispatch(getUpdatedContractRoleUsers()),
  submitApproveNewContractRole: (id, history) =>
    dispatch(setUserContractRoleApproval(id, true, history)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SamlContractRoleApproval));
