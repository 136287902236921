import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { GET_DISPLAY_USER_STARTED } from '../store/constants';
import { makeSelectUser } from '../store/selectors/auth.selector';

import releaseToggles from '../releaseToggles';
import { getRefreshToken } from './refreshToken';

/**
 * Place this hook at the root of the app.
 * On startup, it checks whether we've been redirected from our Cognito SSO page.
 * If so, it sets the refresh token in the browser cookies and clears it from the URL.
 */
export default function useCognitoSSO() {
  const { search, pathname } = useLocation();
  const user = useSelector(makeSelectUser);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!releaseToggles.cognitoAuthentication) return;
    if (isEmpty(user)) dispatch({ type: GET_DISPLAY_USER_STARTED });
  }, [dispatch, user]);

  useEffect(() => {
    if (!releaseToggles.cognitoAuthentication || !search) return;
    const token = getRefreshToken();
    if (token) {
      history.push({ pathname, search: '' });
    }
  }, [search, history, pathname]);
}
