import React from 'react';

import { storiesOf } from '@storybook/react';

import ThemeSelector from './index';

const defaultProps = {
  themes: {
    Alpima: {},
    BlueBay: {},
  },
  selectTheme: () => {},
};

storiesOf('ThemeSelector', module)
  .addDecorator(story => <div style={{ margin: '50px', width: '450px' }}>{story()}</div>)
  .add('default', () => <ThemeSelector {...defaultProps} />);
