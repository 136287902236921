export const STRATEGY_EXPORT_REQUEST = 'strategy/EXPORT_ATTRIBUTES_REQUEST';
export const STRATEGY_EXPORT_SUCCESS = 'strategy/EXPORT_ATTRIBUTES_SUCCESS';
export const STRATEGY_EXPORT_FAILURE = 'strategy/EXPORT_ATTRIBUTES_FAILURE';
export const STRATEGY_EXPORT_CLEAR = 'strategy/EXPORT_ATTRIBUTES_CLEAR';

export const STRATEGY_CALCULATION_REQUEST = 'strategy/CALCULATION_REQUEST';
export const STRATEGY_CALCULATION_SUCCESS = 'strategy/CALCULATION_SUCCESS';
export const STRATEGY_CALCULATION_FAILURE = 'strategy/CALCULATION_FAILURE';
export const STRATEGY_CALCULATION_CLEAR = 'strategy/CALCULATION_CLEAR';

export const GET_STRATEGY_LIST = 'GET_STRATEGY_LIST';
export const GET_STRATEGY_LIST_SUCCESS = 'GET_STRATEGY_LIST_SUCCESS';
export const GET_STRATEGY_LIST_FAILURE = 'GET_STRATEGY_LIST_FAILURE';

export const SET_STRATEGY_SEARCH_RESULTS = 'SET_STRATEGY_SEARCH_RESULTS';
export const CLEAR_STRATEGY_SEARCH_RESULTS = 'CLEAR_STRATEGY_SEARCH_RESULTS';

export const GET_STRATEGY_DETAILS = 'GET_STRATEGY_DETAILS';
export const GET_STRATEGY_DETAILS_SUCCESS = 'GET_STRATEGY_DETAILS_SUCCESS';
export const GET_STRATEGY_DETAILS_FAILURE = 'GET_STRATEGY_DETAILS_FAILURE';

export const SET_STRATEGY_DETAILS = 'SET_STRATEGY_DETAILS';
export const SET_STRATEGY_DETAILS_CANCEL = 'SET_STRATEGY_DETAILS_CANCEL';
export const SET_STRATEGY_DETAILS_FAILURE = 'SET_STRATEGY_DETAILS_FAILURE';
export const SET_STRATEGY_DETAILS_SUCCESS = 'SET_STRATEGY_DETAILS_SUCCESS';

export const UPDATE_STRATEGY_DETAILS = 'UPDATE_STRATEGY_DETAILS';
export const UPDATE_STRATEGY_DETAILS_SUCCESS = 'UPDATE_STRATEGY_DETAILS_SUCCESS';
