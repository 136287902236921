import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { setMonitoringLoginChartType } from '../../../store/actions/monitoring.actions';

const redirectInfo = {
  aggregate: {
    newChartType: 'aggregate',
    newHistoryEntry: '/monitoring/logins',
  },
  'contract-roles': {
    newChartType: 'contract',
    newHistoryEntry: '/monitoring/logins/contract-roles',
  },
  'function-roles': {
    newChartType: 'function',
    newHistoryEntry: '/monitoring/logins/function-roles',
  },
};

export default function useMonitoringRedirect() {
  const dispatch = useDispatch();
  const history = useHistory();
  return useCallback(
    nextPage => {
      const { newChartType, newHistoryEntry } = redirectInfo[nextPage];
      dispatch(setMonitoringLoginChartType(newChartType));
      history.push(newHistoryEntry);
    },
    [dispatch, history],
  );
}
