import React from 'react';
import styled from 'styled-components';

const DivHeader = styled.div`
  color: ${props => props.theme.variables.widgetDefaultHeaderBackground || '#203A4C'};
  border-bottom: 1px solid
    ${props => props.theme.variables.widgetDefaultHeaderBackground || '#203A4C'};
  font-family: 'Open Sans', sans-serif !important;
  padding: 8px 10px;
  border-top-right-radius: 1px;
  border-top-left-radius: 1px;
  text-transform: uppercase;
  overflow: auto;
`;

// eslint-disable-next-line react/prop-types
const WidgetDefaultHeader = ({ children }) => <DivHeader>{children}</DivHeader>;

export default WidgetDefaultHeader;
