import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

export const OptionSeparator = styled.div`
  display: inline-block;
  width: 1px;
  height: 25px;
  border-right: solid 1px #333;
  border-radius: 0;
  margin: 0 10px 3px;
  vertical-align: middle;
`;

export const ChartLink = styled(Button)`
  padding: 0 15px 0 !important;
  border-right: solid 1px #999 !important;
  color: black !important;
  font-weight: normal !important;
  background: transparent !important;
  border-radius: 0 !important;

  &:first-child {
    padding-left: 0 !important;
  }

  &:last-child {
    border-right: none !important;
  }

  &.active {
    color: ${props => props.theme.variables.primary} !important;
  }
`;

export const Controls = styled.div`
  margin-top: 10px;
  margin-left: 30px;
`;
