import React from 'react';
import { Button, Icon, Icons } from '@alpima/picasso';
import { Cross, Tick } from '../../Widgets/Steps';
import useModalListeners from './useModalListeners';
import { AlertStages, StrategiesAlertsMonitoring } from '../../StrategyAlerts.types';
import { capitalizeFirstLetter } from '../../../DataMonitoringDashboard/helper';

const mappedStages: Record<string, string> = {
  data_acquisition: 'Data Acquisition',
  data_validation: 'Data Validation',
  data_processing: 'Data Processing',
  model_validation: 'Model Validation',
};

export const getMappedStage = (stageName: string): string => {
  const stage = mappedStages[stageName];
  return (
    stage ||
    stageName
      .split('_')
      .map(s => capitalizeFirstLetter(s))
      .join(' ')
  );
};

function getStageDescription(step: AlertStages) {
  return `${getMappedStage(step.stage)} ${
    step.status === 'failed' ? 'failed.' : `completed successfully in ${step.duration} seconds.`
  }`;
}

export default function DetailsModal({
  close,
  data,
}: {
  close: () => void;
  data: StrategiesAlertsMonitoring;
}) {
  useModalListeners(close);

  const { dagId, dagRunId, duration, endedAt, error, severity, failedStageIndex } = data;
  const errorLogData = { dagId, dagRunId, duration, severity, failedStageIndex, endedAt, error };

  return (
    <>
      <div
        id="background"
        role="button"
        tabIndex={0}
        onKeyPress={close}
        className="absolute cursor-default w-screen h-screen top-0 left-0 z-10 bg-none faded-background pointer-events-auto"
        onClick={close}
      />
      <div className="bg-transparent absolute top-0 left-0 w-screen h-screen pointer-events-none flex items-center justify-center z-20">
        <div className="bg-white p-10 rounded-xl shadow-xl cursor-auto max-w-screen-md pop-in pointer-events-auto max-h-screen overflow-auto">
          <Button
            className="absolute top-0 right-0 border-0 pr-12 pt-12 hover:opacity-50"
            onClick={close}
          >
            <Icon name={Icons.CLOSE} />
            <span className="sr-only">Close</span>
          </Button>
          <h1 className="mb-8">{dagId}</h1>
          <div>
            <ul className="space-y-6">
              {data.stages.map((step, index) => (
                <li key={index}>
                  <h2 className="text-2xl flex items-center gap-3 mb-0">
                    {step.status === 'failed' ? (
                      <div className="bg-red-400 w-10 h-10 rounded-full text-white inline-flex items-center justify-center">
                        <Cross className="w-7 h-7" />
                      </div>
                    ) : (
                      <div className="bg-green-400 w-10 h-10 rounded-full text-white inline-flex items-center justify-center">
                        <Tick className="w-7 h-7" />
                      </div>
                    )}
                    {getMappedStage(step.stage)}
                  </h2>
                  <p className="ml-10 pl-3">{getStageDescription(step) || 'There was an error'}</p>
                </li>
              ))}
            </ul>
            <span className="block mt-3 mb-2 font-bold text-lg">Error logs:</span>
            <code className="bg-gray-600 rounded-lg block text-gray-50 p-4 overflow-auto leading-7">
              {JSON.stringify(errorLogData)}
            </code>
          </div>
        </div>
      </div>
    </>
  );
}
