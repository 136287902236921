import React from 'react';
import StoryRouter from 'storybook-react-router';
import { storiesOf } from '@storybook/react';

import ToolbarDefault from './index';

const defaultProps = {
  actions: [
    {
      label: 'Export',
      icon: 'file code',
    },
    {
      label: 'Compare',
      icon: 'columns',
    },
  ],
};

storiesOf('ToolbarDefault', module)
  .addDecorator(StoryRouter())
  .add('default', () => <ToolbarDefault {...defaultProps} />)
  .add('floated right', () => <ToolbarDefault {...defaultProps} floated="right" />);
