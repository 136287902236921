/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DivWrapper = styled.div`
    margin-bottom: 20px;
    background-color: ${props => (props.background ? props.background : '#fff')}
    border: ${props => (props.noBorder ? '1px none transparent' : '1px solid #ccc')}
    border-radius: 2px;
    box-shadow: ${props => (props.noBorder ? '' : '0 1px 1px rgba(0, 0, 0, 0.05)')}
    border-color: ${props => props.theme.variables.widgetDefaultBorderColor || '#dddddd'};
`;

const WidgetDefaultWrapper = ({ children, noBorder, background }) => (
  <DivWrapper noBorder={noBorder} background={background}>
    {children}
  </DivWrapper>
);

WidgetDefaultWrapper.defaultProps = {
  noBorder: true,
  background: '',
};

WidgetDefaultWrapper.propTypes = {
  noBorder: PropTypes.bool,
  background: PropTypes.string,
};

export default WidgetDefaultWrapper;
