import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Icon = styled.i`
  margin-right: 8px;
`;

export default function StatusDate({ date, success, children }) {
  const dateFormatted = moment(date).format('DD.MM.YYYY HH:mm:ss');
  return (
    <div className="item">
      {success ? (
        <div className="ui green message">
          <Icon className="icon check circle" />
          {dateFormatted}
          {children ? <div>{children}</div> : null}
        </div>
      ) : (
        <div className="ui red message">
          <Icon className="icon exclamation circle" />
          {dateFormatted}
          {children ? <div>{children}</div> : null}
        </div>
      )}
    </div>
  );
}

StatusDate.propTypes = {
  children: PropTypes.node,
  date: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
};

StatusDate.defaultProps = {
  children: null,
};
