import Cookies from 'js-cookie';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  filter,
  first,
  map,
  pluck,
  switchMap,
  takeUntil,
  takeWhile,
} from 'rxjs/operators';
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { merge } from 'lodash';
import { removeCookie } from '../../../helpers/cookie.helper';
import { accessTokenState$, addToken, isToken, requestNewToken } from './accessToken';
import { redirectToSSO } from './singleSignOn';
import releaseToggles from '../../../releaseToggles';

type Method = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const useCognito = releaseToggles.cognitoAuthentication;

// Set the content-type to JSON by default if not otherwise specified.
const requestDefaults = {
  headers: {
    'Content-Type': 'application/json',
  },
};

/**
 * Take an unauthenticated request and return an Observable which delivers an authenticated request.
 * This will be implemented differently for Cognito and legacy authentication.
 * @param request An unauthenticated request.
 * @returns An observable which delivers the request back, with authentication headers, then completes.
 */
function authenticateRequest$(request: AjaxRequest): Observable<AjaxRequest> {
  // Legacy non-refreshable token.
  if (!useCognito) {
    const token = Cookies.get('alp-auth') || '';
    return of(addToken(request, token));
  }

  // Cognito authentication.
  const tokenState = accessTokenState$.getValue();

  if (isToken(tokenState)) {
    return of(addToken(request, tokenState.validToken));
  }

  // If we don't have a token already, trigger a request, and set the token state to loading.
  if (tokenState === 'NoToken') requestNewToken();

  // Here, we know that the token is in the loading state.
  // As soon as a token is loaded, deliver our subscriber a request with it attached.
  return accessTokenState$.pipe(
    first(isToken),
    map(token => addToken(request, token.validToken)),
  );
}

/**
 * Make an API call given a request, and optionally retry if the request
 * returns a 401.
 * @param request A specification for the request to send, including URL, method, body etc.
 * @param retryAuth Whether to retry authentication. This defaults to whether Cognito auth
 *                  is enabled, as refreshing authentication tokens is an important part of
 *                  the Cognito auth process.
 */
export function apiCallWithRequest$(
  request: AjaxRequest,
  retryAuth = useCognito,
): Observable<AjaxResponse> {
  const requestWithDefaults = merge({}, requestDefaults, request);
  return authenticateRequest$(requestWithDefaults).pipe(
    switchMap(authenticatedRequest => ajax(authenticatedRequest)),
    catchError(handleOutdatedAccessToken(request, retryAuth)),
  );
}

/**
 * Make an API call given a URL, method and body.
 * @param url The resource to request.
 * @param method Method for the API call; defaults to GET.
 * @param body Optionally set a body for the request.
 * @template ResponseType Optionally cast the response object to a given type.
 */
export function apiCall$<ResponseType = any>(
  url: string,
  method: Method = 'GET',
  body?: AjaxRequest['body'],
): Observable<ResponseType> {
  return apiCallWithRequest$({ url, method, body }).pipe(pluck('response'));
}

/**
 * Create an error-handler which deals with outdated access tokens (i.e. 401 responses).
 * This should be suitable for passing to `catchError`.
 * @param request The original request, without authentication.
 * @param retryAuth Whether to reauthenticate and retry the request.
 * @returns Error handler to pass into `catchError`.
 */
function handleOutdatedAccessToken(request: AjaxRequest, retryAuth: boolean) {
  return (err: AjaxResponse) => {
    // Non-401 errors should be propagated immediately, without a retry.
    if (err.status !== 401) {
      return throwError(err);
    }

    if (!useCognito) {
      removeCookie('alp-auth');
      removeCookie('display-user');
      window.location.href = '/login?sessionTimeout=1';
      // Explicit return needed here because Jest just prints a warning on navigation.
      return EMPTY;
    }

    if (!retryAuth) {
      redirectToSSO();
      return EMPTY;
    }

    if (accessTokenState$.getValue() !== 'TokenLoading') {
      requestNewToken();
    }
    return apiCallWithRequest$(request, false);
  };
}
