import React from 'react';
import { storiesOf } from '@storybook/react';

import SmartFilter from './index';

const defaultProps = {
  options: [
    { key: 'emerging-markets', text: 'Emerging Markets', value: 'emerging-markets' },
    { key: 'multi-asset-credit', text: 'Multi-Asset Credit', value: 'multi-asset-credit' },
    { key: 'convertibles', text: 'Convertibles', value: 'convertibles' },
    { key: 'investment-grade', text: 'Investment Grade', value: 'investment-grade' },
  ],
  filter: {
    value: ['emerging-markets', 'investment-grade'],
    query: 'query...',
  },
};

storiesOf('SmartFilter', module)
  .addDecorator(story => <div style={{ margin: '50px', maxWidth: '500px' }}>{story()}</div>)
  .add('default', () => <SmartFilter {...defaultProps} />);
