import { createSelector } from 'reselect';

const selectCompanies = state => state.get('companies');

const selectCompaniesList = createSelector(selectCompanies, companies => companies.get('list'));

const selectCompaniesLoader = createSelector(selectCompanies, companies =>
  companies.get('loading'),
);

export { selectCompaniesList, selectCompaniesLoader };
