// eslint-disable-next-line import/prefer-default-export
export const columns = {
  default: [
    {
      getLabel: () => 'Unique Identifier',
      name: 'uid',
      type: 'string',
      align: 'left',
      width: 3,
      resolver: item => (item && item.uid) || ' - ',
    },
    {
      getLabel: () => '',
      name: 'isSTAA',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item =>
        (item && item.isSTAA
          ? 'Standard'
          : ['saa', 'taa', 'mopo'].includes(item.portfolioType)
          ? 'Derived'
          : '-') || '-',
    },
    {
      getLabel: () => 'Product Line',
      name: 'productLine',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.productLine) || '-',
    },
    {
      getLabel: () => 'Type',
      name: 'portfolioType',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.portfolioType.toUpperCase()) || '-',
    },
    {
      getLabel: () => 'Created',
      name: 'created',
      type: 'date',
      align: 'left',
      width: 1,
      resolver: item => (item && item.created) || '-',
    },
  ],
};
