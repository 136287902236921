import React, { useState } from 'react';
import { Button, ButtonTypes, Icons, Popover } from '@alpima/picasso';

export type DisplayColumnsProps = {
  toggleHeaderVisible: (key: string) => void;
  columns: { key: string; name: string }[];
  visibleColumns: string[];
};

export default function DisplayColumns({
  toggleHeaderVisible,
  columns,
  visibleColumns,
}: DisplayColumnsProps) {
  const [showDisplayPopup, setShowDisplayPopup] = useState(false);
  return (
    <div className="relative">
      <div>
        <Button
          type={ButtonTypes.IconLeft}
          icon={Icons.SETTINGS}
          onClick={() => setShowDisplayPopup(!showDisplayPopup)}
          className={`font-bold text-sm border-none ${showDisplayPopup ? 'text-primary-main' : ''}`}
        >
          Display
        </Button>
        <div>
          {showDisplayPopup && (
            <Popover
              hidePopover={() => setShowDisplayPopup(!showDisplayPopup)}
              classNames="text-black flex flex-col pt-4 right-2 pb-2 px-8 shadow-lg whitespace-nowrap"
            >
              {columns.map(el => (
                <label key={el.key as string} htmlFor={el.key as string}>
                  <input
                    className="mr-2 mb-2"
                    id={el.key as string}
                    type="checkbox"
                    value={visibleColumns.includes(el.key) ? 'checked' : ''}
                    checked={visibleColumns.includes(el.key)}
                    onChange={() => {
                      toggleHeaderVisible(el.key);
                    }}
                    name={el.name}
                  />
                  {el.name}
                </label>
              ))}
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
}
