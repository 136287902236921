import { Times } from '../shared.types';
import { getUTCHours, mapTimeToTimePeriod } from '../shared.utils';
import {
  BreakdownByCategory,
  ParsedStrategiesDashboardMonitoring,
  StrategiesDashboardMonitoring,
} from './DataMonitoringDashboard.types';

export function parseDashboardData(
  data: StrategiesDashboardMonitoring,
): ParsedStrategiesDashboardMonitoring {
  const parsedTimePeriods = data.alertsByTime.map(i => ({
    ...i,
    time: getUTCHours(i.time) as Times,
    formattedTime: mapTimeToTimePeriod(getUTCHours(i.time) as Times),
  }));

  const parsedBreakdownByCategory: BreakdownByCategory = Object.keys(
    data.availableStrategies.breakdownByCategory,
  ).reduce((accumulator, key) => {
    const parsedKey = getUTCHours(key) as Times;
    return {
      ...accumulator,
      [parsedKey]: data.availableStrategies.breakdownByCategory[key],
    };
  }, {});

  const parsedStrategiesByTime = data.availableStrategies.strategiesByTime.map(i => ({
    ...i,
    time: getUTCHours(i.time) as Times,
  }));

  return {
    ...data,
    alertsByTime: parsedTimePeriods,
    availableStrategies: {
      ...data.availableStrategies,
      breakdownByCategory: parsedBreakdownByCategory,
      strategiesByTime: parsedStrategiesByTime,
    },
    countedRunningAlerts: data.countedRunningAlerts,
  };
}
