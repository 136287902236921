import React, { ChangeEvent } from 'react';
import { Dropdown, DropdownSize } from '@alpima/picasso';
import { UrlUpdateType } from 'use-query-params';
import { timeOptions } from '../helpers/timeOptions';
import { TimePeriods } from '../../shared.types';

type TimeDropdownProps = {
  time: TimePeriods;
  // Todo: this isn't good encapsulation since it requires react-query-params.
  setTime: (newTime: TimePeriods, format: UrlUpdateType) => void;
};

export default function TimeDropdown({ time, setTime }: TimeDropdownProps) {
  return (
    <Dropdown
      options={timeOptions}
      selectedOption={time.toString()}
      onChange={(e: ChangeEvent<HTMLSelectElement>) =>
        setTime(e.currentTarget.value as TimePeriods, 'replaceIn')
      }
      customSize={DropdownSize.MEDIUM}
      selectClassName="w-32"
      wrapperClassName="w-32 flex items-center"
    />
  );
}
