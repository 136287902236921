import { Loader } from 'semantic-ui-react';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const LoadingBoundary = props => {
  const { isLoading, children } = props;
  if (!isLoading) return children;

  return (
    <Fragment>
      <div style={{ minHeight: '850px', background: '#F6F8FA' }}>
        <Loader active />
      </div>
    </Fragment>
  );
};

LoadingBoundary.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.object.isRequired,
};

export default LoadingBoundary;
