import _ from 'lodash';
import moment from 'moment';

import Highcharts from 'highcharts';

const oneYearActualPeriod = 'one_year_actual';

function round(value, decimals) {
  return Number((value * 100).toFixed(decimals));
}

function getRangeSelectorButtons() {
  const buttons = [
    {
      type: 'month',
      count: 1,
      text: '1m',
      preventDefault: true,
      performancePeriod: 'one_month_actual',
    },
    {
      type: 'month',
      count: 3,
      text: '3m',
      preventDefault: true,
      performancePeriod: 'three_months_actual',
    },
    {
      type: 'month',
      count: 6,
      text: '6m',
      preventDefault: true,
      performancePeriod: 'six_months_actual',
    },
    {
      type: 'ytd',
      text: 'YTD',
    },
    {
      type: 'year',
      count: 1,
      text: '1y',
      preventDefault: true,
      performancePeriod: oneYearActualPeriod,
    },
    {
      type: 'all',
      text: 'All',
    },
  ];

  return buttons;
}

export const setDefaultColourScheme = () => {
  Highcharts.setOptions({
    colors: [
      '#001489',
      '#007FA3',
      '#6E2B62',
      '#008675',
      '#971B2F',
      '#CC8A00',
      '#717C7D',
      '#7B6469',
      '#3399B5',
      '#8B5581',
      '#339E91',
      '#AC4959',
      '#D6A133',
      '#8D9697',
      '#958387',
      '#66B2C8',
      '#A880A1',
      '#66B6AC',
      '#C17682',
      '#E0B966',
      '#AAB0B1',
      '#B0A2A5',
      '#99CCDA',
      '#C5AAC0',
      '#99CFC8',
      '#D5A4AC',
      '#EBD099',
      '#C6CBCB',
      '#CAC1C3',
      '#CCE5ED',
      '#E2D5E0',
      '#CCE7E3',
      '#EAD1D5',
      '#F5E8CC',
      '#E3E5E5',
      '#E5E0E1',

      // '#02997E',
      // '#0059BF',
      // '#4000BF',
      // '#9200BF',
      // '#BF0073',
      // '#99005C',
      // '#740099',
      // '#320099',
      // '#004799',
      // '#00735E',
      // '#00CCA7',
      // '#0060CC',
      // '#4300CC',
      // '#9C00CC',
      // '#CC007A',
      // '#A5E604',
      // '#5B8002',
      // '#730045',
      // '#760099',
      // '#260073',
      // '#003573',
      // '#00BF9D',
      // '#4DFFDE',
      // '#4DA0FF',
      // '#884DFF',
      // '#D54DFF',
      // '#FF4DB7',
      // '#D3FF66',
      // '#DEFF8D',
      // '#FF8CD1',
      // '#E48CFF',
      // '#B28CFF',
      // '#8CC2FF',
      // '#8CFFE9',
      // '#F1FFCC',
      // '#CCB3FF',
      // '#EDB3FF',
      // '#B3D6FF',
      // '#F5ABD8'
    ],
  });
};

export const getDefaultStockChartOptions = () => {
  const defaultDateFormat = '%b %e, %Y';

  function getMinMaxRange() {
    // let period = performancePeriods[periodKey];
    // if (period) {
    //     return {
    //         min: (new Date(period.start_date)).getTime(),
    //         max: (new Date(period.end_date)).getTime()
    //     };
    // }
    return null;
  }

  const chartConfig = {
    chart: {
      height: 500,
      animation: false,
      events: {
        load() {
          const rangeSelectorButtons = getRangeSelectorButtons();
          _.forEach(rangeSelectorButtons, button => {
            if (button.performancePeriod) {
              const range = getMinMaxRange(button.performancePeriod);
              if (!range || !range.min) {
                // e.target.rangeSelector.buttons[index].setState(buttonStates.disabled);
              }
            }
          });
        },
      },
    },
    title: {
      text: '',
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      useHTML: true,
      y: 15,
    },
    navigator: {
      enabled: true,
      series: {
        type: 'areaspline',
        color: '#4572A7',
        fillOpacity: 0.05,
        dataGrouping: {
          smoothed: true,
        },
        lineWidth: 1,
        marker: {
          enabled: false,
        },
        data: [],
      },
    },
    rangeSelector: {
      selected: 5,
      enabled: true,
      // enabled: canZoom,
      inputEnabled: true,
      buttons: [
        {
          type: 'month',
          count: 1,
          text: '1m',
        },
        {
          type: 'month',
          count: 3,
          text: '3m',
        },
        {
          type: 'month',
          count: 6,
          text: '6m',
        },
        {
          type: 'ytd',
          text: 'YTD',
        },
        {
          type: 'year',
          count: 1,
          text: '1y',
        },
        {
          type: 'all',
          text: 'All',
        },
      ],
    },
    credits: {
      enabled: false,
    },
    yAxis: {
      labels: {
        formatter() {
          return `${(this.value > 0 ? ' + ' : '') + this.value}%`;
        },
      },
      plotLines: [
        {
          value: 0,
          width: 2,
          color: 'silver',
        },
      ],
    },
    plotOptions: {
      series: {
        compare: 'percent',
        compareBase: 0,
        marker: { enabled: false },
      },
    },
    tooltip: {
      dateTimeLabelFormats: {
        millisecond: defaultDateFormat,
        second: defaultDateFormat,
        minute: defaultDateFormat,
        hour: defaultDateFormat,
        day: defaultDateFormat,
        week: defaultDateFormat,
        month: defaultDateFormat,
        year: defaultDateFormat,
      },
      headerFormat: '<strong>{point.key}<strong><br/>',
      formatter() {
        let tooltipText = '';
        let newValue;
        const point = this;
        const sName = this.point.series.name;
        const { chart } = this.series;
        const { points } = chart.series.find(item => item.name === sName);

        const { index } = this.point;
        const current = points.find(item => item.index === index);
        if (current) {
          newValue = current.change;
        }

        if (this.point && newValue) {
          tooltipText = `<b>${new Date(point.x).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}</b><br/>`;

          tooltipText += `<span style="color:${point.series.color}">${
            point.series.name
          }</span>: <b>${newValue.toFixed(2)}% </b><br/>`;
        } else {
          tooltipText = `<b>${new Date(this.x).toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })}</b><br/>`;
        }

        return tooltipText;
      },
      valueDecimals: 2,
    },
    xAxis: {
      type: 'datetime',
    },
    series: [],
  };

  return chartConfig;
};

export const getDefaultPieChartOptions = (n, inner) => ({
  chart: {
    type: 'pie',
    height: 300 + n * 25,
  },
  legend: {
    // layout: 'vertical',
    y: 7,
    itemStyle: {
      // width: '300px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  tooltip: {
    formatter() {
      return (
        `<strong>${this.key}</strong><br/>` +
        `<span>${(Math.round(this.percentage * 100) / 100).toFixed(1)}  %</span>`
      );
    },
    headerFormat: '<strong>{point.key}<strong><br/>',
    pointFormat: '{series.name}: <strong>{point.percentage:.2f}%</strong>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        distance: -28,
        formatter() {
          return `${(Math.round(this.percentage * 100) / 100).toFixed(1)} %`;
        },
        style: {
          fontWeight: 'bold',
          color: 'white',
          textShadow: '0px 1px 2px black',
        },
      },
      showInLegend: true,
      innerSize: inner || '40%',
      size: '300px',
    },
  },
  series: [],
});

export const getDefaultBarChartOptions = (
  name,
  series,
  hasShortPositions,
  totalLength,
  largeStrategy,
) => {
  const defaultDateFormat = '%b %e, %Y';
  const canZoom = true;
  const animation = totalLength <= 2000;
  const useBoost = totalLength > 20000;

  const tickPositions = hasShortPositions
    ? undefined
    : [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0001];

  return {
    chart: {
      description: 'historical',
      marginTop: 35,
      zoomType: canZoom ? 'xy' : '',
      backgroundColor: '#FFF',
      // width: 1108,
      animation,
      // events: largeStrategy ? undefined : {
      //     load: function (e) {
      //         setChartHeight(e.target);
      //     },
      //     redraw: function (e) {
      //         setChartHeight(e.target);
      //     }
      // }
    },
    boost: {
      enabled: useBoost,
      useGPUTranslations: true,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          y: -10,
        },
      },
      filename: `${name} - historical-allocation-chart`,
    },
    title: {
      text: null,
    },
    navigator: {
      enabled: false,
    },
    rangeSelector: {
      selected: 5,
      enabled: true,
      // enabled: canZoom,
      inputEnabled: canZoom,
      buttons: [
        {
          type: 'month',
          count: 1,
          text: '1m',
        },
        {
          type: 'month',
          count: 3,
          text: '3m',
        },
        {
          type: 'month',
          count: 6,
          text: '6m',
        },
        {
          type: 'ytd',
          text: 'YTD',
        },
        {
          type: 'year',
          count: 1,
          text: '1y',
        },
        {
          type: 'all',
          text: 'All',
        },
      ],
    },
    tooltip: {
      dateTimeLabelFormats: {
        millisecond: defaultDateFormat,
        second: defaultDateFormat,
        minute: defaultDateFormat,
        hour: defaultDateFormat,
        day: defaultDateFormat,
        week: defaultDateFormat,
        month: defaultDateFormat,
        year: defaultDateFormat,
      },
      headerFormat: '<strong>{point.key}<strong><br/>',
      pointFormatter() {
        return `<span style="color:${this.series.color}">${this.series.name}</span>: <b>${
          Math.round(this.y * 10000) / 100
        }%</b><br/>`;
      },
      shared: true,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        formatter() {
          const defaultFormat = this.axis.defaultLabelFormatter.call(this);
          if (defaultFormat.indexOf(':') > -1) {
            // hh:mm:ss
            return moment(this.value).format('D. MMM');
          }
          return defaultFormat;
        },
      },
      title: {
        enabled: false,
      },
    },
    yAxis: {
      labels: {
        formatter() {
          return `${Math.floor(this.value * 100)}%`;
        },
      },
      title: {
        enabled: false,
      },
      resize: {
        enabled: true,
      },
      tickPositions,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        lineColor: '#ffffff',
        lineWidth: 0, // $scope.largeStrategy ? 0 : 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      area: {
        stacking: 'normal',
        step: useBoost ? undefined : 'left',
        lineColor: '#ffffff',
        lineWidth: largeStrategy ? 0 : 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series,
  };
};

export const getContributionDefaultOptions = series => {
  const monthlyDateFormat = '%b %Y';

  const monthlyTooltipFormat = {
    millisecond: monthlyDateFormat,
    second: monthlyDateFormat,
    minute: monthlyDateFormat,
    hour: monthlyDateFormat,
    day: monthlyDateFormat,
    week: monthlyDateFormat,
    month: monthlyDateFormat,
    year: monthlyDateFormat,
  };

  return {
    chart: {
      renderTo: 'perf-attribution-chart',
      type: 'column',
      zoomType: 'xy',
      height: 650,
      // events: {
      //     load: function (e) {
      //         setChartHeight(e.target);
      //     },
      //     redraw: function (e) {
      //         setChartHeight(e.target);
      //     }
      // }
    },
    boost: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      filename: ' - performance-attribution-chart',
    },
    navigator: {
      enabled: false,
    },
    title: {
      text: null,
    },
    scrollbar: {
      enabled: false,
    },
    legend: {
      enabled: true,
      //     labelFormatter: function () {
      //         return series.name;
    },
    // },
    rangeSelector: {
      enabled: true,
      inputEnabled: false,
      buttons: [
        {
          type: 'month',
          count: 1,
          text: '1m',
        },
        {
          type: 'month',
          count: 3,
          text: '3m',
        },
        {
          type: 'month',
          count: 6,
          text: '6m',
        },
        {
          type: 'ytd',
          text: 'YTD',
        },
        {
          type: 'year',
          count: 1,
          text: '1y',
        },
        {
          type: 'all',
          text: 'All',
        },
      ],
      selected: 6,
    },
    tooltip: {
      dateTimeLabelFormats: monthlyTooltipFormat,
      headerFormat: '<strong>{point.key}<strong><br/>',
      pointFormatter() {
        const label = this.series.name;
        return `<span style="color:${this.series.color}">${label}</span>: <b>${round(
          this.y,
          3,
        )}%</b><br/>`;
      },
      shared: false,
      split: false,
    },
    xAxis: {
      type: 'datetime',
      // labels: xLabel,
      title: {
        enabled: false,
      },
      // events: {
      //     setExtremes: function (e) {
      //         setPerformanceChartExtremes(e);
      //     },
      //     afterSetExtremes: function (e) {
      //         let startDate = null;
      //         let endDate = null;
      //         let showingFullChart = (e.min === e.dataMin && e.max === e.dataMax);
      //         if (!showingFullChart) {
      //             startDate = e.min || null;
      //             endDate = e.max || null;
      //         }
      //         data.loadContribution({
      //             chart: false,
      //             table: true,
      //             startDate: startDate,
      //             endDate: endDate
      //         });
      //     }
      // }
    },
    yAxis: {
      labels: {
        formatter() {
          return `${round(this.value, 3)}%`;
        },
        align: 'left',
      },
      title: {
        enabled: false,
      },
    },
    plotOptions: {
      column: {
        stacking: 'percentage',
        lineColor: '#ffffff',
        lineWidth: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    series,
  };
};

export const getHeatmapChartOptions = (strategy, securities, series) => ({
  chart: {
    height: 540,
    type: 'heatmap',
    marginTop: 40,
    paddingBottom: 20,
    plotBorderWidth: 0,
    marginLeft: null,
    spacingLeft: 10,
  },
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        y: -10,
      },
    },
    filename: `${strategy ? `${strategy.name} - ` : ''}correlation-heatmap-chart`,
  },
  title: {
    text: null,
  },

  xAxis: {
    categories: securities,
    labels: {
      formatter() {
        if (!this.value || !this.value.name) {
          return;
        }

        // eslint-disable-next-line consistent-return
        return this.value.identifier;
      },
    },
    minPadding: 10,
    maxPadding: 10,
    endOnTick: false,
    startOnTick: false,
  },

  yAxis: {
    categories: securities,
    labels: {
      formatter() {
        if (!this.value || !this.value.name) {
          return;
        }

        // eslint-disable-next-line consistent-return
        return this.value.identifier;
      },
    },
    title: null,
    minPadding: 0,
    maxPadding: 0,
  },
  colorAxis: {
    min: -1,
    max: 1,
    stops: [
      [0, '#0026FF'],
      [0.5, '#FFEE00'],
      [1, '#CD0000'],
    ],
  },

  legend: {
    align: 'right',
    layout: 'vertical',
    margin: 0,
    verticalAlign: 'top',
    y: 25,
    symbolHeight: 360,
  },

  credits: {
    enabled: false,
  },

  tooltip: {
    formatter() {
      let xLabel = '';
      let yLabel = '';

      if (this.series.xAxis.categories[this.point.x].name === 'Brick') {
        xLabel =
          `(${this.series.xAxis.categories[this.point.x].name}) ` +
          `<b>${this.series.xAxis.categories[this.point.x].identifier}</b>`;
      } else {
        xLabel =
          `(${this.series.xAxis.categories[this.point.x].identifier}) ` +
          `<b>${this.series.xAxis.categories[this.point.x].name}</b>`;
      }

      if (this.series.yAxis.categories[this.point.y].name === 'Brick') {
        yLabel =
          `(${this.series.yAxis.categories[this.point.y].name}) ` +
          `<b>${this.series.yAxis.categories[this.point.y].identifier}</b>`;
      } else {
        yLabel =
          `(${this.series.xAxis.categories[this.point.y].identifier}) ` +
          `<b>${this.series.yAxis.categories[this.point.y].name}</b>`;
      }

      // eslint-disable-next-line no-useless-concat
      return `${xLabel}<br/>${yLabel}<br/>` + `Correlation: <b>${this.point.value}</b>`;
    },
  },

  series: [
    {
      type: 'heatmap',
      turboThreshold: 10000,
      grid_width: 25,
      name: 'heat map',
      data: series,
      dataLabels: {
        enabled: securities.length <= 20,
        style: {
          fontWeight: 'normal',
          textOutline: '0px',
          color: '#000',
        },
      },
    },
  ],
});

export const getHistogramOptions = (strategyName, seriesData, monthly) => ({
  chart: {
    height: 500,
    marginTop: 25,
    zoomType: 'xy',
  },
  plotOptions: {
    histogram: {
      tooltip: {
        // pointFormatter: function () {
        //     return '<span style="font-size: 10px"></span>Range <b>' + Math.round(this.x * 100) / 100 + '% </b>
        // to  <b>' + Math.round(this.x2 * 100) / 100 + '%</b>
        // <br/><span style="color:' + this.color + '></span>Frequency <b>' + this.y + '</b><br/>';
        // }
      },
    },
  },

  xAxis: [
    {
      // labels: {
      //     formatter: function () {
      //         var defaultFormat = this.axis.defaultLabelFormatter.call(this);
      //         if (defaultFormat.indexOf(':') !== -1) { // hh:mm:ss
      //             return moment(this.value).format('YYYY');
      //         }
      //         return defaultFormat;
      //     }
      // },
      type: 'datetime',
      title: { text: '' },
      alignTicks: false,
      opposite: true,
    },
    {
      title: { text: 'Strategy Returns (%)' },
      alignTicks: false,
    },
  ],

  yAxis: [
    {
      title: { text: '' },
    },
    {
      title: { text: 'Frequency' },
      opposite: true,
    },
  ],
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: true,
    buttons: {
      contextButton: {
        y: -10,
      },
    },
    filename: `${strategyName} - diversification-ratio-chart`,
  },
  title: {
    text: '',
  },
  legend: {
    enabled: true,
  },
  series: [
    {
      name: 'Histogram',
      type: 'histogram',
      xAxis: 1,
      yAxis: 1,
      baseSeries: 's1',
      zIndex: -1,
    },
    {
      name: 'Strategy Returns',
      type: 'scatter',
      data: seriesData,
      id: 's1',
      visible: false,
      tooltip: {
        pointFormatter() {
          return `<span style="font-size: 10px"></span>Date <b>${
            monthly ? moment(this.x).format('MMM YYYY') : moment(this.x).format('DD/MM/YYYY')
          } </b>  <br/><span style="color:${this.color}></span>Return <b>${this.y}%</b><br/>`;
        },
      },
      marker: {
        radius: 3,
      },
    },
  ],
});

export const getDefaultSunburstChartOptions = () => ({
  chart: {
    height: '100%',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  tooltip: {
    formatter() {
      let secondaryName = '';
      if (this.point.secondaryName) {
        secondaryName = `<span>${this.point.secondaryName}</span><br>`;
      }

      return `<strong>${this.point.name}</strong><br>${secondaryName}${
        Math.floor(this.point.value * 10000) / 100
      } %`;
    },
  },
  series: [],
});

export const getDefaultNetworkChartOptions = () => ({
  chart: {
    type: 'networkgraph',
    height: '30%',
  },
  credits: {
    enabled: false,
  },
  title: {
    text: '',
  },
  plotOptions: {
    networkgraph: {
      dataLabels: {
        enabled: true,
        linkFormat: '',
      },
      layoutAlgorithm: {
        enableSimulation: false,
        linkLength: 50,
        // integration: 'verlet',
        approximation: 'barnes-hut',
        // gravitationalConstant: 1
      },
      marker: {
        radius: 10,
      },
    },
  },
});
