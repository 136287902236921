import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useHistory, useLocation } from 'react-router-dom';
import {
  PageHeader,
  Title,
  Layout,
  Section,
  Button,
  ButtonStates,
  ButtonTypes,
} from '@alpima/picasso';

import {
  fetchNewUserDetails,
  putNewUserDetails,
} from '../../../../../services/clientAPI/user.service';
import useTriggeredService from '../../../../../helpers/useTriggeredService';

import {
  genUserDetailsReqObject,
  getInitialValues,
  Profile,
  UserSchema,
} from './UserDetails.helpers';
import { FormikValues, UserDetailsResponseType } from './UserDetails.types';
import useTabs from './Tabs';
import OperationalLogs from './OperationalLogs';

export default function UserDetails() {
  const history = useHistory();
  const location = useLocation();
  const userId = location.pathname.slice(6);
  const [putLoading, putError, putData, submit] = useTriggeredService<string>(putNewUserDetails);
  const [getLoading, getError, getData, trigger] =
    useTriggeredService<UserDetailsResponseType>(fetchNewUserDetails);
  const hasDetails = !getError || !getLoading;

  useEffect(() => {
    if (userId && !getData) trigger(userId);
  }, [getData, putData, trigger, userId]);

  useEffect(() => {
    if (putData) trigger(userId);
  }, [putData, trigger, userId]);

  const defaultValues = getInitialValues(getData);

  const onSubmit = (values: FormikValues) => {
    const user = genUserDetailsReqObject(values);
    submit(user);
  };

  type TabOptions = 'profile' | 'operations';
  const Tabs = useTabs<TabOptions>({ initial: 'profile' });

  return (
    <Layout noMargins>
      {hasDetails && (
        <Formik
          enableReinitialize
          initialValues={defaultValues}
          validationSchema={UserSchema}
          onSubmit={values => onSubmit(values)}
        >
          {({ initialValues, values, handleSubmit, isSubmitting, dirty }) => (
            <>
              <div className="w-full px-8 xl:px-40 mb-2">
                <PageHeader>
                  <Section>
                    <Title>{`${initialValues.firstName} ${initialValues.lastName}`}</Title>
                    <div className="flex flex-col mr-10">
                      <div className="flex flex-row justify-end space-x-2">
                        <Button
                          type={ButtonTypes.LongText}
                          state={isSubmitting || !dirty ? ButtonStates.Default : ButtonStates.Solid}
                          onClick={handleSubmit}
                          disabled={putLoading}
                        >
                          Save
                        </Button>
                        <Button type={ButtonTypes.LongText} onClick={() => history.goBack()}>
                          Cancel
                        </Button>
                      </div>
                      {putError && (
                        <p className="text-danger-800 mt-2">
                          {putError.response.message ||
                            'There was an issue updating this user, please try again later or contact support.'}
                        </p>
                      )}
                    </div>
                  </Section>
                </PageHeader>
              </div>
              <Tabs.TabList>
                <Tabs.Tab id="profile">Profile</Tabs.Tab>
                <Tabs.Tab id="operations">Operations</Tabs.Tab>
              </Tabs.TabList>
              <Tabs.Content id="profile">
                <Profile handleSubmit={handleSubmit} values={values} />
              </Tabs.Content>
              <Tabs.Content id="operations">
                {getData?.logs && <OperationalLogs logs={getData?.logs} />}
              </Tabs.Content>
            </>
          )}
        </Formik>
      )}
      {getError && (
        <div className="w-full px-8 xl:px-40 mb-2">
          There was an issue with this user, please try again later or contact support.
        </div>
      )}
    </Layout>
  );
}
