/* eslint-disable import/prefer-default-export */
import { getCookie } from './cookie.helper';

export const validSession = () => {
  let valid = false;

  if (
    getCookie('alp-auth') &&
    getCookie('display-user') &&
    JSON.parse(getCookie('display-user')) &&
    JSON.parse(getCookie('display-user')).apiToken
  ) {
    const { expires } = JSON.parse(getCookie('display-user')).apiToken;
    const expDate = new Date(expires);
    const current = new Date();
    valid = current < expDate;
  } else if (
    getCookie('alp-auth') &&
    !getCookie('display-user') &&
    getCookie('alp-provider') &&
    getCookie('alp-provider') === 'saml'
  ) {
    const authToken = getCookie('alp-auth');
    valid = authToken && authToken.length === 36;
  }

  return valid;
};
