import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DivWrapper = styled.div`
  margin: 0;
  padding: 0;
  min-height: 500px;
  width: 100%;
  background-image: url(${props =>
    props.showBackground &&
    `${process.env.PUBLIC_URL}/assets/themes/${props.theme.imageBackground}`});
  background-size: cover;
  color: ${props => props.theme.variables.colourMain};

  // Override the Tailwind base reset styles with browser defaults.
  hr {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }

  img {
    display: initial;
    vertical-align: initial;
  }
`;

// eslint-disable-next-line react/prop-types
const AppWrapper = ({ children, showBackground }) => (
  <DivWrapper showBackground={showBackground}>{children}</DivWrapper>
);

AppWrapper.defaultProps = {
  showBackground: true,
};

AppWrapper.propTypes = {
  showBackground: PropTypes.bool,
};

export default AppWrapper;
