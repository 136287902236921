/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Table, Rating, Label } from 'semantic-ui-react';
import './style.css';

import { formatIsoDate } from '../../../helpers/string.helper';

const Cell = styled(Table.Cell)`
  color: black;
  color: ${props => props.color === 'red' && '#a63860 !important'};
  font-weight: 500;
`;

const Blink = styled.span`
  cursor: pointer;
  font-weight: 600;
  color: #27488f;
`;

const DateSpan = styled.span`
  font-size: 85%;
  opacity: 0.8;
`;

const formatStringOrNumber = val => {
  if (!val) return '-';
  // eslint-disable-next-line no-restricted-globals
  return isNaN(val) ? val : val.toFixed(2);
};

const FundsTableRow = ({ row, onSelect, colored, summary, currentMetric, cob }) => {
  const handleSelectRow = () => onSelect(row);
  const periods = summary
    ? ['one_day_actual', 'mtd_actual', 'ytd_actual']
    : [
        'one_month_actual',
        'three_months_actual',
        'one_year_actual',
        'three_years_actual',
        'five_years_actual',
        'since_inception_annual',
      ];

  const renderSegment = seg => {
    const checkForNull = val => val !== null && val !== undefined;
    const performance = checkForNull(row[`performance_${seg}`])
      ? (row[`performance_${seg}`] * 100).toFixed(1)
      : '-';
    const benchmark = checkForNull(row[`benchmark_${seg}`])
      ? (row[`benchmark_${seg}`] * 100).toFixed(1)
      : '-';
    const alpha = row[`alpha_${seg}`] ? (row[`alpha_${seg}`] * 10000).toFixed(0) : '-';
    return (
      <Fragment key={seg}>
        <Cell
          className="left-border"
          textAlign="center"
          color={!row.isOfficial && colored ? 'red' : null}
        >
          {performance}
        </Cell>
        <Cell textAlign="center" color={!row.isOfficial && colored ? 'red' : null}>
          {benchmark}
        </Cell>
        <Cell textAlign="center" color={!row.isOfficial && colored ? 'red' : null}>
          {alpha}
        </Cell>
      </Fragment>
    );
  };

  return (
    <Table.Row>
      <Cell className={row.isOfficial ? '' : 'unofficial-left-border'} textAlign="center">
        <Rating icon="star" size="huge" defaultRating={0} maxRating={1} />
      </Cell>
      <Cell textAlign="left">
        <Blink onClick={handleSelectRow}>{`${row.code ? row.code : 'Code'} - ${row.name}`}</Blink>
        {row && row.tags && row.tags.length ? <br /> : ''}
        {row &&
          row.tags &&
          row.tags.map((tag, iTag) => (
            <Label key={`${tag}-${iTag}`} color="blue" size="mini">
              {tag}
            </Label>
          ))}
        {/* <Label color={row.isMac ? 'green' : 'orange'} size="mini">{row.isMac ? 'MAC' : 'Fund'}</Label> */}
        <br />
        <DateSpan>
          Data from &nbsp;
          {formatIsoDate(row.launchDate)}
        </DateSpan>
      </Cell>
      <Cell textAlign="left">{row.currency}</Cell>
      {!cob && (
        <Cell textAlign="center">
          {row.performanceEndDate && formatIsoDate(row.performanceEndDate)}
        </Cell>
      )}
      {periods.map(period => renderSegment(period))}
      {summary && (
        <Cell
          className="left-border"
          textAlign="center"
          color={!row.isOfficial && colored ? 'red' : null}
        >
          {formatStringOrNumber(row.current[currentMetric || 'yieldToMaturity'])}
        </Cell>
      )}
    </Table.Row>
  );
};

FundsTableRow.defaultProps = {
  row: {},
  colored: false,
  summary: true,
  currentMetric: '',
  cob: false,
};

FundsTableRow.propTypes = {
  row: PropTypes.object,
  colored: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  summary: PropTypes.bool,
  currentMetric: PropTypes.string,
  cob: PropTypes.bool,
};

export default FundsTableRow;
