import * as constants from '../constants';

export const getInstruments = () => ({
  type: constants.GET_INSTRUMENTS_LIST,
});

export const fetchInstrumentDetails = id => ({
  type: constants.GET_INSTRUMENT_DETAILS,
  id,
});

export const fetchTSTInstrumentMonitoring = date => ({
  type: constants.GET_INSTRUMENTS_TST_MONITORING,
  date,
});

export const fetchInstrumentPerfMonitoring = (dateStart, dateEnd, percentile) => ({
  type: constants.GET_INSTRUMENTS_PERF_MONITORING,
  dateStart,
  dateEnd,
  percentile,
});

export const setSearchResults = payload => ({
  type: constants.SET_INSTRUMENT_SEARCH_RESULTS,
  payload,
});

export const clearSearchResults = () => ({
  type: constants.CLEAR_INSTRUMENT_SEARCH_RESULTS,
});

export const stampInstrumentAttribute = (key, value, data, compiler = args => args) => ({
  type: constants.STAMP_INSTRUMENT_DETAILS,
  compiler,
  data,
  key,
  value,
});

export const stampEnableInstrument = () => ({
  type: constants.STAMP_ENABLE_INSTRUMENT,
});
export const stampDisableInstrument = () => ({
  type: constants.STAMP_DISABLE_INSTRUMENT,
});

export const setInstrumentUpdate = () => ({
  // type: constants.REQUEST_INSTRUMENT_DETAILS_UPDATE
  type: constants.SET_INSTRUMENT_DETAILS,
});

export const cancelInstrumentUpdate = () => ({
  type: constants.SET_INSTRUMENT_DETAILS_CANCEL,
});

export const requestInstrumentExport = json => ({
  type: constants.INSTRUMENT_EXPORT_REQUEST,
  json,
});
export const requestBBExport = json => ({
  type: constants.BB_EXPORT_REQUEST,
  json,
});

export const clearExportData = () => ({
  type: constants.INSTRUMENT_EXPORT_CLEAR,
});
export const clearBBExportData = () => ({
  type: constants.BB_EXPORT_CLEAR,
});

export const sortInstrumentsByColumn = (column, direction, sortedData) => ({
  type: constants.SET_INSTRUMENT_COLUMN_SORT,
  payload: {
    direction,
    column,
    sortedData,
  },
});
