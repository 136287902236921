/* eslint-disable import/prefer-default-export */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { withLatestFrom, mergeMap, catchError, map } from 'rxjs/operators';

import {
  fetchInstrumentList,
  fetchInstrumentTstMonitoringStats,
  fetchInstrumentPerfMonitoringStats,
  fetchInstrumentDetails,
  putInstrumentUpdatedState,
  performInstrumentExport,
  performBBCSVExport,
} from '../../services/clientAPI/instrument.service';

import * as constants from '../constants';

const handleError = type => error =>
  of({
    type,
    error: error.message,
  });

export const getInstrumentListEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_INSTRUMENTS_LIST),
    mergeMap(() =>
      fetchInstrumentList().pipe(
        mergeMap(response => [
          {
            type: constants.GET_INSTRUMENTS_LIST_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.GET_INSTRUMENTS_LIST_FAILURE)),
      ),
    ),
    catchError(handleError(constants.GET_INSTRUMENTS_LIST_FAILURE)),
  );

export const getInstrumentTSTMonitoringEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_INSTRUMENTS_TST_MONITORING),
    mergeMap(action =>
      fetchInstrumentTstMonitoringStats(action.date).pipe(
        mergeMap(response => [
          {
            type: constants.GET_INSTRUMENTS_TST_MONITORING_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.GET_INSTRUMENTS_TST_MONITORING_FAILURE)),
      ),
    ),
    catchError(handleError(constants.GET_INSTRUMENTS_TST_MONITORING_FAILURE)),
  );

export const getInstrumentPerfMonitoringEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_INSTRUMENTS_PERF_MONITORING),
    mergeMap(action =>
      fetchInstrumentPerfMonitoringStats(action.dateStart, action.dateEnd, action.percentile).pipe(
        mergeMap(response => [
          {
            type: constants.GET_INSTRUMENTS_PERF_MONITORING_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.GET_INSTRUMENTS_PERF_MONITORING_FAILURE)),
      ),
    ),
    catchError(handleError(constants.GET_INSTRUMENTS_PERF_MONITORING_FAILURE)),
  );

export const getInstrumentDetailsEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_INSTRUMENT_DETAILS),
    mergeMap(action =>
      fetchInstrumentDetails(action.id).pipe(
        mergeMap(response => [
          {
            type: constants.GET_INSTRUMENT_DETAILS_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.GET_INSTRUMENT_DETAILS_FAILURE)),
      ),
    ),
    catchError(handleError(constants.GET_INSTRUMENT_DETAILS_FAILURE)),
  );

// const isEnableDisableInstrumentRequest = state => () => {
//     const active = state.getIn(['instruments', 'details', 'active']).toJS();
//     const cache = state.getIn(['instruments', 'details', 'cache']).toJS();
//     return active && cache && active.visible !== cache.visible;
// };
const handleInstrumentUpdateSuccess = state =>
  mergeMap(response => [
    {
      type: constants.SET_INSTRUMENT_DETAILS_SUCCESS,
      payload: response,
    },
    {
      type: constants.GET_INSTRUMENT_DETAILS,
      id: state._id,
    },
  ]);

// const updateInstrumentEnabledState = (state) => {
//     const active = state.getIn(['instruments', 'details', 'active']).toJS();
//     return putInstrumentEnabledState(active._id, active.visible)
//         .pipe(
//             handleInstrumentUpdateSuccess(active),
//             catchError(handleError(constants.SET_INSTRUMENT_DETAILS_FAILURE))
//         );
// };
const updateInstrumentDetails = state => {
  const active = state.getIn(['instruments', 'details', 'active']).toJS();
  return putInstrumentUpdatedState(active._id, {
    displayName: active.displayName,
    visible: active.visible,
    comparable: active.comparable,
    buildInstrument: active.buildInstrument,
    marketDataSource: active.marketDataSource,
    acl: active.acl,
  }).pipe(
    handleInstrumentUpdateSuccess(active),
    catchError(handleError(constants.SET_INSTRUMENT_DETAILS_FAILURE)),
  );
};

// export const requestInstrumentDetailsUpdateEpic = (action$, state$) => action$
//     .pipe(
//         ofType(constants.REQUEST_INSTRUMENT_DETAILS_UPDATE),
//         withLatestFrom(state$),
//         mergeMap(
//             ([, state]) => iif(
//                 isEnableDisableInstrumentRequest(state),
//                 updateInstrumentEnabledState(state),
//                 of({ type: constants.SET_INSTRUMENT_DETAILS })
//             )
//         ),
//         catchError(handleError(constants.SET_INSTRUMENT_DETAILS_FAILURE))
//     );

export const setInstrumentDetailsEpic = (action$, state$) =>
  action$.pipe(
    ofType(constants.SET_INSTRUMENT_DETAILS),
    withLatestFrom(state$),
    mergeMap(([, state]) => updateInstrumentDetails(state)),
    catchError(handleError(constants.SET_INSTRUMENT_DETAILS_FAILURE)),
  );

export const getInstrumentExportEpic = actions$ =>
  actions$.pipe(
    ofType(constants.INSTRUMENT_EXPORT_REQUEST),
    mergeMap(action => performInstrumentExport(action.json)),
    map(response => ({
      type: constants.INSTRUMENT_EXPORT_SUCCESS,
      payload: response,
    })),
    catchError(handleError(constants.INSTRUMENT_EXPORT_FAILURE)),
  );

export const getBBExportEpic = actions$ =>
  actions$.pipe(
    ofType(constants.BB_EXPORT_REQUEST),
    mergeMap(action =>
      performBBCSVExport(action.json).pipe(
        mergeMap(response => [
          {
            type: constants.BB_EXPORT_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.BB_EXPORT_FAILURE)),
      ),
    ),
    catchError(handleError(constants.BB_EXPORT_FAILURE)),
  );
