import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Dashboard from '../../../organisms/Dashboard';
import PageWrapper from '../../../molecules/PageWrapper';

const LogsPage = ({ match }) => (
  <PageWrapper>
    <div style={{ minHeight: '850px', background: '#F6F8FA' }}>
      <Switch>
        <Route
          exact
          path="/logs/:page"
          render={() => (
            <Dashboard
              title={<span>Logs</span>}
              icon={{ name: 'list', size: 'large' }}
              name="logList"
            />
          )}
        />
        <Route
          exact
          path={`${match.url}`}
          render={() => (
            <Dashboard
              title={<span>Logs</span>}
              icon={{ name: 'list', size: 'large' }}
              name="logList"
            />
          )}
        />
      </Switch>
    </div>
  </PageWrapper>
);

LogsPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(connect()(LogsPage));
