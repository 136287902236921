/* eslint-disable import/prefer-default-export */
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap, catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import * as constants from '../constants';

import {
  fetchLogList,
  fetchLogFilterTypeContent,
  fetchDailyErrorCount,
} from '../../services/clientAPI/log.service';
import { fetchFunctionalRolesList } from '../../services/clientAPI/roles.service';
import { fetchUserList } from '../../services/clientAPI/user.service';

const handleListError = error =>
  of({
    type: constants.GET_LOGS_LIST_FAILURE,
    error: error.message,
  });
const handleFilterContentError = error =>
  of({
    type: constants.GET_LOG_FILTERS_FAILURE,
    error: error.message,
  });

export const getLogListEpic = (actions$, state$) =>
  actions$.pipe(
    ofType(constants.GET_LOGS_LIST, constants.LOGS_LIST_NAVIGATION, constants.SET_LOG_COLUMN_SORT),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      (() => {
        const _filter = state.getIn(['logs', 'filter']) || '{}';
        const filter = _filter && ((_filter.toJSON && _filter.toJSON()) || JSON.parse(_filter));

        // Hack to override verbose level filter
        // API will return verbose logs if levels array is empty
        // But populating the array puts UI out of sync
        // - so basically here, if the array is empty, just populate with the
        //   default 3 levels
        const _levels = filter && filter.levels;
        let levels = [];
        if (_levels && _levels.toJS) {
          // is immutable object
          levels = _levels.toJS();
        }
        if (levels && !levels.length) {
          filter.levels = [0, 1, 2];
        }

        const companyId = state.getIn(['auth', 'user', 'company']);

        // Read the company from the current user's authentication, not the default filter.
        filter.companies = [companyId || filter.companies[0]];
        const { timeFilterSelection, ...rest } = filter;
        return companyId && fetchLogList(rest);
      })().pipe(
        map(response => ({
          logs: response.logs,
          meta: response.pagination,
        })),
      ),
    ),
    mergeMap(logs => [
      {
        type: constants.GET_LOGS_LIST_SUCCESS,
        payload: logs,
      },
    ]),
    catchError(handleListError),
  );

export const getLogFilterContentEpic = (actions$, state$) =>
  actions$.pipe(
    ofType(constants.GET_LOG_FILTERS),
    switchMap(() =>
      fetchLogFilterTypeContent().pipe(
        map(types => ({
          types,
        })),
      ),
    ),
    withLatestFrom(state$),
    switchMap(([filterContent, state]) =>
      (() => {
        const companyId = state.getIn(['auth', 'user', 'company']) || '';
        return fetchUserList(companyId);
      })().pipe(
        map(users => ({
          ...filterContent,
          users,
        })),
      ),
    ),
    switchMap(filterContent =>
      fetchFunctionalRolesList().pipe(
        map(data => ({
          ...filterContent,
          roles: data.response,
        })),
      ),
    ),
    mergeMap(filterContent => [
      {
        type: constants.GET_LOG_FILTERS_SUCCESS,
        payload: filterContent,
      },
    ]),
    catchError(handleFilterContentError),
  );

export const getDailyErrorCountEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_LOG_DAILY_ERROR_COUNT),
    mergeMap(() =>
      fetchDailyErrorCount().pipe(
        mergeMap(response => [
          {
            type: constants.GET_LOG_DAILY_ERROR_COUNT_SUCCESS,
            payload: response,
          },
        ]),
      ),
    ),
    catchError(error =>
      of({
        type: constants.GET_LOG_DAILY_ERROR_COUNT_FAILURE,
        error: error.message,
      }),
    ),
  );
