import { pluck } from 'rxjs/operators';
import { apiCall$, apiCallWithRequest$ } from './lib/apiCall';
import {
  advisoryReferenceDataURL,
  referenceDataExportUrl,
  advisoryCurrencyDataURL,
} from './clientAPI.config';

export const fetchReferenceDataList = () => apiCall$(advisoryReferenceDataURL);

export const performReferenceDataCSVExport = (json = false) =>
  apiCallWithRequest$({
    url: `${referenceDataExportUrl}?json=${json}`,
    method: 'GET',
    responseType: json ? 'json' : 'text',
  }).pipe(pluck('response'));

export const fetchProductLines = () => apiCall$(`${advisoryReferenceDataURL}/productline`);
export const fetchCurrencies = () => apiCall$(`${advisoryCurrencyDataURL}/build`);
