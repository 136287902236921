import { fromJS } from 'immutable';
import * as constants from '../constants/informationBanner.constants';

const initialState = fromJS({
  isLoading: false,
  error: null,
  informationBanners: [],
  isUpdating: false,
  isModalOpen: false,
});

export default function informationBannerReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_INFORMATION_BANNERS:
      return state.set('isLoading', true);
    case constants.GET_INFORMATION_BANNERS_SUCCESS:
      return state.merge({
        informationBanners: action.payload,
        isLoading: false,
        error: null,
      });
    case constants.GET_INFORMATION_BANNERS_FAILURE:
      return state.merge({
        informationBanners: [],
        isLoading: false,
        error: action.payload,
      });
    case constants.TOGGLE_INFORMATION_BANNER_MODAL:
      return state
        .set('isModalOpen', action.payload)
        .set('error', action.payload ? state.error : null);
    case constants.SAVE_INFORMATION_BANNER:
      return state.set('isUpdating', true);
    case constants.SAVE_INFORMATION_BANNER_SUCCESS:
      return state.merge({ isUpdating: false, isModalOpen: false });
    case constants.SAVE_INFORMATION_BANNER_FAILURE:
      return state.merge({ isUpdating: false, error: action.payload });
    case constants.ARCHIVE_INFORMATION_BANNER:
      return state.set('isUpdating', true);
    case constants.ARCHIVE_INFORMATION_BANNER_SUCCESS:
      return state.set('isUpdating', false);
    case constants.ARCHIVE_INFORMATION_BANNER_FAILURE:
      return state.set('isUpdating', false);
    default:
      return state;
  }
}
