import { useEffect } from 'react';

type KeyListenerProps = {
  listenForKey: string;
  action: () => void;
};

function useKeyListener(key: string, action: () => void) {
  useEffect(() => {
    function fn(event: KeyboardEvent) {
      if (event.key === key) {
        action();
      }
    }

    document.addEventListener('keydown', fn);
    return () => {
      document.removeEventListener('keydown', fn);
    };
  });
}

export default function KeyListener({ listenForKey, action }: KeyListenerProps) {
  useKeyListener(listenForKey, action);
  return null;
}
