export const GET_USER_LOGIN_CHART_DATA = 'GET_USER_LOGIN_CHART_DATA';
export const GET_USER_LOGIN_CHART_DATA_SUCCESS = 'GET_USER_LOGIN_CHART_DATA_SUCCESS';
export const GET_USER_LOGIN_CHART_DATA_FAILURE = 'GET_USER_LOGIN_CHART_DATA_FAILURE';
export const GET_USER_LOGIN_CHART_DATA_CANCEL = 'GET_USER_LOGIN_CHART_DATA_CANCEL';

export const GET_USER_LOGIN_TABLE_DATA = 'GET_USER_LOGIN_TABLE_DATA';
export const GET_USER_LOGIN_TABLE_DATA_SUCCESS = 'GET_USER_LOGIN_TABLE_DATA_SUCCESS';
export const GET_USER_LOGIN_TABLE_DATA_FAILURE = 'GET_USER_LOGIN_TABLE_DATA_FAILURE';

export const GET_STRATEGY_TABLE_DATA = 'GET_STRATEGY_TABLE_DATA';
export const GET_STRATEGY_TABLE_DATA_SUCCESS = 'GET_STRATEGY_TABLE_DATA_SUCCESS';
export const GET_STRATEGY_TABLE_DATA_FAILURE = 'GET_STRATEGY_TABLE_DATA_FAILURE';

export const GET_STRATEGY_CREATION_SERIES_DATA = 'GET_STRATEGY_CREATION_SERIES_DATA';
export const GET_STRATEGY_CREATION_SERIES_DATA_SUCCESS =
  'GET_STRATEGY_CREATION_SERIES_DATA_SUCCESS';
export const GET_STRATEGY_CREATION_SERIES_DATA_FAILURE =
  'GET_STRATEGY_CREATION_SERIES_DATA_FAILURE';

export const GET_SPLUNK_TIMESTAMPS = 'GET_SPLUNK_TIMESTAMPS';
export const GET_SPLUNK_TIMESTAMPS_SUCCESS = 'GET_SPLUNK_TIMESTAMPS_SUCCESS';
export const GET_SPLUNK_TIMESTAMPS_FAILURE = 'GET_SPLUNK_TIMESTAMPS_FAILURE';

export const SET_MONITORING_LOGIN_CHART_TYPE = 'SET_MONITORING_LOGIN_CHART_TYPE';
export const SET_MONITORING_LOGIN_CHART_UNKNOWNS = 'SET_MONITORING_LOGIN_CHART_UNKNOWNS';
export const SET_MONITORING_TIME_FILTER_STATE = 'SET_MONITORING_TIME_FILTER_STATE';

export const GET_BLOOMBERG_IMPORT_STATUS = 'GET_BLOOMBERG_IMPORT_STATUS';
export const GET_BLOOMBERG_IMPORT_STATUS_SUCCESS = 'GET_BLOOMBERG_IMPORT_STATUS_SUCCESS';
export const GET_BLOOMBERG_IMPORT_STATUS_FAILURE = 'GET_BLOOMBERG_IMPORT_STATUS_FAILURE';

export const GET_OVERNIGHT_CALCULATIONS_STATUS = 'GET_OVERNIGHT_CALCULATIONS_STATUS';
export const GET_OVERNIGHT_CALCULATIONS_STATUS_SUCCESS =
  'GET_OVERNIGHT_CALCULATIONS_STATUS_SUCCESS';
export const GET_OVERNIGHT_CALCULATIONS_STATUS_FAILURE =
  'GET_OVERNIGHT_CALCULATIONS_STATUS_FAILURE';

export const GET_IC_DECISION_PRECONDITIONS = 'GET_IC_DECISION_PRECONDITIONS';
export const GET_IC_DECISION_PRECONDITIONS_SUCCESS = 'GET_IC_DECISION_PRECONDITIONS_SUCCESS';
export const GET_IC_DECISION_PRECONDITIONS_FAILURE = 'GET_IC_DECISION_PRECONDITIONS_FAILURE';
