import { pluck } from 'rxjs/operators';
import { apiCall$, apiCallWithRequest$ } from './lib/apiCall.ts';

import {
  advisoryInstrumentsURL,
  instrumentTstMonitoringURL,
  instrumentPerfMonitoringURL,
  instrumentDetailsURL,
  updateInstrumentsURL,
  instrumentExportUrl,
  instrumentBBExportUrl,
} from './clientAPI.config';

export const fetchInstrumentList = () => apiCall$(advisoryInstrumentsURL);

export const fetchInstrumentTstMonitoringStats = date =>
  date
    ? apiCall$(instrumentTstMonitoringURL.replace(/:date/, `date=${date}`))
    : apiCall$(instrumentTstMonitoringURL);

export const fetchInstrumentPerfMonitoringStats = (dateStart, dateEnd, percentile) =>
  apiCall$(
    instrumentPerfMonitoringURL
      .replace(/:dateStart/, `dateStart=${dateStart}`)
      .replace(/:dateEnd/, `dateEnd=${dateEnd}`)
      .replace(/:percentile/, `percentile=${percentile}`),
  );

export const fetchInstrumentDetails = instrumentId =>
  apiCall$(instrumentDetailsURL.replace(/:instrumentId/, instrumentId));

export const putInstrumentEnabledState = (id, enabled) => {
  if (enabled) {
    return apiCall$(advisoryInstrumentsURL.replace(/:id/i, 'PATCH', id));
  }
  return apiCall$(advisoryInstrumentsURL.replace(/:id/i, 'PATCH', id));
};

export const putInstrumentUpdatedState = (id, instrumentState) =>
  apiCall$(updateInstrumentsURL.replace(/:instrumentId/i, id), 'PATCH', {
    instrumentUpdates: instrumentState,
  });

export const performInstrumentExport = (json = false) =>
  apiCallWithRequest$({
    url: `${instrumentExportUrl}?json=${json}`,
    method: 'GET',
    responseType: json ? 'json' : 'text',
  }).pipe(pluck('response'));

export const performBBCSVExport = (json = false) =>
  apiCallWithRequest$({
    url: `${instrumentBBExportUrl}?json=${json}`,
    method: 'GET',
    responseType: json ? 'json' : 'text',
  }).pipe(pluck('response'));
