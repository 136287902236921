import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import LabelInput from '../../../../atoms/LabelInput';

export const Title = styled.h1`
  margin-left: 10px;
`;

export const DataRefreshContainer = styled.div`
  padding: 20px;
`;

export const FormContainer = styled.div`
  max-width: 400px;
`;

export const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckboxWrapper = styled(Horizontal)`
  align-content: center;
  margin: 15px auto 25px auto;
`;

export const CheckboxLabel = styled.label`
  margin: auto 20px auto 10px;
  font-weight: bold;
`;

export const SaveButtonWrapper = styled.div`
  margin-left: 10px;
  margin-right: -10px;
  margin-top: 20px;
  width: calc(100% - 20px);
  display: flex;
  height: 40px;
  justify-content: space-between;
`;

export const InputHalfWidth = styled.div`
  max-width: 50%;
  flex-grow: 1;
`;

export const InvisibleButton = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  user-select: none;

  &:hover {
    text-decoration: underline;
  }
`;

export function DropdownInput({ config, value, setValue, ...props }) {
  return (
    <InputHalfWidth>
      <LabelInput
        style={{ maxWidth: '100px' }}
        name={config.name}
        data={value}
        onChange={setValue(config.key)}
        multiple={false}
        resolver={val => val}
        optionResolver={() => config.options.map(key => ({ value: key, text: key }))}
        {...props}
      />
    </InputHalfWidth>
  );
}

DropdownInput.propTypes = {
  config: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
};
