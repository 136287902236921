import React from 'react';
// import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Dashboard from '../../../organisms/Dashboard';
import PageWrapper from '../../../molecules/PageWrapper';

const MonitoringPage = () => (
  <PageWrapper>
    <div style={{ minHeight: '850px', background: '#F6F8FA' }}>
      <Switch>
        <Route
          exact
          path="/monitoring/logins/:type"
          render={() => (
            <Dashboard
              tabs
              title="User logins over Time"
              icon={{ name: 'chart area', size: 'large' }}
              name="monitoringLogins"
            />
          )}
        />
        <Route
          exact
          path="/monitoring/logins"
          render={() => (
            <Dashboard
              tabs
              title="User logins over Time"
              icon={{ name: 'chart area', size: 'large' }}
              name="monitoringLogins"
            />
          )}
        />
        <Route
          exact
          path="/monitoring/strategies"
          render={() => (
            <Dashboard
              tabs
              title="Object Create / Update Metrics"
              icon={{ name: 'chart area', size: 'large' }}
              name="monitoringStrategies"
            />
          )}
        />
        <Route exact path="/monitoring" render={() => <Redirect to="/monitoring/logins/" />} />
      </Switch>
    </div>
  </PageWrapper>
);

MonitoringPage.defaultProps = {};
MonitoringPage.propTypes = {};

export default withRouter(connect()(MonitoringPage));
