/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Grid, GridColumn, GridRow, Loader } from 'semantic-ui-react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { requestUsersExport, clearExportUsers } from '../../../store/actions/user.actions';
import { selectIsExporting, selectUsersExportData } from '../../../store/selectors/user.selector';
import Button from '../../atoms/Button/Button';

const UsersExportContainer = styled.div`
  padding: 15px;
`;

function UsersExportWidget({ isExporting, requestUsersExport, exportData, clearExportUsers }) {
  const downloadLink = useRef();
  const [downloadAsJson, toggleJsonFormat] = useState(false);
  const filename = `UsersExport-${new Date().getTime()}.${downloadAsJson ? 'json' : 'csv'}`;

  useEffect(() => {
    if (exportData) {
      const URL = window.URL || window.webkitURL;
      const type = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        ? `application/${downloadAsJson ? 'json' : 'csv'}`
        : `${downloadAsJson ? 'application/json' : 'text/csv'}`;
      const downloadData = downloadAsJson ? JSON.stringify(exportData, '', 2) : exportData;

      const downloadable =
        typeof URL.createObjectURL === 'undefined'
          ? `data:${type};charset=utf-8,${downloadData}`
          : URL.createObjectURL(new Blob(['', downloadData], { type }));

      const a = downloadLink.current;
      a.download = filename;
      a.href = downloadable;
      a.click();
      a.href = '';
      clearExportUsers();
    }
  }, [filename, exportData, clearExportUsers, downloadAsJson]);

  const downloadData = () => (
    <a download={filename} ref={downloadLink} target="_self" href={exportData}>
      &nbsp;
    </a>
  );

  return (
    <UsersExportContainer>
      <h2>
        User export <Loader inline size="tiny" active={isExporting} />
      </h2>
      <hr />
      <Grid>
        <GridRow>
          <GridColumn width={16} style={{ marginBottom: 10 }}>
            <Checkbox
              label="JSON format"
              checked={downloadAsJson || false}
              onChange={() => toggleJsonFormat(!downloadAsJson)}
            />
          </GridColumn>
          <GridColumn width={16}>
            <Button primary onClick={() => requestUsersExport(downloadAsJson)}>
              Export
            </Button>
            {downloadData()}
          </GridColumn>
        </GridRow>
      </Grid>
    </UsersExportContainer>
  );
}

UsersExportWidget.propTypes = {
  isExporting: PropTypes.bool.isRequired,
  requestUsersExport: PropTypes.func.isRequired,
  exportData: PropTypes.any.isRequired,
  clearExportUsers: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  isExporting: selectIsExporting,
  exportData: selectUsersExportData,
});

const mapDispatchToProps = {
  requestUsersExport,
  clearExportUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersExportWidget);
