import React, { Fragment } from 'react';
import { List, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import poweredBy from '../../../themes/alpima/alpima-power.svg';

const PoweredBy = styled.img`
  image-rendering: -webkit-optimize-contrast;
  height: 30px;
  margin: 23px 10px;
`;

const TopFooter = styled.div`
  background-color: ${props => props.theme.variables.pageBottomFooter};
  height: 50px;
  text-align: right;
`;

const BottomFooter = styled.div`
  background-color: ${props => props.theme.variables.pageBottomFooter};
  height: 50px;
  padding: 15px;
`;

const AppFooter = () => {
  const handleLink = url => {
    window.open(url);
  };

  return (
    <Fragment>
      <TopFooter>
        <PoweredBy src={poweredBy} />
      </TopFooter>
      <BottomFooter fluid>
        <Grid columns="equal">
          <Grid.Column textAlign="right">
            <List horizontal divided inverted link size="small">
              <List.Item
                as="a"
                onClick={() => handleLink('https://www.alpima.net/terms-and-conditions/')}
              >
                Terms and Conditions
              </List.Item>
              <List.Item
                as="a"
                onClick={() => handleLink('https://www.alpima.net/privacy-policy/')}
              >
                Privacy Policy
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </BottomFooter>
    </Fragment>
  );
};

export default AppFooter;
