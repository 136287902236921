import React from 'react';
import { storiesOf } from '@storybook/react';

import WidgetDefaultToolbar from './index';
import WidgetDefaultTitle from '../../atoms/WidgetDefaultTitle';

const actions = [
  {
    label: 'Contribution',
  },
  {
    label: 'Monthly Returns / Histogram',
  },
  {
    label: 'Efficient Frontier',
  },
  {
    label: 'Risk/Return',
  },
];

const defaultProps = {
  actions,
};

storiesOf('WidgetDefaultToolbar', module)
  .addDecorator(story => (
    <div
      style={{
        margin: '50px',
        padding: '10px',
        textAlign: 'left',
        backgroundColor: 'black',
      }}
    >
      <WidgetDefaultTitle>
        Toolbar
        {story()}
      </WidgetDefaultTitle>
    </div>
  ))
  .add('default', () => <WidgetDefaultToolbar {...defaultProps} />);
