/* eslint-disable import/prefer-default-export */
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as constants from '../constants';
// import { getLayout$ } from '../../services/clientAPI/layout.service';

import { layout } from '../../config/appConfig';

const handleError = error =>
  of({
    type: constants.GET_LAYOUT_FAILED,
    error: error.message,
  });

export const getLayoutEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_LAYOUT_STARTED),
    mergeMap(() => [
      {
        type: constants.GET_LAYOUT_SUCCEEDED,
        payload: layout,
      },
    ]),
    catchError(handleError),
  );
