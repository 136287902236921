/* eslint-disable @typescript-eslint/no-shadow */
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Pagination, Search } from 'semantic-ui-react';
import * as _ from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { columns } from '../../../config/appConfig/tables/strategy.columns';
import TableDefault from '../../molecules/TableDefault';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';
import {
  selectLastRefresh,
  selectStrategyList,
  selectStrategyListPage,
  selectStrategyLoading,
  selectStrategySearchResults,
} from '../../../store/selectors/strategy.selector';
import {
  clearSearchResults,
  getStrategies,
  setSearchResults,
} from '../../../store/actions/strategy.actions';

const WidgetHeaderWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
`;

const TableOptionWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;

  @media screen and (min-width: ${props => props.theme.variables.breakpoints.md}px) {
    flex-direction: row;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1 0 auto;
  margin-bottom: 10px;
`;

const SearchBox = styled(Search)`
  width: 350px;
  & input {
    border-radius: 5px !important;
  }
  & .results.transition {
    width: 100%;
    display: none !important;
  }
`;

const ResultCount = styled.span`
  position: relative;
  top: 0;
  padding: 5px 15px;
  text-align: center;
  line-height: 25px;
`;

const ClearResults = styled.button`
  position: relative;
  top: 0;
  padding: 0 15px;
  background: transparent;
  border: none;
  height: 35px;
  border-left: solid 1px #666;
  color: #4c8ed6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
  &::after {
    content: 'Clear';
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0 0 auto;
  margin-bottom: 15px;

  & .csv-export {
    margin: 5px 15px;
    &:hover {
      & span {
        text-decoration: underline;
      }
    }

    & span {
      padding: 5px 10px;
    }
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;
  flex-direction: column;

  &.hidden {
    display: none;
  }
`;

const StrategyTableWidget = props => {
  const {
    theme,
    match,
    history,
    loading,
    lastRefresh,
    getStrategiesList,
    strategyList,
    paginatedStrategyList,
    paginatedSearchResults,
    setSortedStrategies,
    setSearchResults,
    clearSearchResults,
  } = props;

  const [search, setSearch] = useState({
    searching: false,
    value: '',
    results: null,
  });

  const { formatTime } = useDateTimeFormatter();

  useEffect(() => {
    getStrategiesList();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [getStrategiesList]);

  const handlePaginationChange = (e, { activePage }) =>
    history.push(match.path.replace(':page', activePage));

  const handleClearSearch = () => {
    setSearch({ searching: false, value: '', results: null });
    clearSearchResults();
  };

  const handleSearch = value => {
    if (value === '') {
      return handleClearSearch();
    }

    const re = new RegExp(_.escapeRegExp(value || ''), 'i');
    const isMatch = result => re.test(result.name) || re.test(result.uid);
    const flattenedStrategyList = strategyList.reduce((a, b) => a.concat(b));
    const filteredResults = flattenedStrategyList.filter(isMatch);

    setSearch({
      ...search,
      value,
      searching: false,
      results: filteredResults,
    });

    return setSearchResults(filteredResults);
  };

  const handleSearchInput = (e, { value }) => {
    setSearch({
      ...search,
      value,
      searching: true,
    });

    handleSearch(value);
  };

  const paginationPageCount =
    (paginatedSearchResults && paginatedSearchResults.length
      ? paginatedSearchResults.length
      : strategyList.length) || 0;
  const data = (search.results || paginatedStrategyList || []).filter(strategy => strategy);

  return (
    <LoadingBoundary isLoading={loading}>
      <div style={{ minHeight: '850px', overflowX: 'scroll', overflowY: 'hidden' }}>
        <WidgetHeaderWrapper theme={theme}>
          <TableOptionWrapper>
            <SearchWrapper>
              <SearchBox
                loading={search.searching}
                onSearchChange={handleSearchInput}
                placeholder="Search"
                value={search.value}
                input={{
                  style: {
                    width: '100%',
                  },
                }}
              />
              {search.results ? (
                <Fragment>
                  <ResultCount>Results: {search.results.length}</ResultCount>
                  <ClearResults onClick={handleClearSearch} />
                </Fragment>
              ) : null}
            </SearchWrapper>
            <OptionsWrapper>
              <PaginationWrapper className={paginationPageCount < 2 ? 'hidden' : ''}>
                <Pagination
                  boundaryRange={0}
                  siblingRange={1}
                  totalPages={paginationPageCount}
                  activePage={match.params.page}
                  onPageChange={handlePaginationChange}
                />
                <sub style={{ marginTop: 14, fontSize: '1rem', textAlign: 'right' }}>
                  Last refresh: {formatTime(lastRefresh)}
                </sub>
              </PaginationWrapper>
            </OptionsWrapper>
          </TableOptionWrapper>
        </WidgetHeaderWrapper>
        <TableDefault
          striped
          selectable
          name="Instrument List"
          data={data}
          fullData={_.flatMap(strategyList)}
          onClickLink={strategy => {
            history.push(`/strategy/${strategy._id}`);
          }}
          onSort={setSortedStrategies}
          columns={columns.default}
        />
      </div>
    </LoadingBoundary>
  );
};

StrategyTableWidget.propTypes = {
  theme: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  lastRefresh: PropTypes.number.isRequired,
  getStrategiesList: PropTypes.func.isRequired,
  strategyList: PropTypes.object.isRequired,
  paginatedStrategyList: PropTypes.array,
  paginatedSearchResults: PropTypes.array,
  setSortedStrategies: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
  clearSearchResults: PropTypes.func.isRequired,
};

StrategyTableWidget.defaultProps = {
  theme: {},
  paginatedStrategyList: [],
  paginatedSearchResults: [],
};

const mapStateToProps = createStructuredSelector({
  strategyList: state => selectStrategyList(state),
  paginatedStrategyList: state => selectStrategyListPage(state),
  paginatedSearchResults: state => selectStrategySearchResults(state),
  loading: state => selectStrategyLoading(state),
  lastRefresh: state => selectLastRefresh(state),
});

const mapDispatchToProps = dispatch => ({
  getStrategiesList: () => dispatch(getStrategies()),
  setSearchResults: results => dispatch(setSearchResults(results)),
  clearSearchResults: () => dispatch(clearSearchResults()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StrategyTableWidget));
