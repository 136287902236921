/* eslint-disable react/style-prop-object */
import React, { Fragment, useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
// import styles from 'styles-components';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import _ from 'lodash';
import './style.css';
import styled from 'styled-components';
import { FormatNumberFilter } from '../../../helpers/format.helper';
import { formatIsoDate } from '../../../helpers/string.helper';

const Cell = styled(Table.Cell)`
  text-align: center !important;
`;

const HeaderCell = styled(Table.HeaderCell)`
  text-align: center !important;
`;

const SHOW_LOG_RETURNS = false;

const DateDiv = styled.div`
  color: #2a2968 !important;
  font-weight: bolder;
  text-align: right;
`;

const rangesPortfolioAndBenchmark = [
  {
    maxVal: -0.06,
    legend: 'p ≤ -6%',
    class: 'perf-lt6',
  },
  {
    minVal: -0.0599,
    maxVal: -0.04,
    legend: '-6% < p ≤ -4%',
    class: 'perf-lt4to6',
  },
  {
    minVal: -0.0399,
    maxVal: -0.02,
    legend: '-4% < p ≤ -2%',
    class: 'perf-lt2to4',
  },
  {
    minVal: -0.0199,
    maxVal: 0.0199,
    legend: '-2% < p < 2%',
    class: '',
  },
  {
    minVal: 0.02,
    maxVal: 0.0399,
    legend: '2% ≤ p < 4%',
    class: 'perf-gt2to4',
  },
  {
    minVal: 0.04,
    maxVal: 0.0599,
    legend: '4% ≤ p < 6%',
    class: 'perf-gt4to6',
  },
  {
    minVal: 0.06,
    legend: 'p ≥ 6%',
    class: 'perf-gt6',
  },
];

const MonthlyPerformanceTable = ({ quarterly, data, asOfDate }) => {
  const [logReturns, setLogReturns] = useState(false);

  const getCellClass = value => {
    if (value === null || value === 0 || value === undefined) {
      return 'alp-disabled';
    }

    const heatMapRange = _.find(rangesPortfolioAndBenchmark, range => {
      // eslint-disable-next-line no-prototype-builtins
      if (range.hasOwnProperty('minVal') && !range.hasOwnProperty('maxVal')) {
        return value >= range.minVal; // min value only
      }

      // eslint-disable-next-line no-prototype-builtins
      if (!range.hasOwnProperty('minVal') && range.hasOwnProperty('maxVal')) {
        return value <= range.maxVal; // max value only
      }

      return value >= range.minVal && value <= range.maxVal; // min and max value
    });

    if (heatMapRange) {
      return heatMapRange.class;
    }

    return '';
  };

  const renderCell = c => (
    <Cell key={uuid()} className={getCellClass(c)}>
      <FormatNumberFilter value={c} style="percent" />
    </Cell>
  );

  const renderRow = row => (
    <Table.Row key={uuid()}>
      <Fragment>
        <Cell key={uuid()}>{row.year}</Cell>
        {row.values.map(c => renderCell(c, 'percent'))}
        <Cell key={uuid()} className={getCellClass(row.total)}>
          {' '}
          <FormatNumberFilter value={row.total} style="percent" />
        </Cell>
      </Fragment>
    </Table.Row>
  );

  const renderHeaderQuarterly = () => (
    <Table.Header>
      <Table.Row>
        <HeaderCell>Year</HeaderCell>
        <HeaderCell>Q1</HeaderCell>
        <HeaderCell>Q2</HeaderCell>
        <HeaderCell>Q3</HeaderCell>
        <HeaderCell>Q4</HeaderCell>
        <HeaderCell>Total</HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  const renderHeaderMonthly = () => (
    <Table.Header>
      <Table.Row>
        <HeaderCell>Year</HeaderCell>
        <HeaderCell>Jan</HeaderCell>
        <HeaderCell>Feb</HeaderCell>
        <HeaderCell>Mar</HeaderCell>
        <HeaderCell>Apr</HeaderCell>
        <HeaderCell>May</HeaderCell>
        <HeaderCell>Jun</HeaderCell>
        <HeaderCell>Jul</HeaderCell>
        <HeaderCell>Aug</HeaderCell>
        <HeaderCell>Sep</HeaderCell>
        <HeaderCell>Oct</HeaderCell>
        <HeaderCell>Nov</HeaderCell>
        <HeaderCell>Dec</HeaderCell>
        <HeaderCell>Total</HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  const renderHeader = () => {
    if (quarterly) {
      return renderHeaderQuarterly();
    }
    return renderHeaderMonthly();
  };

  const renderBody = () => {
    let field;
    if (quarterly) {
      field = 'quarterlyRows';
    } else {
      field = logReturns ? 'yearRowsLog' : 'yearRows';
    }

    return <Table.Body>{data[field].map(row => renderRow(row))}</Table.Body>;
  };

  const renderFooter = () => {
    let footerField;
    let positive;
    let negative;
    let totalPositive;
    let totalNegative;
    let totalAverage;

    if (quarterly) {
      footerField = 'quarterlyFooterRows';
      totalAverage = 'quarterlyTotalAverage';
      positive = 'positiveQuarters';
      negative = 'negativeQuarters';
      totalPositive = 'totalPositiveQuarters';
      totalNegative = 'totalNegativeQuarters';
    } else {
      footerField = logReturns ? 'footerRowsLog' : 'footerRows';
      totalAverage = logReturns ? 'totalAverageLog' : 'totalAverage';
      positive = 'positiveMonths';
      negative = 'negativeMonths';
      totalPositive = 'totalPositiveMonths';
      totalNegative = 'totalNegativeMonths';
    }

    return (
      <Table.Footer>
        <Table.Row>
          <Fragment>
            <Cell>Average</Cell>
            {data[footerField].averages.map(c => renderCell(c))}
            {renderCell(data[totalAverage], 'percent')}
          </Fragment>
        </Table.Row>

        <Table.Row>
          <Fragment>
            {quarterly && <Cell>+ve Quarters</Cell>}
            {!quarterly && <Cell>+ve Months</Cell>}
            {data[footerField][positive].map(c => (
              <Cell key={uuid()}> {c}</Cell>
            ))}
            <Cell key="totalPositiveMonths"> {data[totalPositive]}</Cell>
          </Fragment>
        </Table.Row>

        <Table.Row>
          <Fragment>
            {quarterly && <Cell>-ve Quarters</Cell>}
            {!quarterly && <Cell>-ve Months</Cell>}
            {data[footerField][negative].map(c => (
              <Cell key={uuid()}> {c}</Cell>
            ))}
            <Cell key={uuid()}> {data[totalNegative]}</Cell>
          </Fragment>
        </Table.Row>
      </Table.Footer>
    );
  };

  const handleLogReturns = () => {
    setLogReturns(!logReturns);
  };

  return (
    <Fragment>
      {SHOW_LOG_RETURNS && (
        <Button.Group style={{ marginBottom: '15px' }} color="blue" floated="right">
          <Button active={!logReturns} onClick={() => handleLogReturns(!logReturns)}>
            Returns
          </Button>
          <Button active={logReturns} onClick={() => handleLogReturns(!logReturns)}>
            Log Returns
          </Button>
        </Button.Group>
      )}

      <div style={{ marginBottom: '15px' }} color="blue">
        {asOfDate && <DateDiv> As of date: {formatIsoDate(asOfDate)}</DateDiv>}
      </div>

      {_.get(data, 'yearRows') && (
        <Table>
          {renderHeader()}
          {renderBody()}
          {renderFooter()}
        </Table>
      )}
    </Fragment>
  );
};
MonthlyPerformanceTable.defaultProps = {
  data: {},
  quarterly: false,
  asOfDate: null,
};

MonthlyPerformanceTable.propTypes = {
  data: PropTypes.object,
  quarterly: PropTypes.bool,
  asOfDate: PropTypes.any,
};

export default MonthlyPerformanceTable;
