import { fromJS } from 'immutable';
import { SELECT_THEME } from '../actions/themes.actions';
import getDynamicUrlThemeSelection from '../../config/themesConfig';

const initialState = fromJS({
  currentTheme:
    window.localStorage.getItem('alpima-console.theme') ||
    process.env.REACT_APP_THEME ||
    getDynamicUrlThemeSelection() ||
    'Alpima',
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_THEME:
      return state.merge({ currentTheme: action.payload });
    default:
      return state;
  }
}
