import { createSelector } from 'reselect';

const selectRoles = state => state.get('roles');

const selectRolesList = createSelector(selectRoles, roles => roles.get('list'));

const selectFunctionRolesList = createSelector(selectRoles, roles =>
  roles.getIn(['list', 'functionRoles']),
);

const selectRolesLoader = createSelector(selectRoles, roles => roles.get('loading'));

export { selectRolesList, selectRolesLoader, selectFunctionRolesList };
