export const LOGIN_STARTED = 'auth/LOGIN_STARTED';
export const LOGIN_SUCCEEDED = 'auth/LOGIN_SUCCEEDED';
export const LOGIN_FAILED = 'auth/LOGIN_FAILED';
export const SSO_LOGIN_STARTED = 'auth/SSO_LOGIN_STARTED';

export const LOGOUT_STARTED = 'auth/LOGOUT_STARTED';
export const LOGOUT_SUCCEEDED = 'auth/LOGOUT_SUCCEEDED';
export const LOGOUT_FAILED = 'auth/LOGOUT_FAILED';

export const GET_DISPLAY_USER_STARTED = 'auth/GET_DISPLAY_USER_STARTED';
export const GET_DISPLAY_USER_SUCCEEDED = 'auth/GET_DISPLAY_USER_SUCCEEDED';
export const GET_DISPLAY_USER_FAILED = 'auth/GET_DISPLAY_USER_FAILED';

export const LOAD_VALID_SESSION = 'auth/LOAD_VALID_SESSION';
export const CLEAR_SESSION = 'auth/CLEAR_SESSION';
