import { fromJS } from 'immutable';
import * as _ from 'lodash';
import * as constants from '../constants';

const initialState = fromJS({
  error: null,
  lastRequest: -1,
  data: null,
  isExporting: false,
  isRecalculating: false,
  recalculationResponse: {},
  recalculationError: null,
  lastRefresh: -1,
  loading: false,
  list: [],
  search: [],
  listError: null,
  details: {},
  cachedDetails: {},
});

export default (state = initialState, action) => {
  const data = action.payload?.data || {};

  switch (action.type) {
    case constants.STRATEGY_EXPORT_REQUEST:
      return state.set('isExporting', true);
    case constants.STRATEGY_EXPORT_SUCCESS:
      return state.merge({
        data: fromJS(action.payload),
        lastRequest: new Date().getTime(),
        error: null,
        isExporting: false,
      });
    case constants.STRATEGY_EXPORT_FAILURE:
      return state.merge({
        data: null,
        lastRequest: -1,
        isExporting: false,
        error: action.payload ? action.payload.error : action.error,
      });
    case constants.STRATEGY_EXPORT_CLEAR:
      return state.merge(initialState);

    case constants.STRATEGY_CALCULATION_REQUEST:
      return state.merge({
        isRecalculating: true,
        recalculationResponse: fromJS({}),
        recalculationError: null,
      });
    case constants.STRATEGY_CALCULATION_SUCCESS:
      return state.merge({
        recalculationResponse: fromJS(action.payload),
        recalculationError: null,
        isRecalculating: false,
      });
    case constants.STRATEGY_CALCULATION_FAILURE:
      return state.merge({
        isRecalculating: false,
        recalculationResponse: fromJS({}),
        recalculationError: action.payload ? action.payload.error : action.error,
      });
    case constants.GET_STRATEGY_LIST_SUCCESS:
      return state.merge({
        loading: false,
        list: fromJS(_.chunk(action.payload || [], 40)),
        lastRefresh: new Date().getTime(),
        listError: null,
      });
    case constants.GET_STRATEGY_LIST_FAILURE:
      return state.merge({
        loading: false,
        list: fromJS([]),
        listError: action.payload?.error || action.error,
      });
    case constants.GET_STRATEGY_DETAILS:
      return state.set('loading', true);
    case constants.GET_STRATEGY_DETAILS_SUCCESS:
      return state.merge({
        details: fromJS(data),
        cachedDetails: fromJS(data),
        loading: false,
        error: null,
      });
    case constants.UPDATE_STRATEGY_DETAILS_SUCCESS:
      return state.merge({
        details: fromJS(data),
        loading: false,
        error: null,
      });
    case constants.GET_STRATEGY_DETAILS_FAILURE:
      return state.merge({
        loading: false,
        lastRefresh: new Date().getTime(),
        error: action.payload ? action.payload.error : null,
      });
    case constants.SET_STRATEGY_DETAILS_CANCEL:
      return state.merge({
        details: state.get('cachedDetails'),
        error: null,
      });
    case constants.SET_STRATEGY_DETAILS_FAILURE:
      return state.merge({
        error: action.payload?.error || action.error,
      });
    case constants.SET_STRATEGY_DETAILS_SUCCESS:
      return state.merge({
        cachedDetails: state.get('details'),
        loading: false,
        error: null,
      });
    default:
      return state;
  }
};
