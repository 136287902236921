import { createSelector } from 'reselect';

const selectInformationBannersSection = state => state.get('informationBanners');

export const selectInformationBanners = createSelector(
  selectInformationBannersSection,
  informationBanners => informationBanners.get('informationBanners') ?? [],
);

export const selectInformationBannersLoader = createSelector(
  selectInformationBannersSection,
  informationBanners => informationBanners.get('isLoading'),
);

export const selectInformationBannerUpdater = createSelector(
  selectInformationBannersSection,
  informationBanners => informationBanners.get('isUpdating'),
);

export const selectInformationBannerModalStatus = createSelector(
  selectInformationBannersSection,
  informationBanners => informationBanners.get('isModalOpen'),
);

export const selectInformationBannerError = createSelector(
  selectInformationBannersSection,
  informationBanner => informationBanner.get('error') || null,
);
