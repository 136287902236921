import * as constants from '../constants';

export const getLogStream = () => ({
  type: constants.GET_LOGS_LIST,
});

export const getLogFilterContent = () => ({
  type: constants.GET_LOG_FILTERS,
});

export const getDailyErrorCount = () => ({
  type: constants.GET_LOG_DAILY_ERROR_COUNT,
});

export const resetLogFilters = () => ({
  type: constants.RESET_LOG_FILTERS,
});

export const setLogFilterState = (key, valueArray) => ({
  type: constants.SET_LOG_FILTER_STATE,
  key,
  payload: valueArray,
});

export const setLogFilterTimeState = (start, end, selection) => ({
  type: constants.SET_LOG_TIME_FILTER_STATE,
  payload: {
    start,
    end,
    selection,
  },
});

export const sortLogsByColumn = (column, direction) => ({
  type: constants.SET_LOG_COLUMN_SORT,
  payload: {
    direction,
    column,
  },
});

export const paginationChange = page => ({
  type: constants.LOGS_LIST_NAVIGATION,
  payload: page,
});
