import { useEffect } from 'react';

export default function useModalListeners(close: () => void) {
  // The background shouldn't be scrollable while the popup is open.
  useEffect(() => {
    const bodyElement = document.querySelector('body');
    if (bodyElement) bodyElement.style.overflow = 'hidden';

    return () => {
      if (bodyElement) bodyElement.style.overflow = 'initial';
    };
  }, []);

  // Escape key should close the popup.
  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        close();
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [close]);
}
