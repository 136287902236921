import React, { useState } from 'react';
import { BodyCell, HeaderCell, Table, TableHeader, TableBody, TableRow } from '@alpima/picasso';

import { Log } from './UserDetails.types';
import TablePagination from '../helpers/TablePagination';
import useSortAndPagination from '../helpers/usePagination';
import useDateTimeFormatter from '../../../../../helpers/useDateTimeFormatter';

export default function OperationalLogs({ logs }: { logs: Log[] }) {
  const { formatDate, formatDateTime } = useDateTimeFormatter();
  const sortedLogs = logs.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const paginatedLogs = useSortAndPagination<Log>(itemsPerPage, page, sortedLogs, setPage);

  const formatDateStamp = (date: string) =>
    date ? `${formatDate(new Date(date))} - ${formatDateTime(new Date(date))}` : '---';

  return (
    <div className="px-40 mt-12">
      {paginatedLogs && (
        <Table>
          <TableHeader>
            <TableRow className="text-md">
              <HeaderCell className="py-4 w-1/3">Event</HeaderCell>
              <HeaderCell className="py-4 w-1/3">Triggered by</HeaderCell>
              <HeaderCell className="py-4 w-1/3">Date</HeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedLogs?.map(log => (
              <TableRow key={`${log?.user?._id}-${log?.date}`}>
                <BodyCell className="py-4 text-center">{log.details}</BodyCell>
                <BodyCell className="py-4 text-center">{log.user?.displayName || ''}</BodyCell>
                <BodyCell className="py-4 text-center">{formatDateStamp(log.date)}</BodyCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {!paginatedLogs.length && (
        <div className="text-center w-full border-b border-l border-r border-gray-400 py-4">
          {!logs ? 'Loading users...' : 'No users found for this filter selection'}
        </div>
      )}
      {!!paginatedLogs.length && (
        <TablePagination
          itemsPerPage={itemsPerPage}
          itemsPerPageHandler={setItemsPerPage}
          page={page}
          pageHandler={setPage}
          numberOfRows={logs.length}
        />
      )}
    </div>
  );
}
