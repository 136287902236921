import React from 'react';
import { ID_APP_URL } from '../../../../services/clientAPI/lib/cognitoConfig';

export default function RoleMismatchPage() {
  return (
    <main className="h-screen items-center justify-center flex flex-col gap-8">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-24 w-24"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
        />
      </svg>
      <h1 className="text-center m-0">Only Admins and IT Service Operatives allowed.</h1>
      <h2 className="m-0">
        <a href={`${ID_APP_URL}?redirect=${window.location.href}`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 inline-block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11 17l-5-5m0 0l5-5m-5 5h12"
            />
          </svg>{' '}
          Go back to login
        </a>{' '}
        to reauthenticate with an admin account.
      </h2>
    </main>
  );
}
