import React, { useEffect, useMemo, useState } from 'react';
import {
  Back,
  Layout,
  PageHeader,
  Title,
  DateSelector,
  Button,
  Icons,
  ButtonTypes,
  Loader,
  FootNotes,
} from '@alpima/picasso';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import DataGrid, { SortColumn } from 'react-data-grid';
import useTriggeredService from '../../../../../helpers/useTriggeredService';
import { getStrategiesAlertsMonitoring$ } from '../../../../../services/clientAPI/strategyAlerts.service';
import DownloadButton from '../../../../molecules/DownloadButton/DownloadButton';
import DetailsModal, { getMappedStage } from './Popups/Details/DetailsModal';
import getStrategyAlertsColumns, { getRowDescription } from './helpers/getStrategyAlertsColumns';
import { Search, matchesSearch } from './Popups/Search';
import useVisibleColumns from './Popups/DisplayColumns/useVisibleColumns';
import DisplayColumns from './Popups/DisplayColumns/DisplayColumns';
import sortRows from './helpers/sortRows';
import { FilterSeverity, useFilterSeverity } from './Widgets/FilterSeverity';
import Filters, { useMoreFilters } from './Popups/Filters';
import filterOptions from './helpers/filterOptions';
import TimeDropdown from './Widgets/TimeDropdown';
import { StrategiesAlertsMonitoring } from './StrategyAlerts.types';
import './StrategyAlerts.css';
import { oneMonthAgo, today } from './helpers/timeOptions';
import { parseAlertsData } from './helpers/parseData';
import { TimePeriods } from '../shared.types';

/**
 * Main component for the Strategy Alerts page.
 * This contains:
 * - Back, title & download button
 * - Severity, date and time filters
 * - More filters, display-columns and search buttons
 * - Data grid showing the filtered strategy alerts.
 */

export default function StrategyAlerts() {
  const [time, setTime] = useQueryParam('time', withDefault(StringParam, '4am'));
  const [date, setDate] = useState(today);
  const [searchText, setSearchText] = useState('');
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);
  const now = new Date();
  const timeZoneOffset = now.getTimezoneOffset() * -1;

  const [loadingAlerts, errorAlerts, dataAlerts, refetchAlerts] = useTriggeredService<
    StrategiesAlertsMonitoring[]
  >(getStrategiesAlertsMonitoring$);

  const parsedAlertsData = useMemo(() => dataAlerts && parseAlertsData(dataAlerts), [dataAlerts]);

  useEffect(() => {
    if (!dataAlerts) refetchAlerts(date, timeZoneOffset);
  }, [dataAlerts, refetchAlerts, date, timeZoneOffset]);

  useEffect(() => {
    refetchAlerts(date, timeZoneOffset);
  }, [refetchAlerts, date, timeZoneOffset]);

  // Data for the popup which shows all the succeeded and failed steps for an alert in detail.
  const [popupDetails, setPopupDetails] = useState<StrategiesAlertsMonitoring | null>(null);
  // Filter rows by severity, timeslot, filters and search query.
  const [severity, filterSeverityProps] = useFilterSeverity();
  const [matchesFilter, clearFilters, filtersApplied, filterProps] = useMoreFilters(
    parsedAlertsData,
    filterOptions,
  );
  const filteredRows = useMemo(
    (): null | StrategiesAlertsMonitoring[] =>
      parsedAlertsData?.filter(
        row =>
          (!severity || row.severity === severity) &&
          row.formattedTime === time &&
          matchesFilter(row) &&
          matchesSearch(row, searchText),
      ) || null,
    [parsedAlertsData, severity, time, matchesFilter, searchText],
  );

  // Sort all the filtered rows by whatever columns we've chosen to sort by.
  const sortedRows = useMemo(
    (): StrategiesAlertsMonitoring[] => (filteredRows ? sortRows(filteredRows, sortColumns) : []),
    [filteredRows, sortColumns],
  );

  // Generate columns for react-data-grid (i.e. something which renders the cells.)
  const columns = useMemo(() => getStrategyAlertsColumns(setPopupDetails), [setPopupDetails]);
  const [visibleColumns, displayColumnsProps] = useVisibleColumns(columns);

  const filterOrSearchActive = filtersApplied || searchText !== '';

  // Format the CSV data to download with readable headers.
  const downloadData = parsedAlertsData?.map(row => {
    const data = columns.map(col => {
      if (col.name === 'Time') {
        return [col.name, row.scheduledAtTime];
      }
      if (col.name === 'Date') {
        return [col.name, row.scheduledAtDate];
      }
      if (col.name === 'Description') {
        return [col.name, getRowDescription(row.alertId)];
      }
      if (col.name === 'Details') {
        const details = row.stages.map(
          (item, index) =>
            `${index + 1}. ${getMappedStage(item.stage)}: ${item.status} ${item.error}`,
        );
        return [col.name, details];
      }
      return [col.name, row[col.key as keyof StrategiesAlertsMonitoring]];
    });

    return Object.fromEntries(data);
  });

  if (loadingAlerts) {
    return (
      <div className="my-12 w-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (errorAlerts) {
    return (
      <FootNotes className="border-none py-24 text-center">
        Failed to fetch alerts data, please try again!
      </FootNotes>
    );
  }

  return (
    <div className="flex flex-col mt-6">
      <Layout>
        <PageHeader>
          <Back />
          <div className="flex items-center justify-between w-full">
            <Title>Strategy Alerts</Title>
            {downloadData && (
              <DownloadButton
                disabled={!!(loadingAlerts || errorAlerts)}
                dataWithHeaders={downloadData}
                download="data.csv"
              >
                Download
              </DownloadButton>
            )}
          </div>
        </PageHeader>

        <div className="border-t-1 w-full flex items-center border-gray-300 py-5 justify-between gap-3 flex-wrap ml-auto flex-shrink">
          <div className="flex items-center gap-3">
            Severity
            <FilterSeverity {...filterSeverityProps} />
          </div>

          <div className="text-gray-600 flex items-center gap-3">
            Date
            <DateSelector value={date} setValue={setDate} minDate={oneMonthAgo} maxDate={today} />
          </div>

          <div className="text-gray-600 flex gap-3 items-center">
            Time
            <TimeDropdown time={time as TimePeriods} setTime={setTime} />
          </div>

          <div className="flex ml-auto 2xl:ml-0 flex-col items-end">
            <div className="flex flex-row">
              <DisplayColumns {...displayColumnsProps} />
              <Filters {...filterProps} />
              <Search searchText={searchText} setSearchText={setSearchText} />
            </div>
            <Button
              className={`font-bold text-sm border-none ${
                filterOrSearchActive ? '' : 'opacity-10 cursor-default'
              }`}
              type={ButtonTypes.IconLeft}
              icon={Icons.DELETE}
              disabled={!filterOrSearchActive}
              onClick={() => {
                setSearchText('');
                clearFilters();
              }}
            >
              Clear Filters &amp; Search
            </Button>
          </div>
        </div>

        {popupDetails && <DetailsModal data={popupDetails} close={() => setPopupDetails(null)} />}

        <div className="flex w-full justify-center sm:px-0 overflow-y-auto">
          {sortedRows?.length ? (
            <div className="max-w-screen mx-auto overflow-auto pb-10">
              <DataGrid
                className={`hide-ugly-selection h-full rdg-light text-sm ${
                  loadingAlerts ? 'opacity-50' : ''
                }`}
                columns={visibleColumns}
                rows={sortedRows}
                rowHeight={70}
                defaultColumnOptions={{
                  sortable: true,
                  resizable: true,
                }}
                sortColumns={sortColumns}
                onSortColumnsChange={setSortColumns}
              />
            </div>
          ) : (
            <>
              {!loadingAlerts && !errorAlerts && (
                <FootNotes className="text-center w-full">
                  No strategy alerts matched your current date and filter selection.
                </FootNotes>
              )}
            </>
          )}
        </div>
      </Layout>
    </div>
  );
}
