import React from 'react';
import { Button, ButtonStates, Popover, PopoverHeader } from '@alpima/picasso';

import { User } from './Navbar.types';
import { ID_APP_URL } from '../../../services/clientAPI/lib/cognitoConfig';
import { accessTokenState$ } from '../../../services/clientAPI/lib/accessToken';

type UsernamePopoverProps = {
  user: User;
  hidePopover: () => void;
  handleLogout: () => void;
};

const UsernamePopover = ({ user, hidePopover, handleLogout }: UsernamePopoverProps) => {
  const value = accessTokenState$.getValue();
  const hasToken = !(value === 'NoToken' || value === 'TokenLoading');
  const validToken = hasToken && value.validToken;
  return (
    <Popover hidePopover={hidePopover} classNames="top-16 right-8 w-64">
      <PopoverHeader>
        <div className="flex flex-col p-7">
          <p className="text-lg mb-0 text-gray-800">{user?.displayName}</p>
          <p className="text-sm text-gray-500">{user?.email}</p>
        </div>
      </PopoverHeader>
      <div className="flex flex-col px-7 pb-7 pt-4">
        {validToken && (
          <a
            className="text-gray-800"
            href={`${ID_APP_URL}/security?token=${validToken}`}
            target="_blank"
            rel="noreferrer"
          >
            Password &amp; Security
          </a>
        )}
        <div className="mt-5">
          <Button state={ButtonStates.Solid} onClick={handleLogout} className="w-full">
            Sign out
          </Button>
        </div>
      </div>
    </Popover>
  );
};

export default UsernamePopover;
