/* eslint-disable import/prefer-default-export */
import { of, iif } from 'rxjs';
import { mergeMap, catchError, withLatestFrom } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as constants from '../constants';

import {
  fetchLoginUsersByContractRole,
  fetchLoginUsersByFunctionRole,
  fetchLoginUsersAggregate,
  fetchLoginUsersTableData,
  fetchStrategyCreationSeriesData,
  fetchStrategyTableData,
  fetchLatestSplunkTimestamps,
  fetchBloombergImportStatus,
  fetchOvernightCalculationsStatus,
  fetchICDecisionPreconditions,
} from '../../services/clientAPI/monitoring.service';

const fetchLoginStatsByState = (state = {}) => {
  const filter = state.getIn(['monitoring', 'filter']).toJS() || {};

  switch (filter.chartType) {
    case 'contract-roles':
      return fetchLoginUsersByContractRole(filter);
    case 'function-roles':
      return fetchLoginUsersByFunctionRole(filter);
    case 'aggregate':
      return fetchLoginUsersAggregate(filter);
    default:
      return fetchLoginUsersAggregate(filter);
  }
};

export const monitoringTimeRangeUpdatedEpic = (actions$, state$) =>
  actions$.pipe(
    ofType(constants.SET_MONITORING_TIME_FILTER_STATE),
    withLatestFrom(state$),
    mergeMap(([, state]) =>
      iif(
        () => {
          const s = state.getIn(['router', 'location', 'pathname']);
          return !!~s.indexOf('/monitoring/logins');
        },
        of({ type: constants.GET_USER_LOGIN_CHART_DATA }),
        of({ type: constants.GET_USER_LOGIN_CHART_DATA_CANCEL }),
      ),
    ),
    catchError(error =>
      of({
        type: constants.GET_USER_LOGIN_CHART_DATA_FAILURE,
        error: error.message,
      }),
    ),
  );

export const getUserLoginsChartDataEpic = (actions$, state$) =>
  actions$.pipe(
    ofType(constants.GET_USER_LOGIN_CHART_DATA, constants.SET_MONITORING_LOGIN_CHART_UNKNOWNS),
    withLatestFrom(state$),
    mergeMap(([, state]) =>
      fetchLoginStatsByState(state).pipe(
        mergeMap(response => [
          {
            type: constants.GET_USER_LOGIN_CHART_DATA_SUCCESS,
            payload: response,
          },
        ]),
      ),
    ),
    catchError(error =>
      of({
        type: constants.GET_USER_LOGIN_CHART_DATA_FAILURE,
        error: error.message,
      }),
    ),
  );

export const fetchLoginUserTableDataEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_USER_LOGIN_TABLE_DATA),
    mergeMap(action =>
      fetchLoginUsersTableData(action.year).pipe(
        mergeMap(response => [
          {
            type: constants.GET_USER_LOGIN_TABLE_DATA_SUCCESS,
            payload: response,
          },
        ]),
      ),
    ),
    catchError(error =>
      of({
        type: constants.GET_USER_LOGIN_TABLE_DATA_FAILURE,
        error: error.message,
      }),
    ),
  );

export const fetchStrategyCreationSeriesDataEpic = (actions$, state$) =>
  actions$.pipe(
    ofType(constants.GET_STRATEGY_CREATION_SERIES_DATA),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      fetchStrategyCreationSeriesData({
        productLines: action.productLines || [],
        currencies: action.currencies || [],
        unknown: action.showUnknowns || false,
        cumulative: action.showCumulative,
        timeRanges:
          (() => {
            const filter = state.getIn(['monitoring', 'filter']).toJS();
            return {
              rangeStart: filter.rangeStart,
              rangeEnd: filter.rangeEnd,
            };
          })() || {},
      }).pipe(
        mergeMap(response => [
          {
            type: constants.GET_STRATEGY_CREATION_SERIES_DATA_SUCCESS,
            unknown: action.unknown,
            cumulative: action.cumulative,
            payload: response,
          },
        ]),
      ),
    ),
    catchError(error =>
      of({
        type: constants.GET_STRATEGY_CREATION_SERIES_DATA_FAILURE,
        error: error.message,
      }),
    ),
  );

export const fetchStrategyTableDataEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_STRATEGY_TABLE_DATA),
    mergeMap(action =>
      fetchStrategyTableData(action.year, action.includeDeleted).pipe(
        mergeMap(response => [
          {
            type: constants.GET_STRATEGY_TABLE_DATA_SUCCESS,
            payload: response,
          },
        ]),
      ),
    ),
    catchError(error =>
      of({
        type: constants.GET_STRATEGY_TABLE_DATA_FAILURE,
        error: error.message,
      }),
    ),
  );

export const fetchSplunkTimestamps = actions$ =>
  actions$.pipe(
    ofType(constants.GET_SPLUNK_TIMESTAMPS),
    mergeMap(() =>
      fetchLatestSplunkTimestamps().pipe(
        mergeMap(response => [
          {
            type: constants.GET_SPLUNK_TIMESTAMPS_SUCCESS,
            payload: response,
          },
        ]),
      ),
    ),
    catchError(error =>
      of({
        type: constants.GET_SPLUNK_TIMESTAMPS_FAILURE,
        error: error.message,
      }),
    ),
  );

export const fetchBloombergImportStatusEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_BLOOMBERG_IMPORT_STATUS),
    mergeMap(() =>
      fetchBloombergImportStatus().pipe(
        mergeMap(response => [
          { type: constants.GET_BLOOMBERG_IMPORT_STATUS_SUCCESS, payload: response },
        ]),
      ),
    ),
    catchError(error =>
      of({
        type: constants.GET_BLOOMBERG_IMPORT_STATUS_FAILURE,
        error: error.message,
      }),
    ),
  );

export const fetchOvernightCalculationsStatusEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_OVERNIGHT_CALCULATIONS_STATUS),
    mergeMap(() =>
      fetchOvernightCalculationsStatus().pipe(
        mergeMap(response => [
          { type: constants.GET_OVERNIGHT_CALCULATIONS_STATUS_SUCCESS, payload: response },
        ]),
      ),
    ),
    catchError(error =>
      of({
        type: constants.GET_OVERNIGHT_CALCULATIONS_STATUS_FAILURE,
        error: error.message,
      }),
    ),
  );

export const fetchICDecisionPreconditionsEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_IC_DECISION_PRECONDITIONS),
    mergeMap(() =>
      fetchICDecisionPreconditions().pipe(
        mergeMap(response => [
          { type: constants.GET_IC_DECISION_PRECONDITIONS_SUCCESS, payload: response },
        ]),
      ),
    ),
    catchError(error =>
      of({
        type: constants.GET_IC_DECISION_PRECONDITIONS_FAILURE,
        error: error.message,
      }),
    ),
  );
