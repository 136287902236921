import React from 'react';
import { storiesOf } from '@storybook/react';

import { WidgetDefault } from './index';
import ChartDefault from '../../atoms/ChartDefault';

const Footer = () => <span>Footer</span>;

const WidgetContent = () => <h1>Widget Default Content</h1>;

const actions = [
  {
    label: 'Contribution',
  },
  {
    label: 'Monthly Returns / Histogram',
  },
  {
    label: 'Efficient Frontier',
  },
  {
    label: 'Risk/Return',
  },
];

const defaultProps = {
  footerComponent: <Footer />,
  title: 'Alpima Default Widget',
  actions,
};

storiesOf('WidgetDefault', module)
  .addDecorator(story => <div style={{ margin: '50px', textAlign: 'left' }}>{story()}</div>)
  .add('default', () => (
    <WidgetDefault {...defaultProps}>
      <WidgetContent />
    </WidgetDefault>
  ))
  .add('header hidden', () => (
    <WidgetDefault {...defaultProps} hideHeader>
      <WidgetContent />
    </WidgetDefault>
  ))
  .add('with footer', () => (
    <WidgetDefault {...defaultProps} showFooter>
      <WidgetContent />
    </WidgetDefault>
  ))
  .add('widget with a chart', () => (
    <WidgetDefault title="Widget with a chart">
      <ChartDefault />
    </WidgetDefault>
  ))
  .add('widget with a chart and actions', () => (
    <WidgetDefault title="Widget with a chart and actions" actions={[{ label: 'Contribution' }]}>
      <ChartDefault />
    </WidgetDefault>
  ));
