import React from 'react';
import { Button, ButtonTypes, Icons } from '@alpima/picasso';

import List, { Item } from './List';
import BlankCanvas from '../../atoms/BlankCanvas';

type UniverseProps<T extends Item> = {
  data: T[];
  setData: (arg: T[]) => void;
  selected: T[];
  setSelected: (arg: T[]) => void;
  title?: string;
};

function TransferList<T extends Item>({
  leftUniverse,
  rightUniverse,
  loading,
}: {
  leftUniverse: UniverseProps<T> & { filteredData: T[] };
  rightUniverse: UniverseProps<T>;
  loading: boolean;
}) {
  const handleAllRight = () => {
    rightUniverse.setData([...leftUniverse.data, ...rightUniverse.data]);
    leftUniverse.setData([]);
  };

  const handleAllLeft = () => {
    leftUniverse.setData([...rightUniverse.data, ...leftUniverse.data]);
    rightUniverse.setData([]);
  };

  const handleToggleRight = () => {
    const selectedIds = leftUniverse.selected.map(item => item._id);
    rightUniverse.setData([...rightUniverse.data, ...leftUniverse.selected]);
    leftUniverse.setSelected([]);
    leftUniverse.setData(leftUniverse.data.filter(item => !selectedIds.includes(item._id)));
  };

  const handleToggleLeft = () => {
    const selectedIds = rightUniverse.selected.map(item => item._id);
    leftUniverse.setData([...leftUniverse.data, ...rightUniverse.selected]);
    rightUniverse.setSelected([]);
    rightUniverse.setData(rightUniverse.data.filter(item => !selectedIds.includes(item._id)));
  };

  const handleSelection = (selected: T[], setSelected: (items: T[]) => void, asset: T) =>
    selected.find(item => item._id === asset._id)
      ? setSelected(selected.filter(item => item._id !== asset._id))
      : setSelected([...selected, asset]);

  const handleLeftSelection = (asset: Item) =>
    handleSelection(leftUniverse.selected, leftUniverse.setSelected, asset as unknown as T);
  const handleRightSelection = (asset: Item) =>
    handleSelection(rightUniverse.selected, rightUniverse.setSelected, asset as unknown as T);

  return (
    <div className="w-full h-128 flex items-center flex-row space-x-10">
      <div style={{ width: '45%' }} className="h-full">
        {leftUniverse.title && (
          <p className="m-0 text-gray-500 font-semibold mb-2">
            {leftUniverse.title}
            <span className="text-black ml-1">{leftUniverse.filteredData.length}</span>
          </p>
        )}
        <List
          items={leftUniverse.filteredData as unknown as Item[]}
          selected={leftUniverse.selected as unknown as Item[]}
          handleSelection={handleLeftSelection}
          fallback={
            <BlankCanvas
              loading={loading}
              title={loading ? 'Loading items...' : 'Select users on the right'}
            />
          }
        />
      </div>
      <div className="space-y-6">
        <Button
          type={ButtonTypes.Icon}
          icon={Icons.FIRST_PAGE}
          onClick={handleAllLeft}
          disabled={!rightUniverse.data.length}
        />
        <Button
          type={ButtonTypes.Icon}
          icon={Icons.CHEVRON_RIGHT}
          onClick={handleToggleRight}
          disabled={!leftUniverse.selected.length}
        />
        <Button
          type={ButtonTypes.Icon}
          icon={Icons.CHEVRON_LEFT}
          onClick={handleToggleLeft}
          disabled={!rightUniverse.selected.length}
        />
        <Button
          type={ButtonTypes.Icon}
          icon={Icons.LAST_PAGE}
          onClick={handleAllRight}
          disabled={!leftUniverse.data.length}
        />
      </div>
      <div style={{ width: '45%' }} className="h-full">
        {rightUniverse.title && (
          <p className="m-0 text-gray-500 font-semibold mb-2">
            {rightUniverse.title}
            <span className="text-black ml-1">{rightUniverse.data.length}</span>
          </p>
        )}
        <List
          items={rightUniverse.data}
          selected={rightUniverse.selected}
          handleSelection={handleRightSelection}
          fallback={<BlankCanvas title="Select users on the left" />}
        />
      </div>
    </div>
  );
}

export default TransferList;
