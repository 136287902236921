import { filter as LogLevelFilter } from './logLevels.filters';
import { filter as LogTypeFilter } from './logTypes.filters';
import { filter as LogUsersFilter } from './logUsers.filters';
import { filter as LogGroupsFilter } from './logGroups.filters';
import { filter as StrategyExportFilter } from './strategy.filters';
import { filter as StrategyMonitoringProductLines } from './monitoringStrategyCreationLines.filters';
import { filter as StrategyMonitoringCurrencies } from './monitoringStrategyCreationCurrencies.filters';
import { filter as DateRangeFilters } from './logDateRange.filters';

const filters = {
  LogLevelFilter,
  LogTypeFilter,
  LogUsersFilter,
  LogGroupsFilter,
  StrategyExportFilter,
  StrategyMonitoringProductLines,
  StrategyMonitoringCurrencies,
  DateRangeFilters,
};

export default filters;
