/* eslint-disable import/prefer-default-export */
import _ from 'lodash';

export const filterFundsData = (data, filter, tags) => {
  const { searchQuery, toggleValue } = filter;
  const tagsFilters = filter.value.map(val => (tags.findIndex(el => el === val) > -1 ? val : null));

  let toggleFiltered = [];

  if (
    toggleValue &&
    toggleValue.indexOf('Segregated') > -1 &&
    toggleValue.indexOf('Sleeves') > -1
  ) {
    toggleFiltered = data.filter(
      item => item.isSleeve || !item.isSleeve || item.isSegregated || !item.isSegregated,
    );
  }
  if (
    toggleValue &&
    toggleValue.indexOf('Segregated') === -1 &&
    toggleValue.indexOf('Sleeves') > -1
  ) {
    toggleFiltered = data.filter(item => (item.isSleeve || !item.isSleeve) && !item.isSegregated);
  }
  if (
    toggleValue &&
    toggleValue.indexOf('Segregated') > -1 &&
    toggleValue.indexOf('Sleeves') === -1
  ) {
    toggleFiltered = data.filter(
      item => !item.isSleeve && (item.isSegregated || !item.isSegregated),
    );
  }
  if (
    toggleValue &&
    toggleValue.indexOf('Segregated') === -1 &&
    toggleValue.indexOf('Sleeves') === -1
  ) {
    toggleFiltered = data.filter(item => !item.isSleeve && !item.isSegregated);
  }

  if (!searchQuery && !tagsFilters.length) return toggleFiltered;

  const filteredByTags = tagsFilters.length
    ? toggleFiltered.filter(
        item =>
          tagsFilters.findIndex(el => item.tags && item.tags.findIndex(x => x === el) > -1) > -1,
      )
    : toggleFiltered;

  const regex = new RegExp(searchQuery, 'i');
  const filteredByName = filteredByTags.filter(item => regex.test(item.name));
  const filteredByCode = filteredByTags.filter(item => regex.test(item.code));

  const repetitionRemoved = filteredByCode.filter(item => {
    const isAlready = filteredByName.find(it => it._id === item._id);
    return isAlready === undefined;
  });
  return filteredByName.concat(repetitionRemoved);
};

export const holdingsFilter = (data, query) => {
  const re = new RegExp(_.escapeRegExp(query), 'i');
  const isMatchIssuer = result => re.test(result.issuer);
  const isMatchIsin = result => re.test(result.isin);

  const filteredByIssuer = _.filter(data, isMatchIssuer);
  const filteredByIsin = _.filter(data, isMatchIsin);
  const repetitionRemoved = filteredByIsin.filter(item => {
    const isAlready = filteredByIssuer.find(it => it._id === item._id);
    return isAlready === undefined;
  });
  return filteredByIssuer.concat(repetitionRemoved);
};

export const sortDataByColumn = (data, column, dir) => {
  const sorted = _.orderBy(
    data,
    [
      o => {
        if (typeof o[column] === 'string') {
          return o[column].toLowerCase();
        }
        return o[column];
      },
      o => {
        if (!o[column] && o[column] !== 0) return -100000000;
        return o[column];
      },
    ],
    dir,
  );

  return sorted;
};

export const filterShareClasses = (data, filter) => {
  const tagsFilters = filter.value;
  const filteredByTags = tagsFilters.length
    ? data.filter(
        item =>
          tagsFilters.findIndex(el => item.tags && item.tags.findIndex(x => x === el) > -1) > -1,
      )
    : data;

  return filteredByTags;
};
