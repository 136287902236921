import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

const BenchmarkFilter = ({ benchmarkId, options, onChange }) => {
  const handleChange = (e, data) => {
    onChange(data.value);
  };

  return (
    <Dropdown
      placeholder="Select Benchmark"
      options={options}
      fluid
      selection
      closeOnChange
      value={benchmarkId}
      onChange={handleChange}
    />
  );
};

BenchmarkFilter.propTypes = {
  benchmarkId: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

BenchmarkFilter.defaultProps = {
  benchmarkId: '',
};

export default BenchmarkFilter;
