import React from 'react';
import styled from 'styled-components';

const WidgetDefaultFooterWrapper = styled.div`
  padding: 10px 15px;
  background-color: ;
  background-color: ${props => props.theme.variables.widgetDefaultFooterBackground || '#f5f5f5'};
  border-top: 1px solid #dddddd;
  border-bottom-right-radius: 1px;
  border-bottom-left-radius: 1px;
`;

// eslint-disable-next-line react/prop-types
const WidgetDefaultFooter = ({ children }) => (
  <WidgetDefaultFooterWrapper>{children}</WidgetDefaultFooterWrapper>
);

export default WidgetDefaultFooter;
