import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { List } from 'semantic-ui-react';

const HeaderLink = styled.a`
  font-size: smaller;
  padding: 3px 5px 0 0;
  text-decoration: none;
  color: ${props => props.theme.variables.widgetDefaultHeaderBackground || 'blue;'};
  cursor: pointer;
  text-transform: none;

  &:hover {
    color: ${props => props.theme.variables.linkHover || 'blue;'};
  }
`;

const WidgetDefaultToolbar = ({
  actions,
  openModal,
  toggleContent,
  isCollapsed,
  hideToggleContent,
}) => {
  const handleOpenModal = template => openModal(template);

  return (
    <List horizontal floated="right">
      {actions &&
        actions.map(action => (
          <List.Item key={`action-${action.label}`} onClick={() => handleOpenModal(action)}>
            <HeaderLink>{action.label}</HeaderLink>
            <List.Icon name={action.icon || 'cog'} />
          </List.Item>
        ))}
      {hideToggleContent ? (
        <List.Item>
          {' '}
          <span style={{ margin: '1em 0 !important' }}>&nbsp;</span>
        </List.Item>
      ) : (
        <List.Item onClick={() => toggleContent()}>
          <List.Icon name={isCollapsed ? 'angle left' : 'angle down'} />
        </List.Item>
      )}
    </List>
  );
};

WidgetDefaultToolbar.defaultProps = {
  actions: [],
  openModal: () => {},
  toggleContent: () => {},
  isCollapsed: false,
  hideToggleContent: false,
};

WidgetDefaultToolbar.propTypes = {
  actions: PropTypes.array,
  openModal: PropTypes.func,
  toggleContent: PropTypes.func,
  isCollapsed: PropTypes.bool,
  hideToggleContent: PropTypes.bool,
};

export default WidgetDefaultToolbar;
