import { useCallback, useState } from 'react';
import { Order } from './UserList.types';

const useSortOrder = <T>(defaultSortProperty: keyof T, defaultOrder?: Order) => {
  const [sortingProperty, setSortingProperty] = useState<keyof T>(defaultSortProperty);
  const [order, setOrder] = useState<Order>(defaultOrder || Order.Ascending);

  const changeSortOrder = useCallback(
    (property: keyof T) => {
      if (!property) return;

      if (property === sortingProperty)
        setOrder(order === Order.Ascending ? Order.Descending : Order.Ascending);
      else setSortingProperty(property);
    },
    [order, sortingProperty],
  );

  return {
    order,
    changeSortOrder,
    sortingProperty,
  };
};

export default useSortOrder;
