import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Checkbox, Grid, GridColumn, GridRow, Loader } from 'semantic-ui-react';

import {
  selectStrategyExportData,
  selectStrategyExportDataLoading,
} from '../../../store/selectors/strategy.selector';
import Button from '../../atoms/Button';
import useToggle from '../../../helpers/useToggle';
import useExportStrategyData from './useExportStrategyData';

const StrategyExport = styled.div`
  padding: 15px;
`;

export default function StrategyExportWidget() {
  const dataForExport = useSelector(selectStrategyExportData);
  const isFetching = useSelector(selectStrategyExportDataLoading);
  const [formatAsJSON, toggleFormatAsJSON] = useToggle(false);
  const filename = `StrategyExport-${new Date().getTime()}.${formatAsJSON ? 'json' : 'csv'}`;
  const { exportData, linkRef } = useExportStrategyData(dataForExport, formatAsJSON, filename);

  return (
    <StrategyExport style={{ minHeight: '125px' }}>
      <h2>
        Strategy Export <Loader inline size="tiny" active={isFetching} />
      </h2>
      <hr />
      <Grid>
        <GridRow>
          <GridColumn width={16} style={{ marginBottom: 10 }}>
            <Checkbox label="JSON format" checked={formatAsJSON} onChange={toggleFormatAsJSON} />
          </GridColumn>
          <GridColumn width={16}>
            <Button primary onClick={exportData}>
              Export
            </Button>
            <a download={filename} ref={linkRef} target="_self" href={exportData}>
              &nbsp;
            </a>
          </GridColumn>
        </GridRow>
      </Grid>
    </StrategyExport>
  );
}
