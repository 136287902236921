import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const ThemeSelector = ({ themes, selectTheme }) => {
  const [theme, setTheme] = useState('BlueBay');
  const handleSelect = (e, { value }) => {
    setTheme(value);
    selectTheme(value);
  };
  const items = Object.keys(themes);

  return (
    <Form>
      <Form.Group inline>
        <span>Theme</span>
        {items.map(item => (
          <Form.Radio
            key={item}
            label={item}
            value={item}
            checked={theme === item}
            onChange={handleSelect}
          />
        ))}
      </Form.Group>
    </Form>
  );
};

ThemeSelector.propTypes = {
  themes: PropTypes.object.isRequired,
  selectTheme: PropTypes.func.isRequired,
};

export default ThemeSelector;
