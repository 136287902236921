export const GET_USERS_LIST = 'GET_USERS_LIST';
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS';
export const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE';
export const GET_USERS_LIST_CANCELLED = 'GET_USERS_LIST_CANCELLED';

export const SET_USER_SEARCH_RESULTS = 'SET_USER_SEARCH_RESULTS';
export const CLEAR_USER_SEARCH_RESULTS = 'CLEAR_USER_SEARCH_RESULTS';

export const SET_USER_COLUMN_SORT = 'SET_USER_COLUMN_SORT';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILURE = 'GET_USER_DETAILS_FAILURE';
export const STAMP_USER_DETAILS = 'STAMP_USER_DETAILS';
export const STAMP_INITIAL_USER_DETAILS_ROLES = 'STAMP_INITIAL_USER_DETAILS_ROLES';

export const STAMP_ENABLE_USER = 'STAMP_ENABLE_USER';
export const STAMP_DISABLE_USER = 'STAMP_DISABLE_USER';

export const REQUEST_USER_DETAILS_UPDATE = 'REQUEST_USER_DETAILS_UPDATE';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_USER_DETAILS_SUCCESS = 'SET_USER_DETAILS_SUCCESS';
export const SET_USER_DETAILS_FAILURE = 'SET_USER_DETAILS_FAILURE';
export const SET_USER_DETAILS_CANCEL = 'SET_USER_DETAILS_CANCEL';

export const GET_ONLINE_USERS = 'GET_ONLINE_USERS';
export const GET_ONLINE_USERS_SUCCESS = 'GET_ONLINE_USERS_SUCCESS';
export const GET_ONLINE_USERS_FAILURE = 'GET_ONLINE_USERS_FAILURE';
export const GET_ONLINE_USERS_CANCELLED = 'GET_ONLINE_USERS_CANCELLED';

export const GET_PENDING_USERS = 'GET_PENDING_USERS';
export const GET_PENDING_USERS_SUCCESS = 'GET_PENDING_USERS_SUCCESS';
export const GET_PENDING_USERS_FAILURE = 'GET_PENDING_USERS_FAILURE';
export const GET_PENDING_USERS_CANCELLED = 'GET_PENDING_USERS_CANCELLED';

export const GET_UPDATED_CONTRACT_ROLE_USERS = 'GET_UPDATED_CONTRACT_ROLE_USERS';
export const GET_UPDATED_CONTRACT_ROLE_USERS_SUCCESS = 'GET_UPDATED_CONTRACT_ROLE_USERS_SUCCESS';
export const GET_UPDATED_CONTRACT_ROLE_USERS_FAILURE = 'GET_UPDATED_CONTRACT_ROLE_USERS_FAILURE';
export const GET_UPDATED_CONTRACT_ROLE_USERS_CANCELLED =
  'GET_UPDATED_CONTRACT_ROLE_USERS_CANCELLED';

export const SET_USER_ONBOARDING_STATE = 'SET_USER_ONBOARDING_STATE';
export const SET_USER_ONBOARDING_STATE_SUCCESS = 'SET_USER_ONBOARDING_STATE_SUCCESS';
export const SET_USER_ONBOARDING_STATE_FAILURE = 'SET_USER_ONBOARDING_STATE_FAILURE';
export const SET_USER_ONBOARDING_STATE_CANCELLED = 'SET_USER_ONBOARDING_STATE_CANCELLED';

export const SET_USER_NEW_CONTRACT_ROLE = 'SET_USER_NEW_CONTRACT_ROLE';
export const SET_USER_NEW_CONTRACT_ROLE_SUCCESS = 'SET_USER_NEW_CONTRACT_ROLE_SUCCESS';
export const SET_USER_NEW_CONTRACT_ROLE_FAILURE = 'SET_USER_NEW_CONTRACT_ROLE_FAILURE';
export const SET_USER_NEW_CONTRACT_ROLE_CANCELLED = 'SET_USER_NEW_CONTRACT_ROLE_CANCELLED';

export const SET_OBJECT_ROLES_SUCCESS = 'SET_OBJECT_ROLES_SUCCESS';
export const SET_OBJECT_ROLES_FAILURE = 'SET_OBJECT_ROLES_FAILURE';

export const GET_USER_OBJECT_ROLES = 'GET_USER_OBJECT_ROLES';
export const GET_USER_OBJECT_ROLES_SUCCESS = 'GET_USER_OBJECT_ROLES_SUCCESS';
export const GET_USER_OBJECT_ROLES_FAILURE = 'GET_USER_OBJECT_ROLES_FAILURE';

export const USERS_EXPORT_REQUEST = 'USERS_EXPORT_REQUEST';
export const USERS_EXPORT_REQUEST_SUCCESS = 'USERS_EXPORT_REQUEST_SUCCESS';
export const USERS_EXPORT_REQUEST_FAILURE = 'USERS_EXPORT_REQUEST_FAILURE';

export const USERS_EXPORT_CLEAR = 'USERS_EXPORT_CLEAR';
