/* eslint-disable no-case-declarations */
import { fromJS } from 'immutable';
import { removeCookie } from '../../helpers/cookie.helper';

import * as constants from '../constants';

const authorisedUserRoles = [
  'admin',
  'bjb-itso',
  'bjb-business-tech',
  'bbva-admin',
  'bluebay-admin',
];

const initialState = fromJS({
  isAuthenticated: false,
  user: null,
  token: null,
  loading: false,
  error: null,
});

function userHasValidRole(roles = []) {
  let userAuthorised = false;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < authorisedUserRoles.length; i++) {
    if (roles.indexOf(authorisedUserRoles[i]) !== -1) {
      userAuthorised = true;
      break;
    }
  }

  return userAuthorised;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.LOGIN_STARTED:
      return state.set('loading', true);
    case constants.LOGIN_SUCCEEDED:
      return state.merge({
        isAuthenticated: true,
        token: action.payload ? action.payload.token : null,
        loading: false,
        error: null,
      });
    case constants.LOGIN_FAILED:
      return state.merge({
        loading: false,
        error: action.payload ? action.payload.error : 'Unable to login with supplied credentials',
      });

    case constants.GET_DISPLAY_USER_STARTED:
      return state.set('loading', true);
    case constants.GET_DISPLAY_USER_SUCCEEDED:
      const userRoles = (action.payload && action.payload.roles) || [];
      const validRoleAttached = userHasValidRole(userRoles);
      if (validRoleAttached) {
        return state.merge({
          user: action.payload,
          loading: false,
        });
      }
      removeCookie('alp-auth');
      removeCookie('alp-provider');
      removeCookie('display-user');
      return state.merge({
        isAuthenticated: false,
        invalidRoles: true,
        loading: false,
      });

    case constants.GET_DISPLAY_USER_FAILED:
      return state.merge({
        loading: false,
        error: action.payload ? action.payload.error : null,
      });

    case constants.LOGOUT_STARTED:
      return state.set('loading', true);
    case constants.LOGOUT_SUCCEEDED:
      return state.merge({
        isAuthenticated: false,
        user: null,
        token: null,
        loading: false,
        error: null,
      });
    case constants.LOGOUT_FAILED:
      return state.merge({
        loading: false,
        error: action.payload ? action.payload.error : null,
      });

    case constants.CLEAR_SESSION:
      return state.merge({
        isAuthenticated: false,
        user: null,
        token: null,
      });
    default:
      return state;
  }
};
