import _, { cloneDeep, isEmpty, isEqual } from 'lodash';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { FilterOption, FilterProps, FilterState, MatchesFilter } from './Filters.types';

export default function useMoreFilters<T extends Record<string, unknown>>(
  allItems: null | T[],
  filters: FilterOption<T>[],
): [MatchesFilter<T>, () => void, boolean, FilterProps<T>] {
  // Compute all possible values for each of our defined filters.
  const possibleValues = useMemo(() => {
    const allKeys = filters.map(f => f.key);
    const allPossibleValues = Object.fromEntries(allKeys.map(key => [key, new Set()]));
    allItems?.forEach(item => {
      allKeys.forEach(key => {
        const value = item[key];
        allPossibleValues[key].add(value);
      });
    });
    return allPossibleValues;
  }, [allItems, filters]);

  // Start the filtering with *all* the options selected, and deselect items to filter them down.
  const [filterState, setFilterState] = useState<FilterState>(cloneDeep(possibleValues));

  // If `allItems` doesn't load immediately, we need to update our filter state with the new set
  // of possible values. This has the nice side-effect of resetting filters when we change our data,
  // but if this isn't desired, this function should be changed to copy the existing filters.
  useEffect(() => {
    if (!isEmpty(possibleValues)) {
      setFilterState(cloneDeep(possibleValues));
    }
  }, [possibleValues]);

  // Provide a function to test if an item matches the current set of filters.
  const matchesFilter = useCallback(
    (item: T): boolean =>
      Object.entries(filterState).every(([itemKey, selectedOptions]) => {
        const thisItemValue = item[itemKey];
        return selectedOptions.has(thisItemValue);
      }),
    [filterState],
  );

  const clearFilter = useCallback(() => {
    setFilterState(cloneDeep(possibleValues));
  }, [possibleValues]);

  // Memoize the props sent to the Filters component so it doesn't rerender unnecessarily.
  const filterProps = useMemo(
    () => ({ filterState, setFilterState, possibleValues, filters }),
    [filterState, setFilterState, possibleValues, filters],
  );

  const filtersApplied = useMemo(() => {
    return !isEqual(filterState, possibleValues);
  }, [filterState, possibleValues]);

  return [matchesFilter, clearFilter, filtersApplied, filterProps];
}
