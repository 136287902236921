import React, { Fragment } from 'react';

export function Cross({
  width,
  height,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className || 'w-3.5 h-3.5 pt-px'}
      width={width || 12}
      height={height || 12}
      fill="currentColor"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  );
}

export function Tick({
  width,
  height,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width || 12}
      height={height || 12}
      fill="currentColor"
      className={className || 'w-3.5 h-3.5'}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M8.99999 16.2L4.79999 12L3.39999 13.4L8.99999 19L21 7L19.6 5.6L8.99999 16.2Z" />
    </svg>
  );
}

const successStep = 'bg-green-400 w-5 h-5 rounded-full';
const successDivider = 'bg-green-400 w-3 h-1';
const failureDivider = 'bg-gradient-to-r from-green-500 via-yellow-400 to-red-500 w-3 h-1';
const failureStep =
  'bg-red-500 w-5 h-5 rounded-full text-white text-center font-bold flex items-center justify-center';
const unreachedDivider = 'bg-gray-400 w-3 h-1';
const unreachedStep = 'bg-gray-400 w-5 h-5 rounded-full';

type StepsProps = {
  total: number;
  failedStep?: number;
  onClick: () => void;
};

type StepStyles = (readonly [indicator: string, divider: string, isFailure: boolean])[];

function getStepStyles(total: number, failedStep: number): StepStyles {
  const steps: (readonly [string, string, boolean])[] = [];
  for (let i = 0; i < total; i += 1) {
    const isLast = i === total - 1;
    if (i < failedStep) {
      const divider = i === failedStep - 1 ? failureDivider : successDivider;
      steps.push([successStep, isLast ? 'hidden' : divider, false]);
    } else if (i === failedStep) {
      steps.push([failureStep, isLast ? 'hidden' : unreachedDivider, true]);
    } else {
      steps.push([unreachedStep, isLast ? 'hidden' : unreachedDivider, false]);
    }
  }
  return steps;
}

export default function Steps({ onClick, total, failedStep = total }: StepsProps) {
  const steps = getStepStyles(total, failedStep);
  return (
    <button
      type="button"
      className="flex items-center justify-center transform hover:scale-105 mx-1"
      onClick={onClick}
    >
      {steps.map(([stepClassName, dividerClassName, isFailure], i) => (
        <Fragment key={i}>
          <div className={stepClassName} key={i}>
            {isFailure && (
              <div className="fill-current text-white flex items-center justify-center">
                <Cross />
              </div>
            )}
            {!isFailure && (
              <div className="fill-current text-white flex items-center justify-center h-full">
                <Tick width={24} height={24} />
              </div>
            )}
            <p className="sr-only">{isFailure ? 'Failure' : 'Success'}</p>
          </div>
          {i !== total - 1 && <div className={dividerClassName} key={`divider-${i}`} />}
        </Fragment>
      ))}
    </button>
  );
}
