import React, { ChangeEvent, useMemo, useState } from 'react';
import {
  Back,
  BodyCell,
  Button,
  ButtonTypes,
  DateRangeSelector,
  Filter,
  HeaderCell,
  Icon,
  Icons,
  Layout,
  PageHeader,
  Section,
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TextInput,
  TextInputSize,
  TextInputType,
  Title,
} from '@alpima/picasso';
import { FilterOption } from '@alpima/picasso/build/types/components/molecules/Filter/Filter.types';
import { DateTime } from 'luxon';
import { Loader } from 'semantic-ui-react';
import sortBy from 'lodash/sortBy';
import useService from '../../../../helpers/useService';
import { baseUrl } from '../../../../services/clientAPI/clientAPI.config';
import { apiCall$ } from '../../../../services/clientAPI/lib/apiCall';
import TablePagination from '../../common/Users/helpers/TablePagination';

const minDate = DateTime.fromISO('2021-01-01');
const maxDate = DateTime.now();

type LogEvent = {
  user: {
    username: string;
    displayName: string;
    email: string;
    company: {
      _id: string;
      name: string;
    };
    deleted: boolean;
  };
  eventType: string;
  timestamp: string;
};

const defaultDates = {
  startDate: maxDate.minus({ days: 1 }),
  endDate: maxDate,
};

const getAuthorizationLogs$ = (startTime: string, endTime: string) =>
  apiCall$<LogEvent[]>(`${baseUrl}/logs/authorization?stream=false`, 'POST', {
    startTime,
    endTime,
  });

export default function AuthorizationLogsPage() {
  const [{ startDate, endDate }, setDates] = useState(defaultDates);
  const startDateISO = startDate.toISO();
  const endDateISO = endDate.toISO();
  const [loading, error, logs] = useService<LogEvent[]>(
    getAuthorizationLogs$,
    startDateISO,
    endDateISO,
  );

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [searchText, setSearchText] = useState('');
  const [eventTypes, setEventTypes] = useState(new Set<string>(['InitiateAuth']));

  const searchParams = searchText.split(/\s+/).filter(Boolean);

  const sortedFilteredLogs = useMemo(
    () =>
      sortBy(
        logs?.filter(
          log =>
            eventTypes.has(log.eventType) &&
            searchParams.every(searchParam =>
              `${log.user.username} ${log.user.email} ${log.user.displayName} ${log.eventType}`
                .toLowerCase()
                .includes(searchParam),
            ),
        ),
        'timestamp',
      ),
    [logs, searchParams, eventTypes],
  );

  const start = (page - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const slicedLogs = sortedFilteredLogs.slice(start, end);

  const eventTypeOptions: FilterOption[] = [...new Set(logs?.map(log => log.eventType))].map(
    text => ({
      title: text,
      id: text,
    }),
  );

  return (
    <div className="min-h-screen">
      <Layout noMargins>
        <div className="w-full px-8 mb-2">
          <PageHeader>
            <Back />
            <Section>
              <Title>Authorization Logs</Title>
            </Section>
          </PageHeader>
        </div>
        <div className="w-full border-b border-gray-200" />

        <div className="flex justify-between self-stretch mr-5 mt-5 gap-4">
          <div className="ml-5">
            <Filter
              showAll
              title="Event Type"
              options={eventTypeOptions}
              selected={eventTypes}
              setSelected={setEventTypes}
            />
          </div>
          <DateRangeSelector
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            onSetDates={(newStart, newEnd) => setDates({ startDate: newStart, endDate: newEnd })}
          />
        </div>

        <div className="w-full px-5">
          <div className="my-5 w-full flex gap-4">
            <TextInput
              wrapperClassName="flex-1"
              customType={TextInputType.IconLeft}
              customSize={TextInputSize.Large}
              icon={<Icon name={Icons.SEARCH} />}
              value={searchText}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
              placeholder="Search logs by user, email, event type..."
            />
            <Button
              icon={Icons.CLOSE}
              type={ButtonTypes.IconLeft}
              onClick={() => setSearchText('')}
            >
              Clear
            </Button>
          </div>

          {loading && <Loader active inline="centered" />}
          {error && (
            <p className="m-5">
              An error has occurred fetching authorization logs. Please try again later.
            </p>
          )}
          {!loading && !error && (
            <>
              <Table className="mb-5">
                <TableHeader>
                  <TableRow className="text-md">
                    <HeaderCell className="py-4 w-1/6">User</HeaderCell>
                    <HeaderCell className="py-4 w-1/6">Email</HeaderCell>
                    <HeaderCell className="py-4 w-1/6">Event Type</HeaderCell>
                    <HeaderCell className="py-4 w-1/6">Time</HeaderCell>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {slicedLogs.map(log => (
                    <TableRow className="text-center" key={`${Math.random()}`}>
                      <BodyCell className="py-4">
                        {log.user.displayName} ({log.user.username})
                      </BodyCell>
                      <BodyCell className="py-4">{log.user.email}</BodyCell>
                      <BodyCell className="py-4">{log.eventType}</BodyCell>
                      <BodyCell className="py-4">{log.timestamp}</BodyCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                itemsPerPage={itemsPerPage}
                itemsPerPageHandler={setItemsPerPage}
                page={page}
                pageHandler={setPage}
                numberOfRows={sortedFilteredLogs.length}
              />
            </>
          )}
        </div>
      </Layout>
    </div>
  );
}
