import { mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import * as constants from '../constants/informationBanner.constants';
import {
  fetchInformationBanners,
  saveInformationBanner,
  archiveInformationBanner,
} from '../../services/clientAPI/informationBanners.service';

export const getInformationBannersEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_INFORMATION_BANNERS),
    mergeMap(() =>
      fetchInformationBanners().pipe(
        mergeMap(response => [
          {
            type: constants.GET_INFORMATION_BANNERS_SUCCESS,
            payload: response,
          },
        ]),
        catchError(error =>
          of({
            type: constants.GET_INFORMATION_BANNERS_FAILURE,
            payload: error.response,
          }),
        ),
      ),
    ),
  );

export const archiveInformationBannerEpic = actions$ =>
  actions$.pipe(
    ofType(constants.ARCHIVE_INFORMATION_BANNER),
    mergeMap(action =>
      archiveInformationBanner(action.payload).pipe(
        mergeMap(response => [
          { type: constants.ARCHIVE_INFORMATION_BANNER_SUCCESS, payload: response },
          { type: constants.GET_INFORMATION_BANNERS },
        ]),
        catchError(error =>
          of({
            type: constants.ARCHIVE_INFORMATION_BANNER_FAILURE,
            payload: error.response,
          }),
        ),
      ),
    ),
  );

export const saveInformationBannerEpic = actions$ =>
  actions$.pipe(
    ofType(constants.SAVE_INFORMATION_BANNER),
    mergeMap(action =>
      saveInformationBanner(action.payload).pipe(
        mergeMap(response => [
          { type: constants.SAVE_INFORMATION_BANNER_SUCCESS, payload: response },
          { type: constants.GET_INFORMATION_BANNERS },
        ]),
        catchError(error =>
          of({ type: constants.SAVE_INFORMATION_BANNER_FAILURE, payload: error.response }),
        ),
      ),
    ),
  );
