import React from 'react';
import { PageHeader, Back, Title, Layout, Section, Widget, TabOptions } from '@alpima/picasso';

// import Logins from './Logins';
import LicensesWidget from './Licenses';

export default function Dashboard() {
  return (
    /* External div should be removes when page layout is sorted */
    <div className="min-h-screen">
      <Layout noMargins>
        <div className="w-full px-8 mb-2">
          <PageHeader>
            <Back />
            <Section>
              <Title>My BlueBay Dashboard</Title>
            </Section>
          </PageHeader>
        </div>
        <div className="w-full border-b border-gray-300" />
        <div className="w-full px-12">
          <Widget
            title="License Availability"
            tabs={[{ type: TabOptions.TAB, name: 'Overview', content: <LicensesWidget /> }]}
          />
          {/* <Widget
            title="Logins"
            tabs={[{ type: TabOptions.TAB, name: 'Overview', content: <Logins /> }]}
          />
          <Widget
            title="Funds"
            tabs={[{ type: TabOptions.TAB, name: 'Overview', content: <Logins /> }]}
          />
          <Widget
            title="Documents"
            tabs={[{ type: TabOptions.TAB, name: 'Overview', content: <Logins /> }]}
          /> */}
        </div>
      </Layout>
    </div>
  );
}
