// These entries are non BJB and their purpose is be able to delete this file for deploying to BJB escrow.
const baseThemesConfig = [
  [/fil-admin\..*\.alpima\.net/, 'FIL'],
  [/bbva/, 'BBVA'],
  [/bluebay/, 'BlueBay'],
  [/natixis/, 'Natixis'],
  [/fil/, 'FIL'],
  [/next/, 'Alpima'],
  [/manage.dev\.alpima\.net/, 'Alpima'],
];

export default baseThemesConfig;
