import { useState, useRef, useCallback, useEffect, MutableRefObject } from 'react';
import { Observable, Subscription } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Service<T> = (...params: any[]) => Observable<T>;

type ServiceState<T> = Readonly<[boolean, null | AjaxError, T, CallableFunction]>;

/**
 * React hook to expose a service which should be called when we run a submit function.
 * @param {*} service$ The service to use
 * @returns [loading, error, data, submit]
 */

export default function useTriggeredService<T>(service$: Service<T>): ServiceState<T | null> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const subscription: MutableRefObject<Subscription | null> = useRef(null);

  const submit = useCallback(
    (...submitValues) => {
      setLoading(true);
      subscription.current = service$(...submitValues).subscribe({
        next: value => {
          setData(value);
          setLoading(false);
        },
        error: err => {
          setError(err);
          setLoading(false);
        },
      });
    },
    [service$],
  );

  useEffect(() => () => subscription.current?.unsubscribe(), []);

  return [loading, error, data, submit];
}
