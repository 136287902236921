import { padZero } from 'helpers/format.helper';
import logo from '../../themes/bluebay/logo.png';

export default {
  name: 'bluebay',
  imageBackground: 'bluebay/start-bg.jpg',
  logo,
  startOfTime: '2016-01-01',
  yearLabels: [2019, 2020, 2021, 2022],
  variables: {
    backgroundMain: '#111',
    colourMain: '#333',
    primary: '#4f98d1',
    secondaryActive: '#203A4C',
    pageTopFooter: '#203A4C',
    colorPageTopFooter: '#8094aa',
    pageBottomFooter: '#253445',
    highlightedText: '#264092',
    dashboardHeaderBackground: '#203A4C',
    dashboardHeaderColor: '#fff',
    breakpoints: {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  instrumentConfig: {
    attributes: [
      {
        name: 'BBG Ticker',
        type: 'bbgTicker',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.bbgticker || '',
      },
      {
        name: 'ISIN',
        type: 'isin',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.idISIN || '',
      },
      {
        name: 'Name',
        type: 'name',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.name || '',
      },
      {
        name: 'Display Name',
        type: 'displayName',
        readOnly: false,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.displayName || '',
      },
      {
        name: 'Created',
        type: 'datetime',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => {
          if (!instrument.created) {
            return '';
          }
          const dt = new Date(instrument.created);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
      {
        name: 'Ticker',
        type: 'ticker',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.ticker || '',
      },
      {
        name: 'Exchange',
        type: 'exchange',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.primaryExchange || '',
      },
      {
        name: 'Primary Exchange',
        type: 'primaryExchange',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.primaryExchange || '',
      },
      {
        name: 'Instrument ID',
        type: 'instrumentId',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.instrumentId || '',
      },
      {
        name: 'Valor',
        type: 'valor',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.valor || '',
      },
      {
        name: 'ID BB Unique',
        type: 'bbUnique',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.idBBUnique || '',
      },
      {
        name: 'ID BB Global',
        type: 'bbGlobal',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.idBBGlobal || '',
      },
      {
        name: 'Instrument Type',
        type: 'instrumentType',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.type || '',
      },
      {
        name: 'Market Status',
        type: 'marketStatus',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.marketStatus || '',
      },
      {
        name: 'Ref-Currency',
        type: 'currency',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.refCurrency || '',
      },
      {
        name: 'Market Data Source',
        type: 'marketDataSource',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.marketDataSource || '',
      },
      {
        name: 'Primary Asset Class',
        type: 'assetClassPrimary',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.assetClass || '',
      },
      {
        name: 'Sub Asset Classes',
        type: 'assetClassSet',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.assetSubClass || '',
      },
      {
        name: 'Risk Currency',
        type: 'riskCurrency',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.riskCurrency || '',
      },
      {
        name: 'Risk Geography',
        type: 'riskGeography',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.riskGeography || '',
      },
      {
        name: 'Region',
        type: 'region',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.region || '',
      },
      {
        name: 'Country',
        type: 'country',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.country || '',
      },
      {
        name: 'Performance Inception',
        type: 'performanceStartDate',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => {
          if (!instrument.dataStartDate) {
            return '';
          }
          const dt = new Date(instrument.dataStartDate);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
      {
        name: 'Performance Last Updated',
        type: 'performanceEndDate',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => {
          if (!instrument.dataEndDate) {
            return '';
          }
          const dt = new Date(instrument.dataEndDate);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
    ],
  },
  instrumentColumns: [
    {
      getLabel: () => 'Enabled',
      name: 'visible',
      type: 'checkbox',
      align: 'center',
      width: 1,
      resolver: item => (item && item.visible) || false,
    },
    {
      getLabel: () => 'Instrument ID',
      name: 'instrumentId',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.instrumentId) || '-',
    },
    {
      getLabel: () => 'ISIN',
      name: 'idISIN',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.idISIN) || '-',
    },
    {
      getLabel: () => 'Valor',
      name: 'valor',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.valor) || '-',
    },
    {
      getLabel: () => 'Bloomberg Ticker',
      name: 'bloombergTicker',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.bloombergTicker) || '-',
    },
    {
      getLabel: () => 'Name',
      name: 'shortName',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.name) || '-',
    },
    {
      getLabel: () => 'Instrument Type',
      name: 'instrumentType',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.type) || '-',
    },
    {
      getLabel: () => 'Asset Class',
      name: 'assetClass',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.assetClass) || '-',
    },
    {
      getLabel: () => 'Sub Asset Class',
      name: 'subAssetClass',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.assetSubClass) || '-',
    },
    {
      getLabel: () => 'Ref-Currency',
      name: 'refCurrency',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.refCurrency) || '-',
    },
    {
      getLabel: () => 'Risk-Currency',
      name: 'riskCurrency',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.riskCurrency) || '-',
    },
    {
      getLabel: () => 'Region',
      name: 'region',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.region) || '-',
    },
    {
      getLabel: () => 'Country',
      name: 'country',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.country) || '-',
    },
    {
      getLabel: () => 'Risk-Geography',
      name: 'riskGeography',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.riskGeography) || '-',
    },
    // {
    //     getLabel: () => 'Type',
    //     name: 'type',
    //     type: 'string',
    //     align: 'left',
    //     width: 1,
    //     resolver: item => (item && item.type) || '-'
    // },
    {
      getLabel: () => 'Market Data Source',
      name: 'marketDataSource',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.marketDataSource) || '-',
    },
    {
      getLabel: () => 'Market Status',
      name: 'marketStatus',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.marketStatus) || '-',
    },
    {
      getLabel: () => 'Performance Inception',
      name: 'performanceStartDate',
      type: 'date',
      align: 'left',
      width: 1,
      resolver: item => item && item.dataStartDate,
    },
    {
      getLabel: () => 'Performance Last Updated',
      name: 'performanceEndDate',
      type: 'date',
      align: 'left',
      width: 1,
      resolver: item => item && item.dataEndDate,
    },
  ],
  userColumns: [
    {
      getLabel: () => 'Enabled',
      name: 'enabled',
      type: 'checkbox',
      align: 'center',
      width: 1,
      resolver: item => item.enabled,
    },
    {
      getLabel: () => 'Online',
      name: 'online',
      type: 'online',
      align: 'center',
      width: 1,
      resolver: item => item.online,
    },
    {
      getLabel: () => 'Name',
      name: 'displayName',
      type: 'string',
      align: 'left',
      width: 3,
      resolver: item => item.displayName,
    },
    {
      getLabel: () => 'Username',
      name: 'username',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => item.username,
    },
    {
      getLabel: () => 'Company',
      name: 'company.name',
      type: 'string',
      align: 'center',
      width: 2,
      resolver: item => (item.company && item.company.name) || '',
    },
    {
      getLabel: () => 'Email',
      name: 'email',
      type: 'string',
      align: 'left',
      width: 3,
      resolver: item => item.email,
    },
    {
      getLabel: () => 'Contractual Role',
      name: 'contractRole',
      type: 'tag',
      align: 'left',
      width: 1,
      resolver: item => item.contractRole,
    },
    {
      getLabel: () => 'Functional Roles',
      name: 'functionalRoles',
      type: 'tag',
      align: 'left',
      width: 3,
      resolver: item => item.functionalRoles,
    },
    {
      getLabel: () => 'Object Roles',
      name: 'objectRoles',
      type: 'tag',
      align: 'left',
      width: 3,
      resolver: item => item.objectRoles,
    },
    {
      getLabel: () => 'Created',
      name: 'created',
      type: 'datetime',
      align: 'left',
      width: 1,
      resolver: item => item.created,
    },
    {
      getLabel: () => 'Last Activity',
      name: 'lastActivity',
      type: 'datetime',
      align: 'left',
      width: 1,
      resolver: item => item.lastActivity,
    },
  ],
  monitorLoginsControlPanels: [
    {
      panelURL: 'aggregate',
      title: 'Aggregate',
      panelName: 'aggregate',
    },
    {
      panelURL: 'contract-roles',
      title: 'Contract Roles',
      panelName: 'contract',
    },
    {
      panelURL: 'function-roles',
      title: 'Functional Roles',
      panelName: 'function',
    },
  ],
  strategyConfig: {
    attributes: [
      {
        name: 'Unique Identifier',
        type: 'uid',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ strategy }) => strategy.uid || '',
      },
      {
        name: 'Product Line',
        type: 'productLine',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ strategy }) => strategy.productLine?.name || '',
      },
      {
        name: 'Observation Currency',
        type: 'currency',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ strategy }) => strategy.currency?.isoCode || '',
      },
      {
        name: 'Investment Strategy',
        type: 'riskProfile',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ strategy }) => strategy.riskProfile?.name || '',
      },
      {
        name: 'Start Date',
        type: 'datetime',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ strategy }) => {
          if (!strategy.startDate) {
            return '';
          }
          const dt = new Date(strategy.startDate);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
      {
        name: 'Last Rebalance',
        type: 'datetime',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ strategy }) => {
          if (!strategy.lastRebalanceDate) {
            return '';
          }
          const dt = new Date(strategy.lastRebalanceDate);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
      {
        name: 'Performance Data Updated',
        type: 'datetime',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ strategy }) => {
          if (!strategy.performanceEndDate) {
            return '';
          }
          const dt = new Date(strategy.performanceEndDate);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
      {
        name: 'Created',
        type: 'datetime',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ strategy }) => {
          if (!strategy.created) {
            return '';
          }
          const dt = new Date(strategy.created);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
    ],
  },
  userConfig: {
    attributes: [
      {
        name: 'ID',
        type: '_id',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ user }) => user._id,
      },
      {
        name: 'Username',
        type: 'username',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ user }) => user.username,
      },
      {
        name: 'First name',
        type: 'firstName',
        readOnly: false,
        atom: 'TextInput',
        column: 1,
        validations: 'required',
        resolver: ({ user }) => user.firstName,
      },
      {
        name: 'Last name',
        type: 'lastName',
        readOnly: false,
        atom: 'TextInput',
        column: 2,
        validations: 'required',
        resolver: ({ user }) => user.lastName,
      },
      {
        name: 'Email',
        type: 'email',
        readOnly: false,
        atom: 'TextInput',
        column: 1,
        validations: 'required|email',
        resolver: ({ user }) => user.email,
      },
      {
        name: 'Contact Number',
        type: 'contactPhoneNumber',
        readOnly: false,
        atom: 'TextInput',
        column: 2,
        validations: '',
        resolver: ({ user }) => user.contactPhoneNumber,
        // }, {
        //     name: 'Company',
        //     type: 'company',
        //     readOnly: false,
        //     atom: 'DropdownInput',
        //     placeholder: 'User\'s Company Affiliation',
        //     column: 1,
        //     resolver: ({ user, company }) => {
        //         const found = company.find(c => c._id === user.company);
        //         return (found && found._id) || '';
        //     },
        //     optionResolver: ({ company }) => {
        //         if (company && company.length) {
        //             return company.map(option => ({ value: option._id, text: option.name }));
        //         }
        //         return [];
        //     }
      },
      {
        name: 'Contract Role',
        type: 'contractRole',
        readOnly: false,
        multiple: false,
        atom: 'LabelInput',
        column: 1,
        validations: '',
        resolver: ({ user }) => (user && user.contractRole) || '',
        optionResolver: () => [
          { value: 'ITSO', text: 'ITSO' },
          { value: 'PowerUser', text: 'PowerUser' },
          { value: 'IMUser', text: 'IMUser' },
          { value: 'QuantUser', text: 'QuantUser' },
          { value: 'ReadOnly', text: 'ReadOnly' },
          { value: 'BJBSupport', text: 'BJBSupport' },
        ],
        compiler: newContractRole => newContractRole,
      },
      {
        name: 'Functional Roles',
        type: 'functionRoles',
        readOnly: false,
        atom: 'LabelInput',
        placeholder: 'Add Functional Roles',
        column: 2,
        resolver: ({ user }) => (user && user.functionRoles) || [],
        optionResolver: ({ roles }) =>
          (roles &&
            roles.functionRoles &&
            roles.functionRoles.map(option => ({ value: option, text: option }))) ||
          [],
        compiler: newRolesArray => {
          const set = new Set(newRolesArray);
          return [...set];
        },
      },
      {
        name: 'Object Roles',
        type: 'objectRoles',
        readOnly: false,
        atom: 'LabelInput',
        placeholder: 'Add Object Roles',
        column: 1,
        resolver: ({ user }) => (user && user.objectRoles) || [],
        optionResolver: ({ roles }) =>
          (roles?.objectRoles &&
            roles.objectRoles.map(({ name, role }) => ({ value: role, text: name }))) ||
          [],
        compiler: newRoles => [...new Set(newRoles)],
      },
      {
        name: 'Country',
        type: 'bjbCountry',
        readOnly: false,
        multiple: false,
        atom: 'LabelInput',
        column: 1,
        validations: 'required',
        default: { value: 'Switzerland', text: 'Switzerland' },
        resolver: ({ user }) => (user && user.bjbCountry) || 'Switzerland',
        optionResolver: () => [
          { value: 'Germany', text: 'Germany' },
          { value: 'Guernsey', text: 'Guernsey' },
          { value: 'Hong Kong', text: 'Hong Kong' },
          { value: 'Luxembourg', text: 'Luxembourg' },
          { value: 'Monaco', text: 'Monaco' },
          { value: 'Singapore', text: 'Singapore' },
          { value: 'Spain', text: 'Spain' },
          { value: 'Switzerland', text: 'Switzerland' },
          { value: 'United Kingdom', text: 'United Kingdom' },
          { value: 'United Arab Emirates', text: 'Utd. Arab Emirates' },
        ],
        compiler: bjbCountry => bjbCountry,
      },
      {
        name: 'Location',
        type: 'bjbLocation',
        readOnly: false,
        multiple: false,
        atom: 'LabelInput',
        column: 2,
        validations: 'required',
        default: { value: 'Zurich', text: 'Zurich' },
        resolver: ({ user }) => (user && user.bjbLocation) || 'Zurich',
        optionResolver: () => [
          { value: 'Dubai', text: 'Dubai' },
          { value: 'Frankfurt am Main', text: 'Frankfurt am Main' },
          { value: 'Geneva', text: 'Geneva' },
          { value: 'Hong Kong', text: 'Hong Kong' },
          { value: 'Lausanne', text: 'Lausanne' },
          { value: 'London', text: 'London' },
          { value: 'Lugano', text: 'Lugano' },
          { value: 'Luxembourg', text: 'Luxembourg' },
          { value: 'Madrid', text: 'Madrid' },
          { value: 'Monaco', text: 'Monaco' },
          { value: 'Saint Peter Port', text: 'Saint Peter Port' },
          { value: 'Singapore', text: 'Singapore' },
          { value: 'Zurich', text: 'Zurich' },
        ],
        compiler: bjbLocation => bjbLocation,
      },
    ],
  },
};
