export const GET_REFERENCE_DATA_LIST = 'GET_REFERENCE_DATA_LIST';
export const GET_REFERENCE_DATA_LIST_SUCCESS = 'GET_REFERENCE_DATA_LIST_SUCCESS';
export const GET_REFERENCE_DATA_LIST_FAILURE = 'GET_REFERENCE_DATA_LIST_FAILURE';
export const GET_REFERENCE_DATA_LIST_CANCELLED = 'GET_REFERENCE_DATA_LIST_CANCELLED';

export const GET_REFERENCE_PRODUCT_LINES = 'GET_REFERENCE_PRODUCT_LINES';
export const GET_REFERENCE_PRODUCT_LINES_SUCCESS = 'GET_REFERENCE_PRODUCT_LINES_SUCCESS';
export const GET_REFERENCE_PRODUCT_LINES_FAILURE = 'GET_REFERENCE_PRODUCT_LINES_FAILURE';
export const GET_REFERENCE_PRODUCT_LINES_CANCELLED = 'GET_REFERENCE_PRODUCT_LINES_CANCELLED';

export const GET_REFERENCE_CURRENCIES = 'GET_REFERENCE_CURRENCIES';
export const GET_REFERENCE_CURRENCIES_SUCCESS = 'GET_REFERENCE_CURRENCIES_SUCCESS';
export const GET_REFERENCE_CURRENCIES_FAILURE = 'GET_REFERENCE_CURRENCIES_FAILURE';
export const GET_REFERENCE_CURRENCIES_CANCELLED = 'GET_REFERENCE_CURRENCIES_CANCELLED';

export const REFERENCE_DATA_EXPORT_REQUEST = 'reference/EXPORT_DATA_REQUEST';
export const REFERENCE_DATA_EXPORT_SUCCESS = 'reference/EXPORT_DATA_SUCCESS';
export const REFERENCE_DATA_EXPORT_FAILURE = 'reference/EXPORT_DATA_FAILURE';
export const REFERENCE_DATA_EXPORT_CLEAR = 'reference/EXPORT_DATA_CLEAR';
