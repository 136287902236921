import * as constants from '../constants';
import { filters } from '../../config/appConfig';

export const getUserLoginChartData = year => ({
  type: constants.GET_USER_LOGIN_CHART_DATA,
  year,
});

export const getUserLoginTableData = year => ({
  type: constants.GET_USER_LOGIN_TABLE_DATA,
  year,
});

export const getStrategyTableData = (year, includeDeleted) => ({
  type: constants.GET_STRATEGY_TABLE_DATA,
  year,
  includeDeleted,
});

export const fetchLatestSplunkTimestamps = () => ({
  type: constants.GET_SPLUNK_TIMESTAMPS,
});

export const setMonitoringLoginChartType = type => ({
  type: constants.SET_MONITORING_LOGIN_CHART_TYPE,
  chart: type,
});
export const setMonitoringLoginChartUnknowns = unknowns => ({
  type: constants.SET_MONITORING_LOGIN_CHART_UNKNOWNS,
  unknowns,
});

export const setMonitoringFilterTimeState = (start, end, name, selection) => ({
  type: constants.SET_MONITORING_TIME_FILTER_STATE,
  payload: {
    start,
    end,
    name,
    selection,
  },
});

export const getStrategyCreationData = (
  productLines,
  currencies,
  showCumulative = true,
  showUnknowns = false,
) => {
  const defaultAllProductLines = filters.StrategyMonitoringProductLines.filters.map(f => f.value); // default options "all"
  const defaultAllCurrencies = filters.StrategyMonitoringCurrencies.filters.map(f => f.value); // default options "all"
  const _productLines = Object.entries(productLines)
    .map(([key, value]) => (value ? key : null))
    .filter(f => f);
  const _currencies = Object.entries(currencies)
    .map(([key, value]) => (value ? key : null))
    .filter(f => f);

  return {
    type: constants.GET_STRATEGY_CREATION_SERIES_DATA,
    showUnknowns,
    showCumulative,
    productLines: Object.keys(productLines).length ? _productLines : defaultAllProductLines,
    currencies: Object.keys(currencies).length ? _currencies : defaultAllCurrencies,
  };
};

export const getBloombergImportStatus = () => ({ type: constants.GET_BLOOMBERG_IMPORT_STATUS });

export const getOvernightCalculationsStatus = () => ({
  type: constants.GET_OVERNIGHT_CALCULATIONS_STATUS,
});

export const getICDecisionPreconditions = () => ({ type: constants.GET_IC_DECISION_PRECONDITIONS });
