import type { FilterOption } from '../Popups/Filters';
import { StrategiesAlertsMonitoring } from '../StrategyAlerts.types';

const filters: FilterOption<StrategiesAlertsMonitoring>[] = [
  {
    name: 'Failed Step',
    key: 'failedStageIndex',
  },
  {
    name: 'Status',
    key: 'status',
  },
  {
    name: 'Asset Class',
    key: 'assetClass',
  },
  {
    name: 'ID',
    key: 'alertId',
  },
];

export default filters;
