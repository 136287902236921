/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  PageHeader,
  Title,
  Layout,
  Section,
  Button,
  ButtonTypes,
  ButtonStates,
  Switch,
} from '@alpima/picasso';
import renderConfig from 'helpers/renderConfig';
import {
  fetchNewUserDetails,
  putUserEnabledState,
} from '../../../../../services/clientAPI/user.service';
import useTriggeredService from '../../../../../helpers/useTriggeredService';
import { FormField } from './UserDetails.helpers';
import { UserDetailsResponseType } from './UserDetails.types';

export default function UserDetailsNew() {
  const history = useHistory();
  const location = useLocation();

  const userId = location.pathname.slice(6);

  const [editLoading, editError, editResponse, editUser] =
    useTriggeredService<string>(putUserEnabledState);
  const [getLoading, getError, userDetails, getUserDetails] =
    useTriggeredService<UserDetailsResponseType>(fetchNewUserDetails);

  const [enabled, setEnabled] = useState<boolean | undefined>(undefined);
  const onSubmit = () => editUser(userDetails?._id, enabled);

  useEffect(() => {
    if (userId && !userDetails) getUserDetails(userId);
  }, [userDetails, getUserDetails, userId]);

  useEffect(() => {
    if (enabled === undefined) setEnabled(userDetails?.enabled);
  }, [enabled, userDetails]);

  useEffect(() => {
    if (editResponse && userId) getUserDetails(userId);
  }, [editResponse, getUserDetails, userId]);

  return (
    userDetails && (
      <Layout noMargins>
        <>
          <div className="w-full px-8 xl:px-40 mb-2">
            <PageHeader>
              <Section>
                <Title>{`${userDetails?.firstName} ${userDetails?.lastName}`}</Title>
                <div className="flex flex-col mr-10">
                  <div className="flex flex-row justify-end space-x-2">
                    <Button
                      type={ButtonTypes.LongText}
                      state={
                        enabled === userDetails?.enabled ? ButtonStates.Default : ButtonStates.Solid
                      }
                      onClick={onSubmit}
                      disabled={editLoading}
                    >
                      Save
                    </Button>
                    <Button type={ButtonTypes.LongText} onClick={() => history.goBack()}>
                      Cancel
                    </Button>
                  </div>
                  {editError && (
                    <p className="text-danger-800 mt-2">
                      {editError.response.message ||
                        'There was an issue updating this user, please try again later or contact support.'}
                    </p>
                  )}
                </div>
              </Section>
            </PageHeader>
          </div>
          <div className="w-full border-b mt-2 border-gray-300" />
          <div className="w-full px-8 xl:px-40 pt-12">
            <div className="grid grid-cols-2 2xl:grid-cols-3">
              {renderConfig.setUserEnabled && (
                <div className="flex flex-row mb-6">
                  <p className="w-5/6 text-gray-500">Enabled</p>
                  <div className="w-1/6 pr-10">
                    <Switch
                      name="enabled"
                      id="enabled"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEnabled(e.currentTarget.checked)
                      }
                      checked={enabled}
                    />
                  </div>
                </div>
              )}
              <FormField label="ID" content={userDetails?._id || ''} />
              <FormField label="Username" content={userDetails?.username || ''} />
              <FormField label="First name" content={userDetails?.firstName || ''} />
              <FormField label="Last name" content={userDetails?.lastName || ''} />
              <FormField label="Email" content={userDetails?.email || ''} />
              <FormField label="Contract role" content={userDetails?.contractRole || ''} />
            </div>
          </div>
        </>
      </Layout>
    )
  );
}
