import React, { useCallback } from 'react';
import { Button } from '@alpima/picasso';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { SeverityLevel } from '../StrategyAlerts.types';

type FilterSeverityProps = {
  onClickSeverity: (clicked: SeverityLevel) => void;
  severity: SeverityLevel | undefined;
};

export function useFilterSeverity(): [SeverityLevel | undefined, FilterSeverityProps] {
  const [_severity, setSeverity] = useQueryParam('severity', withDefault(StringParam, undefined));
  const severity = _severity as SeverityLevel | undefined;

  const onClickSeverity = useCallback(
    (clicked: SeverityLevel) =>
      setSeverity(oldSeverity => (oldSeverity === clicked ? undefined : clicked), 'replaceIn'),
    [setSeverity],
  );

  return [severity, { onClickSeverity, severity }];
}

export function FilterSeverity({ onClickSeverity, severity }: FilterSeverityProps) {
  return (
    <>
      <Button active={severity === 'high'} onClick={() => onClickSeverity('high')}>
        High
      </Button>
      <Button active={severity === 'medium'} onClick={() => onClickSeverity('medium')}>
        Medium
      </Button>
    </>
  );
}
