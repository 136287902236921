import React from 'react';
import { storiesOf } from '@storybook/react';
import { Table } from 'semantic-ui-react';

import TableDefaultRow from './index';

const defaultProps = {
  name: 'Funds',
  data: [],
  columns: [],
  rowIndex: 1,
};

storiesOf('TableDefaultRow', module)
  .addDecorator(story => (
    <Table celled>
      <Table.Body>{story()}</Table.Body>
    </Table>
  ))
  .add('default', () => <TableDefaultRow {...defaultProps} />);
