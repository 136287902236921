/* eslint import/no-dynamic-require: 0 */
/* eslint @typescript-eslint/no-var-requires: 0 */
/* eslint global-require: 0 */
/* eslint no-console: 0 */
import getDynamicUrlThemeSelection from '../config/themesConfig';
import defaultRenderConfig from '../config/themes/default.js';

const client = getDynamicUrlThemeSelection();

let renderThemeConfig = defaultRenderConfig;

try {
  if (client) {
    const clientRenderThemeConfig = require(`../config/themes/${client}.js`);
    renderThemeConfig = { ...defaultRenderConfig, ...clientRenderThemeConfig };
  }
} catch (err) {
  console.log(`No theme config found for subdomain ${client}`, err);
}

const exportedThemeConfig = renderThemeConfig;
export default exportedThemeConfig;
