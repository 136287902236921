import { apiCall$, apiCallWithRequest$ } from './lib/apiCall';
import {
  logListURL,
  logListCSVExportURL,
  logTypeContentURL,
  logDailyErrorCountURL,
} from './clientAPI.config';

export const fetchLogList = data => apiCall$(logListURL, 'POST', data);

export const fetchLogListCSVExport = data =>
  apiCallWithRequest$({
    url: logListCSVExportURL,
    method: 'POST',
    body: data,
    responseType: 'text/csv',
  });

export const fetchLogFilterTypeContent = () => apiCall$(logTypeContentURL);

export const fetchDailyErrorCount = () => apiCall$(logDailyErrorCountURL);
