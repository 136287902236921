export const GET_LOGS_LIST = 'GET_LOGS_LIST';
export const GET_LOGS_LIST_SUCCESS = 'GET_LOGS_LIST_SUCCESS';
export const GET_LOGS_LIST_FAILURE = 'GET_LOGS_LIST_FAILURE';

export const GET_LOG_FILTERS = 'GET_LOG_FILTERS';
export const GET_LOG_FILTERS_SUCCESS = 'GET_LOG_FILTERS_SUCCESS';
export const GET_LOG_FILTERS_FAILURE = 'GET_LOG_FILTERS_FAILURE';

export const SET_LOG_FILTER_STATE = 'SET_LOG_FILTER_STATE';
export const SET_LOG_TIME_FILTER_STATE = 'SET_LOG_TIME_FILTER_STATE';
export const SET_LOG_COLUMN_SORT = 'SET_LOG_COLUMN_SORT';
export const RESET_LOG_FILTERS = 'RESET_LOG_FILTERS';

export const LOGS_LIST_NAVIGATION = 'LOGS_LIST_NAVIGATION';

export const GET_LOG_DAILY_ERROR_COUNT = 'GET_LOG_DAILY_ERROR_COUNT';
export const GET_LOG_DAILY_ERROR_COUNT_SUCCESS = 'GET_LOG_DAILY_ERROR_COUNT_SUCCESS';
export const GET_LOG_DAILY_ERROR_COUNT_FAILURE = 'GET_LOG_DAILY_ERROR_COUNT_FAILURE';
