/* eslint-disable max-len */
import React from 'react';

const BlankCanvas = ({
  title,
  subtitle,
  loading,
}: {
  title?: string;
  subtitle?: string;
  loading?: boolean;
}) => (
  <div className="w-full h-full flex flex-col justify-center items-center text-gray-500">
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={loading ? 'animate-spin' : ''}
    >
      <path
        d="M0.5 20.001C0.5 20.1826 0.572169 20.3569 0.700632 20.4853C0.829094 20.6138 1.00333 20.686 1.185 20.686H5.297C5.38696 20.686 5.47603 20.6682 5.55914 20.6338C5.64225 20.5994 5.71776 20.5489 5.78137 20.4853C5.84498 20.4217 5.89543 20.3462 5.92986 20.2631C5.96428 20.18 5.982 20.0909 5.982 20.001C5.982 19.911 5.96428 19.8219 5.92986 19.7388C5.89543 19.6557 5.84498 19.5802 5.78137 19.5166C5.71776 19.453 5.64225 19.4025 5.55914 19.3681C5.47603 19.3337 5.38696 19.3159 5.297 19.3159H1.185C1.00341 19.3162 0.829329 19.3885 0.700924 19.5169C0.572519 19.6453 0.500265 19.8194 0.5 20.001Z"
        fill="#7D858B"
      />
      <path
        d="M39.815 19.3159H35.703C35.6131 19.3159 35.524 19.3337 35.4409 19.3681C35.3578 19.4025 35.2822 19.453 35.2186 19.5166C35.155 19.5802 35.1046 19.6557 35.0702 19.7388C35.0357 19.8219 35.018 19.911 35.018 20.001C35.018 20.0909 35.0357 20.18 35.0702 20.2631C35.1046 20.3462 35.155 20.4217 35.2186 20.4853C35.2822 20.5489 35.3578 20.5994 35.4409 20.6338C35.524 20.6682 35.6131 20.686 35.703 20.686H39.815C39.9967 20.686 40.1709 20.6138 40.2994 20.4853C40.4278 20.3569 40.5 20.1826 40.5 20.001C40.5 19.8193 40.4278 19.645 40.2994 19.5166C40.1709 19.3881 39.9967 19.3159 39.815 19.3159Z"
        fill="#7D858B"
      />
      <path
        d="M19.815 39.315C19.815 39.4967 19.8872 39.6709 20.0156 39.7993C20.1441 39.9278 20.3183 40 20.5 40C20.6817 40 20.8559 39.9278 20.9844 39.7993C21.1128 39.6709 21.185 39.4967 21.185 39.315V35.204C21.185 35.0223 21.1128 34.8481 20.9844 34.7196C20.8559 34.5911 20.6817 34.519 20.5 34.519C20.3183 34.519 20.1441 34.5911 20.0156 34.7196C19.8872 34.8481 19.815 35.0223 19.815 35.204V39.315Z"
        fill="#7D858B"
      />
      <path
        d="M20.502 5.48298C20.6838 5.48271 20.858 5.41032 20.9864 5.2817C21.1149 5.15308 21.187 4.97874 21.187 4.79698V0.685976C21.187 0.504303 21.1148 0.330071 20.9864 0.201608C20.8579 0.073146 20.6837 0.000976562 20.502 0.000976562C20.3203 0.000976562 20.1461 0.073146 20.0176 0.201608C19.8892 0.330071 19.817 0.504303 19.817 0.685976V4.79698C19.817 4.97874 19.8891 5.15308 20.0176 5.2817C20.146 5.41032 20.3202 5.48271 20.502 5.48298Z"
        fill="#7D858B"
      />
      <path
        d="M7.96598 32.533C7.83738 32.4047 7.66314 32.3326 7.48148 32.3326C7.29983 32.3326 7.12559 32.4047 6.99698 32.533L4.09198 35.44C4.02836 35.5036 3.97789 35.5791 3.94345 35.6622C3.90902 35.7454 3.8913 35.8345 3.8913 35.9245C3.8913 36.0144 3.90902 36.1035 3.94345 36.1867C3.97789 36.2698 4.02836 36.3453 4.09198 36.409C4.15561 36.4726 4.23114 36.5231 4.31427 36.5575C4.3974 36.5919 4.4865 36.6096 4.57648 36.6096C4.66646 36.6096 4.75556 36.5919 4.83869 36.5575C4.92182 36.5231 4.99736 36.4726 5.06098 36.409L7.96698 33.502C8.03069 33.4383 8.0812 33.3628 8.11564 33.2796C8.15008 33.1964 8.16776 33.1073 8.16766 33.0173C8.16757 32.9272 8.14971 32.8381 8.1151 32.755C8.08049 32.6719 8.02982 32.5964 7.96598 32.533Z"
        fill="#7D858B"
      />
      <path
        d="M36.908 3.59198C36.7794 3.46368 36.6052 3.39163 36.4235 3.39163C36.2418 3.39163 36.0676 3.46368 35.939 3.59198L33.033 6.49898C32.9691 6.56261 32.9184 6.6382 32.8837 6.72143C32.849 6.80466 32.8311 6.8939 32.8309 6.98407C32.8307 7.07423 32.8483 7.16356 32.8826 7.24693C32.917 7.3303 32.9674 7.4061 33.031 7.46998C33.0946 7.53387 33.1702 7.5846 33.2534 7.61928C33.3367 7.65396 33.4259 7.6719 33.5161 7.67208C33.6063 7.67227 33.6956 7.6547 33.7789 7.62036C33.8623 7.58603 33.9381 7.53561 34.002 7.47198L36.908 4.56498C36.9724 4.50137 37.0235 4.42561 37.0584 4.3421C37.0932 4.25859 37.1112 4.16898 37.1112 4.07848C37.1112 3.98798 37.0932 3.89838 37.0584 3.81487C37.0235 3.73136 36.9724 3.6556 36.908 3.59198Z"
        fill="#7D858B"
      />
      <path
        d="M34.002 32.533C33.8735 32.4045 33.6992 32.3323 33.5175 32.3323C33.3358 32.3323 33.1615 32.4045 33.033 32.533C32.9045 32.6615 32.8323 32.8358 32.8323 33.0175C32.8323 33.1992 32.9045 33.3735 33.033 33.502L35.939 36.409C36.0675 36.5375 36.2418 36.6097 36.4235 36.6097C36.6052 36.6097 36.7795 36.5375 36.908 36.409C37.0365 36.2805 37.1087 36.1062 37.1087 35.9245C37.1087 35.7428 37.0365 35.5685 36.908 35.44L34.002 32.533Z"
        fill="#7D858B"
      />
      <path
        d="M5.059 3.59195C4.9305 3.46345 4.75622 3.39127 4.5745 3.39127C4.39278 3.39127 4.2185 3.46345 4.09 3.59195C3.9615 3.72045 3.88931 3.89473 3.88931 4.07645C3.88931 4.25818 3.9615 4.43246 4.09 4.56095L6.997 7.47195C7.1255 7.60045 7.29977 7.67264 7.4815 7.67264C7.66322 7.67264 7.8375 7.60045 7.966 7.47195C8.0945 7.34346 8.16668 7.16918 8.16668 6.98745C8.16668 6.80573 8.0945 6.63145 7.966 6.50295L5.059 3.59195Z"
        fill="#7D858B"
      />
    </svg>
    {title && <h4>{title}</h4>}
    {subtitle && <h6>{subtitle}</h6>}
  </div>
);

export default BlankCanvas;
