import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Label, Dropdown } from 'semantic-ui-react';

const LabelInputWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  margin: 0 10px 25px;

  & label {
    margin: 10px 0 5px;
    color: #333;
    font-weight: 700;

    &.disabled {
      opacity: 0.45;
    }
  }
  & input {
    padding: 10px 15px;
    border: solid 1px #999;
    background: transparent;
    border-radius: 5px;

    margin-bottom: 5px;

    &[disabled] {
      background: #e5e5e5;
    }
  }

  & .custom-label-input {
  }
`;

const resultRenderer = ({ displayName }) => <Label content={displayName} />;
resultRenderer.propTypes = {
  displayName: PropTypes.string.isRequired,
};

const LabelInput = props => {
  const { name, type, resolver, placeholder, optionResolver, onChange, data, multiple, disabled } =
    props;

  // No userData provided
  if (!data) return null;

  const handleSelectChange = (e, { value }) => onChange(value);

  const renderLabel = label => ({
    color: 'blue',
    content: (
      <span key={`label-input-tag-${label.text}`} className="">
        {label.text}
      </span>
    ), // `Customized label - ${label.text}`
  });

  return (
    <LabelInputWrapper>
      <label htmlFor={`label-input-${type}`} className={disabled ? 'disabled' : ''}>
        {name}
      </label>
      <Dropdown
        id={`${name}-label-input`}
        className="custom-label-input"
        placeholder={placeholder}
        fluid
        multiple={multiple}
        selection
        disabled={disabled}
        onChange={handleSelectChange}
        renderLabel={renderLabel}
        value={resolver(data)}
        options={optionResolver(data)}
      />
    </LabelInputWrapper>
  );
};

LabelInput.propTypes = {
  data: PropTypes.object.isRequired,
  resolver: PropTypes.func.isRequired,
  optionResolver: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

LabelInput.defaultProps = {
  name: '',
  type: '',
  placeholder: '',
  multiple: true,
  disabled: false,
};

export default LabelInput;
