/* eslint-disable no-case-declarations */
import { fromJS } from 'immutable';

import * as constants from '../constants';

const initialState = fromJS({
  loading: false,
  loadingMonitoring: false,
  loadingPerfMonitoring: false,
  error: null,
  lastRefresh: -1,
  lastRequest: -1,
  lastRequestBB: -1,
  cacheTimestamp: -1,
  cacheTimestampTST: -1,
  cacheTimestampPerf: -1,
  isExporting: false,
  isBBExporting: false,
  data: null,
  dataBB: null,
  list: [],
  tstList: {},
  perfList: [],
  search: [],
  details: {
    active: {},
    cache: {},
  },
});

function paginateList(list = [], maxPerPage = 50) {
  const paginatedList = [];
  let count = 0;
  let currentPage = 0;
  list.forEach(item => {
    currentPage = count < maxPerPage - 1 ? currentPage : currentPage + 1;
    count = count < maxPerPage - 1 ? count + 1 : 0;

    paginatedList[currentPage] = paginatedList[currentPage] || [];
    paginatedList[currentPage].push(item);
  });
  return paginatedList;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_INSTRUMENTS_LIST:
      return state.set('loading', true);
    case constants.GET_INSTRUMENTS_LIST_SUCCESS:
      return state.merge({
        list: fromJS(paginateList((action.payload && action.payload.data) || {}, 40)),
        cacheTimestamp: (action.payload && action.payload.timestamp) || null,
        lastRefresh: new Date().getTime(),
        loading: false,
        error: null,
      });

    case constants.GET_INSTRUMENT_DETAILS:
      return state.set('loading', true);
    case constants.GET_INSTRUMENT_DETAILS_SUCCESS:
      const { payload } = action;
      const data = payload.data || {};
      const cacheTimestamp = payload.timestamp || -1;
      return state.merge({
        details: {
          active: fromJS(data),
          cache: fromJS(data),
        },
        cacheTimestamp,
        loading: false,
        error: null,
      });

    case constants.STAMP_ENABLE_INSTRUMENT:
      return state.setIn(['details', 'active', 'visible'], true);
    case constants.STAMP_DISABLE_INSTRUMENT:
      return state.setIn(['details', 'active', 'visible'], false);

    case constants.GET_INSTRUMENTS_LIST_FAILURE:
    case constants.GET_INSTRUMENT_DETAILS_FAILURE:
      return state.merge({
        loading: false,
        lastRefresh: new Date().getTime(),
        error: action.payload ? action.payload.error : null,
      });
    case constants.SET_INSTRUMENT_DETAILS_CANCEL:
      return state.setIn(['details', 'active'], state.getIn(['details', 'cache']));

    case constants.SET_INSTRUMENT_SEARCH_RESULTS:
      return state.set('search', fromJS(paginateList(action.payload, 20)));
    case constants.CLEAR_INSTRUMENT_SEARCH_RESULTS:
      return state.set('search', fromJS([]));

    case constants.SET_INSTRUMENT_COLUMN_SORT:
      return state.merge({
        list: fromJS(paginateList(action.payload.sortedData, 20)),
      });

    case constants.STAMP_INSTRUMENT_DETAILS:
      return state.mergeIn(['details', 'active'], {
        [action.key]: action.compiler(
          action.value,
          state && state.getIn(['details', 'active']),
          action.data,
        ),
      });

    case constants.GET_INSTRUMENTS_TST_MONITORING:
      return state.set('loadingMonitoring', true);
    case constants.GET_INSTRUMENTS_TST_MONITORING_SUCCESS:
      return state.merge({
        tstList: fromJS((action.payload && action.payload.data) || {}),
        cacheTimestampTST: (action.payload && action.payload.timestamp) || null,
        loadingMonitoring: false,
        error: null,
      });
    case constants.GET_INSTRUMENTS_TST_MONITORING_FAILURE:
      return state.merge({
        loadingMonitoring: false,
        error: action.payload ? action.payload.error : action.error,
      });

    case constants.GET_INSTRUMENTS_PERF_MONITORING:
      return state.set('loadingPerfMonitoring', true);
    case constants.GET_INSTRUMENTS_PERF_MONITORING_SUCCESS:
      return state.merge({
        perfList: fromJS(
          (action.payload && action.payload.data && action.payload.data.marketData) || {},
        ),
        cacheTimestampPerf: (action.payload && action.payload.timestamp) || null,
        loadingPerfMonitoring: false,
        error: null,
      });
    case constants.GET_INSTRUMENTS_PERF_MONITORING_FAILURE:
      return state.merge({
        loadingPerfMonitoring: false,
        error: action.payload ? action.payload.error : action.error,
      });

    case constants.INSTRUMENT_EXPORT_REQUEST:
      return state.set('isExporting', true);
    case constants.INSTRUMENT_EXPORT_SUCCESS:
      return state.merge({
        data: fromJS(action.payload),
        lastRequest: new Date().getTime(),
        error: null,
        isExporting: false,
      });
    case constants.INSTRUMENT_EXPORT_FAILURE:
      return state.merge({
        data: null,
        lastRequest: -1,
        isExporting: false,
        error: action.payload ? action.payload.error : null,
      });
    case constants.INSTRUMENT_EXPORT_CLEAR:
      return state.merge(initialState);

    case constants.BB_EXPORT_REQUEST:
      return state.set('isBBExporting', true);
    case constants.BB_EXPORT_SUCCESS:
      return state.merge({
        dataBB: fromJS(action.payload),
        lastRequestBB: new Date().getTime(),
        error: null,
        isBBExporting: false,
      });
    case constants.BB_EXPORT_FAILURE:
      return state.merge({
        dataBB: null,
        lastRequestBB: -1,
        isBBExporting: false,
        error: action.payload ? action.payload.error : null,
      });
    case constants.BB_EXPORT_CLEAR:
      return state.merge(initialState);

    case constants.CLEAR_SESSION:
    case constants.LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};
