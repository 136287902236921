import { createSelector } from 'reselect';

const selectStrategy = state => state.get('strategy');
const selectMatch = state => state.getIn(['router', 'location', 'pathname']) || [];

const selectLastExportRequest = createSelector(selectStrategy, users => users.get('lastRequest'));

const selectStrategyExportData = createSelector(selectStrategy, strategy => {
  const data = strategy.get('data');
  if (!data) return null;
  if (typeof data === 'string') return data;
  return data.toJS() || {};
});

const selectStrategyExportDataLoading = createSelector(selectStrategy, strategy =>
  strategy.get('isExporting'),
);
const selectStrategyCalculationLoading = createSelector(selectStrategy, strategy =>
  strategy.get('isRecalculating'),
);

const selectStrategyCalculationResponse = createSelector(
  selectStrategy,
  strategy => strategy.get('recalculationResponse').toJS() || {},
);

const selectStrategyCalculationError = createSelector(
  selectStrategy,
  strategy => strategy.get('recalculationError') || null,
);

const selectStrategyList = createSelector(
  selectStrategy,
  strategies => strategies.get('list').toJS() || [],
);

const selectStrategyListPage = createSelector(
  [selectStrategy, selectMatch],
  (strategies, match) => {
    const page = parseInt(match.substr(match.lastIndexOf('/') + 1), 10) - 1;
    return strategies.get('list').toJS()[page] || [];
  },
);

const selectStrategySearchResults = createSelector(
  selectStrategy,
  strategies => strategies.get('search').toJS() || [],
);

const selectStrategyLoading = createSelector(selectStrategy, strategy => strategy.get('loading'));

const selectLastRefresh = createSelector(selectStrategy, strategy => strategy.get('lastRefresh'));

const selectStrategyDetails = createSelector(
  selectStrategy,
  strategy => strategy.get('details')?.toJS() || {},
);

const selectHasDetailsStateChanged = createSelector(selectStrategy, strategy => {
  const active = strategy.get('details')?.toJS() || {};
  const cache = strategy.get('cachedDetails')?.toJS() || {};
  return JSON.stringify(active) !== JSON.stringify(cache) || false;
});

const selectStrategyDetailsError = createSelector(
  selectStrategy,
  strategy => strategy.get('error') || '',
);

export {
  selectStrategy,
  selectLastExportRequest,
  selectStrategyExportData,
  selectStrategyExportDataLoading,
  selectStrategyCalculationLoading,
  selectStrategyCalculationResponse,
  selectStrategyCalculationError,
  selectStrategyList,
  selectStrategyListPage,
  selectStrategySearchResults,
  selectStrategyLoading,
  selectLastRefresh,
  selectStrategyDetails,
  selectHasDetailsStateChanged,
  selectStrategyDetailsError,
};
