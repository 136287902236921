import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';

const DropdownInputWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  margin: 0 10px 25px;

  & label {
    margin: 10px 0 5px;
    color: #333;
    font-weight: 700;

    &.disabled {
      opacity: 0.45;
    }
  }
  & input {
    padding: 10px 15px;
    border: solid 1px #999;
    background: transparent;
    border-radius: 5px;

    margin-bottom: 5px;

    &[disabled] {
      background: #e5e5e5;
    }
  }

  & .custom-label-input {
  }
`;

const DropdownInput = props => {
  const { name, type, resolver, placeholder, optionResolver, onChange, data, disabled, values } =
    props;

  // No userData provided
  if (!data) return null;

  const handleSelectChange = (e, { value }) => onChange(value);

  return (
    <DropdownInputWrapper>
      <label htmlFor={`dropdown-input-${type}`} className={disabled ? 'disabled' : ''}>
        {name}
      </label>
      <Dropdown
        id={`${name}-dropdown-input`}
        className="custom-dropdown-input"
        placeholder={placeholder}
        disabled={disabled}
        fluid
        selection
        onChange={handleSelectChange}
        value={resolver(values, data)}
        options={optionResolver(values)}
      />
    </DropdownInputWrapper>
  );
};

DropdownInput.propTypes = {
  data: PropTypes.object.isRequired,
  resolver: PropTypes.func.isRequired,
  optionResolver: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  values: PropTypes.array,
};

DropdownInput.defaultProps = {
  name: '',
  type: '',
  placeholder: '',
  disabled: false,
  values: [],
};

export default DropdownInput;
