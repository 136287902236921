import React, { useEffect, useMemo, useState, useCallback } from 'react';
import {
  Icons,
  Icon,
  Table,
  TableBody,
  TableRow,
  HeaderCell,
  Layout,
  PageHeader,
  Back,
  Section,
  Title,
  TableHeader,
  TextInput,
  TextInputType,
  TextInputSize,
  ButtonTypes,
  Button,
  BodyCell,
  ButtonStates,
} from '@alpima/picasso';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button as SemanticButton, Modal } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import TablePagination from '../../common/Users/helpers/TablePagination';
import useSortAndPagination from '../../common/Users/UserList/useSortAndPagination';
import { sortingByProp } from '../../common/Users/UserList/UserList.helpers';
import useSortOrder from '../../common/Users/UserList/useSortOrder';
import useTriggeredService from '../../../../helpers/useTriggeredService';
import {
  fetchUserGroupsByCompany,
  deleteUserGroup,
} from '../../../../services/clientAPI/distributionGroups.service';
import { makeSelectUser } from '../../../../store/selectors/auth.selector';
import getTableSortableHeaders, { userGroupsSearch } from './DistributionGroups.helpers';
import { UserGroup, User, Order } from './DistributionGroups.types';

const DistributionGroups = () => {
  const history = useHistory();
  const [query, setQuery] = useState<string>('');
  const currentUser = useSelector(makeSelectUser);
  const [, deleteError, deleteResponse, deleteUserGroupApiCall] =
    useTriggeredService(deleteUserGroup);
  const [loading, error, userGroupResponse, getUserGroup] =
    useTriggeredService(fetchUserGroupsByCompany);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [userGroupToDelete, setUserGroupToDelete] = useState('');

  const usersGroups = useMemo(() => {
    if (userGroupResponse?.userGroups.length) {
      const flattned = userGroupResponse.userGroups.map((userGroup: UserGroup) => ({
        _id: userGroup._id,
        name: userGroup.name,
        users: userGroup.users.map((user: User) => ({
          firstName: user.firstName,
          lastName: user.lastName,
        })),
        created: {
          firstName: userGroup.createdUser.firstName,
          lastName: userGroup.createdUser.lastName,
        },
      }));
      return flattned;
    }
    return [];
  }, [userGroupResponse]);

  useEffect(() => {
    if (currentUser) {
      getUserGroup(currentUser.company);
    }
  }, [currentUser, deleteResponse, getUserGroup]);

  const { order, sortingProperty, changeSortOrder } = useSortOrder<User>(
    'firstName',
    Order.Ascending,
  );

  const sortingFunction = useCallback(
    () => sortingByProp(sortingProperty, order),
    [sortingProperty, order],
  );

  const searchedItems = userGroupsSearch(usersGroups, query);

  const paginatedUserGroups = useSortAndPagination(
    itemsPerPage,
    page,
    searchedItems,
    setPage,
    sortingFunction,
  );

  useEffect(() => {
    if (deleteError) {
      toast.error('Error deleting Distribution Group');
    }
  }, [deleteError]);

  useEffect(() => {
    if (deleteResponse) {
      toast.success('Success deleting Distribution Group');
    }
  }, [deleteResponse]);

  return (
    <div className="min-h-screen">
      <Layout noMargins>
        <div className="w-full px-8 mb-2">
          <PageHeader>
            <Back />
            <Section>
              <Title>Distribution Groups</Title>
              <Button
                state={ButtonStates.Solid}
                onClick={() => history.push('/distribution-groups/create')}
              >
                Create
              </Button>
            </Section>
          </PageHeader>
        </div>
        <div className="w-full border-b border-gray-200" />
        <div className="flex flex-row w-full py-5 px-8 items-center justify-between">
          <TextInput
            customType={TextInputType.IconLeft}
            customSize={TextInputSize.Large}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
            placeholder="Search by name, member, created..."
            value={query}
            wrapperClassName="w-full flex-grow mr-6"
          />
          <div className="flex space-x-2 flex-grow-0">
            <Button
              type={ButtonTypes.IconLeft}
              icon={Icons.CLOSE}
              onClick={() => setQuery('')}
              disabled={!query.length || loading}
            >
              Clear
            </Button>
          </div>
        </div>
        {!error && (
          <div className="w-full px-8">
            <Table>
              <TableHeader>
                <TableRow>
                  {getTableSortableHeaders().map(
                    header =>
                      header.enabled && (
                        <HeaderCell
                          key={header.property}
                          className="p-4 text-left cursor-pointer"
                          onClick={() => changeSortOrder(header.property as keyof User)}
                        >
                          <div className="justify-between flex flex-row items-center">
                            {header.name}
                            <Icon name={Icons.SORT} className="float-right" />
                          </div>
                        </HeaderCell>
                      ),
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                {paginatedUserGroups.length > 0 &&
                  paginatedUserGroups?.map((usersGroup: UserGroup) => (
                    <TableRow key={usersGroup._id} className="text-center cursor-pointer">
                      <BodyCell
                        onClick={() => history.push(`/distribution-groups/edit/${usersGroup._id}`)}
                        className="py-4"
                      >
                        {usersGroup.name}
                      </BodyCell>
                      <BodyCell
                        onClick={() => history.push(`/distribution-groups/edit/${usersGroup._id}`)}
                        className="py-2"
                      >
                        {usersGroup.users.map(
                          (user: User, index: number) =>
                            `${user.firstName} ${user.lastName}${
                              usersGroup.users.length && index !== usersGroup.users.length - 1
                                ? ', '
                                : ''
                            }`,
                        )}
                      </BodyCell>
                      <BodyCell
                        onClick={() => history.push(`/distribution-groups/edit/${usersGroup._id}`)}
                        className="py-4"
                      >
                        {usersGroup.created.firstName} {usersGroup.created.lastName}
                      </BodyCell>
                      <BodyCell className="py-4">
                        <div className="m-auto fill" style={{ marginLeft: '45%' }}>
                          <Icon
                            name={Icons.DELETE}
                            size={18}
                            onClick={() => {
                              setUserGroupToDelete(usersGroup._id);
                              setConfirmDeleteIsOpen(true);
                            }}
                            className="m-auto fill-current text-white rounded-full bg-danger-800 p-0.5"
                          />
                        </div>
                      </BodyCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        )}
        {loading && (
          <div className="text-center text-black w-full border-b border-l border-r border-gray-400 py-4">
            Loading distribution groups...
          </div>
        )}
        {!paginatedUserGroups.length && !loading && (
          <div className="text-center text-black w-full border-b border-l border-r border-gray-400 py-4">
            No distribution groups found for this search selection
          </div>
        )}
        {paginatedUserGroups.length > 0 && (
          <TablePagination
            itemsPerPage={itemsPerPage}
            itemsPerPageHandler={setItemsPerPage}
            page={page}
            pageHandler={setPage}
            numberOfRows={usersGroups.length}
          />
        )}
      </Layout>
      <Modal open={confirmDeleteIsOpen}>
        <Modal.Header>Delete Distribution Group</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            Are you sure you want to delete this Distribution Group
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <SemanticButton
            onClick={() => {
              setUserGroupToDelete('');
              setConfirmDeleteIsOpen(false);
            }}
          >
            Cancel
          </SemanticButton>
          <SemanticButton
            primary
            onClick={() => {
              deleteUserGroupApiCall(userGroupToDelete);
              setConfirmDeleteIsOpen(false);
            }}
          >
            Delete
          </SemanticButton>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default DistributionGroups;
