/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WidgetDefaultBodyWrapper = styled.div`
  padding: ${props =>
    props.noPadding ? '0' : props.theme.variables.widgetDefaultBodyPadding || '15px'};
  color: #203a4c;
`;

const WidgetDefaultBody = ({ children, noPadding }) => (
  <WidgetDefaultBodyWrapper noPadding={noPadding}>{children}</WidgetDefaultBodyWrapper>
);

WidgetDefaultBody.defaultProps = {
  noPadding: true,
};

WidgetDefaultBody.propTypes = {
  noPadding: PropTypes.bool,
};

export default WidgetDefaultBody;
