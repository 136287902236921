import { get } from 'lodash';
import moment from 'moment';
import renderThemeConfig from './renderThemeConfig';

/** Set of functions to format date and time.
 * @returns {{
 *  formatDate: (date: Date) => string,
 *  formatTime: (date: Date) => string,
 *  formatDateTime: (date: Date) => string,
 *  dateFormat?: string,
 *  timeFormat?: string,
 *  dateTimeFormat?: string
 * }}
 * Client specific functions and formats for date and time.
 */
export default function useDateTimeFormatter() {
  const theme = renderThemeConfig.default.variables;

  const dateFormat = get(theme, 'formats.date');
  function formatDate(date) {
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      return null;
    }
    return dateFormat ? momentDate.format(dateFormat) : momentDate.toDate().toLocaleDateString();
  }

  const timeFormat = get(theme, 'formats.time');
  function formatTime(date) {
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      return null;
    }
    return timeFormat ? momentDate.format(timeFormat) : momentDate.toDate().toLocaleTimeString();
  }

  const dateTimeFormat = get(theme, 'formats.dateTime');
  function formatDateTime(date) {
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      return null;
    }
    return dateTimeFormat
      ? momentDate.format(dateTimeFormat)
      : momentDate.toDate().toLocaleTimeString();
  }

  return {
    formatDate,
    formatTime,
    formatDateTime,
    dateFormat,
    timeFormat,
    dateTimeFormat,
  };
}
