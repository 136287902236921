/* eslint-disable no-case-declarations */
import { fromJS } from 'immutable';

import * as constants from '../constants';

const initialState = fromJS({
  loading: false,
  loadingTable: false,
  loadingSplunk: false,
  fetching: false,
  lastRefresh: -1,
  lastRefreshTable: -1,
  cacheTimestampChart: -1,
  cacheTimestampTable: -1,
  error: null,
  withCumulativeData: false,
  withUnknownData: false,
  splunk: {},
  filter: {
    unique: true,
    chartType: null,
    includeUnknowns: false,
    rangeStart: new Date(2022, 0, 1).toISOString(),
    rangeEnd: new Date(2022, 11, 31, 23, 59, 59).toISOString(),
    rangeName: '2022',
    selection: 2022,
  },
  data: {
    'function-roles': [],
    'contract-roles': [],
    aggregate: [],
    strategyCreation: [],
  },
  loginTable: {
    data: {},
    stats: {},
  },
  strategyTable: {
    data: {},
    stats: {},
  },
  loadingBloombergImportStatus: false,
  bloombergImportStatus: [],
  bloombergImportStatusError: null,
  loadingOvernightCalculationsStatus: false,
  overnightCalculationsStatus: [],
  overnightCalculationsStatusError: null,
  loadingICDecisionPreconditions: false,
  icDecisionPreconditions: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USER_LOGIN_CHART_DATA:
      return state.merge({
        loading: true,
      });

    case constants.GET_USER_LOGIN_CHART_DATA_SUCCESS:
      const chartType = state.getIn(['filter', 'chartType']);
      return state
        .setIn(['data', chartType], fromJS((action.payload && action.payload.data) || {}))
        .merge(
          fromJS({
            loading: false,
            fetching: false,
            lastRefresh: new Date().getTime(),
            cacheTimestampChart: (action.payload && action.payload.timestamp) || '',
            error: null,
          }),
        );

    case constants.GET_USER_LOGIN_CHART_DATA_FAILURE:
      return state.merge(
        fromJS({
          loading: false,
          fetching: false,
          error: action.payload ? action.payload.error : null,
        }),
      );

    case constants.GET_USER_LOGIN_TABLE_DATA:
      return state.merge({
        loadingTable: true,
      });
    case constants.GET_USER_LOGIN_TABLE_DATA_SUCCESS:
      return state.set('loginTable', fromJS(action.payload)).merge(
        fromJS({
          loadingTable: false,
          lastRefreshTable: new Date().getTime(),
          cacheTimestampTable: (action.payload && action.payload.timestamp) || '',
          error: null,
        }),
      );
    case constants.GET_USER_LOGIN_TABLE_DATA_FAILURE:
      return state.merge(
        fromJS({
          loadingTable: false,
          error: action.payload ? action.payload.error : null,
        }),
      );

    case constants.GET_STRATEGY_TABLE_DATA:
      return state.merge({
        loadingTable: true,
      });
    case constants.GET_STRATEGY_TABLE_DATA_SUCCESS:
      return state.set('strategyTable', fromJS(action.payload)).merge(
        fromJS({
          loadingTable: false,
          lastRefreshTable: new Date().getTime(),
          cacheTimestampTable: (action.payload && action.payload.timestamp) || '',
          error: null,
        }),
      );
    case constants.GET_STRATEGY_TABLE_DATA_FAILURE:
      return state.merge(
        fromJS({
          loadingTable: false,
          error: action.payload ? action.payload.error : null,
        }),
      );

    case constants.GET_STRATEGY_CREATION_SERIES_DATA:
      return state.merge({
        loading: true,
      });
    case constants.GET_STRATEGY_CREATION_SERIES_DATA_SUCCESS:
      return state
        .setIn(['data', 'strategyCreation'], fromJS((action.payload && action.payload.data) || {}))
        .merge(
          fromJS({
            loading: false,
            withUnknownData: action.unknown,
            withCumulativeData: action.cumulative,
            lastRefresh: new Date().getTime(),
            cacheTimestampChart: (action.payload && action.payload.timestamp) || '',
            error: null,
          }),
        );
    case constants.GET_STRATEGY_CREATION_SERIES_DATA_FAILURE:
      return state.merge(
        fromJS({
          loading: false,
          error: action.payload ? action.payload.error : null,
        }),
      );

    case constants.GET_SPLUNK_TIMESTAMPS:
      return state.merge({
        loadingSplunk: true,
      });
    case constants.GET_SPLUNK_TIMESTAMPS_SUCCESS:
      return state.set('splunk', fromJS(action.payload)).merge(
        fromJS({
          loadingSplunk: false,
          error: null,
        }),
      );
    case constants.GET_SPLUNK_TIMESTAMPS_FAILURE:
      return state.merge(
        fromJS({
          loadingSplunk: false,
          error: action.payload ? action.payload.error : null,
        }),
      );

    case constants.SET_MONITORING_LOGIN_CHART_TYPE:
      return state.set('fetching', true).setIn(['filter', 'chartType'], action.chart);
    case constants.SET_MONITORING_LOGIN_CHART_UNKNOWNS:
      return state.set('fetching', true).setIn(['filter', 'includeUnknowns'], action.unknowns);

    case constants.SET_MONITORING_TIME_FILTER_STATE:
      return state.set('fetching', true).mergeIn(
        ['filter'],
        fromJS({
          rangeStart: new Date(action.payload.start).toISOString(),
          rangeEnd: new Date(action.payload.end).toISOString(),
          rangeName: action.payload.name,
          selection: action.payload.selection,
        }),
      );

    case constants.GET_BLOOMBERG_IMPORT_STATUS:
      return state.set('loadingBloombergImportStatus', true);
    case constants.GET_BLOOMBERG_IMPORT_STATUS_SUCCESS:
      return state.merge(
        fromJS({
          loadingBloombergImportStatus: false,
          bloombergImportStatus: action.payload,
          bloombergImportStatusError: null,
        }),
      );
    case constants.GET_BLOOMBERG_IMPORT_STATUS_FAILURE:
      return state.merge(
        fromJS({
          loadingBloombergImportStatus: false,
          bloombergImportStatus: [],
          bloombergImportStatusError: action.payload,
        }),
      );

    case constants.GET_OVERNIGHT_CALCULATIONS_STATUS:
      return state.set('loadingOvernightCalculationsStatus', true);
    case constants.GET_OVERNIGHT_CALCULATIONS_STATUS_SUCCESS:
      return state.merge(
        fromJS({
          loadingOvernightCalculationsStatus: false,
          overnightCalculationsStatus: action.payload,
          overnightCalculationsStatusError: null,
        }),
      );
    case constants.GET_OVERNIGHT_CALCULATIONS_STATUS_FAILURE:
      return state.merge(
        fromJS({
          loadingOvernightCalculationsStatus: false,
          overnightCalculationsStatus: [],
          overnightCalculationsStatusError: action.payload,
        }),
      );

    case constants.GET_IC_DECISION_PRECONDITIONS:
      return state.set('loadingICDecisionPreconditions', true);
    case constants.GET_IC_DECISION_PRECONDITIONS_SUCCESS:
      return state.merge(
        fromJS({
          loadingICDecisionPreconditions: false,
          icDecisionPreconditions: action.payload,
        }),
      );
    case constants.GET_IC_DECISION_PRECONDITIONS_FAILURE:
      return state.merge(
        fromJS({
          loadingICDecisionPreconditions: false,
          icDecisionPreconditions: null,
        }),
      );

    default:
      return state;
  }
};
