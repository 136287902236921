import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import renderThemeConfig from 'helpers/renderThemeConfig';
import useRemoteFilterData from './useRemoteFilterData';
import { setLogFilterState } from '../../../../store/actions/log.actions';
import { filter as LogLevelFilters } from '../../../../config/appConfig/filters/logLevels.filters';
import { filter as LogDateRangeFilters } from '../../../../config/appConfig/filters/logDateRange.filters';

export default function useFilters() {
  const dispatch = useDispatch();
  const remoteFilterData = useRemoteFilterData();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { startOfTime } = renderThemeConfig.default;

  const LOG_LEVEL_OPTIONS = LogLevelFilters.filters.map(e => ({ title: e.text, id: e.value }));
  const LOG_DATERANGE_OPTIONS = LogDateRangeFilters.filters.map(e => ({
    title: e.text,
    id: e.value,
  }));
  const levelOptionsInitialSet = LOG_LEVEL_OPTIONS.map(e => e.id);
  const dateRangeOptionsInitialSet = LOG_DATERANGE_OPTIONS[4].id;

  const [dates, setDates] = useState({
    start: DateTime.now().startOf('day').toISO(),
    end: DateTime.now().toISO(),
  });
  const [logLevelFilters, setLogLevelFilters] = useState(new Set(levelOptionsInitialSet));
  const [logDateRangeFilter, setLogDateRangeFilter] = useState(dateRangeOptionsInitialSet);
  const [logDateRangeDates, setLogDateRangeDates] = useState({
    start: DateTime.fromISO(startOfTime),
    end: DateTime.now(),
  });
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const generateDateRange = useCallback(
    (selection: number): { start: string; end: string } => {
      let start = DateTime.now().startOf('day');
      let end = DateTime.now();

      if (selection === 0) {
        end = DateTime.now().endOf('day');
        start = DateTime.now().minus({ hours: 1 });
      } else if (selection === 1) {
        end = DateTime.now().endOf('day');
        start = DateTime.now().startOf('day');
      } else if (selection === 2) {
        end = DateTime.now().endOf('day');
        start = DateTime.now().minus({ hours: 24 });
      } else if (selection === 3) {
        end = DateTime.now().endOf('day');
        start = DateTime.now().minus({ days: 3 });
      } else if (selection === 4) {
        end = DateTime.now().endOf('day');
        start = DateTime.fromISO(startOfTime).endOf('day');
      }

      return { start: start?.toISO(), end: end?.toISO() };
    },
    [startOfTime],
  );
  const updateFilters = useCallback(
    (key: string, value: unknown) => dispatch(setLogFilterState(key, value)),
    [dispatch],
  );
  const updateDateRangeFilter = useCallback(
    (value: number) => {
      if (value === 5) {
        return setDates({
          start: logDateRangeDates.start.toISO(),
          end: logDateRangeDates.end.toISO(),
        });
      }

      const { start, end } = generateDateRange(value);
      return setDates({ start, end });
    },
    [generateDateRange, logDateRangeDates.end, logDateRangeDates.start],
  );

  const filtersOn =
    logLevelFilters.size !== levelOptionsInitialSet.length ||
    dateRangeOptionsInitialSet !== logDateRangeFilter ||
    remoteFilterData.types.filters.size !== remoteFilterData.types.initial?.length ||
    remoteFilterData.users.filters.size !== remoteFilterData.users.initial?.length;

  const clearFilters = () => {
    setLogLevelFilters(new Set(levelOptionsInitialSet));
    setLogDateRangeFilter(dateRangeOptionsInitialSet);
    remoteFilterData.types.setFilters(new Set(remoteFilterData.types.initial));
    remoteFilterData.users.setFilters(new Set(remoteFilterData.users.initial));
  };

  useEffect(() => {
    updateFilters('levels', Array.from(logLevelFilters));
    updateFilters('types', Array.from(remoteFilterData.types.filters));
    updateFilters('users', Array.from(remoteFilterData.users.filters));
  }, [
    updateFilters,
    logLevelFilters,
    remoteFilterData.types.filters,
    remoteFilterData.users.filters,
  ]);

  useEffect(() => {
    updateDateRangeFilter(logDateRangeFilter);
  }, [logDateRangeFilter, updateDateRangeFilter]);

  return {
    clearFilters,
    filtersOn,
    filter: {
      level: {
        options: LOG_LEVEL_OPTIONS,
        initial: levelOptionsInitialSet,
        selected: logLevelFilters,
        setSelected: setLogLevelFilters,
      },
      type: {
        options: remoteFilterData.types.options,
        initial: remoteFilterData.types.initial,
        selected: remoteFilterData.types.filters,
        setSelected: remoteFilterData.types.setFilters,
      },
      user: {
        options: remoteFilterData.users.options,
        initial: remoteFilterData.users.initial,
        selected: remoteFilterData.users.filters,
        setSelected: remoteFilterData.users.setFilters,
      },
      dateRange: {
        options: LOG_DATERANGE_OPTIONS,
        initial: dateRangeOptionsInitialSet,
        selected: logDateRangeFilter,
        setSelected: setLogDateRangeFilter,
      },
      datePicker: {
        dates: logDateRangeDates,
        setDates: setLogDateRangeDates,
      },
      dates,
      pagination: {
        pageSize: {
          value: pageSize,
          setValue: setPageSize,
        },
        page: {
          value: page,
          setValue: setPage,
        },
      },
    },
  };
}
