import Cookies from 'js-cookie';
import produce from 'immer';
import set from 'lodash/set';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { ajax, AjaxRequest } from 'rxjs/ajax';
import { filter, map } from 'rxjs/operators';
import { COGNITO_APP_ID, REFRESH_TOKEN_URL } from './cognitoConfig';
import { redirectToSSO } from './singleSignOn';
import { getRefreshToken } from '../../../helpers/refreshToken';

type ValidToken = { validToken: string };
type AccessTokenState = 'NoToken' | 'TokenLoading' | ValidToken;

/**
 * Test if an access token state is a valid token.
 * @param tokenState Either a valid token or a loading/empty token.
 * @returns Whether `tokenState` was a valid token, as a type predicate.
 */
export function isToken(tokenState: AccessTokenState): tokenState is ValidToken {
  return tokenState !== 'TokenLoading' && tokenState !== 'NoToken' && tokenState !== undefined;
}

/**
 * Get a token stored in cookies as the app loads.
 * @returns An AccessTokenState depending on what was stored in cookies.
 */
function getInitialToken(): AccessTokenState {
  const cookieToken = Cookies.get('cognito-access-token');
  if (cookieToken) return { validToken: cookieToken };
  return 'NoToken';
}

/**
 * This is the app-wide shared state for the current access token. It can
 * be in one of three states:
 * 1. No Token (only on fresh startup)
 * 2. Token Loading
 * 3. Valid Token
 * It's in a BehaviorSubject so that multiple places in the code can
 * subscribe to different views of it (e.g. all new *valid* tokens).
 */
export const accessTokenState$ = new BehaviorSubject<AccessTokenState>(getInitialToken());

/**
 * Persist a valid access token to the browser's cookies.
 */
function saveToken(token: ValidToken) {
  Cookies.set('cognito-access-token', token.validToken);
}

/**
 * Persist all new valid access tokens to the browser's cookies.
 */
accessTokenState$.pipe(filter(isToken)).subscribe(saveToken);

/**
 * Authenticate a request with the given token.
 * @param request An unauthenticated request
 * @param token A token intended for the `Bearer <token>` method of authorization
 * @returns A request with the correct Authorization header
 */
export function addToken(request: AjaxRequest, token: string) {
  return produce(request, draftRequest => {
    set(draftRequest, 'headers.Authorization', `Bearer ${token}`);
  });
}

/**
 * Format the body of our request for a new access token.
 * @param refreshToken A valid, but possibly out-of-date, refresh token.
 * @returns The formatted request body as a string.
 */
function getAccessTokenBody(refreshToken: string): string {
  return new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: COGNITO_APP_ID,
    refresh_token: refreshToken,
  }).toString();
}

/**
 * Call Cognito with our refresh token and format the response as a valid
 * access token, ready to update our access token state.
 * If we don't have a valid refresh token, the returned observable will
 * deliver an error, which can be handled later on.
 * @returns An observable which, when subscribed, sends a request for a new access token.
 */
function getNewAccessToken(): Observable<ValidToken> {
  const refreshToken = getRefreshToken();

  // Short-circuit making an unnecessary network request: treat missing
  // or empty refresh tokens as immediate errors.
  if (!refreshToken) {
    redirectToSSO();
    return EMPTY;
  }

  // Return an observable to send our Cognito request on subscription.
  return ajax({
    url: REFRESH_TOKEN_URL,
    method: 'POST',
    body: getAccessTokenBody(refreshToken),
    withCredentials: false,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }).pipe(
    map(ajaxResponse => ({
      validToken: ajaxResponse.response.access_token,
    })),
  );
}

/**
 * Set the token state to loading, and then request a new access token using our refresh token.
 * As soon as we get the new token, put it into our token state. If this fails, redirect to
 * our SSO login page.
 */
export async function requestNewToken() {
  // N.B. `.next()` is synchronous; we can call `getValue()` after this and be guarateed
  // that the token state will be `TokenLoading`.
  accessTokenState$.next('TokenLoading');
  try {
    const token = await getNewAccessToken().toPromise();
    accessTokenState$.next(token);
  } catch (e) {
    redirectToSSO();
  }
}
