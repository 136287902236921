import React, { Fragment } from 'react';

import UserTableWidget from '../components/organisms/UserTableWidget';
import UserDetailsWidget from '../components/organisms/UserDetailsWidget';
import OnlineUsersWidget from '../components/organisms/UserOnlineWidget';
import SamlUserApproval from '../components/organisms/UserSamlApproval';
import SamlContractRoleApproval from '../components/organisms/UserSamlContractRoleApproval';

import LogsTableWidget from '../components/organisms/LogsTableWidget';
import LogsErrorMonitoring from '../components/organisms/LogsErrorMonitoringWidget';

import MonitorLoginsChartWidget from '../components/organisms/MonitorLoginsChartWidget';
import MonitorLoginsTableWidget from '../components/organisms/MonitoringLoginsTableWidget';
import MonitorStrategiesChartWidget from '../components/organisms/MonitorStrategiesChartWidget';
import MonitorStrategiesTableWidget from '../components/organisms/MonitoringStrategiesTableWidget';

import InstrumentsTableWidget from '../components/organisms/InstrumentsTableWidget';
import NatixisInstrumentsList from '../components/organisms/NatixisInstrumentsList';
import InstrumentDetailsWidget from '../components/organisms/InstrumentDetailsWidget';
import InstrumentsPerfMonitoringWidget from '../components/organisms/InstrumentsPerfMonitoringWidget';
import InstrumentsUploadMonitoringWidget from '../components/organisms/InstrumentsUploadMonitoringWidget';
import InstrumentsMarketPerformanceMonitoringWidget from '../components/organisms/InstrumentsMarketPerfMonitoring';

import StrategyExportWidget from '../components/organisms/StrategyExportWidget';
import InstrumentExportWidget from '../components/organisms/InstrumentExportWidget';
import BBExportWidget from '../components/organisms/BBExportWidget';
import ReferenceDataExportWidget from '../components/organisms/ReferenceExportWidget';
import SplunkTimestampWidget from '../components/organisms/SplunkTimestampWidget';
import StrategyCalculationWidget from '../components/organisms/StrategyCalculationWidget';
import InformationBannerWidget from '../components/organisms/InformationBannerWidget';
import OvernightCalculationsWidget from '../components/organisms/OvernightCalculationsWidget';
import BloombergFeedImportWidget from '../components/organisms/BloombergImportWidget';
import ICDecisionPreconditions from '../components/organisms/ICDecisionPreconditions';
import UsersExportWidget from '../components/organisms/UsersExportWidget';
import StrategyTableWidget from '../components/organisms/StrategyTableWidget';
import StrategyDetailsWidget from '../components/organisms/StrategyDetailsWidget';

const Default = () => (
  <Fragment>
    <h3>Content Placeholder</h3>
  </Fragment>
);

const TEMPLATES = {
  default: <Default />,
  userTable: <UserTableWidget />,
  userDetails: <UserDetailsWidget />,
  onlineUsersWidget: <OnlineUsersWidget />,
  samlUserApproval: <SamlUserApproval />,
  samlContractRoleApproval: <SamlContractRoleApproval />,
  logTable: <LogsTableWidget />,
  logErrorMonitoring: <LogsErrorMonitoring />,
  instrumentTable: <InstrumentsTableWidget />,
  natixisInstrumentList: <NatixisInstrumentsList />,
  instrumentDetails: <InstrumentDetailsWidget />,
  instrumentsPerfMonitoringWidget: <InstrumentsPerfMonitoringWidget />,
  instrumentsUploadMonitoringWidget: <InstrumentsUploadMonitoringWidget />,
  instrumentsMarketPerfMonitoringWidget: <InstrumentsMarketPerformanceMonitoringWidget />,
  monitorLogins: <MonitorLoginsChartWidget />,
  monitorLoginsTable: <MonitorLoginsTableWidget />,
  monitorStrategies: <MonitorStrategiesChartWidget />,
  monitorStrategiesTable: <MonitorStrategiesTableWidget />,
  strategyExportWidget: <StrategyExportWidget />,
  instrumentExportWidget: <InstrumentExportWidget />,
  bbExportWidget: <BBExportWidget />,
  referenceDataExportWidget: <ReferenceDataExportWidget />,
  splunkTimestampWidget: <SplunkTimestampWidget />,
  strategyCalculationWidget: <StrategyCalculationWidget />,
  informationBanner: <InformationBannerWidget />,
  overnightCalculations: <OvernightCalculationsWidget />,
  bloombergFeedImport: <BloombergFeedImportWidget />,
  icDecisionPreconditions: <ICDecisionPreconditions />,
  usersExportWidget: <UsersExportWidget />,
  strategyTable: <StrategyTableWidget />,
  strategyDetails: <StrategyDetailsWidget />,
};

export default TEMPLATES;
