import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Grid, GridColumn, GridRow, Segment, Loader } from 'semantic-ui-react';

import { fetchInstrumentPerfMonitoring } from '../../../store/actions/instruments.actions';
import {
  selectInstrumentPerfMonitoring,
  selectMonitoringInstrumentPerfLoading,
} from '../../../store/selectors/instruments.selector';

import NumberInput from '../../atoms/NumberInput';
import DateInput from '../../atoms/DateInput';
import Button from '../../atoms/Button';

const InstrumentPerformanceMonitoring = styled.div`
  padding: 15px;

  .rowWrapper {
    max-height: 415px;
    overflow-y: scroll;
  }
`;

const InstrumentsMarketPerformanceMonitoringWidget = props => {
  const { loading, getInstrumentMonitoring, instruments } = props;

  const [percentileThreshold, setPercentileThreshold] = useState(10);
  const [dateStart, setDateStart] = useState(moment(new Date()).subtract(1, 'day').toDate());
  const [dateEnd, setDateEnd] = useState(new Date());
  const getData = () =>
    getInstrumentMonitoring(dateStart.toISOString(), dateEnd.toISOString(), percentileThreshold);
  const submitForm = () => getData();

  useEffect(() => {
    getInstrumentMonitoring(dateStart.toISOString(), dateEnd.toISOString(), percentileThreshold);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInstrumentMonitoring]);

  const formatRow = instrument => (
    <Segment vertical key={`instrumentBreach-${instrument._id}`}>
      <Grid>
        <GridRow>
          <GridColumn width={3}>
            <span>
              <a href={`/instruments/${instrument._id}`}>{instrument.externalId}</a>
            </span>
          </GridColumn>
          <GridColumn width={3}>
            <span>{instrument.ticker}</span>
          </GridColumn>
          <GridColumn width={3}>
            <span>{instrument.isin}</span>
          </GridColumn>
          <GridColumn width={2}>
            <span>{instrument.startDate}</span>
          </GridColumn>
          <GridColumn width={2}>
            <span>{instrument.endDate}</span>
          </GridColumn>
          <GridColumn width={3}>{instrument.diff.toFixed(2)}%</GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );

  // eslint-disable-next-line
  const perfData = (instruments || [])
    .map(({ instrumentId, marketData = [], instrument = {}, diff }) => {
      const dates = marketData.sort((a, b) =>
        new Date(a.date).getTime() > new Date(b.date).getTime() ? 1 : -1,
      );
      return {
        _id: instrument._id || '',
        created: instrument.created || '',
        externalId: instrument.externalId || instrumentId || '',
        ticker: instrument.bbgticker || instrument.ticker || '',
        isin: instrument.idISIN || '',
        startDate: moment(dates[0].date).format('DD.MM.YYYY'),
        endDate: moment(dates[1].date).format('DD.MM.YYYY'),
        diff,
      };
    })
    .map(formatRow);

  const instrumentRows = perfData
    ? perfData.sort((a, b) => {
        const aDate = a && a.created;
        const bDate = b && b.created;
        if (!aDate) {
          return 1;
        }
        if (!bDate) {
          return -1;
        }
        return moment(aDate).diff(moment(bDate));
      })
    : [];

  return (
    <InstrumentPerformanceMonitoring style={{ minHeight: '125px' }}>
      <h2>{`Instruments Market Performance Monitoring${
        instruments.length ? `: ${instruments.length}` : ''
      }`}</h2>
      <hr />
      <Grid verticalAlign="middle" columns={4} centered>
        <GridRow>
          <GridColumn width={4}>
            <DateInput
              name="Start Date"
              data={moment(dateStart).format('YYYY-MM-DD')}
              resolver={data => data}
              onChange={value => setDateStart(moment(value, 'YYYY-MM-DD').toDate())}
            />
          </GridColumn>
          <GridColumn width={4}>
            <DateInput
              name="End Date"
              data={moment(dateEnd).format('YYYY-MM-DD')}
              resolver={data => data}
              onChange={value => setDateEnd(moment(value, 'YYYY-MM-DD').toDate())}
            />
          </GridColumn>
          <GridColumn width={4}>
            <NumberInput
              name="Percentage Threshold"
              data={percentileThreshold}
              resolver={data => data}
              onChange={value =>
                !value || value === '' ? setPercentileThreshold(0) : setPercentileThreshold(value)
              }
            />
          </GridColumn>
          <GridColumn width={4}>
            <Button primary onClick={submitForm}>
              Submit
            </Button>
            <Loader inline size="tiny" active={loading} />
          </GridColumn>
        </GridRow>
      </Grid>
      <hr />
      {instruments.length ? (
        <Fragment>
          <Segment vertical>
            <Grid>
              <GridRow>
                <GridColumn width={3}>
                  <h4>Id</h4>
                </GridColumn>
                <GridColumn width={3}>
                  <h4>Ticker</h4>
                </GridColumn>
                <GridColumn width={3}>
                  <h4>ISIN</h4>
                </GridColumn>
                <GridColumn width={2}>
                  <h4>Start Date</h4>
                </GridColumn>
                <GridColumn width={2}>
                  <h4>End Date</h4>
                </GridColumn>
                <GridColumn width={3}>
                  <h4>Percentile Diff.</h4>
                </GridColumn>
              </GridRow>
            </Grid>
          </Segment>
          <Grid>
            <GridRow>
              <GridColumn className="rowWrapper" width={16}>
                {instrumentRows}
              </GridColumn>
            </GridRow>
          </Grid>
        </Fragment>
      ) : (
        <p>No instruments to report</p>
      )}
    </InstrumentPerformanceMonitoring>
  );
};

InstrumentsMarketPerformanceMonitoringWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  instruments: PropTypes.array.isRequired,
  getInstrumentMonitoring: PropTypes.func.isRequired,
};

InstrumentsMarketPerformanceMonitoringWidget.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  instruments: state => selectInstrumentPerfMonitoring(state),
  loading: state => selectMonitoringInstrumentPerfLoading(state),
});

const mapDispatchToProps = dispatch => ({
  getInstrumentMonitoring: (dateStart, dateEnd, percentile) =>
    dispatch(fetchInstrumentPerfMonitoring(dateStart, dateEnd, percentile)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InstrumentsMarketPerformanceMonitoringWidget);
