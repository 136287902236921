import { createSelector } from 'reselect';

const selectLogs = state => state.get('logs');

const selectLogList = createSelector(selectLogs, logs => logs.get('list'));

const selectLogMeta = createSelector(selectLogs, logs => logs.get('metadata'));

const selectLastRefresh = createSelector(selectLogs, logs => logs.get('lastRefresh'));

const selectLogLoading = createSelector(selectLogs, logs => logs.get('loading'));

const selectLogFetching = createSelector(selectLogs, logs => logs.get('fetching'));

const selectFilter = createSelector(selectLogs, logs => logs.get('filter').toJS());

const selectFilterContent = createSelector(selectLogs, logs => logs.get('filterContent'));

const selectDailyErrorCount = createSelector(selectLogs, logs => logs.get('dailyErrorCount').count);
const selectDailyErrorCountLoading = createSelector(selectLogs, logs =>
  logs.get('dailyErrorCountLoading'),
);
const selectDailyErrorCountError = createSelector(selectLogs, logs =>
  logs.get('dailyErrorCountError'),
);

export {
  selectLogs,
  selectLogLoading,
  selectLogFetching,
  selectLastRefresh,
  selectLogList,
  selectLogMeta,
  selectFilter,
  selectFilterContent,
  selectDailyErrorCount,
  selectDailyErrorCountLoading,
  selectDailyErrorCountError,
};
