/* eslint-disable jsx-a11y/label-has-associated-control */
import { yupResolver } from '@hookform/resolvers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Grid, GridColumn, Label, Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import * as yup from 'yup';
import InformationBannerPreview from './InformationBannerPreview';
import { HIGH_SEVERITY, MEDIUM_SEVERITY, INFO_SEVERITY } from './severityLevel';

const FieldContainer = styled.div`
  width: 100%;
  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

const SmallText = styled.div`
  font-size: 12px;
`;

const defaultValues = {
  message: '',
  displayFrom: null,
  displayUntil: null,
  isDismissable: false,
  severityLevel: HIGH_SEVERITY,
};

/* eslint-disable no-template-curly-in-string */
const informationBannerSchema = yup.object().shape({
  message: yup.string().label('Message').required().min(1),
  displayFrom: yup
    .date()
    .label('Display from')
    .typeError('${label} should be a valid date.')
    .required(),
  displayUntil: yup
    .date()
    .label('Display until')
    .typeError('${label} should be a valid date.')
    .required()
    .test(
      'isAfterDisplayFrom',
      'Display until date has to be later than the display from date.',
      function isAfterDisplayFrom(value) {
        const { displayFrom } = this.parent;
        return value > displayFrom;
      },
    ),
  isDismissable: yup.boolean().required(),
  severityLevel: yup.number().oneOf([HIGH_SEVERITY, MEDIUM_SEVERITY, INFO_SEVERITY]).required(),
});

export default function SaveInformationBannerModal({
  isOpen,
  closeModal,
  saveInformationBanner,
  error,
  initialData,
}) {
  const { control, handleSubmit, watch, errors, register, reset } = useForm({
    defaultValues: initialData ?? defaultValues,
    resolver: yupResolver(informationBannerSchema),
  });

  useEffect(() => {
    reset(initialData ?? defaultValues);
  }, [reset, initialData]);

  const displayFrom = watch('displayFrom');
  const id = watch('id');

  return (
    <Modal open={isOpen}>
      <Modal.Header>Save Information Banner</Modal.Header>
      <Modal.Content>
        <Form>
          <Grid>
            {error?.message ? (
              <Grid.Row>
                <GridColumn>
                  <Label color="red">{error.message}</Label>
                </GridColumn>
              </Grid.Row>
            ) : null}
            {id ? <input type="hidden" value={id} name="id" ref={register} /> : null}
            <Grid.Row>
              <GridColumn width={16}>
                <div className="field">
                  <label>Message</label>
                  <Controller control={control} name="message" as={Form.TextArea} />
                  <SmallText>
                    Markdown examples: **bold text**, *italic text*, [click me](https://smth.com)
                    for a link. More examples{' '}
                    <a
                      href="https://www.markdownguide.org/basic-syntax/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here.
                    </a>
                  </SmallText>
                  {errors.message ? (
                    <Label color="red" pointing="above">
                      {errors.message.message}
                    </Label>
                  ) : null}
                </div>
              </GridColumn>
            </Grid.Row>
            <Grid.Row>
              <GridColumn width={8}>
                <FieldContainer className="field">
                  <label>Display From</label>
                  <Controller
                    control={control}
                    name="displayFrom"
                    render={props => (
                      <Fragment>
                        <DatePicker
                          {...props}
                          className="input"
                          placeholderText="Select date"
                          showTimeSelect
                          selected={props.value}
                          dateFormat="dd.MM.yyyy HH:mm"
                          timeIntervals={30}
                          timeCaption="time"
                          timeFormat="HH:mm"
                          minDate={new Date()}
                        />
                        {errors.displayFrom ? (
                          <Label color="red" pointing="above">
                            {errors.displayFrom.message}
                          </Label>
                        ) : null}
                      </Fragment>
                    )}
                  />
                </FieldContainer>
              </GridColumn>
              <GridColumn width={8}>
                <FieldContainer className="field">
                  <label>Display Until</label>
                  <Controller
                    control={control}
                    name="displayUntil"
                    render={props => (
                      <Fragment>
                        <DatePicker
                          {...props}
                          className="input"
                          placeholderText="Select date"
                          showTimeSelect
                          selected={props.value}
                          dateFormat="dd.MM.yyyy HH:mm"
                          timeIntervals={30}
                          timeCaption="time"
                          timeFormat="HH:mm"
                          minDate={
                            moment.isDate(displayFrom) ? moment(displayFrom).toDate() : new Date()
                          }
                        />
                        {errors.displayUntil ? (
                          <Label color="red" pointing="above">
                            {errors.displayUntil.message}
                          </Label>
                        ) : null}
                      </Fragment>
                    )}
                  />
                </FieldContainer>
              </GridColumn>
            </Grid.Row>
            <Grid.Row>
              <GridColumn width={16}>
                <Controller
                  control={control}
                  name="isDismissable"
                  render={({ value, ...props }) => (
                    <Form.Checkbox
                      {...props}
                      label="Is dismissable"
                      checked={value}
                      onChange={(_, { checked }) => {
                        props.onChange(checked);
                      }}
                    />
                  )}
                />
              </GridColumn>
            </Grid.Row>
            <Grid.Row>
              <GridColumn width={16}>
                <Form.Group inline>
                  <Form.Field>
                    <label htmlFor="severityLevel">Severity Level</label>
                    <Controller
                      control={control}
                      name="severityLevel"
                      render={props => (
                        <Fragment>
                          <Form.Radio
                            {...props}
                            name="severityLevel"
                            label="High"
                            value={HIGH_SEVERITY}
                            checked={props.value === HIGH_SEVERITY}
                            onChange={(_, { value }) => {
                              props.onChange(value);
                            }}
                          />
                          <Form.Radio
                            {...props}
                            name="severityLevel"
                            label="Medium"
                            value={MEDIUM_SEVERITY}
                            checked={props.value === MEDIUM_SEVERITY}
                            onChange={(_, { value }) => {
                              props.onChange(value);
                            }}
                          />
                          <Form.Radio
                            {...props}
                            name="severityLevel"
                            label="Info"
                            value={INFO_SEVERITY}
                            checked={props.value === INFO_SEVERITY}
                            onChange={(_, { value }) => {
                              props.onChange(value);
                            }}
                          />
                        </Fragment>
                      )}
                    />
                  </Form.Field>
                </Form.Group>
              </GridColumn>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <InformationBannerPreview informationBanner={watch()} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button primary onClick={handleSubmit(saveInformationBanner)}>
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

SaveInformationBannerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveInformationBanner: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  initialData: PropTypes.object,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

SaveInformationBannerModal.defaultProps = {
  initialData: defaultValues,
};
