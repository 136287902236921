import getDynamicUrlThemeSelection from '../../../config/themesConfig';
import releaseToggles from '../../../releaseToggles';

const REFRESH_TOKEN_URL = process.env.REACT_APP_REFRESH_TOKEN_URL;

if (!REFRESH_TOKEN_URL && releaseToggles.cognitoAuthentication && process.env.NODE_ENV !== 'test') {
  throw new Error(
    // eslint-disable-next-line max-len
    'REFRESH_TOKEN_URL not found in environment variables. Please specify an environment file (.env.dev, .env.qa etc) with `env-cmd -f`, and ensure the file contains a REACT_APP_REFRESH_TOKEN_URL, to use Cognito authentication.',
  );
}
const appIDs = process.env.REACT_APP_CLIENT_APPIDS;
const appIDMap = new URLSearchParams(appIDs);
const COGNITO_APP_ID =
  appIDMap.get((getDynamicUrlThemeSelection() as string) || '') ||
  (appIDMap.get('Default') as string);

if (!COGNITO_APP_ID && releaseToggles.cognitoAuthentication && process.env.NODE_ENV !== 'test') {
  throw new Error(
    // eslint-disable-next-line max-len
    'COGNITO_APP_ID not found in environment variables. Please specify an environment file (.env.dev, .env.qa etc) with `env-cmd -f`, and ensure the file contains a REACT_APP_COGNITO_APPIDS section, to use Cognito authentication. It should be formatted as a query-string with whitelabel keys and Cognito App IDs as the values.',
  );
}

const ID_APP_URL = process.env.REACT_APP_ID_APP_URL;
if (!ID_APP_URL && releaseToggles.cognitoAuthentication && process.env.NODE_ENV !== 'test') {
  throw new Error(
    'ID_APP_URL not found in environment variables. Please specify an environment file (.env.dev, .env.qa etc) with `env-cmd -f`, and ensure the file contains a REACT_APP_ID_APP_URL key.',
  );
}

export { REFRESH_TOKEN_URL, COGNITO_APP_ID, ID_APP_URL };
