import moment from 'moment';

export default function getChartConfig(loginsData) {
  return {
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b',
      },
    },
    yAxis: {
      title: {
        text: 'Number of Logins',
      },
    },
    data: {
      name: 'User Logins over Time',
      series: loginsData.map(series => ({
        name: series.name,
        data: series.data.map(item => [
          moment(item[0], 'ddd MMM DD YYYY').utc().valueOf(),
          item[1],
        ]),
      })),
      tooltip: {
        formatter() {
          const self = this;
          let s = `<b>${moment(new Date(self.x)).format('DD.MM.YYYY')}</b>`;
          let sum = 0;

          self.points.forEach(point => {
            s += `<br/>${point.series.name}: ${point.y}`;
            sum += point.y;
          });

          s += `<br/>Total: ${sum}`;
          return s;
        },
        shared: true,
      },
    },
  };
}
