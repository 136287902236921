import React from 'react';
import PropTypes from 'prop-types';
import renderThemeConfig from 'helpers/renderThemeConfig';
import TimeFilter from '../../atoms/TimeFilter';

const yearLabel = setTimeFilter => year => ({
  id: year,
  label: `${year}`,
  func: id => () => {
    const rangeStart = new Date(year, 0, 1).getTime();
    const rangeEnd = new Date(year, 11, 31, 23, 59, 59).getTime();
    setTimeFilter({ rangeStart, rangeEnd, selection: id });
  },
});

const customDateTimeOptions = setTimeFilter =>
  renderThemeConfig.default.yearLabels.map(yearLabel(setTimeFilter));

export default function MonitorLoginsTimeFilter({ filter, setFilter }) {
  return (
    <TimeFilter
      timeInput={false}
      displayTimeRangeText={false}
      state={{
        start: filter.rangeStart,
        end: filter.rangeEnd,
        selection: filter.selection,
      }}
      setFilterState={(rangeStart, rangeEnd, selection) => {
        setFilter({ rangeStart, rangeEnd, selection });
      }}
      style={{
        display: 'inline-block',
        paddingLeft: '1.5em',
        position: 'relative',
        marginBottom: 0,
        paddingBottom: '2em',
      }}
      customDateTimeOptions={customDateTimeOptions(setFilter)}
    />
  );
}

MonitorLoginsTimeFilter.propTypes = {
  filter: PropTypes.shape({
    rangeStart: PropTypes.number.isRequired,
    rangeEnd: PropTypes.number.isRequired,
    selection: PropTypes.number.isRequired,
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
};
