import React, { useEffect, useMemo } from 'react';

const useSortAndPagination = <T,>(
  itemsPerPage: number,
  page: number,
  itemsList: T[],
  setPage: React.Dispatch<React.SetStateAction<number>>,
  sortingFunction: () => (x: T, y: T) => 0 | 1 | -1,
) => {
  useEffect(() => {
    if (itemsPerPage > itemsList.length) setPage(1);
  }, [itemsPerPage, itemsList, setPage]);

  return useMemo(() => {
    const sortedItems = itemsList.sort(sortingFunction());
    const paginatedFilteredRequests = sortedItems.slice(
      itemsPerPage * (page - 1),
      itemsPerPage * page,
    );

    return paginatedFilteredRequests;
  }, [itemsPerPage, page, itemsList, sortingFunction]);
};

export default useSortAndPagination;
