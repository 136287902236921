/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown, Form, Checkbox } from 'semantic-ui-react';

const FilterLabel = styled.label`
  color: #2a2968 !important;
  margin-top: 25px;
  font-weight: bolder;
`;

const CobSpan = styled.span`
  color: #2a2968 !important;
  font-weight: bolder;
`;

const SmartFilter = ({ filter, options, handleFilter, toggleFilter }) => {
  const handleSearchInput = e => {
    handleFilter({
      searchQuery: e.target.value,
    });
  };

  const handleChange = (e, data) => {
    handleFilter({
      value: data.value,
    });
  };
  // const handleSecondaryChange = (e, data) => {
  //   handleFilter({
  //     secondaryValue: data.value,
  //   });
  // };

  const handleToggle = val => {
    const { toggleValue } = filter;
    const index = toggleValue.findIndex(el => el === val);

    if (index === -1) {
      toggleValue.push(val);
    } else {
      toggleValue.splice(index, 1);
    }

    handleFilter({
      toggleValue,
    });
  };

  const renderPrimaryFilter = () => (
    <Fragment>
      <CobSpan>Search</CobSpan>
      <br />
      <Dropdown
        placeholder="Enter search term"
        options={options}
        search
        fluid
        multiple
        selection
        clearable
        closeOnChange
        value={filter.value}
        searchQuery={filter.searchQuery}
        onChange={handleChange}
        onSearchChange={handleSearchInput}
      />
    </Fragment>
  );

  // const renderSecondaryFilter = () => (
  //     <Dropdown
  //       placeholder="No Theme / Criteria Filter"
  //       options={secondaryOptions}
  //       fluid
  //       multiple
  //       selection
  //       clearable
  //       closeOnChange
  //       value={filter.secondaryValue}
  //       onChange={handleSecondaryChange}
  //     />
  // );

  const renderToggleFilter = () =>
    toggleFilter.map(tog => (
      <Fragment key={tog.name}>
        <FilterLabel>{tog.label}</FilterLabel>
        <Form.Field style={{ marginTop: '26px' }} key={tog.name} inline width="2">
          <Checkbox
            onChange={() => handleToggle(tog.name)}
            checked={filter.toggleValue.indexOf(tog.name) > -1}
          />
        </Form.Field>
      </Fragment>
    ));

  return (
    <Form>
      <Form.Group>
        <Form.Field width={toggleFilter.length ? 6 : 16}>{renderPrimaryFilter()}</Form.Field>
        <Form.Field width="1" />
        {toggleFilter && renderToggleFilter()}
      </Form.Group>
      {/* <Form.Group> */}
      {/*    <Form.Field width={5}> */}
      {/*        {secondaryOptions.length ? renderSecondaryFilter() : null} */}
      {/*    </Form.Field> */}
      {/* </Form.Group> */}
    </Form>
  );
};

SmartFilter.defaultProps = {
  toggleFilter: [],
};

SmartFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  toggleFilter: PropTypes.array,
};

export default SmartFilter;
