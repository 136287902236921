import React from 'react';
import useService from 'helpers/useService';

import { fetchCompanyLicenses } from 'services/clientAPI/companies.service';

type CompanyLicense = { _id: string; role: string; total: number; active: number };

function CompanyLicenses({
  title,
  licenses,
}: {
  title: string;
  licenses: { amount: string; type: string }[];
}) {
  return (
    <div className="flex flex-row">
      <h3 className="text-3xl">{title}</h3>
      <div className="flex flex-col ml-16">
        {licenses.map(i => (
          <div key={i.amount + i.type} className="flex flex-row items-center mb-4">
            <h3 className="text-4xl mr-4 mb-0">{i.amount}</h3> <p>{i.type}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function LicensesWidget() {
  const roleLicenseTitleLookup: { [key: string]: string } = {
    bluebay: 'BlueBay Pro',
    'bluebay-client': 'My BlueBay',
    // currently does not work for BBVA roles. TODO: clarify BBVA roles/titles
  };
  const [loading, , licenses] = useService<CompanyLicense[]>(fetchCompanyLicenses);

  return (
    <div className="flex flex-row py-14 px-36 justify-between">
      {!loading &&
        licenses?.map(({ _id, role, total, active }, idx) => (
          <CompanyLicenses
            key={_id + idx}
            title={roleLicenseTitleLookup[role]}
            licenses={[
              { amount: total.toString(), type: 'Purchased' },
              { amount: active.toString(), type: 'Utilised' },
              { amount: (total - active).toString(), type: 'Remaining' },
            ]}
          />
        ))}
    </div>
  );
}
