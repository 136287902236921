/* eslint-disable @typescript-eslint/no-shadow */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getBloombergImportStatus } from '../../../store/actions/monitoring.actions';
import { selectBloombergImportStatus } from '../../../store/selectors/monitoring.selector';
import DashboardCard from '../../atoms/DashboardCard';
import StatusDate from '../../atoms/StatusDate';

function BloombergImportWidget({ bloombergImportStatus, getBloombergImportStatus }) {
  useEffect(() => {
    getBloombergImportStatus();
  }, [getBloombergImportStatus]);

  return (
    <DashboardCard title="Bloomberg Import">
      <div className="ui list">
        {bloombergImportStatus.map(({ date, success }, index) => (
          <StatusDate key={index} date={date} success={success} />
        ))}
      </div>
    </DashboardCard>
  );
}

BloombergImportWidget.propTypes = {
  bloombergImportStatus: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      success: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  getBloombergImportStatus: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  bloombergImportStatus: selectBloombergImportStatus,
});

const mapDispatchToProps = {
  getBloombergImportStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(BloombergImportWidget);
