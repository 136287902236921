import React from 'react';
import { Button, Checkbox, Loader } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import renderThemeConfig from 'helpers/renderThemeConfig';
import renderConfig from 'helpers/renderConfig';
import { setMonitoringLoginChartUnknowns } from '../../../store/actions/monitoring.actions';
import {
  selectCacheTimestampChart,
  selectLoginMonitoringDataFetching,
  selectMonitoringFilter,
} from '../../../store/selectors/monitoring.selector';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';
import useMonitoringRedirect from './useMonitoringRedirect';
import { ChartLink, Controls, OptionSeparator } from './MonitorLoginsControls.styles';
import useTimeFilter from './useTimeFilter';
import useActivePanel from './MonitorLoginsPanels';
import MonitorLoginsTimeFilter from './MonitorLoginsTimeFilter';

export default function MonitorLoginsControls() {
  const dispatch = useDispatch();
  const activePanel = useActivePanel();
  const handleRedirect = useMonitoringRedirect();
  const filter = useSelector(selectMonitoringFilter);
  const cacheTimestamp = useSelector(selectCacheTimestampChart);
  const fetching = useSelector(selectLoginMonitoringDataFetching);
  const panels = renderThemeConfig.default.monitorLoginsControlPanels;
  const { setTimeFilter, resetFilters } = useTimeFilter();
  const { formatDateTime } = useDateTimeFormatter();

  return (
    <Controls>
      <div>
        {panels.map(({ panelURL, title, panelName }) => (
          <ChartLink
            key={panelName}
            onClick={() => handleRedirect(panelURL)}
            className={activePanel === panelName ? 'active' : ''}
          >
            {title}
          </ChartLink>
        ))}
        <MonitorLoginsTimeFilter filter={filter} setFilter={setTimeFilter} />
        <OptionSeparator />
        <Button onClick={resetFilters}>Reset</Button>
        <Loader inline size="mini" active={fetching} />
        <span
          style={{
            margin: 15,
            fontSize: '1rem',
            textAlign: 'right',
          }}
        >
          Cached since: {formatDateTime(cacheTimestamp)}
        </span>
      </div>
      <Checkbox
        style={{ marginTop: '10px', marginBottom: '10px' }}
        label={`Include ${renderConfig.displayName} Support`}
        defaultChecked={filter.includeUnknowns}
        onClick={() => dispatch(setMonitoringLoginChartUnknowns(!filter.includeUnknowns))}
      />
    </Controls>
  );
}
