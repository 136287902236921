import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';

import auth from './auth.reducer';
import layout from './layout.reducer';
import themes from './themes.reducer';
import user from './user.reducer';
import companies from './companies.reducer';
import roles from './roles.reducer';
import logs from './log.reducer';
import monitoring from './monitoring.reducer';
import instruments from './instruments.reducer';
import strategy from './strategy.reducer';
import reference from './reference.reducer';
import informationBanners from './informationBanner.reducer';
import regions from './regions.reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth,
    themes,
    layout,
    user,
    companies,
    roles,
    logs,
    monitoring,
    instruments,
    strategy,
    reference,
    informationBanners,
    regions,
  });
