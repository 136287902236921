import { apiCall$ } from './lib/apiCall';
import {
  loginUsersByContractRole,
  loginUsersByFunctionRole,
  loginUsersAggregated,
  loginUsersTableData,
  strategyCreationSeriesData,
  strategyTableData,
  splunkTimestampUrl,
  bloombergImportStatusURL,
  overnightCalculationsStatusURL,
  icDecisionPreconditionsURL,
} from './clientAPI.config';

export const fetchLoginUsersByContractRole = data =>
  apiCall$(loginUsersByContractRole, 'POST', data);
export const fetchLoginUsersByFunctionRole = data =>
  apiCall$(loginUsersByFunctionRole, 'POST', data);
export const fetchLoginUsersAggregate = data => apiCall$(loginUsersAggregated, 'POST', data);
export const fetchLoginUsersTableData = (year = new Date().getFullYear()) =>
  apiCall$(loginUsersTableData.replace(/:year/, year));

export const fetchStrategyCreationSeriesData = data =>
  apiCall$(`${strategyCreationSeriesData}`, 'POST', data);
export const fetchStrategyTableData = (year = new Date().getFullYear(), includeDeleted = false) =>
  apiCall$(strategyTableData.replace(/:year/, year).replace(/:deleted/, includeDeleted));

export const fetchBloombergImportStatus = () => apiCall$(bloombergImportStatusURL);
export const fetchOvernightCalculationsStatus = () => apiCall$(overnightCalculationsStatusURL);

export const fetchLatestSplunkTimestamps = () => apiCall$(splunkTimestampUrl);
export const fetchICDecisionPreconditions = () => apiCall$(icDecisionPreconditionsURL);
