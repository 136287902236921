import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button as SemanticUIButton } from 'semantic-ui-react';

const ButtonStyles = styled.div`
  display: contents; // Don't show this div, only its children.
  
  .ui.button {
    &.primary,&.positive,&.active {
      background: ${props => props.theme.variables.primary} !important;
      color: white !important;
    }
  }
}
`;

export default function Button(props) {
  return (
    <ButtonStyles>
      <SemanticUIButton {...props} />
    </ButtonStyles>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
};
