import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownInput from '../../atoms/DropdownInput';
import { selectReferenceDataCurrencies } from '../../../store/selectors/reference.selector';
import { requestReferenceCurrencies } from '../../../store/actions/reference.actions';

const CurrencySelector = props => {
  const dispatch = useDispatch();

  const currencies = useSelector(selectReferenceDataCurrencies);

  useEffect(() => {
    dispatch(requestReferenceCurrencies());
  }, [dispatch]);

  return <DropdownInput {...props} values={currencies} />;
};

export default CurrencySelector;
