/* eslint-disable import/prefer-default-export */
export const filter = {
  config: {
    identifier: 'levels',
    name: 'Log Levels',
  },
  defaults: {},
  filters: [
    {
      key: 0,
      text: 'Error',
      value: 0,
    },
    {
      key: 1,
      text: 'Warn',
      value: 1,
    },
    {
      key: 2,
      text: 'Info',
      value: 2,
    }, // ,
    // {
    //     key: 3,
    //     text: 'Verbose',
    //     value: 3
    // }
  ],
};
