import React, { useEffect, useMemo } from 'react';

const usePagination = <T,>(
  itemsPerPage: number,
  page: number,
  itemsList: T[],
  setPage: React.Dispatch<React.SetStateAction<number>>,
) => {
  useEffect(() => {
    if (itemsPerPage > itemsList.length) setPage(1);
  }, [itemsPerPage, itemsList, setPage]);

  return useMemo(() => {
    const paginatedItems = itemsList?.slice(itemsPerPage * (page - 1), itemsPerPage * page);

    return paginatedItems;
  }, [itemsPerPage, page, itemsList]);
};

export default usePagination;
