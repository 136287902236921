/* eslint-disable react/display-name */
import React, { MouseEventHandler, CSSProperties } from 'react';
import Checkbox from '../../atoms/Checkbox';

type Item = {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
};

const ListItem = React.forwardRef(
  (
    {
      data,
      selected,
      onClick,
      style,
    }: {
      data: Item;
      selected: boolean;
      onClick: MouseEventHandler<HTMLLabelElement>;
      style?: CSSProperties;
    },
    ref: React.Ref<HTMLLabelElement>,
  ) => {
    const selectedWrapper = 'border-primary-800 bg-primary-100 text-primary-800';
    const unselectedWrapper = 'border-gray-400 bg-gray-100 text-gray-800';

    return (
      <label
        htmlFor={`checkbox-${data._id}`}
        style={style}
        className={`flex flex-row items-center p-8 w-128 h-24 border cursor-pointer border-solid select-none relative ${
          selected ? selectedWrapper : unselectedWrapper
        } pr-12`}
        ref={ref}
      >
        <Checkbox
          checked={selected}
          onChange={onClick as unknown as React.ChangeEventHandler<HTMLInputElement>}
          id={data._id}
        />
        <div className="flex flex-col justify-self-end ml-5">
          <p className="m-0 text-left font-bold">
            {data.firstName} {data.lastName}
          </p>
          <p className="m-0 text-left truncate max-w-64">{data.username}</p>
        </div>
      </label>
    );
  },
);

export default ListItem;
