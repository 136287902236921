import { fromJS } from 'immutable';

import * as constants from '../constants';

const initialState = fromJS({
  loading: false,
  error: null,
  list: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_ROLES_LIST:
      return state.set('loading', true);

    case constants.GET_ROLES_LIST_SUCCESS:
      return state.merge({
        list: action.payload,
        loading: false,
        error: null,
      });

    case constants.GET_ROLES_LIST_FAILURE:
      return state.merge({
        list: {},
        loading: false,
        error: action.payload ? action.payload.error : null,
      });

    case constants.CLEAR_SESSION:
    case constants.LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};
