const baseAuthUrl =
  process.env.REACT_APP_CORE_AUTH_API_BASE_URL || 'https://app-api.dev.alpima.net/api/auth';
const baseSSOAuthUrl =
  process.env.REACT_APP_CORE_SSO_AUTH_API_BASE_URL || 'https://authapi.dev.alpima.net';

export const baseUrl =
  process.env.REACT_APP_CORE_API_BASE_URL || 'https://app-api.dev.alpima.net/api';
export const baseAdvisoryUrl =
  process.env.REACT_APP_ADVISORY_API_BASE_URL || 'https://advisoryapi.dev.alpima.net/advisoryapi';

export const baseAdminUrl =
  process.env.REACT_APP_ADMIN_API_BASE_URL || 'https://adminapi.dev.alpima.net';

export const bluebayURL = `${baseUrl}/bluebay`;

export const getAjaxConfig = () => ({
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
});

// SHARED
export const ssoLoginURL = `${baseSSOAuthUrl}/login`;
export const loginURL = `${baseAuthUrl}/authenticate`;
export const logoutURL = `${baseAuthUrl}/logout`;
export const displayUserURL = `${baseAuthUrl}/user`;

// ALPIMA
export const companyLayoutURL = `${baseUrl}/companies/layout`;
export const perfattributionURL = `${baseUrl}/perfattribution`;

// CORE
export const companiesListURL = `${baseAdminUrl}/companies`;
export const companyLicensesURL = `${baseUrl}/roles/company/licenses`;
export const rolesListURL = `${baseAdminUrl}/roles/user`;
// CORE USERS
export const userListURL = `${baseAdminUrl}/users`;
export const newUserListURL = `${baseUrl}/admin/users/:companyId`;
export const newUserDetailsURL = `${baseUrl}/admin/users/:id/details`;
export const newEditUserURL = `${baseUrl}/admin/bluebayusers/edit`;
export const userDetailsURL = `${baseAdminUrl}/users/:id`;
export const userUpdateURL = `${baseAdminUrl}/users/:id`;
export const userEnableURL = `${baseAdminUrl}/users/:id/enable`;
export const userDisableURL = `${baseAdminUrl}/users/:id/disable`;
// CORE LOGS
export const logListURL = `${baseAdminUrl}/logs/list/filter`;
export const logListCSVExportURL = `${baseUrl}/logs/list/filter/csvexport`;
export const logTypeContentURL = `${baseAdminUrl}/logs/types`;
// CORE INSTRUMENTS
export const updateInstrumentsURL = `${baseAdminUrl}/instruments/:instrumentId`;

// ADVISORY
export const advisoryStrategiesURL = `${baseAdminUrl}/strategies`;
export const advisoryInstrumentsURL = `${baseAdminUrl}/instruments`;
export const advisoryReferenceDataURL = `${baseAdminUrl}/reference`;
export const advisoryCurrencyDataURL = `${baseAdvisoryUrl}/currencies`;
export const instrumentDetailsURL = `${baseAdminUrl}/instruments/:instrumentId`;
export const instrumentTstMonitoringURL = `${baseAdminUrl}/monitoring/tst-instruments?:date`;
export const instrumentPerfMonitoringURL = `${baseAdminUrl}/monitoring/instrument-performance?:dateStart&:dateEnd&:percentile`;
export const strategyDetailsURL = `${baseAdminUrl}/strategies/:strategyId`;

export const usersOnlineURL = {
  default: `${baseAdminUrl}/users/online`,
  Natixis: `${baseUrl}/admin/onlineUsers/lite`,
};
export const usersPendingURL = `${baseAdminUrl}/users/pending`;
export const usersWithUpdatedContractRolesURL = `${baseAdminUrl}/users/updatedcontractroles`;
export const usersWithUpdatedContractRolesUpdateURL = `${baseAdminUrl}/users/updatedcontractroles/:id`;
export const userOnboardingUpdateURL = `${baseAdminUrl}/users/onboarding/:id`;
export const userFunctionalRolesURL = `${baseAdminUrl}/roles/functional?useUserCompany=true`;
export const objectRolesURL = `${baseAdminUrl}/roles/object`;
export const getUserObjectRolesURL = `${baseAdminUrl}/users/:userId/object-roles`;
export const setUserObjectRolesURL = `${baseAdminUrl}/users/:userId/object-roles`;
export const userContractualRolesURL = `${baseAdminUrl}/roles/contractual`;

export const loginUsersByContractRole = `${baseAdminUrl}/monitoring/logins/contractRole`;
export const loginUsersByFunctionRole = `${baseAdminUrl}/monitoring/logins/functionRoles`;
export const loginUsersAggregated = `${baseAdminUrl}/monitoring/logins/aggregate`;
// `${baseAdminUrl}/monitoring/logins/:rollup/:year`;
export const loginUsersTableData = `${baseAdminUrl}/monitoring/stats/logins/:year`;
export const strategyCreationSeriesData = `${baseAdminUrl}/monitoring/strategies`;
export const strategyTableData = `${baseAdminUrl}/monitoring/strategies?year=:year&deleted=:deleted`;

export const strategyCalculationUrl = `${baseAdminUrl}/strategies/recalculate`;
export const splunkTimestampUrl = `${baseAdminUrl}/monitoring/splunk`;
export const logDailyErrorCountURL = `${baseAdminUrl}/monitoring/dailyErrorCount`;
export const strategyExportUrl = `${baseAdminUrl}/strategies/export`;
export const instrumentExportUrl = `${baseAdminUrl}/instruments/export`;
export const instrumentBBExportUrl = `${baseAdminUrl}/instruments/bb/export`;
export const referenceDataExportUrl = `${baseAdminUrl}/reference/export`;
export const usersExportURL = `${baseAdminUrl}/users/export`;

export const informationBannersUrl = `${baseAdvisoryUrl}/information-banners`;
export const bloombergImportStatusURL = `${baseAdminUrl}/monitoring/bloomberg-import-status`;
export const overnightCalculationsStatusURL = `${baseAdminUrl}/monitoring/overnight-calculations-status`;
export const icDecisionPreconditionsURL = `${baseAdminUrl}/monitoring/ic-decision-preconditions-status`;

export const strategyListURL = `${baseAdminUrl}/strategies`;

export const regionsListURL = `${baseAdvisoryUrl}/regions/product`;

// User Groups
export const getCompanyUsersURL = `${baseUrl}/admin/users/:companyId`;
export const userGroupsByCompanyURL = `${baseUrl}/userGroups/byCompany`;
export const deleteUserGroupURL = `${baseUrl}/userGroups/:id`;
export const createUserGroupURL = `${baseUrl}/userGroups`;
export const getUserGroupURL = `${baseUrl}/userGroups/:id`;
export const updateUserGroupURL = `${baseUrl}/userGroups/:id`;
