import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as moment from 'moment';
import styled from 'styled-components';
import { Button, Checkbox } from 'semantic-ui-react';
import { createStructuredSelector } from 'reselect';
import renderConfig from 'helpers/renderConfig';
import {
  getStrategyCreationData,
  setMonitoringFilterTimeState,
} from '../../../store/actions/monitoring.actions';
import {
  requestReferenceCurrencies,
  requestReferenceProductLines,
} from '../../../store/actions/reference.actions';
import {
  selectLastRefresh,
  selectCacheTimestampChart,
  selectLoginMonitoringDataLoading,
  selectLoginMonitoringDataFetching,
  selectStrategyCreationSeries,
  selectMonitoringFilter,
} from '../../../store/selectors/monitoring.selector';
import {
  selectReferenceDataProductLines,
  selectReferenceDataCurrencies,
} from '../../../store/selectors/reference.selector';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import StrategyChart from '../../atoms/LineChart';
import FilterMenu from '../../atoms/FilterMenu';
import TimeFilter from '../../atoms/TimeFilter';
import { filters } from '../../../config/appConfig';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';

const OptionSeparator = styled.div`
  display: inline-block;
  width: 1px;
  height: 25px;
  border-right: solid 1px #333;
  margin: 0 10px 3px;
  vertical-align: middle;
`;

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const defaultProps = {
  xAxis: {
    type: 'datetime',
    dateTimeLabelFormats: {
      // don't display the dummy year
      month: '%e. %b',
      year: '%b',
    },
  },
  yAxis: {
    title: {
      text: 'Number of Strategies',
    },
  },
};

const MonitorStrategiesWidget = ({
  // lastRefresh,
  cacheTimestamp,
  withUnknownData,
  withCumulativeData,
  loading,
  // fetching,
  timeFilter,
  setTimeFilter,
  chartData,
  getChartData,
  fetchProductLines,
  // fetchCurrencies,
  referenceProductLines,
  referenceCurrencies,
}) => {
  const [showEmptyDates, toggleShowEmptyDates] = useState(false);
  const [showCumulative, toggleShowCumulative] = useState(withCumulativeData);
  const [showUnknowns, toggleShowUnknowns] = useState(withUnknownData);

  const [plFilter, setPlFilter] = useState(filters.StrategyMonitoringProductLines.defaults);
  const [currencyFilter, setCurrencyFilter] = useState(
    filters.StrategyMonitoringCurrencies.defaults,
  );

  const { formatDateTime } = useDateTimeFormatter();

  // Fetch all relevant data - initial chart, product lines and currencies for filters
  useEffect(() => {
    getChartData(plFilter, currencyFilter, showCumulative, showUnknowns);
    scrollToTop();
  }, [getChartData, plFilter, currencyFilter, showCumulative, showUnknowns]);
  // useEffect(() => { fetchCurrencies(); }, [fetchCurrencies]); // Disable - use only configured currencies
  useEffect(() => {
    fetchProductLines();
  }, [fetchProductLines]);

  const resetFilters = () => {
    setPlFilter(filters.StrategyMonitoringProductLines.defaults);
    setCurrencyFilter(filters.StrategyMonitoringCurrencies.defaults);
    setTimeFilter({
      rangeStart: new Date(2022, 0, 1).toISOString(),
      rangeEnd: new Date(2022, 11, 31, 23, 59, 59).toISOString(),
      selection: 2022,
    });

    toggleShowEmptyDates(false);
    toggleShowCumulative(true);
    toggleShowUnknowns(false);
    getChartData(
      filters.StrategyMonitoringProductLines.defaults,
      filters.StrategyMonitoringCurrencies.defaults,
      true,
      false,
    );
  };

  const filterSeriesData = data => (!showEmptyDates ? data.filter(item => item[1] !== 0) : data);
  const chartConfig = {
    ...defaultProps,
    data: {
      name: 'Object Creation over Time',
      series: (chartData || []).map(series => ({
        name: series.name,
        type: 'area',
        data: filterSeriesData(series.data).map(item => [
          moment(item[0], 'ddd MMM DD YYYY').utc().valueOf(),
          item[1],
        ]),
      })),
      tooltip: {
        formatter() {
          const self = this;
          let s = `<b>${moment(new Date(self.x)).format('DD.MM.YYYY')}</b>`;
          let sum = 0;

          self.points.forEach(point => {
            s += `<br/>${point.series.name}: ${point.y}`;
            sum += point.y;
          });

          s += `<br/>Total: ${sum}`;
          return s;
        },
        shared: true,
      },
    },
  };

  // Override default config filters with what we got from API
  const productLineFilterOptions =
    referenceProductLines && referenceProductLines.length
      ? referenceProductLines.map(pl => ({
          key: pl.code,
          value: pl.code,
          text: pl.name,
        }))
      : filters.StrategyMonitoringProductLines.filters;
  const currencyFilterOptions =
    referenceCurrencies && referenceCurrencies.length
      ? referenceCurrencies.map(currency => ({
          key: currency.isoCode,
          value: currency.isoCode,
          text: `[${currency.isoCode}] ${currency.name}`,
        }))
      : filters.StrategyMonitoringCurrencies.filters;

  return (
    <LoadingBoundary isLoading={loading}>
      <Fragment>
        <div>
          {renderConfig.useProductLinesFilter && (
            <FilterMenu
              config={filters.StrategyMonitoringProductLines.config}
              defaults={filters.StrategyMonitoringProductLines.defaults}
              options={productLineFilterOptions}
              state={plFilter}
              setFilterState={state => setPlFilter(state)}
            />
          )}
          <FilterMenu
            config={filters.StrategyMonitoringCurrencies.config}
            defaults={filters.StrategyMonitoringCurrencies.defaults}
            options={currencyFilterOptions}
            state={currencyFilter}
            setFilterState={state => setCurrencyFilter(state)}
          />
          <TimeFilter
            timeInput={false}
            displayTimeRangeText={false}
            state={{
              start: timeFilter.rangeStart,
              end: timeFilter.rangeEnd,
              selection: timeFilter.selection,
            }}
            setFilterState={(rangeStart, rangeEnd, selection, rangeName) => {
              setTimeFilter({
                rangeStart,
                rangeEnd,
                rangeName,
                selection,
              });
              getChartData(plFilter, currencyFilter, showCumulative, showUnknowns);
            }}
            style={{
              display: 'inline-block',
              paddingLeft: '1.5em',
              position: 'relative',
            }}
            customDateTimeOptions={[
              {
                id: 2019,
                label: '2019',
                func: id => () => {
                  // NOTE: this needs to be a double function due to TimeFilter registering the callback internally
                  const rangeStart = new Date(2019, 10, 18).getTime();
                  const rangeEnd = new Date(2019, 11, 31, 23, 59, 59).getTime();
                  setTimeFilter({
                    rangeStart,
                    rangeEnd,
                    rangeName: '2019',
                    selection: id,
                  });
                  getChartData(plFilter, currencyFilter, showCumulative, showUnknowns);
                },
              },
              {
                id: 2020,
                label: '2020',
                func: id => () => {
                  // NOTE: this needs to be a double function due to TimeFilter registering the callback internally
                  const rangeStart = new Date(2020, 0, 1).getTime();
                  const rangeEnd = new Date(2020, 11, 31, 23, 59, 59).getTime();
                  setTimeFilter({
                    rangeStart,
                    rangeEnd,
                    rangeName: '2020',
                    selection: id,
                  });
                  getChartData(plFilter, currencyFilter, showCumulative, showUnknowns);
                },
              },
              {
                id: 2021,
                label: '2021',
                func: id => () => {
                  // NOTE: this needs to be a double function due to TimeFilter registering the callback internally
                  const rangeStart = new Date(2021, 0, 1).getTime();
                  const rangeEnd = new Date(2021, 11, 31, 23, 59, 59).getTime();
                  setTimeFilter({
                    rangeStart,
                    rangeEnd,
                    rangeName: '2021',
                    selection: id,
                  });
                  getChartData(plFilter, currencyFilter, showCumulative, showUnknowns);
                },
              },
              {
                id: 2022,
                label: '2022',
                func: id => () => {
                  // NOTE: this needs to be a double function due to TimeFilter registering the callback internally
                  const rangeStart = new Date(2022, 0, 1).getTime();
                  const rangeEnd = new Date(2022, 11, 31, 23, 59, 59).getTime();
                  setTimeFilter({
                    rangeStart,
                    rangeEnd,
                    rangeName: '2022',
                    selection: id,
                  });
                  getChartData(plFilter, currencyFilter, showCumulative, showUnknowns);
                },
              },
            ]}
          />
          <OptionSeparator />
          <Button onClick={resetFilters}>Reset</Button>
          <span style={{ margin: 15, fontSize: '1rem', textAlign: 'right' }}>
            Cached since: {formatDateTime(cacheTimestamp)}
          </span>
          {/* <Loader inline size="mini" active={fetching} /> */}
        </div>
        <div>
          <Checkbox
            label="Show Cumulative Count"
            defaultChecked={showCumulative}
            onClick={() => {
              const nextCumulativeState = !showCumulative;
              toggleShowCumulative(nextCumulativeState);
              toggleShowEmptyDates(!nextCumulativeState);
            }}
          />
          <OptionSeparator />
          {/* <Checkbox */}
          {/*    label="Show Unknown Strategy Types" */}
          {/*    defaultChecked={showUnknowns} */}
          {/*    onClick={() => toggleShowUnknowns(!showUnknowns)} */}
          {/* /> */}
          {/* <OptionSeparator /> */}
          <Checkbox
            label="Show Empty Dates"
            defaultChecked={showEmptyDates}
            onClick={() => toggleShowEmptyDates(!showEmptyDates)}
          />
        </div>
        <StrategyChart {...chartConfig} />
      </Fragment>
    </LoadingBoundary>
  );
};

MonitorStrategiesWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  // lastRefresh: PropTypes.number.isRequired,
  cacheTimestamp: PropTypes.number.isRequired,
  fetching: PropTypes.bool.isRequired,
  withCumulativeData: PropTypes.bool,
  withUnknownData: PropTypes.bool,
  getChartData: PropTypes.func.isRequired,
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      data: PropTypes.array.isRequired,
    }),
  ),
  fetchProductLines: PropTypes.func.isRequired,
  fetchCurrencies: PropTypes.func.isRequired,
  referenceProductLines: PropTypes.array,
  referenceCurrencies: PropTypes.array,
  timeFilter: PropTypes.object.isRequired,
  setTimeFilter: PropTypes.func.isRequired,
};

MonitorStrategiesWidget.defaultProps = {
  chartData: [{ name: '', data: [] }],
  withUnknownData: false,
  withCumulativeData: true,
  referenceProductLines: [],
  referenceCurrencies: [],
};

const mapStateToProps = createStructuredSelector({
  loading: state => selectLoginMonitoringDataLoading(state),
  lastRefresh: state => selectLastRefresh(state),
  cacheTimestamp: state => selectCacheTimestampChart(state),
  fetching: state => selectLoginMonitoringDataFetching(state),
  chartData: state => selectStrategyCreationSeries(state),
  referenceProductLines: state => selectReferenceDataProductLines(state),
  referenceCurrencies: state => selectReferenceDataCurrencies(state),
  timeFilter: state => selectMonitoringFilter(state),
});

const mapDispatchToProps = dispatch => ({
  getChartData: (productLines, currencies, showCumulative, showUnknowns) =>
    dispatch(getStrategyCreationData(productLines, currencies, showCumulative, showUnknowns)),
  fetchProductLines: () => dispatch(requestReferenceProductLines()),
  fetchCurrencies: () => dispatch(requestReferenceCurrencies()),
  setTimeFilter: timeFilter =>
    dispatch(
      setMonitoringFilterTimeState(
        timeFilter.rangeStart,
        timeFilter.rangeEnd,
        timeFilter.rangeName,
        timeFilter.selection,
      ),
    ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MonitorStrategiesWidget));
