import { LogGroup } from '../logGroup.mapping';

// eslint-disable-next-line import/prefer-default-export
export const columns = [
  {
    getLabel: () => 'User',
    name: 'user',
    type: 'string',
    align: 'left',
    width: 2,
    resolver: item => (item && item.user && item.user.displayName) || '-',
  },
  {
    getLabel: () => 'Company',
    name: 'company',
    type: 'string',
    align: 'left',
    width: 2,
    resolver: item => (item && item.user && item.user.company && item.user.company.name) || '-',
  },
  {
    getLabel: () => 'Event Type',
    name: 'accountType',
    type: 'string',
    align: 'left',
    width: 1,
    resolver: item => (item && item.type && item.type.name) || '-',
  },
  {
    getLabel: () => 'Group',
    name: 'group',
    type: 'string',
    align: 'left',
    width: 1,
    resolver: item => (item && item.group && (LogGroup[item.group] || item.group)) || '-',
  },
  {
    getLabel: () => 'Level',
    name: 'level',
    type: 'string',
    align: 'left',
    width: 1,
    resolver: item => (item.level && item.level.name) || '-',
  },
  {
    getLabel: () => 'Details',
    name: 'details',
    type: 'string',
    align: 'left',
    width: 2,
    resolver: item =>
      item.detailsSummary || (item.details && (item.details.event || item.details.page)) || '-',
  },
  {
    getLabel: () => 'Created',
    name: 'created',
    type: 'datetime',
    align: 'left',
    width: 1,
    resolver: item => item.created,
  },
  {
    getLabel: () => 'Actions',
    name: 'actions',
    type: 'object',
    align: 'left',
    width: 1,
    resolver: data => [
      {
        type: 'details',
        display: 'icon',
        icon: 'fa-info',
        onClick: event => {
          data.actionInfoCallback(event);
        },
      },
    ],
  },
];
