import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import SimpleReactValidator from 'simple-react-validator';

const NumberInputWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  margin: 15px 10px;

  & .field {
    opacity: 1 !important;

    & label {
      margin: 10px 0 5px;
      color: #333;
    }
    & input {
      padding: 10px 15px;
      border: solid 1px #999;
      background: transparent;
      border-radius: 5px;

      margin-bottom: 5px;

      &[readonly] {
        background: #e5e5e5 !important;
        opacity: 1 !important;
      }
    }
  }
`;

const NumberInput = props => {
  const { name, readOnly, disabled, resolver, onChange, data, validations, min, max } = props;

  const [, forceUpdate] = useState();
  const simpleValidator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate },
      locale: 'en',
    }),
  );

  // No data provided
  if (typeof data === 'undefined') return null;

  const handleChange = value => {
    if (!simpleValidator.current.allValid()) {
      simpleValidator.current.showMessages();
    }
    onChange(value);
  };

  // eslint-disable-next-line
  validations && simpleValidator.current.message(name, resolver(data), validations);
  const validation = validations && simpleValidator.current.errorMessages[name];
  const validationError =
    (validations &&
      !simpleValidator.current.allValid() && { content: validation, pointing: 'below' }) ||
    undefined;

  const value = resolver(data) ? resolver(data) : min;

  return (
    <NumberInputWrapper>
      <Form.Input
        fluid
        type="number"
        id={`number-input-${name}`}
        label={name}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        min={min}
        max={max}
        onChange={event => handleChange(event.target.value)}
        error={validationError}
      />
    </NumberInputWrapper>
  );
};

NumberInput.propTypes = {
  data: PropTypes.number.isRequired,
  validations: PropTypes.string,
  resolver: PropTypes.func.isRequired,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

NumberInput.defaultProps = {
  name: '',
  readOnly: false,
  disabled: false,
  validations: null,
  min: 0,
  max: 100,
};

export default NumberInput;
