import { Icon, Icons } from '@alpima/picasso';
import React, { HTMLProps, ReactNode } from 'react';
import { unparse } from 'papaparse';

type DownloadButtonProps = {
  children?: ReactNode;
  dataWithHeaders: unknown[];
  download: string;
};

export default function DownloadButton({
  dataWithHeaders,
  download,
  children,
  ...props
}: DownloadButtonProps & HTMLProps<HTMLAnchorElement>) {
  const dataURLBody = unparse(dataWithHeaders, { header: true, quotes: true });
  const dataURL = `data:text/csv;charset=utf-8,${dataURLBody}`;
  return (
    <a
      {...props}
      className="flex w-fit items-center gap-3 border active:text-black hover:text-black text-black border-gray-400 rounded-full px-4 py-2"
      download={download}
      href={dataURL}
    >
      <Icon name={Icons.DOWNLOAD} />
      {children}
    </a>
  );
}
