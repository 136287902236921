import React from 'react';
import { storiesOf } from '@storybook/react';
import StoryRouter from 'storybook-react-router';

import { NavBar } from './index';

const defaultProps = {
  theme: 'Alpima',
  user: {
    firstName: 'Joe',
    lastName: 'Doe',
  },
  handleLogout: () => {},
  onSelectTheme: () => {},
  links: [
    {
      linkText: 'Founds',
      url: '/founds',
    },
    {
      linkText: 'MAC',
      url: '/mac',
    },
    {
      linkText: 'My Founds',
      url: '/my-founds',
    },
  ],
};

storiesOf('NavBar', module)
  .addDecorator(StoryRouter())
  .add('unauthenticated', () => (
    <NavBar showThemeSelector={false} isAuthenticated={false} isLoading={false} {...defaultProps} />
  ))
  .add('authenticated', () => (
    <NavBar showThemeSelector={false} isAuthenticated isLoading={false} {...defaultProps} />
  ))
  .add('with theme selector', () => (
    <NavBar showThemeSelector isAuthenticated isLoading={false} {...defaultProps} />
  ));
