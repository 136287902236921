import React, { Dispatch, SetStateAction } from 'react';
import { CalculatedColumn } from 'react-data-grid';
import SeverityTag from '../Widgets/SeverityTag';
import Steps from '../Widgets/Steps';
import { capitalizeFirstLetter } from '../../DataMonitoringDashboard/helper';
import { StrategiesAlertsMonitoring } from '../StrategyAlerts.types';

export declare interface FormatterProps<TRow, TSummaryRow = unknown> {
  column: CalculatedColumn<TRow, TSummaryRow>;
  row: TRow;
  isCellSelected: boolean;
  onRowChange: (row: TRow) => void;
}

const descriptionMap: Record<string, string> = {
  '1': 'The process has failed',
  '2': 'The process has not run at the proper time',
  '3': 'There is data missing',
};

export const getRowDescription = (alertId?: number) =>
  alertId && descriptionMap[alertId] ? descriptionMap[alertId] : '--';

export default function getStrategyAlertsColumns(
  setPopupDetails: Dispatch<SetStateAction<StrategiesAlertsMonitoring | null>>,
) {
  return [
    {
      key: 'strategy',
      name: 'Name',
      frozen: true,
      width: 200,
    },
    {
      key: 'severity',
      name: 'Severity',
      formatter(props: FormatterProps<StrategiesAlertsMonitoring>) {
        return (
          <div className="w-full h-full grid place-content-center leading-none">
            <SeverityTag severity={props.row.severity} />
          </div>
        );
      },
      width: 100,
    },
    {
      key: 'stages',
      name: 'Details',
      width: 150,
      formatter(props: FormatterProps<StrategiesAlertsMonitoring>) {
        return (
          <div className="flex content-center items-center w-full h-full">
            <Steps
              onClick={() => setPopupDetails(props.row)}
              total={4}
              failedStep={props.row.failedStageIndex}
            />
          </div>
        );
      },
    },
    {
      key: 'assetClass',
      name: 'Asset Class',
      width: 100,
    },
    {
      key: 'scheduledAtDate',
      name: 'Date',
      width: 100,
    },
    {
      key: 'scheduledAtTime',
      name: 'Time',
      width: 100,
    },
    {
      key: 'startedAt',
      name: 'Started',
      width: 100,
    },
    {
      key: 'endedAt',
      name: 'Ended',
      width: 100,
    },
    {
      key: 'duration',
      name: 'Duration',
      width: 100,
      formatter(props: FormatterProps<StrategiesAlertsMonitoring>) {
        const { duration } = props.row;
        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration % 60);
        return (
          <>
            {minutes ? `${minutes}m` : ''} {seconds}s
          </>
        );
      },
    },
    {
      key: 'description',
      name: 'Description',
      width: 400,
      formatter(props: FormatterProps<StrategiesAlertsMonitoring>) {
        const { alertId } = props.row;
        return <>{getRowDescription(alertId)}</>;
      },
    },
    {
      key: 'status',
      name: 'Status',
      width: 100,
      formatter(props: FormatterProps<StrategiesAlertsMonitoring>) {
        const { status } = props.row;
        return <>{capitalizeFirstLetter(status)}</>;
      },
    },
    {
      key: 'alertId',
      name: 'ID',
      width: 100,
    },
  ];
}
