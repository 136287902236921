import React, { useState, ReactNode } from 'react';

export default function useTabs<T extends string>({ initial }: { initial: T }) {
  const [active, setActive] = useState<T>(initial);

  function TabList({ children }: { children: ReactNode }) {
    return (
      <div className="w-full border-0 border-solid border-b border-gray-300">
        <div className="w-full flex flex-row px-40 space-x-8">{children}</div>
      </div>
    );
  }

  function Tab({ id, children, disabled }: { id: T; children: ReactNode; disabled?: boolean }) {
    const isSelected = active === id;
    const styles = `-mb-px h-10 bg-transparent cursor-pointer box-border border-0 text-base ${
      isSelected
        ? 'border-solid border-b-2 border-primary-800 text-gray-900 hover:text-gray-900'
        : 'text-gray-500'
    }`;

    return (
      <button
        type="button"
        id={id}
        onClick={() => setActive(id)}
        role="tab"
        disabled={disabled}
        className={styles}
      >
        <h3>{children}</h3>
      </button>
    );
  }

  function Content({ id, children }: { id: T; children: ReactNode }) {
    return active === id ? (
      <div id="activeTabContent" className="w-full h-full">
        {children}
      </div>
    ) : (
      <></>
    );
  }

  return {
    Tab,
    TabList,
    Content,
  };
}
