import { createSelector } from 'reselect';

const selectLayout = state => state.get('layout');

const makeSelectNavigation = createSelector(
  selectLayout,
  subState => subState.getIn(['items', 'navbar', 'navigationLinks']) || [],
);

const makeSelectLayoutLoading = createSelector(selectLayout, layoutState =>
  layoutState.get('loading'),
);

const makeSelectFluidLayout = createSelector(selectLayout, layoutState => layoutState.get('fluid'));

const makeSelectTabsLayout = createSelector(selectLayout, layoutState => layoutState.get('tabs'));

const makeSelectLinkPageLayout = createSelector(selectLayout, layoutState =>
  layoutState.get('linkPage'),
);

export {
  selectLayout,
  makeSelectNavigation,
  makeSelectLayoutLoading,
  makeSelectFluidLayout,
  makeSelectTabsLayout,
  makeSelectLinkPageLayout,
};
