import { combineEpics } from 'redux-observable';

import * as auth from './auth.epic';
import * as layout from './layout.epic';
import * as user from './user.epic';
import * as monitoring from './monitoring.epic';
import * as companies from './companies.epic';
import * as roles from './roles.epic';
import * as logs from './log.epic';
import * as instruments from './instruments.epic';
import * as strategies from './strategy.epic';
import * as reference from './reference.epic';
import * as informationBanner from './informationBanner.epic';
import * as regions from './regions.epic';

export default combineEpics(
  ...Object.values({
    ...auth,
    ...layout,
    ...user,
    ...monitoring,
    ...companies,
    ...roles,
    ...logs,
    ...instruments,
    ...strategies,
    ...reference,
    ...informationBanner,
    ...regions,
  }),
);
