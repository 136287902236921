/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import uuid from 'uuid/v4';

import styled from 'styled-components';
import TableDefaultRow from '../TableDefaultRow';

import { sortDataByColumn } from '../../../helpers/filter.helper';

const HeadCell = styled(Table.HeaderCell)`
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 1px solid #203a4c !important;
  white-space: nowrap !important;
  height: 47px;

  &::after {
    display: inline-flex;
  }
`;

const HeadCellContent = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

const HeadCellLabel = styled.div`
  flex: 1 0 auto;
`;

// const FilterIcon = styled.div`
//   flex: 0 0 30px;
// `;
//
// const FilterCard = styled(Card)`
//   display: none !important;
//   &.active {
//     position: absolute;
//     display: block !important;
//   }
//   & .close {
//     position: absolute;
//     top: 5px;
//     right: 5px;
//     &::before {
//       padding: 5px;
//     }
//   }
//   & .header {
//     text-align: left;
//   }
// `;

const TableDefault = ({
  data,
  fullData,
  name,
  striped,
  columns,
  onSelect,
  timeFilter,
  rowspan,
  onClickLink,
  onSort,
  clickLink,
}) => {
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  // const [columnFilter, setColumnFilter] = useState({});

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const handleSort = clickedColumn => () => {
    if (column !== clickedColumn) {
      const newSortedData = sortDataByColumn(fullData, clickedColumn);
      setColumn(clickedColumn);
      setDirection('ascending');
      setSortedData(newSortedData);
      onSort(clickedColumn, 'asc', newSortedData);
    } else {
      const newSortedData = sortDataByColumn(
        fullData,
        clickedColumn,
        direction === 'ascending' ? 'desc' : 'asc',
      );
      setSortedData(newSortedData);
      setDirection(direction === 'ascending' ? 'descending' : 'ascending');
      onSort(clickedColumn, direction === 'ascending' ? 'desc' : 'asc', newSortedData);
    }
  };

  // const filterColumnSelector = (col) => {
  //     const map = ['enabled', 'company.name', 'accountType'];
  //     if (map.indexOf(col && col.name) > -1) {
  //         return (
  //             <FilterIcon onClick={(event) => {
  //                 event.stopPropagation();
  //                 setColumnFilter({
  //                     ...columnFilter,
  //                     [col.name]: {
  //                         ...columnFilter[col.name],
  //                         active: true
  //                     }
  //                 });
  //             }}
  //             >
  //                 <i className="filter icon" />
  //                 <FilterCard
  //                     className={columnFilter[col.name] && columnFilter[col.name].active ? 'active' : ''}
  //                     onClick={event => event.stopPropagation()}
  //                 >
  //                     <i
  //                         className="close icon"
  //                         role="button"
  //                         tabIndex="0"
  //                         onKeyDown={() => {}}
  //                         onClick={() => setColumnFilter({
  //                             ...columnFilter,
  //                             [col.name]: {
  //                                 ...columnFilter[col.name],
  //                                 active: false
  //                             }
  //                         })}
  //                     />
  //                     <Card.Content>
  //                         <Card.Header>Filter: {col.getLabel()}</Card.Header>
  //                         <Card.Description>
  //                       TBD
  //                         </Card.Description>
  //                     </Card.Content>
  //                 </FilterCard>
  //             </FilterIcon>
  //         );
  //     }
  //     return null;
  // };

  const getColumnName = val => (val === 'derivedRating' ? 'ratingOrder' : val);

  const renderHeaderCell = col => {
    if (col.type === 'hidden') {
      return null;
    }

    return (
      <HeadCell
        key={uuid()}
        width={col.width || 'auto'}
        textAlign={col.align || 'center'}
        sorted={column === getColumnName(col.name) ? direction : null}
        onClick={handleSort(getColumnName(col.name))}
      >
        <HeadCellContent>
          <HeadCellLabel>{col.getLabel()}</HeadCellLabel>
          {/* {filterColumnSelector(col)} */}
        </HeadCellContent>
      </HeadCell>
    );
  };

  return (
    <Table celled padded sortable striped={striped} basic="very" compact>
      <Table.Header>
        <Table.Row>{columns.map(col => renderHeaderCell(col))}</Table.Row>
      </Table.Header>
      <Table.Body>
        {sortedData.map((rowData, rowIndex) => (
          <TableDefaultRow
            key={`${name}-row-${rowIndex}`}
            data={rowData}
            filter={timeFilter}
            name={name}
            columns={columns}
            rowIndex={rowIndex}
            onSelect={onSelect}
            rowspan={rowspan}
            onClickLink={onClickLink}
            clickLink={clickLink}
          />
        ))}
      </Table.Body>
    </Table>
  );
};

TableDefault.defaultProps = {
  name: '',
  columns: [],
  data: [],
  fullData: [],
  onSelect: () => {},
  striped: true,
  timeFilter: '',
  rowspan: false,
  onClickLink: () => {},
  onSort: () => {},
  clickLink: false,
};

TableDefault.propTypes = {
  name: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  fullData: PropTypes.array,
  striped: PropTypes.bool,
  onSelect: PropTypes.func,
  timeFilter: PropTypes.string,
  rowspan: PropTypes.bool,
  onClickLink: PropTypes.func,
  onSort: PropTypes.func,
  clickLink: PropTypes.bool,
};

export default TableDefault;
