import React from 'react';
import { storiesOf } from '@storybook/react';
import StoryRouter from 'storybook-react-router';

import UIMessage from './index';

const defaultProps = {
  type: 'default',
  message: 'This is a UI Message',
  list: [],
};

storiesOf('UIMessage', module)
  .addDecorator(StoryRouter())
  .addDecorator(story => <div style={{ margin: '50px' }}>{story()}</div>)
  .add('default', () => <UIMessage {...defaultProps} />)
  .add('error', () => <UIMessage {...defaultProps} type="error" />)
  .add('warning', () => <UIMessage {...defaultProps} type="warning" />)
  .add('success', () => <UIMessage {...defaultProps} type="success" />)
  .add('info', () => <UIMessage {...defaultProps} type="info" />)
  .add('With list', () => <UIMessage {...defaultProps} type="info" list={['Item 1', 'Item 2']} />);
