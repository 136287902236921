export const GET_INSTRUMENTS_LIST = 'GET_INSTRUMENTS_LIST';
export const GET_INSTRUMENTS_LIST_SUCCESS = 'GET_INSTRUMENTS_LIST_SUCCESS';
export const GET_INSTRUMENTS_LIST_FAILURE = 'GET_INSTRUMENTS_LIST_FAILURE';
export const GET_INSTRUMENTS_LIST_CANCELLED = 'GET_INSTRUMENTS_LIST_CANCELLED';

export const GET_INSTRUMENTS_TST_MONITORING = 'GET_INSTRUMENTS_TST_MONITORING';
export const GET_INSTRUMENTS_TST_MONITORING_SUCCESS = 'GET_INSTRUMENTS_TST_MONITORING_SUCCESS';
export const GET_INSTRUMENTS_TST_MONITORING_FAILURE = 'GET_INSTRUMENTS_TST_MONITORING_FAILURE';
export const GET_INSTRUMENTS_TST_MONITORING_CANCELLED = 'GET_INSTRUMENTS_TST_MONITORING_CANCELLED';

export const GET_INSTRUMENTS_PERF_MONITORING = 'GET_INSTRUMENTS_PERF_MONITORING';
export const GET_INSTRUMENTS_PERF_MONITORING_SUCCESS = 'GET_INSTRUMENTS_PERF_MONITORING_SUCCESS';
export const GET_INSTRUMENTS_PERF_MONITORING_FAILURE = 'GET_INSTRUMENTS_PERF_MONITORING_FAILURE';
export const GET_INSTRUMENTS_PERF_MONITORING_CANCELLED =
  'GET_INSTRUMENTS_PERF_MONITORING_CANCELLED';

export const SET_INSTRUMENT_SEARCH_RESULTS = 'SET_INSTRUMENT_SEARCH_RESULTS';
export const CLEAR_INSTRUMENT_SEARCH_RESULTS = 'CLEAR_INSTRUMENT_SEARCH_RESULTS';

export const SET_INSTRUMENT_COLUMN_SORT = 'SET_INSTRUMENT_COLUMN_SORT';

export const GET_INSTRUMENT_DETAILS = 'GET_INSTRUMENT_DETAILS';
export const GET_INSTRUMENT_DETAILS_SUCCESS = 'GET_INSTRUMENT_DETAILS_SUCCESS';
export const GET_INSTRUMENT_DETAILS_FAILURE = 'GET_INSTRUMENT_DETAILS_FAILURE';
export const STAMP_INSTRUMENT_DETAILS = 'STAMP_INSTRUMENT_DETAILS';

export const STAMP_ENABLE_INSTRUMENT = 'STAMP_ENABLE_INSTRUMENT';
export const STAMP_DISABLE_INSTRUMENT = 'STAMP_DISABLE_INSTRUMENT';

export const REQUEST_INSTRUMENT_DETAILS_UPDATE = 'REQUEST_INSTRUMENT_DETAILS_UPDATE';
export const SET_INSTRUMENT_DETAILS = 'SET_INSTRUMENT_DETAILS';
export const SET_INSTRUMENT_DETAILS_SUCCESS = 'SET_INSTRUMENT_DETAILS_SUCCESS';
export const SET_INSTRUMENT_DETAILS_FAILURE = 'SET_INSTRUMENT_DETAILS_FAILURE';
export const SET_INSTRUMENT_DETAILS_CANCEL = 'SET_INSTRUMENT_DETAILS_CANCEL';

export const INSTRUMENT_EXPORT_REQUEST = 'instrument/EXPORT_ATTRIBUTES_REQUEST';
export const INSTRUMENT_EXPORT_SUCCESS = 'instrument/EXPORT_ATTRIBUTES_SUCCESS';
export const INSTRUMENT_EXPORT_FAILURE = 'instrument/EXPORT_ATTRIBUTES_FAILURE';
export const INSTRUMENT_EXPORT_CLEAR = 'instrument/EXPORT_ATTRIBUTES_CLEAR';

export const BB_EXPORT_REQUEST = 'bb/EXPORT_ATTRIBUTES_REQUEST';
export const BB_EXPORT_SUCCESS = 'bb/EXPORT_ATTRIBUTES_SUCCESS';
export const BB_EXPORT_FAILURE = 'bb/EXPORT_ATTRIBUTES_FAILURE';
export const BB_EXPORT_CLEAR = 'bb/EXPORT_ATTRIBUTES_CLEAR';
