import _ from 'lodash';
import React, { useState } from 'react';
import { Table } from 'semantic-ui-react';

import { numberWithCommas } from '../../../helpers/format.helper';

const tableData = [
  {
    classCode: 'BBGSOFIEP',
    isin: 'LU154977407',
    currency: 'EUR',
    classType1: 'I',
    fundCode: 'GSOV',
    classType2: 'Institutional',
    fee: 100,
    value: 96970677,
  },
  {
    classCode: 'BBGSOFIEP1',
    isin: 'LU154977407',
    currency: 'EUR',
    classType1: 'I',
    fundCode: 'GSOV',
    classType2: 'Institutional',
    fee: 100,
    value: 96970677,
  },
  {
    classCode: 'BBGSOFIEP2',
    isin: 'LU154977407',
    currency: 'EUR',
    classType1: 'I',
    fundCode: 'GSOV',
    classType2: 'Institutional',
    fee: 100,
    value: 96970677,
  },
  {
    classCode: 'BBGSOFIEP3',
    isin: 'LU154977407',
    currency: 'EUR',
    classType1: 'I',
    fundCode: 'GSOV',
    classType2: 'Institutional',
    fee: 100,
    value: 96970677,
  },
];

const FundShareTable = () => {
  const [direction, setDirection] = useState();
  const [column, setColumn] = useState();
  let data = tableData;

  const handleSort = clickedColumn => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      data = _.sortBy(data, [clickedColumn]);
      setDirection('ascending');

      return;
    }
    data = data.reverse();
    setDirection(direction === 'ascending' ? 'descending' : 'ascending');
  };

  return (
    <Table sortable celled fixed compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={column === 'classCode' ? direction : null}
            onClick={handleSort('classCode')}
          >
            Share Class Code
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'isin' ? direction : null}
            onClick={handleSort('isin')}
          >
            ISIN
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'currency' ? direction : null}
            onClick={handleSort('currency')}
          >
            Currency
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'classType1' ? direction : null}
            onClick={handleSort('classType1')}
          >
            Share Class Type 1
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'fundCode' ? direction : null}
            onClick={handleSort('fundCode')}
          >
            Fund Code
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'classType2' ? direction : null}
            onClick={handleSort('classType2')}
          >
            Share Class Type 2
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'fee' ? direction : null}
            onClick={handleSort('fee')}
          >
            Headline Fee
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === 'value' ? direction : null}
            onClick={handleSort('value')}
          >
            Market Value
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_.map(
          data,
          ({ isin, currency, classCode, classType1, fundCode, classType2, fee, value }) => (
            <Table.Row key={classCode}>
              <Table.Cell>{classCode}</Table.Cell>
              <Table.Cell>{isin}</Table.Cell>
              <Table.Cell>{currency}</Table.Cell>
              <Table.Cell>{classType1}</Table.Cell>
              <Table.Cell>{fundCode}</Table.Cell>
              <Table.Cell>{classType2}</Table.Cell>
              <Table.Cell textAlign="right">{fee}</Table.Cell>
              <Table.Cell textAlign="right">{numberWithCommas(value)}</Table.Cell>
            </Table.Row>
          ),
        )}
      </Table.Body>
    </Table>
  );
};

export default FundShareTable;
