import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Dashboard from '../../../organisms/Dashboard';
import PageWrapper from '../../../molecules/PageWrapper';

const InstrumentsPage = ({ match }) => (
  <PageWrapper>
    <div style={{ minHeight: '850px', background: '#F6F8FA' }}>
      <Switch>
        <Route
          exact
          path="/instruments/list/:page"
          render={() => (
            <Dashboard
              title="Instruments List"
              icon={{ name: 'th list', size: 'large' }}
              name="instrumentList"
            />
          )}
        />
        <Route exact path="/instruments/list" render={() => <Redirect to={`${match.url}/1`} />} />
        <Route
          path="/instruments/:id"
          render={() => (
            <Dashboard
              title="Instrument"
              icon={{ name: 'th list', size: 'large' }}
              name="instrument"
            />
          )}
        />
        <Route
          exact
          path={`${match.url}`}
          render={() => (
            <Fragment>
              <p>Redirecting...</p>
              <Redirect to={`${match.url}/list`} />
            </Fragment>
          )}
        />
      </Switch>
    </div>
  </PageWrapper>
);

InstrumentsPage.defaultProps = {};
InstrumentsPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(connect()(InstrumentsPage));
