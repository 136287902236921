import React from 'react';
import { storiesOf } from '@storybook/react';
import StoryRouter from 'storybook-react-router';

import LogoWrapper from './index';

storiesOf('LogoWrapper', module)
  .addDecorator(StoryRouter())
  .addDecorator(story => <div style={{ margin: '50px' }}>{story()}</div>)
  .add('Alpima', () => <LogoWrapper />);
