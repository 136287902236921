import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import Markdown from '../../atoms/Markdown';
import { getSeverityLevelColor } from './severityLevel';

const dateFormat = 'DD.MM.YYYY HH:mm';

const EditContainer = styled.div`
  position: absolute;
  bottom: 0.78575em;
  right: 0.5em;
  opacity: 0.7;
  cursor: pointer;
`;

const SmallText = styled.span`
  font-size: 11px;
`;

export default function InformationBanner({ informationBanner, archive, edit }) {
  const { id, message, displayFrom, displayUntil, severityLevel, isDismissable } =
    informationBanner;
  const visibility = `${moment(displayFrom).format(dateFormat)} - ${moment(displayUntil).format(
    dateFormat,
  )}`;
  const dismissable = isDismissable ? '(dismissable)' : '(non-dismissable)';
  const color = getSeverityLevelColor(severityLevel);
  const overlineText = `${visibility} ${dismissable}`;

  return (
    <Message color={color} onDismiss={() => archive(id)}>
      <SmallText>{overlineText}</SmallText>
      <br />
      <Markdown>{message}</Markdown>
      <br />
      <EditContainer onClick={() => edit(informationBanner)}>
        <Icon name="pencil" size="small" />
      </EditContainer>
    </Message>
  );
}

InformationBanner.propTypes = {
  informationBanner: PropTypes.shape({
    id: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    displayFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    displayUntil: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    severityLevel: PropTypes.number.isRequired,
    isDismissable: PropTypes.bool.isRequired,
  }).isRequired,
  archive: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
};
