/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect } from 'react';
import {
  Filter,
  Button,
  Icons,
  ButtonTypes,
  ButtonStates,
  ButtonSizes,
  Icon,
  DateRangeSelector,
} from '@alpima/picasso';
import throttle from 'lodash/throttle';
import { DateTime } from 'luxon';
import { FilterOption } from '@alpima/picasso/build/types/components/molecules/Filter/Filter.types';

type SingleFilter = {
  options: FilterOption[];
  initial: string[];
  selected: Set<string>;
  setSelected: (newOptions: Set<string>) => void;
};

type DateRangeOption = {
  title: string;
  id: number;
};

type FilterList = {
  type: SingleFilter;
  user: SingleFilter;
  level: SingleFilter;
  dateRange: {
    options: Array<DateRangeOption>;
    initial: number;
    selected: number;
    setSelected: (value: number) => void;
  };
  datePicker: {
    dates: { start: DateTime; end: DateTime };
    setDates: (dates: { start: DateTime; end: DateTime }) => void;
  };
};

export type Filters = {
  clearFilters: CallableFunction;
  filtersOn: boolean;
  filter: FilterList;
};

const PeriodSelect = ({ filterList }: { filterList: FilterList }) => (
  <label htmlFor="dateRange" className="flex items-center px-4">
    <>
      Period:{' '}
      <select
        id="dateRange"
        className="cursor-pointer"
        value={filterList.dateRange.selected as number}
        onChange={e => filterList.dateRange.setSelected(parseInt(e.target?.value, 10))}
      >
        {filterList.dateRange.options &&
          filterList.dateRange.options.map((option: DateRangeOption) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))}
      </select>
    </>
  </label>
);

const Toolbar = ({ getLogs, filters }: { getLogs: CallableFunction; filters: Filters }) => {
  const { clearFilters, filtersOn, filter: filterList } = filters;

  const handleClearFilters = () => {
    clearFilters();
  };

  useEffect(() => {
    if (!filtersOn) getLogs();
  }, [filtersOn, getLogs]);

  return (
    <div className="flex w-full py-5 px-8 items-center justify-between">
      {filterList.type.options && filterList.user.options && (
        <div className="flex space-x-2">
          <Filter
            showAll
            title="Level"
            options={filterList.level.options}
            selected={filterList.level.selected}
            setSelected={filterList.level.setSelected}
          />
          <Filter
            showAll
            title="Type"
            options={filterList.type.options}
            selected={filterList.type.selected}
            setSelected={filterList.type.setSelected}
          />
          <Filter
            showAll
            title="Users"
            options={filterList.user.options}
            selected={filterList.user.selected}
            setSelected={filterList.user.setSelected}
          />
          <PeriodSelect filterList={filterList} />
          {filterList.dateRange.selected === 5 && (
            <DateRangeSelector
              labelClassName="hidden"
              onSetDates={(start, end) => filterList.datePicker.setDates({ start, end })}
              minDate={filterList.datePicker.dates.start}
              maxDate={filterList.datePicker.dates.end}
            />
          )}
        </div>
      )}
      <div className="md:flex md:space-x-2">
        <Button
          className="text-sm md:text-base"
          type={ButtonTypes.LongText}
          state={ButtonStates.Solid}
          size={ButtonSizes.Large}
          onClick={throttle(() => getLogs(), 1000)}
        >
          <Icon name={Icons.DONE} className="fill-current text-white mr-4 sm-md:mr-2" />
          Filter logs
        </Button>
        <Button
          type={ButtonTypes.IconLeft}
          icon={Icons.CLOSE}
          onClick={() => handleClearFilters()}
          className="text-sm md:text-base mt-2 md:mt-0"
          disabled={!filtersOn}
        >
          Clear
        </Button>
      </div>
    </div>
  );
};

export default Toolbar;
