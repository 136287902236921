import { createSelector } from 'reselect';

const selectUsers = state => state.get('user');
const selectMatch = state => state.getIn(['router', 'location', 'pathname']) || [];

const selectLastRefresh = createSelector(selectUsers, users => users.get('lastRefresh'));
const selectUserLoading = createSelector(selectUsers, users => users.get('loading'));
const selectOnlineUsersLoading = createSelector(selectUsers, users =>
  users.getIn(['online', 'loading']),
);
const selectPendingUsersLoading = createSelector(selectUsers, users =>
  users.getIn(['pending', 'loading']),
);
const selectUpdatedContractLoading = createSelector(selectUsers, users =>
  users.getIn(['contractRoles', 'loading']),
);
const selectOnlineUsersLastRefresh = createSelector(selectUsers, users =>
  users.getIn(['online', 'lastRefresh']),
);

const selectUserList = createSelector(selectUsers, users => users.get('list').toJS() || []);

const selectUserListPage = createSelector([selectUsers, selectMatch], (users, match) => {
  const page = parseInt(match.substr(match.lastIndexOf('/') + 1), 10) - 1;
  return users.get('list').toJS()[page] || [];
});

const selectOnlineUsers = createSelector(
  selectUsers,
  users => users.getIn(['online', 'users']).toJS() || [],
);
const selectPendingUsers = createSelector(
  selectUsers,
  users => users.getIn(['pending', 'users']).toJS() || [],
);
const selectUpdatedContractUsers = createSelector(
  selectUsers,
  users => users.getIn(['contractRoles', 'users']).toJS() || [],
);

const selectPendingUserError = createSelector(
  selectUsers,
  users => users.getIn(['pending', 'error']) || '',
);
const selectUpdatedContractUsersError = createSelector(
  selectUsers,
  users => users.getIn(['contractRoles', 'error']) || '',
);

const selectUserSearchResults = createSelector(
  selectUsers,
  users => users.get('search').toJS() || [],
);

const selectUserDetails = createSelector(
  selectUsers,
  users => users.getIn(['details', 'active']).toJS() || {},
);
const selectUserDetailsCache = createSelector(
  selectUsers,
  users => users.getIn(['details', 'cache']).toJS() || {},
);

const selectHasDetailsStateChanged = createSelector(selectUsers, users => {
  const active = users.getIn(['details', 'active']).toJS() || {};
  const cache = users.getIn(['details', 'cache']).toJS() || {};
  return JSON.stringify(active) !== JSON.stringify(cache) || false;
});

export const selectUsersExportData = createSelector(selectUsers, users => users.get('exportData'));

export const selectIsExporting = createSelector(selectUsers, users => users.get('isExporting'));

export {
  selectUsers,
  selectUserLoading,
  selectLastRefresh,
  selectOnlineUsersLoading,
  selectPendingUsersLoading,
  selectUpdatedContractLoading,
  selectOnlineUsersLastRefresh,
  selectUserList,
  selectUserListPage,
  selectOnlineUsers,
  selectPendingUsers,
  selectUpdatedContractUsers,
  selectPendingUserError,
  selectUpdatedContractUsersError,
  selectUserSearchResults,
  selectUserDetails,
  selectUserDetailsCache,
  selectHasDetailsStateChanged,
};
