import moment from 'moment';

/* eslint-disable max-len */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
export const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeArray = stringArray => stringArray.map(s => capitalize(s));

export const formatIsoDate = string => {
  const date = moment(string);
  return date.isValid() ? date.format('DD/MM/YYYY') : '-';
};

export const formatIsoDateTime = string => {
  const dateTime = moment(string, 'YYYY-MM-DD HH:mm:ss Z');
  return dateTime.isValid() ? dateTime.format('DD/MM/YYYY HH:mm:ss') : '-';
};

export const lightenColor = (color, percent) => {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;
  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)}`;
};
