/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useMemo } from 'react';
import { Button, ButtonTypes, Loader, Icons, LoaderSize } from '@alpima/picasso';
import { fetchLogListCSVExport } from 'services/clientAPI/log.service';

type DownloadLogsProps = {
  userCompany: string;
  filter: any;
  rawLogList: any;
  loading: boolean;
};

export default function DownloadLogsButton({
  userCompany,
  filter,
  rawLogList,
  loading,
}: DownloadLogsProps) {
  const [downloading, setDownloading] = useState(false);
  const isLoading = loading || downloading || !userCompany;

  const requestPayloadCSVExport = useMemo(
    () => ({
      isExport: true,
      companies: [userCompany],
      levels: [...filter.level.selected] || [0, 1, 2],
      loadTotalPages: true,
      onlyEnabledUsers: false,
      pageNumber: 1,
      pageSize: rawLogList?.pagination?.totalItems || filter.pagination.pageSize.value,
      rangeFrom: filter.dates.start,
      rangeTo: filter.dates.end,
      sortDirection: 'desc',
      sortField: 'created',
      types: [...filter.type.selected] || [],
      users: [...filter.user.selected] || [],
    }),
    [
      filter.dates.end,
      filter.dates.start,
      filter.level.selected,
      rawLogList?.pagination?.totalItems,
      filter.pagination.pageSize.value,
      filter.type.selected,
      filter.user.selected,
      userCompany,
    ],
  );

  const handleDownloadClick = () => {
    setDownloading(true);

    fetchLogListCSVExport(requestPayloadCSVExport).subscribe(data => {
      const filename = 'filteredLogs.csv';
      const filteredLogsCSVBlob = new Blob([data.response], {
        type: 'text/csv',
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(filteredLogsCSVBlob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setDownloading(false);
    });
  };

  return (
    <Button
      className="text-sm md:text-lg"
      type={ButtonTypes.IconLeft}
      icon={isLoading ? <Loader size={LoaderSize.SMALL} /> : Icons.DOWNLOAD}
      onClick={() => handleDownloadClick()}
      disabled={isLoading}
    >
      Download
    </Button>
  );
}
