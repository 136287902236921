/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import produce, { enableMapSet } from 'immer';
import mapValues from 'lodash/mapValues';
import isEqual from 'lodash/isEqual';
import { Button, ButtonTypes, Dropdown, Popover, TextInput } from '@alpima/picasso';
import { FilterIconOutline } from './FilterIcon';
import { FilterProps, StringKey } from './Filters.types';
import KeyListener from '../Search/KeyListener';

// Enable Map() and Set() support for immer.
enableMapSet();

// Only show a search if >5 items are present.
const minItemsForSearch = 5;

export default function Filters<T>({
  filterState,
  setFilterState,
  possibleValues,
  filters,
}: FilterProps<T>) {
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(filters[0].key);
  const [filterSearch, setFilterSearch] = useState('');
  const filterSearchInputRef = useRef<HTMLInputElement>(null);

  /**
   * Change a column value from being hidden to being shown, or vice versa.
   */
  function toggleValue(key: string, value: unknown) {
    setFilterState(prev =>
      produce(prev, draft => {
        const currentOptions = draft[key];
        if (currentOptions.has(value)) {
          currentOptions.delete(value);
        } else {
          currentOptions.add(value);
        }
        return draft;
      }),
    );
  }

  // Business logic for the search box.
  const valuesForFilter = [...possibleValues[selectedFilter]];
  const selectedFilterName = filters.find(f => f.key === selectedFilter)!.name;
  const showFilterSearch = valuesForFilter.length > minItemsForSearch;
  const searchedValues = valuesForFilter.filter(x => `${x}`.includes(filterSearch));

  // Focus the filter search if it becomes visible when changing filter option.
  useEffect(() => {
    if (showFilterSearch) {
      filterSearchInputRef.current?.focus();
    } else {
      setFilterSearch('');
    }
  }, [showFilterSearch]);

  // Focus the filter search if it's visible when the popup opens.
  useEffect(() => {
    if (showFilterPopup && showFilterSearch) {
      filterSearchInputRef.current?.focus();
    }
  }, [showFilterPopup, showFilterSearch]);

  // UI logic for the "select all" checkbox.
  // Todo: This is a whole lot of logic; can it be refactored into a separate file?
  const allSelected = searchedValues.every(searchedValue =>
    filterState[selectedFilter].has(searchedValue),
  );
  const noneSelected = searchedValues.every(
    searchedValue => !filterState[selectedFilter].has(searchedValue),
  );
  const selectAllCheckboxRef = useRef<HTMLInputElement>(null);
  const indeterminate = !allSelected && !noneSelected;

  // There's no `indeterminate` prop on checkboxes, so we set it manually:
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes
  useEffect(() => {
    const checkbox = selectAllCheckboxRef.current;
    if (!checkbox) return;
    checkbox.indeterminate = indeterminate;
  }, [indeterminate]);

  // Control what happens when we click the "select all" checkbox, considering our current search.
  const toggleSelectAll = useCallback(() => {
    setFilterState(prevState =>
      produce(prevState, draft => {
        const previouslySelected = new Set(draft[selectedFilter]);
        if (noneSelected) {
          draft[selectedFilter] = new Set([...searchedValues, ...previouslySelected]);
        } else {
          searchedValues.forEach(item => previouslySelected.delete(item));
          draft[selectedFilter] = previouslySelected;
        }
      }),
    );
  }, [noneSelected, searchedValues, selectedFilter, setFilterState]);

  // Active filter keys should have an asterisk next to them in the dropdown.
  const activeFilters = mapValues(
    possibleValues,
    (value, key) => !isEqual(filterState[key], value),
  );

  return (
    <div className="relative">
      <KeyListener listenForKey="Escape" action={() => setShowFilterPopup(false)} />
      <div className="border-gray-300 -ml-px">
        <Button
          type={ButtonTypes.IconLeft}
          icon={FilterIconOutline}
          onClick={() => setShowFilterPopup(prev => !prev)}
          className={`font-bold text-sm border-none ${showFilterPopup ? 'text-primary-main' : ''}`}
        >
          Filter
        </Button>
        <div>
          {showFilterPopup && (
            <Popover
              hidePopover={() => setShowFilterPopup(false)}
              classNames="text-black flex flex-col pt-4 right-2 pb-2 px-8 shadow-lg whitespace-nowrap"
            >
              <Dropdown
                options={filters.map(({ name, key }) => ({
                  text: `${activeFilters[key] ? '• ' : ''}${name}`,
                  value: key,
                  key,
                }))}
                selectedOption={selectedFilter}
                onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                  setSelectedFilter(event.target.value as StringKey<T>)
                }
              />

              {showFilterSearch && (
                <TextInput
                  ref={filterSearchInputRef}
                  wrapperClassName="mt-4"
                  placeholder={`Search for ${selectedFilterName}...`}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setFilterSearch(event.target.value)
                  }
                  value={filterSearch}
                />
              )}

              <div className="max-h-60 overflow-auto my-3">
                <label
                  key="select-all"
                  htmlFor="select-all"
                  className="my-3 flex gap-3 items-center cursor-pointer font-bold"
                >
                  <input
                    type="checkbox"
                    id="select-all"
                    checked={allSelected}
                    ref={selectAllCheckboxRef}
                    onChange={toggleSelectAll}
                  />
                  Select all
                </label>
                {searchedValues.map(possibleValue => (
                  <label
                    key={`${possibleValue}`}
                    htmlFor={`${possibleValue}`}
                    className="my-3 flex gap-3 items-center cursor-pointer ml-3"
                  >
                    <input
                      type="checkbox"
                      id={`${possibleValue}`}
                      name={`${possibleValue}`}
                      checked={filterState[selectedFilter].has(possibleValue)}
                      onChange={() => toggleValue(selectedFilter, possibleValue)}
                    />
                    {possibleValue}
                  </label>
                ))}
              </div>
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
}
