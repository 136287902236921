import React from 'react';
import { storiesOf } from '@storybook/react';
import StoryRouter from 'storybook-react-router';

import LoginForm from './index';

const defaultProps = {
  onLogin: () => {},
};

storiesOf('LoginForm', module)
  .addDecorator(StoryRouter())
  .addDecorator(story => <div style={{ margin: '50px' }}>{story()}</div>)
  .add('default', () => <LoginForm {...defaultProps} />);
