import { apiCall$ } from './lib/apiCall';

import {
  userGroupsByCompanyURL,
  deleteUserGroupURL,
  createUserGroupURL,
  getCompanyUsersURL,
  getUserGroupURL,
  updateUserGroupURL,
} from './clientAPI.config';

export const fetchAllCompanyUsers = companyId =>
  apiCall$(getCompanyUsersURL.replace(/:companyId/i, companyId));

export const fetchUserGroupsByCompany = () => apiCall$(userGroupsByCompanyURL);

export const deleteUserGroup = id => apiCall$(deleteUserGroupURL.replace(/:id/i, id), 'DELETE');

export const getUserGroup = id => apiCall$(getUserGroupURL.replace(/:id/i, id));

export const createUserGroup = payload => apiCall$(createUserGroupURL, 'POST', payload);

export const updateUserGroup = (id, payload) =>
  apiCall$(updateUserGroupURL.replace(/:id/i, id), 'PATCH', payload);
