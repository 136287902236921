import React from 'react';
import { storiesOf } from '@storybook/react';
import { Grid, Button, Image } from 'semantic-ui-react';

import CardDefault from './index';
import AppWrapper from '../AppWrapper';
import buildImage from '../../../images/build.png';

const defaultProps = {};

storiesOf('CardDefault', module)
  .addDecorator(story => (
    <AppWrapper>
      <Grid style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Row columns={5}>
          <Grid.Column />
          <Grid.Column />
          <Grid.Column>{story()}</Grid.Column>
          <Grid.Column />
          <Grid.Column />
        </Grid.Row>
      </Grid>
    </AppWrapper>
  ))
  .add('default', () => (
    <CardDefault {...defaultProps}>
      <Image size="tiny" centered src={buildImage} />
      <p>FUNDS & PORTFOLIOS</p>
      <Button color="blue" fluid>
        VIEW FUNDS
      </Button>
    </CardDefault>
  ));
