import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react';
import moment from 'moment';

import { fetchTSTInstrumentMonitoring } from '../../../store/actions/instruments.actions';
import {
  selectTstInstrumentMonitoring,
  selectMonitoringInstrumentLoading,
} from '../../../store/selectors/instruments.selector';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';

import LoadingBoundary from '../../atoms/LoadingBoundary';

const InstrumentUploadMonitoring = styled.div`
  padding: 15px;

  .rowWrapper {
    max-height: 415px;
    overflow-y: scroll;
  }
`;

const InstrumentsUploadMonitoringWidget = props => {
  const { loading, getInstrumentMonitoring, tstInstruments } = props;
  const { dateFormat } = useDateTimeFormatter();

  useEffect(() => {
    getInstrumentMonitoring();
  }, [getInstrumentMonitoring]);
  const formatRow = instrument => (
    <Segment vertical key={`instrumentBreach-${instrument._id}`}>
      <Grid>
        <GridRow>
          <GridColumn width={3}>
            <span>
              <a href={`/instruments/${instrument._id}`}>{instrument.externalId}</a>
            </span>
          </GridColumn>
          <GridColumn width={3}>
            <span>{instrument.ticker}</span>
          </GridColumn>
          <GridColumn width={3}>
            <span>{instrument.isin}</span>
          </GridColumn>
          <GridColumn width={2}>
            <span>{instrument.dataStartDate}</span>
          </GridColumn>
          <GridColumn width={2}>
            <span>{instrument.dataEndDate}</span>
          </GridColumn>
          <GridColumn width={3}>{instrument.message}</GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );

  // eslint-disable-next-line
  const successfulUploads = ((tstInstruments && tstInstruments.success) || [])
    .map(({ created, instrument = {}, details = {} }) => ({
      _id: instrument._id,
      created,
      externalId: instrument.externalId || instrument.instrumentId || instrument._id,
      ticker: instrument.bbgticker || instrument.ticker,
      isin: instrument.idISIN,
      dataStartDate: moment(instrument.dataStartDate).format(dateFormat || 'YYYY-MM-DD'),
      dataEndDate: moment(instrument.dataEndDate).format(dateFormat || 'YYYY-MM-DD'),
      message: <span style={{ color: 'green' }}>{details.message || ''}</span>,
    }))
    .map(formatRow);

  // eslint-disable-next-line
  const failedUploads = ((tstInstruments && tstInstruments.failure) || [])
    .map(({ created, instrument = {}, details: { error = {} } = {} }) => ({
      _id: instrument._id,
      created,
      externalId: instrument.externalId || instrument.instrumentId,
      ticker: instrument.bbgticker || instrument.ticker,
      isin: instrument.idISIN,
      dataStartDate: moment(instrument.dataStartDate).format(dateFormat || 'YYYY-MM-DD'),
      dataEndDate: moment(instrument.dataEndDate).format(dateFormat || 'YYYY-MM-DD'),
      message:
        error.Error.code && error.Error.message ? (
          <span style={{ color: 'red' }}>
            {error.Error.code}: {error.Error.message}
          </span>
        ) : (
          ''
        ),
    }))
    .map(formatRow);

  const instruments =
    successfulUploads && failedUploads
      ? successfulUploads.concat(failedUploads).sort((a, b) => {
          const aDate = a && a.created;
          const bDate = b && b.created;
          if (!aDate) {
            return 1;
          }
          if (!bDate) {
            return -1;
          }
          return moment(aDate).diff(moment(bDate));
        })
      : [];

  return (
    <LoadingBoundary isLoading={loading}>
      <InstrumentUploadMonitoring style={{ minHeight: '125px' }}>
        <h2>{`Instruments Upload Monitoring${
          instruments.length ? `: ${instruments.length}` : ''
        }`}</h2>
        <hr />
        {instruments.length ? (
          <Fragment>
            <Segment vertical>
              <Grid>
                <GridRow>
                  <GridColumn width={3}>
                    <h4>Id</h4>
                  </GridColumn>
                  <GridColumn width={3}>
                    <h4>Ticker</h4>
                  </GridColumn>
                  <GridColumn width={3}>
                    <h4>ISIN</h4>
                  </GridColumn>
                  <GridColumn width={2}>
                    <h4>Data Start Date</h4>
                  </GridColumn>
                  <GridColumn width={2}>
                    <h4>Data End Date</h4>
                  </GridColumn>
                  <GridColumn width={3}>
                    <h4>Message</h4>
                  </GridColumn>
                </GridRow>
              </Grid>
            </Segment>
            <Grid>
              <GridRow>
                <GridColumn className="rowWrapper" width={16}>
                  {instruments}
                </GridColumn>
              </GridRow>
            </Grid>
          </Fragment>
        ) : (
          <p>No instruments to report</p>
        )}
      </InstrumentUploadMonitoring>
    </LoadingBoundary>
  );
};

InstrumentsUploadMonitoringWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  getInstrumentMonitoring: PropTypes.func.isRequired,
  tstInstruments: PropTypes.object.isRequired,
};

InstrumentsUploadMonitoringWidget.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  tstInstruments: state => selectTstInstrumentMonitoring(state),
  loading: state => selectMonitoringInstrumentLoading(state),
});

const mapDispatchToProps = dispatch => ({
  getInstrumentMonitoring: date => dispatch(fetchTSTInstrumentMonitoring(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentsUploadMonitoringWidget);
