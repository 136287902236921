import { padZero } from 'helpers/format.helper';
import logo from '../../themes/natixis/logo.png';

export default {
  name: 'natixis',
  imageBackground: 'natixis/start-bg.png',
  logo,
  startOfTime: '2020-01-01',
  yearLabels: [2020, 2021, 2022],
  variables: {
    backgroundMain: '#202020',
    colourMain: '#fff',
    pageTopFooter: 'rgb(142, 114, 158)',
    pageBottomFooter: '#5f6367',
    linkHover: '#4f98d1',
    primary: 'rgb(142, 114, 158)',
    secondary: '#707372',
    secondaryActive: 'rgb(142, 114, 158)',
    highlightedText: 'rgb(142, 114, 158)',
    dashboardHeaderBackground: '#5f6367',
    dashboardHeaderColor: '#fff',
    widgetDefaultHeaderBackground: '#2a2968',
    widgetDefaultBodyPadding: '5px',
    widgetDefaultFooterBackground: '#a8b3d3',
    toolbarDefaultBackground: '#a6a6a6',
    toolbarDefaultColor: '#fff',
    toolbarDefaultHoverBackground: '#5f6367',
    toolbarDefaultHoverColor: '#fff',
    toolbarDefaultHeight: '40px',
    customCardSelectedBackground: '#541d74',
    customCardHoverBackground: '#8E729E',
    customCardColor: '#541d74',
    customCardSelectedColor: '#fff',
    customCardHoverColor: '#fff',
    customButtonBackground: '#8E729E',
    customButtonHoverBackground: '#541d74',
    customButtonColor: '#fff',
    customButtonHoverColor: '#fff',
    customMenuBackground: '#fff',
    customMenuHeight: 74,
    customDropdownColor: '#541d74',
    customMenuItemColor: '#541d74',
    customMenuItemHoverColor: '#8E729E',
    listItemSelectedBackground: '#e5d1f1',
    customSpanColor: '#8E729E',
    customStepperColor: '#8E729E',
    customStepperActiveColor: '#541d74',
    breakpoints: {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    highchartColors: [
      '#662584',
      '#00AEC7',
      '#707372',
      '#000000',
      '#0077C8',
      '#97D700',
      '#64A70B',
      '#B884CB',
      '#9ADBE8',
      '#9EA2A2',
      '#DBE442',
      '#FFB81C',
      '#FF7F41',
    ],
  },
  instrumentConfig: {
    attributes: [
      {
        name: 'BBG Ticker',
        type: 'bbgTicker',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.bbgticker || '',
      },
      {
        name: 'ISIN',
        type: 'isin',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.idISIN || '',
      },
      {
        name: 'Name',
        type: 'name',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.name || '',
      },
      {
        name: 'Display Name',
        type: 'displayName',
        readOnly: false,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.displayName || '',
      },
      {
        name: 'Created',
        type: 'datetime',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => {
          if (!instrument.created) {
            return '';
          }
          const dt = new Date(instrument.created);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
      {
        name: 'Ticker',
        type: 'ticker',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.ticker || '',
      },
      // {
      //     name: 'Primary Exchange',
      //     type: 'primaryExchange',
      //     readOnly: true,
      //     atom: 'TextInput',
      //     column: 2,
      //     resolver: ({ instrument }) => instrument.primaryExchange || ''
      // },
      {
        name: 'Instrument ID',
        type: 'instrumentId',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.instrumentId || '',
      },
      {
        name: 'Instrument Type',
        type: 'instrumentType',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.type || '',
      },
      // {
      //     name: 'Market Data Source',
      //     type: 'marketDataSource',
      //     readOnly: true,
      //     atom: 'TextInput',
      //     column: 2,
      //     resolver: ({ instrument }) => instrument.marketDataSource || ''
      // },
      {
        name: 'Asset Class',
        type: 'assetClassPrimary',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => instrument.assetClass || '',
      },
      // {
      //     name: 'Sub Asset Classes',
      //     type: 'assetClassSet',
      //     readOnly: true,
      //     atom: 'TextInput',
      //     column: 2,
      //     resolver: ({ instrument }) => instrument.assetSubClass || ''
      // },
      {
        name: 'Currency',
        type: 'currency',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.refCurrency || '',
      },
      // {
      //     name: 'Risk Geography',
      //     type: 'riskGeography',
      //     readOnly: true,
      //     atom: 'TextInput',
      //     column: 2,
      //     resolver: ({ instrument }) => instrument.riskGeography || ''
      // },
      {
        name: 'Geography',
        type: 'region',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.region || '',
      },
      {
        name: 'Country',
        type: 'country',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => instrument.country || '',
      },
      {
        name: 'Performance Inception',
        type: 'performanceStartDate',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ instrument }) => {
          if (!instrument.dataStartDate) {
            return '';
          }
          const dt = new Date(instrument.dataStartDate);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
      {
        name: 'Performance Last Updated',
        type: 'performanceEndDate',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ instrument }) => {
          if (!instrument.dataEndDate) {
            return '';
          }
          const dt = new Date(instrument.dataEndDate);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
    ],
  },
  instrumentColumns: [
    {
      getLabel: () => 'Enabled',
      name: 'visible',
      type: 'checkbox',
      align: 'center',
      width: 1,
      resolver: item => (item && item.visible) || false,
    },
    {
      getLabel: () => 'Instrument ID',
      name: 'instrumentId',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.instrumentId) || '-',
    },
    {
      getLabel: () => 'ISIN',
      name: 'idISIN',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.idISIN) || '-',
    },
    {
      getLabel: () => 'Bloomberg Ticker',
      name: 'bloombergTicker',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.bloombergTicker) || '-',
    },
    {
      getLabel: () => 'Name',
      name: 'shortName',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.name) || '-',
    },
    {
      getLabel: () => 'Instrument Type',
      name: 'instrumentType',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.type) || '-',
    },
    {
      getLabel: () => 'Asset Class',
      name: 'assetClass',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.assetClass) || '-',
    },
    {
      getLabel: () => 'Currency',
      name: 'riskCurrency',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => (item && item.refCurrency) || '-',
    },
    {
      getLabel: () => 'Region',
      name: 'region',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.region) || '-',
    },
    {
      getLabel: () => 'Market Data Source',
      name: 'marketDataSource',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.marketDataSource) || '-',
    },
    {
      getLabel: () => 'Market Status',
      name: 'marketStatus',
      type: 'string',
      align: 'left',
      width: 1,
      resolver: item => (item && item.marketStatus) || '-',
    },
    {
      getLabel: () => 'Performance Inception',
      name: 'performanceStartDate',
      type: 'date',
      align: 'left',
      width: 1,
      resolver: item => item && item.dataStartDate,
    },
    {
      getLabel: () => 'Performance Last Updated',
      name: 'performanceEndDate',
      type: 'date',
      align: 'left',
      width: 1,
      resolver: item => item && item.dataEndDate,
    },
  ],
  userColumns: [
    {
      getLabel: () => 'Enabled',
      name: 'enabled',
      type: 'checkbox',
      align: 'center',
      width: 1,
      resolver: item => item.enabled,
    },
    {
      getLabel: () => 'Online',
      name: 'online',
      type: 'online',
      align: 'center',
      width: 1,
      resolver: item => item.online,
    },
    {
      getLabel: () => 'Name',
      name: 'displayName',
      type: 'string',
      align: 'left',
      width: 3,
      resolver: item => item.displayName,
    },
    {
      getLabel: () => 'Username',
      name: 'username',
      type: 'string',
      align: 'left',
      width: 2,
      resolver: item => item.username,
    },
    {
      getLabel: () => 'Company',
      name: 'company.name',
      type: 'string',
      align: 'center',
      width: 2,
      resolver: item => (item.company && item.company.name) || '',
    },
    {
      getLabel: () => 'Email',
      name: 'email',
      type: 'string',
      align: 'left',
      width: 3,
      resolver: item => item.email,
    },
    {
      getLabel: () => 'Functional Roles',
      name: 'functionalRoles',
      type: 'tag',
      align: 'left',
      width: 3,
      resolver: item => item.functionalRoles,
    },
    {
      getLabel: () => 'Object Roles',
      name: 'objectRoles',
      type: 'tag',
      align: 'left',
      width: 3,
      resolver: item => item.objectRoles,
    },
    {
      getLabel: () => 'Created',
      name: 'created',
      type: 'datetime',
      align: 'left',
      width: 1,
      resolver: item => item.created,
    },
    {
      getLabel: () => 'Last Activity',
      name: 'lastActivity',
      type: 'datetime',
      align: 'left',
      width: 1,
      resolver: item => item.lastActivity,
    },
  ],
  monitorLoginsControlPanels: [
    {
      panelURL: 'aggregate',
      title: 'Aggregate',
      panelName: 'aggregate',
    },
    {
      panelURL: 'function-roles',
      title: 'Functional Roles',
      panelName: 'function',
    },
  ],
  strategyConfig: {
    attributes: [
      {
        name: 'Created',
        type: 'datetime',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ strategy }) => {
          if (!strategy.created) {
            return '';
          }
          const dt = new Date(strategy.created);
          return `${padZero(dt.getDate())}-${padZero(
            dt.getMonth() + 1,
          )}-${dt.getFullYear()} ${padZero(dt.getHours())}:${padZero(dt.getMinutes())}`;
        },
      },
      {
        name: 'Name',
        type: 'name',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ strategy }) => strategy.name || '',
      },
    ],
  },
  userConfig: {
    attributes: [
      {
        name: 'ID',
        type: '_id',
        readOnly: true,
        atom: 'TextInput',
        column: 1,
        resolver: ({ user }) => user._id,
      },
      {
        name: 'Username',
        type: 'username',
        readOnly: true,
        atom: 'TextInput',
        column: 2,
        resolver: ({ user }) => user.username,
      },
      {
        name: 'First name',
        type: 'firstName',
        readOnly: false,
        atom: 'TextInput',
        column: 1,
        validations: 'required',
        resolver: ({ user }) => user.firstName,
      },
      {
        name: 'Last name',
        type: 'lastName',
        readOnly: false,
        atom: 'TextInput',
        column: 2,
        validations: 'required',
        resolver: ({ user }) => user.lastName,
      },
      {
        name: 'Email',
        type: 'email',
        readOnly: false,
        atom: 'TextInput',
        column: 1,
        validations: 'required|email',
        resolver: ({ user }) => user.email,
      },
      {
        name: 'Contact Number',
        type: 'contactPhoneNumber',
        readOnly: false,
        atom: 'TextInput',
        column: 2,
        validations: '',
        resolver: ({ user }) => user.contactPhoneNumber,
      },
      {
        name: 'Functional Roles',
        type: 'functionRoles',
        readOnly: false,
        atom: 'LabelInput',
        placeholder: 'Add Functional Roles',
        column: 2,
        resolver: ({ user }) => (user && user.functionRoles) || [],
        optionResolver: ({ roles }) =>
          (roles &&
            roles.functionRoles &&
            roles.functionRoles.map(option => ({ value: option, text: option }))) ||
          [],
        compiler: newRolesArray => {
          const set = new Set(newRolesArray);
          return [...set];
        },
      },
      {
        name: 'Object Roles',
        type: 'objectRoles',
        readOnly: false,
        atom: 'LabelInput',
        placeholder: 'Add Object Roles',
        column: 1,
        resolver: ({ user }) => (user && user.objectRoles) || [],
        optionResolver: ({ roles }) =>
          (roles?.objectRoles &&
            roles.objectRoles.map(({ name, role }) => ({ value: role, text: name }))) ||
          [],
        compiler: newRoles => [...new Set(newRoles)],
      },
      {
        name: 'Country',
        type: 'bjbCountry',
        readOnly: false,
        multiple: false,
        atom: 'LabelInput',
        column: 1,
        validations: 'required',
        default: { value: 'Switzerland', text: 'Switzerland' },
        resolver: ({ user }) => (user && user.bjbCountry) || 'Switzerland',
        optionResolver: () => [
          { value: 'Germany', text: 'Germany' },
          { value: 'Guernsey', text: 'Guernsey' },
          { value: 'Hong Kong', text: 'Hong Kong' },
          { value: 'Luxembourg', text: 'Luxembourg' },
          { value: 'Monaco', text: 'Monaco' },
          { value: 'Singapore', text: 'Singapore' },
          { value: 'Spain', text: 'Spain' },
          { value: 'Switzerland', text: 'Switzerland' },
          { value: 'United Kingdom', text: 'United Kingdom' },
          { value: 'United Arab Emirates', text: 'Utd. Arab Emirates' },
        ],
        compiler: bjbCountry => bjbCountry,
      },
      {
        name: 'Location',
        type: 'bjbLocation',
        readOnly: false,
        multiple: false,
        atom: 'LabelInput',
        column: 2,
        validations: 'required',
        default: { value: 'Zurich', text: 'Zurich' },
        resolver: ({ user }) => (user && user.bjbLocation) || 'Zurich',
        optionResolver: () => [
          { value: 'Dubai', text: 'Dubai' },
          { value: 'Frankfurt am Main', text: 'Frankfurt am Main' },
          { value: 'Geneva', text: 'Geneva' },
          { value: 'Hong Kong', text: 'Hong Kong' },
          { value: 'Lausanne', text: 'Lausanne' },
          { value: 'London', text: 'London' },
          { value: 'Lugano', text: 'Lugano' },
          { value: 'Luxembourg', text: 'Luxembourg' },
          { value: 'Madrid', text: 'Madrid' },
          { value: 'Monaco', text: 'Monaco' },
          { value: 'Saint Peter Port', text: 'Saint Peter Port' },
          { value: 'Singapore', text: 'Singapore' },
          { value: 'Zurich', text: 'Zurich' },
        ],
        compiler: bjbLocation => bjbLocation,
      },
    ],
  },
};
