import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const Wrapper = styled.span`
  display: inline-block;
  float: right;
  margin-top: -15px;
  cursor: pointer;
  z-index: 100;
  position: relative;
`;

const ShowHideAll = ({ onShowHideAll }) => (
  <Wrapper onClick={onShowHideAll}>
    <Icon name="adjust" />
    Show/Hide All
  </Wrapper>
);

ShowHideAll.propTypes = {
  onShowHideAll: PropTypes.func.isRequired,
};

export default ShowHideAll;
