const toggleFeatureByRole = (user, confg) => {
  if (!user) return false;
  if (typeof confg === 'boolean') {
    return confg;
  }
  if (typeof confg === 'string') {
    return user.roles.includes(confg);
  }
  return false;
};

export default toggleFeatureByRole;
