import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import fromEntries from 'fromentries';

import { Dropdown, Checkbox, Loader } from 'semantic-ui-react';

const Filter = styled(Dropdown)`
  padding: 1.5rem !important;
  margin-bottom: 1rem !important;
  min-width: 250px;
  background: #fff !important;
  border: solid 1px ${props => props.theme.variables.primary} !important;
  color: ${props => props.theme.variables.primary};
  transition: background 1s ease !important;

  &.dirty {
    background: ${props => props.theme.variables.primary} !important;
    color: #fff !important;
  }
`;

const FilterMenu = ({ config, defaults, options, state, setFilterState }) => {
  // Local react state for open/close menu
  const [openState, setOpenState] = useState(false);

  // Clicking away closes menu
  const handleMenuBlur = useCallback(() => {
    setOpenState(false);
  }, [setOpenState]);
  useEffect(() => {
    window.addEventListener('click', handleMenuBlur);
    return () => {
      window.removeEventListener('click', handleMenuBlur);
    };
  }, [handleMenuBlur]);

  // Builds local state for checkboxes from redux object
  // Basically a conversion function to map the option keys and merge with defaults
  const _state = Object.entries(state)
    .map(([_key, value]) => {
      const key = /^\d+$/.test(_key) ? parseInt(_key, 10) : _key;
      const option = options.find(opt => opt.key === key);
      if (option) {
        return [option.key, value || true];
      }
      return null;
    })
    .filter(f => f);

  let filterCheckboxState = {};
  if (Object.fromEntries) {
    filterCheckboxState = Object.fromEntries(_state);
  } else {
    filterCheckboxState = fromEntries(_state);
  }

  // Function to convert local filterCheckboxState back to redux filter state
  // Returns an array of key string values to be passed to api
  const setFilterCheckboxState = (checkedOptions = {}) => {
    const filteredState = Object.entries(checkedOptions).filter(([, checked]) => checked);

    return Object.fromEntries
      ? setFilterState(Object.fromEntries(filteredState))
      : setFilterState(fromEntries(filteredState));
  };

  // Flag to determine if filter is in original defaults state
  const isPristine = _.isEqual(
    Object.entries(filterCheckboxState).filter(([, s]) => s),
    Object.entries(defaults).filter(([, s]) => s),
  );

  // Open/Close menu only on clicking the button
  // Disables blur closing
  const toggleFilterMenu = event => {
    if (event.target.classList.contains('menu') || event.target.closest('.menu')) {
      return;
    }
    setOpenState(!openState);
  };

  // Toggle option checkbox state, then dispatch to redux to update filter object
  const toggleFilterState =
    option =>
    (e, { checked }) => {
      const newState = {
        ...filterCheckboxState,
        [option.key]: checked,
      };
      setFilterCheckboxState(newState);
    };

  // Reduce all options to checked state
  const toggleCheckAll = () => {
    const stateObj = options
      .filter(option => option.key !== 'all')
      .map(option => option.key)
      .reduce((a, b) => ({ ...a, [b]: true }), []);
    setFilterCheckboxState(stateObj);
  };
  // Reduce all options to un-checked state
  const toggleUncheckAll = () => {
    const stateObj = options
      .filter(option => option.key !== 'all')
      .map(option => option.key)
      .reduce((a, b) => ({ ...a, [b]: false }), []);
    setFilterCheckboxState(stateObj);
  };

  const selectedCount = Object.entries(filterCheckboxState).filter(
    ([key, checked]) => key !== 'all' && checked === true,
  ).length;

  const dropdownOptions = options.length ? (
    options
      .filter(option => option.key !== 'all')
      .map(({ key, text, ...props }) => (
        <Dropdown.Item key={`${key}-${text}`} {...props}>
          <Checkbox
            label={text}
            checked={(filterCheckboxState[key] && filterCheckboxState[key] === true) || false}
            onChange={toggleFilterState({ key, text, ...props })}
          />
        </Dropdown.Item>
      ))
  ) : (
    <Dropdown.Item style={{ height: 100 }}>
      <Loader active />
    </Dropdown.Item>
  );

  return (
    <Filter
      text={`${config.name} ${selectedCount > 0 ? `(${selectedCount})` : ''}`}
      icon="filter"
      open={openState}
      onClick={toggleFilterMenu}
      floating
      labeled
      button
      className={`icon ${!isPristine ? 'dirty' : ''}`}
      closeOnBlur={false}
    >
      <Dropdown.Menu>
        <Dropdown.Item onClick={toggleCheckAll}>
          <i className="fa fa-check" /> Check All
        </Dropdown.Item>
        <Dropdown.Item onClick={toggleUncheckAll}>
          <i className="fa fa-times" /> Uncheck All
        </Dropdown.Item>
        <Dropdown.Header icon="tags" content={config.name} />
        <Dropdown.Menu scrolling>{dropdownOptions}</Dropdown.Menu>
      </Dropdown.Menu>
    </Filter>
  );
};

FilterMenu.propTypes = {
  config: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  defaults: PropTypes.object,
  state: PropTypes.object.isRequired,
  setFilterState: PropTypes.func.isRequired,
};
FilterMenu.defaultProps = {
  defaults: {},
};

export default FilterMenu;
