import { fromJS } from 'immutable';

import * as constants from '../constants';

const initialState = fromJS({
  fluid: true,
  tabs: false,
  linkPage: true,
  items: null,
  loading: false,
  error: null,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_LAYOUT_STARTED:
      return state.set('loading', true);
    case constants.GET_LAYOUT_SUCCEEDED:
      return state.merge({
        items: action.payload,
        loading: false,
      });
    case constants.GET_LAYOUT_FAILED:
      return state.merge({
        loading: false,
        error: action.payload ? action.payload.error : null,
      });
    case constants.SET_FLUID_LAYOUT:
      return state.set('fluid', !state.get('fluid'));
    case constants.SET_TABS_LAYOUT:
      return state.set('tabs', !state.get('tabs'));
    case constants.SET_LINK_PAGE_LAYOUT:
      return state.set('linkPage', !state.get('linkPage'));

    case constants.CLEAR_SESSION:
    case constants.LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};
