import * as constants from '../constants';

export const setFluidLayout = () => ({
  type: constants.SET_FLUID_LAYOUT,
});

export const setTabsLayout = () => ({
  type: constants.SET_TABS_LAYOUT,
});

export const setLinkPageLayout = () => ({
  type: constants.SET_LINK_PAGE_LAYOUT,
});
