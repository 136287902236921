/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const ToolbarWrapper = styled.div`
  height: ${props => props.theme.variables.toolbarDefaultHeight || '35px'};
  background-color: ${props => props.theme.variables.toolbarDefaultBackground || '#1b1c1d;'};
  color: ${props => props.theme.variables.toolbarDefaultColor || '#fff'};
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.floated === 'right' ? 'row-reverse' : 'row')};
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  padding-left: 10px;
`;

const DivAction = styled.div`
  position: relative;
  vertical-align: middle;
  flex: 0 0 auto;
  padding: 0.5em 1.3em;
  font-size: 0.9rem;
  cursor: pointer;
  border-right: 1px solid ${props => props.theme.variables['body-bg'] || '#1b1c1d'};
  &:hover {
    font-weight: 400;
    color: ${props => props.theme.variables.toolbarDefaultHoverColor || '#fff'};
    background-color: ${props => props.theme.variables.toolbarDefaultHoverBackground || '#1b1c1d'};
  }
`;

const SpanAction = styled.span`
  margin-right: 3px;
  text-shadow: none !important;
`;

const IconAction = styled(Icon)`
  opacity: 0.9;
  float: none;
  margin: 0 0.35714286em 0 0;
  width: 1.18em;
  height: 1em;
  font-family: Icons;
  font-style: normal;
  text-align: center;
  speak: none;
`;

const ItemAction = ({ label, icon, openModal, template }) => (
  <DivAction onClick={() => openModal(template)}>
    <SpanAction>{label}</SpanAction>
    <IconAction name={icon} />
  </DivAction>
);

const BackAction = ({ goBack }) => (
  <DivAction onClick={() => goBack()}>
    <IconAction name="angle left" />
    <SpanAction>Back</SpanAction>
  </DivAction>
);

const ToolbarDefault = ({ actions, floated, openModal, back, history }) => {
  const goBack = () => {
    history.goBack();
  };

  return (
    <ToolbarWrapper floated={floated}>
      {back && <BackAction goBack={goBack} />}
      {actions &&
        actions.map(action => (
          <ItemAction
            key={action.label}
            label={action.label}
            icon={action.icon}
            template={action}
            openModal={openModal}
          />
        ))}
    </ToolbarWrapper>
  );
};

ToolbarDefault.defaultProps = {
  floated: '',
  back: false,
  actions: [],
};

ToolbarDefault.propTypes = {
  floated: PropTypes.string,
  actions: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  back: PropTypes.bool,
};

export default withRouter(ToolbarDefault);
