import { fromJS } from 'immutable';
import { GET_REGIONS_LIST_FAILURE, GET_REGIONS_LIST_SUCCESS } from '../constants';

const initialState = fromJS({
  loading: false,
  error: null,
  list: [],
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REGIONS_LIST_SUCCESS:
      return state.merge({
        loading: false,
        list: fromJS(action.payload || []),
        error: null,
      });
    case GET_REGIONS_LIST_FAILURE:
      return state.merge({
        loading: false,
        list: fromJS([]),
        error: action.payload?.error || action.error,
      });
    default:
      return state;
  }
};
