import { useParams } from 'react-router-dom';

function useActivePanel() {
  const { type } = useParams();
  const activePanels = {
    'contract-roles': 'contract',
    'function-roles': 'function',
  };
  return activePanels[type] || 'aggregate';
}

export default useActivePanel;
