import * as constants from '../constants';

export const requestStrategyExport = json => ({
  type: constants.STRATEGY_EXPORT_REQUEST,
  json,
});

export const clearExportData = () => ({
  type: constants.STRATEGY_EXPORT_CLEAR,
});

export const setRecalculationError = error => ({
  type: constants.STRATEGY_CALCULATION_FAILURE,
  error,
});

export const requestStrategyCalculation = name => ({
  type: constants.STRATEGY_CALCULATION_REQUEST,
  name,
});

export const getStrategies = () => ({
  type: constants.GET_STRATEGY_LIST,
});

export const setSearchResults = payload => ({
  type: constants.SET_STRATEGY_SEARCH_RESULTS,
  payload,
});

export const clearSearchResults = () => ({
  type: constants.CLEAR_STRATEGY_SEARCH_RESULTS,
});

export const fetchStrategyDetails = id => ({
  type: constants.GET_STRATEGY_DETAILS,
  id,
});

export const setStrategyUpdate = payload => ({
  type: constants.SET_STRATEGY_DETAILS,
  payload,
});

export const cancelStrategyUpdate = () => ({
  type: constants.SET_STRATEGY_DETAILS_CANCEL,
});

export const updateStrategyDetails = payload => ({
  type: constants.UPDATE_STRATEGY_DETAILS,
  payload,
});
