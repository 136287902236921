/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Table, Rating, Label, Checkbox } from 'semantic-ui-react';
import moment from 'moment';

import { formatIsoDate, formatIsoDateTime } from '../../../helpers/string.helper';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';

const CellWrapper = styled(Table.Cell)`
  cursor: ${props => (props.clicklink ? 'pointer' : 'auto')};
`;

const Online = styled.i`
  &::before {
    color: green;
  }
`;

const CellRenderer = ({ item, index, config, onSelect, rowSpan, data, onClickLink, clickLink }) => {
  const { dateFormat, dateTimeFormat } = useDateTimeFormatter();
  const { type, align, decimalFormat } = config;

  if (config.type === 'hidden') return null;

  if (item === 'hidden') return null;

  const formatDecimal = val => (decimalFormat ? val.toFixed(decimalFormat) : val.toFixed(2));

  // Function will return JSX object of mapped array
  // - expand to fill requirements when needed
  const renderArrayOfObjects = array => {
    if (!array.length) {
      return null;
    }
    return array.map(obj => {
      if (obj && obj.type === 'details') {
        if (obj.display === 'icon') {
          return (
            <Label
              key={index}
              title={obj.type}
              content={<i className={`fa ${obj.icon}`} />}
              onClick={obj.onClick}
            />
          );
        }
      }
      return null;
    });
  };

  return (
    <CellWrapper
      textAlign={align}
      width={config.width || 'auto'}
      onClick={() => onClickLink(data, index)}
      rowSpan={rowSpan}
      clicklink={clickLink.toString()}
    >
      {type === 'rating' && <Rating icon="star" defaultRating={0} maxRating={1} />}
      {type === 'string' && <span>{item}</span>}
      {type === 'date' && (
        <span>
          {item ? (dateFormat ? moment(item).format(dateFormat) : formatIsoDate(item)) : '-'}
        </span>
      )}
      {type === 'datetime' && (
        <span>
          {item
            ? dateTimeFormat
              ? moment(item).format(dateTimeFormat)
              : formatIsoDateTime(item)
            : '-'}
        </span>
      )}
      {type === 'link' && (
        <Label as="a" color="blue" basic onClick={onSelect}>
          {item}
        </Label>
      )}
      {type === 'number' && <span>{item ? formatDecimal(item) : '-'}</span>}
      {type === 'number-zero' && <span>{item ? formatDecimal(item) : 0}</span>}
      {type === 'percentage' && <span>{item ? `${formatDecimal(item)} %` : '-'}</span>}
      {type === 'number-percentage' && <span>{item ? `${formatDecimal(item)} %` : '-'}</span>}
      {type === 'checkbox' && <Checkbox checked={item} disabled />}
      {type === 'online' && (
        <span>
          {item ? <Online className="fa fa-check-circle" /> : <i className="fa fa-times-circle" />}
        </span>
      )}
      {type === 'tag' &&
        item &&
        (Array.isArray(item) ? (
          item.length ? (
            item.map((i, k) => <Label key={k} content={i} />)
          ) : (
            <span key={index}>-</span>
          )
        ) : (
          <Label key={index} content={item} />
        ))}
      {type === 'object' && Array.isArray(item) && renderArrayOfObjects(item)}
    </CellWrapper>
  );
};

const TableDefaultRow = ({
  columns,
  data,
  name,
  rowIndex,
  onSelect,
  filter,
  rowspan,
  onClickLink,
  clickLink,
}) => {
  const handleSelect = () => {
    onSelect(data);
  };

  return (
    <Table.Row key={`${name}-row-${rowIndex}`}>
      {columns.map((col, i) => (
        <CellRenderer
          rowSpan={rowspan && i === 0 && col.rowSpan ? col.rowSpan : null}
          key={`${name}-${rowIndex}-${col.name}`}
          config={col}
          item={col.resolver && col.resolver(data, filter)}
          onSelect={handleSelect}
          data={data}
          index={rowIndex}
          onClickLink={onClickLink}
          clickLink={clickLink}
        />
      ))}
    </Table.Row>
  );
};

TableDefaultRow.defaultProps = {
  onSelect: () => {},
  filter: '',
  rowspan: false,
  onClickLink: () => {},
  clickLink: false,
};

TableDefaultRow.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
  filter: PropTypes.string,
  rowspan: PropTypes.bool,
  onClickLink: PropTypes.func,
  clickLink: PropTypes.bool,
};

export default TableDefaultRow;
