import PropTypes from 'prop-types';
import React from 'react';
import { Message } from 'semantic-ui-react';
import Markdown from '../../atoms/Markdown';
import { getSeverityLevelColor } from './severityLevel';

export default function InformationBanner({ informationBanner }) {
  const { message, severityLevel, isDismissable } = informationBanner;

  // If there is no message, there's nothing to display.
  if (!message) {
    return null;
  }

  const color = getSeverityLevelColor(severityLevel);

  const onDismiss = isDismissable ? () => {} : undefined;

  return (
    <Message color={color} onDismiss={onDismiss}>
      <Markdown>{message}</Markdown>
    </Message>
  );
}

InformationBanner.propTypes = {
  informationBanner: PropTypes.shape({
    id: PropTypes.string,
    message: PropTypes.string.isRequired,
    severityLevel: PropTypes.number.isRequired,
    isDismissable: PropTypes.bool.isRequired,
  }).isRequired,
};
