import * as constants from '../constants';

export const getReferenceData = () => ({
  type: constants.GET_REFERENCE_DATA_LIST,
});

export const requestReferenceExport = json => ({
  type: constants.REFERENCE_DATA_EXPORT_REQUEST,
  json,
});

export const requestReferenceProductLines = () => ({
  type: constants.GET_REFERENCE_PRODUCT_LINES,
});

export const requestReferenceCurrencies = () => ({
  type: constants.GET_REFERENCE_CURRENCIES,
});

export const clearExportData = () => ({
  type: constants.REFERENCE_DATA_EXPORT_CLEAR,
});
