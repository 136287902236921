import _ from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Grid, GridColumn, GridRow, Label, Segment } from 'semantic-ui-react';

import { getOnlineUsers } from '../../../store/actions/user.actions';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import {
  selectOnlineUsers,
  selectOnlineUsersLastRefresh,
  selectOnlineUsersLoading,
} from '../../../store/selectors/user.selector';
import { makeSelectCurrentTheme } from '../../../store/selectors/themes.selector';

const OnlineUsers = styled.div`
  padding: 15px;
`;

function DisplayUser({ contractRole, userArray }) {
  const whitelabel = useSelector(makeSelectCurrentTheme);
  const userLink = _id => `/user/${_id}`;
  return (
    <Segment vertical key={`user-${contractRole}`}>
      <Grid>
        <h3>{contractRole}</h3>
        {userArray?.map(({ _id, displayName, roles }, key) => (
          <GridRow key={key}>
            <GridColumn width={6}>
              <span>
                <a href={userLink(_id)}>{displayName}</a>
              </span>
            </GridColumn>
            <GridColumn width={10}>
              {roles &&
                whitelabel === 'BJB' &&
                roles
                  .filter(role => role.includes('bjb-'))
                  .map(role => (
                    <Label key={role} title={role}>
                      {role}
                    </Label>
                  ))}
            </GridColumn>
          </GridRow>
        ))}
      </Grid>
    </Segment>
  );
}

DisplayUser.propTypes = {
  contractRole: PropTypes.string.isRequired,
  userArray: PropTypes.array.isRequired,
};

export default function OnlineUsersWidget() {
  const loading = useSelector(selectOnlineUsersLoading);
  const lastRefreshed = useSelector(selectOnlineUsersLastRefresh);
  const onlineUsers = useSelector(selectOnlineUsers);
  const onlineUsersCount = onlineUsers?.length;
  const dispatch = useDispatch();

  useEffect(() => {
    if (lastRefreshed === -1) dispatch(getOnlineUsers());
  }, [dispatch, lastRefreshed]);

  const groupedByContractRole = _.groupBy(onlineUsers, user => _.get(user, 'contractRole'));
  const formattedUsers = Object.entries(groupedByContractRole).map(([key, users]) => {
    const role = key === 'undefined' ? 'Non-SAML' : key;
    return { role, users };
  });

  return (
    <LoadingBoundary isLoading={loading}>
      <OnlineUsers className="dashboard-online-users" style={{ minHeight: '125px' }}>
        <h2>Online Users: {onlineUsersCount}</h2>
        <hr />
        {formattedUsers.map((user, key) => (
          <DisplayUser key={key} {...user} />
        ))}
      </OnlineUsers>
    </LoadingBoundary>
  );
}
