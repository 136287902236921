/* eslint-disable react/style-prop-object */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import uuid from 'uuid/v4';

const Cell = styled(Table.Cell)`
  color: ${props => props.color === 'red' && '#a63860 !important'};
`;

const Bolded = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.variables.highlightedText};
`;

const Sup = styled.sup`
  color: ${props => props.theme.variables.highlightedText};
`;

const HeadCell = styled(Table.HeaderCell)`
  border-bottom: 1px solid #203a4c !important;
`;

const FootCell = styled(Table.HeaderCell)`
  border-top: 1px solid #203a4c !important;
`;

const TableCustom = ({ data, headers, footers, colored, firstColumnWidth }) => {
  const renderCell = item => item || '-';
  const renderRow = rowData => {
    const unofficial = rowData[0] === 'unofficial';
    const align = i => {
      if (colored && i === 1) return 'left';
      if (i === 0) return 'left';
      return 'center';
    };
    return (
      <Table.Row key={uuid()}>
        {rowData.map((item, i) => {
          if (colored && i === 0) return null;
          return (
            <Cell
              className={colored && i === 1 && unofficial ? 'unofficial-left-border' : ''}
              key={uuid()}
              textAlign={align(i)}
              color={i !== 1 && colored && unofficial ? 'red' : null}
            >
              {(colored && i === 1) || (!colored && i === 0) ? (
                <Bolded>{item}</Bolded>
              ) : (
                renderCell(item)
              )}
            </Cell>
          );
        })}
      </Table.Row>
    );
  };

  const firstColumn = () => firstColumnWidth || 6;

  const renderHeader = () => (
    <Table.Header>
      <Table.Row>
        {headers.map((header, i) => (
          <HeadCell
            key={uuid()}
            textAlign={i === 0 ? 'left' : 'center'}
            width={i === 0 ? firstColumn() : 1}
          >
            <Bolded>{header.label}</Bolded>
            {header.sup && <Sup>{header.sup}</Sup>}
          </HeadCell>
        ))}
      </Table.Row>
    </Table.Header>
  );

  const renderFooter = () => (
    <Table.Footer>
      <Table.Row>
        {footers.map((header, i) => (
          <FootCell
            key={uuid()}
            textAlign={i === 0 ? 'left' : 'center'}
            width={i === 0 ? firstColumn() : 1}
          >
            <Bolded>{header.label}</Bolded>
          </FootCell>
        ))}
      </Table.Row>
    </Table.Footer>
  );

  return (
    <Fragment>
      <Table basic="very" striped>
        {renderHeader()}
        <Table.Body>{data.map(row => renderRow(row))}</Table.Body>
        {renderFooter()}
      </Table>
    </Fragment>
  );
};

TableCustom.defaultProps = {
  data: [],
  headers: [],
  footers: [],
  colored: false,
  firstColumnWidth: 6,
};

TableCustom.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  footers: PropTypes.array,
  colored: PropTypes.bool,
  firstColumnWidth: PropTypes.number,
};

export default TableCustom;
