import { createSelector } from 'reselect';

const selectInstruments = state => state.get('instruments');
const selectMatch = state => state.getIn(['router', 'location', 'pathname']) || [];

const selectLastRefresh = createSelector(selectInstruments, instruments =>
  instruments.get('lastRefresh'),
);
const selectInstrumentLoading = createSelector(selectInstruments, instruments =>
  instruments.get('loading'),
);
const selectMonitoringInstrumentLoading = createSelector(selectInstruments, instruments =>
  instruments.get('loadingMonitoring'),
);
const selectMonitoringInstrumentPerfLoading = createSelector(selectInstruments, instruments =>
  instruments.get('loadingPerfMonitoring'),
);

const selectInstrumentList = createSelector(
  selectInstruments,
  instruments => instruments.get('list').toJS() || [],
);

const selectInstrumentSearchList = createSelector(
  selectInstruments,
  instruments => instruments.get('search').toJS() || [],
);

const selectInstrumentListPage = createSelector(
  [selectInstruments, selectMatch],
  (instruments, match) => {
    const page = parseInt(match.substr(match.lastIndexOf('/') + 1), 10) - 1;
    return instruments.get('list').toJS()[page] || [];
  },
);

const selectInstrumentSearchResults = createSelector(
  [selectInstruments, selectMatch],
  (instruments, match) => {
    const page = parseInt(match.substr(match.lastIndexOf('/') + 1), 10) - 1;
    return instruments.get('search').toJS()[page] || [];
  },
);

const selectTstInstrumentMonitoring = createSelector(
  selectInstruments,
  instruments => instruments.get('tstList').toJS() || {},
);

const selectInstrumentPerfMonitoring = createSelector(
  selectInstruments,
  instruments => instruments.get('perfList').toJS() || [],
);

const selectInstrumentDetails = createSelector(
  selectInstruments,
  instruments => instruments.getIn(['details', 'active']).toJS() || {},
);

const selectHasDetailsStateChanged = createSelector(selectInstruments, instruments => {
  const active = instruments.getIn(['details', 'active']).toJS() || {};
  const cache = instruments.getIn(['details', 'cache']).toJS() || {};
  return JSON.stringify(active) !== JSON.stringify(cache) || false;
});

const selectLastExportRequest = createSelector(selectInstruments, instrument =>
  instrument.get('lastRequest'),
);
const selectLastBBExportRequest = createSelector(selectInstruments, instrument =>
  instrument.get('lastRequestBB'),
);

const selectInstrumentsExportData = createSelector(selectInstruments, instrument => {
  const data = instrument.get('data');
  if (!data) return null;
  if (typeof data === 'string') return data;
  return data.toJS() || {};
});
const selectBBExportData = createSelector(selectInstruments, instrument => {
  const data = instrument.get('dataBB');
  if (!data) return null;
  if (typeof data === 'string') return data;
  return data.toJS() || {};
});

const selectInstrumentsExportDataLoading = createSelector(selectInstruments, instrument =>
  instrument.get('isExporting'),
);
const selectBBExportDataLoading = createSelector(selectInstruments, instrument =>
  instrument.get('isBBExporting'),
);

export {
  selectInstruments,
  selectInstrumentLoading,
  selectMonitoringInstrumentLoading,
  selectMonitoringInstrumentPerfLoading,
  selectLastRefresh,
  selectInstrumentList,
  selectInstrumentSearchList,
  selectInstrumentListPage,
  selectInstrumentSearchResults,
  selectInstrumentDetails,
  selectTstInstrumentMonitoring,
  selectInstrumentPerfMonitoring,
  selectHasDetailsStateChanged,
  selectLastExportRequest,
  selectInstrumentsExportData,
  selectInstrumentsExportDataLoading,
  selectLastBBExportRequest,
  selectBBExportData,
  selectBBExportDataLoading,
};
