import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Modal, List } from 'semantic-ui-react';

import { formatIsoDate } from '../../../helpers/string.helper';

const ColorSpan = styled.div`
  color: ${props => props.theme.variables.highlightedText} !important;
  dispplay: block;
  margin-top: 3px;
`;

const ColorSpanTop = styled.div`
  color: ${props => props.theme.variables.highlightedText} !important;
  dispplay: block;
  font-size: 13px;
`;

const ColorSpanBottom = styled.div`
  color: ${props => props.theme.variables.highlightedText} !important;
  dispplay: block;
  margin-top: 3px;
  font-size: 12px;
`;

const SelectedItem = styled(List.Item)`
  background-color: #b9d6ed !important;
`;

// const CheckboxItem = styles(Checkbox)`
//   margin-top: 15px;
// `;

const CompareListModal = ({ instruments, isOpen, closeModal, addInstruments, currentSelected }) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    setSelected(currentSelected);
  }, [currentSelected]);

  const handleToggleSelect = val => {
    const index = selected.indexOf(val);
    if (index === -1) {
      selected.push(val);
    } else {
      selected.splice(index, 1);
    }
    setSelected([...selected]);
  };

  // const renderCheckbox = instrument => (
  //     <CheckboxItem
  //       onChange={() => handleToggleSelect(instrument._id)}
  //       checked={selected.find(item => item._id === instrument._id)}
  //     />
  // );

  const renderItem = instrument => (
    <Fragment>
      <List.Content floated="right">{/* {renderCheckbox(instrument)} */}</List.Content>
      <List.Content>
        <ColorSpanTop>{instrument.code}</ColorSpanTop>
        <List.Header>
          <ColorSpan>{instrument.name}</ColorSpan>
        </List.Header>
        <ColorSpanBottom>
          Data from {formatIsoDate(instrument.performanceStartDate)}
        </ColorSpanBottom>
      </List.Content>
    </Fragment>
  );

  const checkSelected = instrument => !!selected.find(item => item === instrument._id);

  const renderItemWrapper = instrument =>
    checkSelected(instrument) ? (
      <SelectedItem key={instrument._id} onClick={() => handleToggleSelect(instrument._id)}>
        {renderItem(instrument)}
      </SelectedItem>
    ) : (
      <List.Item key={instrument._id} onClick={() => handleToggleSelect(instrument._id)}>
        {renderItem(instrument)}
      </List.Item>
    );

  const renderInstrumentsList = () => (
    <List selection verticalAlign="middle" divided>
      {instruments.map(instrument => renderItemWrapper(instrument))}
    </List>
  );

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Modal.Header>
        <ColorSpan>Comparison: Add More Items</ColorSpan>
      </Modal.Header>
      <Modal.Content image scrolling>
        <Modal.Description>
          {/* <Header>
Modal Header
                      {' '}
                      { selectedIds[0]}
                  </Header> */}
          {renderInstrumentsList()}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => addInstruments(selected)}>
          Add Items
        </Button>
        <Button onClick={() => closeModal()}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  );
};

CompareListModal.propTypes = {
  instruments: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  addInstruments: PropTypes.func.isRequired,
  currentSelected: PropTypes.array,
};

CompareListModal.defaultProps = {
  instruments: [],
  currentSelected: [],
};

export default CompareListModal;
