import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import WidgetDefaultWrapper from '../../atoms/WidgetDefaultWrapper';
import WidgetDefaultHeader from '../../atoms/WidgetDefaultHeader';
import WidgetDefaultTitle from '../../atoms/WidgetDefaultTitle';
import WidgetDefaultToolbar from '../WidgetDefaultToolbar';
import WidgetDefaultBody from '../../atoms/WidgetDefaultBody';
import WidgetDefaultFooter from '../../atoms/WidgetDefaultFooter';

export const WidgetDefault = ({
  hideHeader,
  showFooter,
  footerComponent,
  title,
  children,
  actions,
  openModal,
  collapsed,
  noPadding,
  noBorder,
  background,
  hideToggleContent,
}) => {
  const [isCollapsed, setCollapsed] = useState(false);

  useEffect(() => {
    if (collapsed) setCollapsed(true);
  }, [collapsed, title]);

  const toggleContent = () => setCollapsed(!isCollapsed);

  return (
    <WidgetDefaultWrapper noBorder={noBorder} background={background}>
      {!hideHeader && (
        <WidgetDefaultHeader>
          <WidgetDefaultTitle>
            {title}
            <WidgetDefaultToolbar
              actions={actions}
              openModal={openModal}
              toggleContent={toggleContent}
              isCollapsed={isCollapsed}
              hideToggleContent={hideToggleContent}
            />
          </WidgetDefaultTitle>
        </WidgetDefaultHeader>
      )}
      {!isCollapsed && <WidgetDefaultBody noPadding={noPadding}>{children}</WidgetDefaultBody>}
      {showFooter && !isCollapsed && <WidgetDefaultFooter>{footerComponent}</WidgetDefaultFooter>}
    </WidgetDefaultWrapper>
  );
};

WidgetDefault.defaultProps = {
  title: '',
  hideHeader: false,
  showFooter: false,
  footerComponent: {},
  actions: [],
  openModal: () => {},
  noPadding: false,
  noBorder: false,
  background: '',
  children: {},
  collapsed: false,
  hideToggleContent: false,
};

WidgetDefault.propTypes = {
  title: PropTypes.string,
  hideHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  footerComponent: PropTypes.object,
  actions: PropTypes.array,
  openModal: PropTypes.func,
  noPadding: PropTypes.bool,
  noBorder: PropTypes.bool,
  background: PropTypes.string,
  children: PropTypes.object,
  collapsed: PropTypes.bool,
  hideToggleContent: PropTypes.bool,
};

export default WidgetDefault;
