import * as constants from '../constants';
import { ssoLogin as redirectToSAML } from '../../services/clientAPI/auth.service';

export const login = payload => ({
  type: constants.LOGIN_STARTED,
  payload,
});

export const ssoLogin = () => {
  redirectToSAML();
  return {
    type: constants.SSO_LOGIN_STARTED,
  };
};

export const logout = payload => ({
  type: constants.LOGOUT_STARTED,
  payload,
});

export const loadValidSession = () => ({
  type: constants.LOAD_VALID_SESSION,
});
