import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Icons, AppHeader, Illustration, Illustrations } from '@alpima/picasso';
import { logout } from '../../../store/actions/auth.actions';
import { makeSelectAuthenticated, makeSelectUser } from '../../../store/selectors/auth.selector';
import LogoWrapper from '../LogoWrapper';
import { PopoverState, User } from './Navbar.types';
import UsernamePopover from './UsernamePopover';
import AppsPopover from './AppsPopover';

const NavBar = () => {
  const isAuthenticated = useSelector(makeSelectAuthenticated);
  const user: User = useSelector(makeSelectUser);
  const dispatch = useDispatch();
  const handleLogout = () => dispatch(logout());

  const [popoverState, setPopoverState] = useState<PopoverState>('NeitherOpen');
  const hidePopover = () => setPopoverState('NeitherOpen');

  const UserSection = () => (
    <div className="flex items-center">
      <button
        type="button"
        onClick={() =>
          setPopoverState(state => (state !== 'AppsOpen' ? 'AppsOpen' : 'NeitherOpen'))
        }
        className="w-12 h-12 mr-5 flex flex-row justify-end items-center bg-white border-0 cursor-pointer ml-3 lg:ml-8 lg:mr-5 xl:mx-12"
      >
        <Illustration name={Illustrations.APPS} />
      </button>
      <button
        type="button"
        onClick={() =>
          setPopoverState(state => (state !== 'UserOpen' ? 'UserOpen' : 'NeitherOpen'))
        }
        className="flex flex-row justify-end items-center bg-white border-0"
      >
        <p className="mb-0 ml-4 mr-2 text-gray-dark font-semibold cursor-pointer whitespace-nowrap">
          {user?.displayName}
        </p>
        <Icon name={Icons.DROPDOWN} className="ml-1" />
      </button>
      {popoverState === 'UserOpen' && (
        <UsernamePopover user={user} hidePopover={hidePopover} handleLogout={handleLogout} />
      )}
      {popoverState === 'AppsOpen' && <AppsPopover hidePopover={hidePopover} />}
    </div>
  );

  return (
    <AppHeader
      logo={<LogoWrapper />}
      searchbar={null}
      actions={isAuthenticated && <UserSection />}
    />
  );
};

export default NavBar;
