/* eslint-disable import/prefer-default-export */
export const filter = {
  config: {
    identifier: 'pl',
    name: 'Product Lines',
  },
  defaults: {
    MAETFDS: true,
    GLOBMAC: true,
    MASTAND: true,
  },
  filters: [
    {
      key: 'MAETFDS',
      text: 'Multi-Asset - ETFs',
      value: 'MAETFDS',
    },
    {
      key: 'GLOBMAC',
      text: 'Global Multi Asset Class',
      value: 'GLOBMAC',
    },
    {
      key: 'MASTAND',
      text: 'Multi-Asset',
      value: 'MASTAND',
    },
  ],
};
