/* eslint import/no-dynamic-require: 0 */
/* eslint @typescript-eslint/no-var-requires: 0 */
/* eslint global-require: 0 */
/* eslint no-console: 0 */
import getDynamicUrlThemeSelection from '../config/themesConfig';
import defaultRenderConfig from '../config/client/default.json';

const client = getDynamicUrlThemeSelection();

let renderConfig = defaultRenderConfig;

try {
  const clientRenderConfig = require(`../config/client/${client}.json`);
  renderConfig = { ...defaultRenderConfig, ...clientRenderConfig };
} catch (err) {
  console.log(`No client config found for subdomain ${client}`, err);
}

const exportedConfig = renderConfig;
export default exportedConfig;
