import { fromJS } from 'immutable';

import * as constants from '../constants';

const initialState = fromJS({
  loading: false,
  error: null,
  lastRefresh: -1,
  lastRequest: -1,
  isExporting: false,
  data: null,
  list: [],
  reference: {},
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_REFERENCE_DATA_LIST:
      return state.set('loading', true);
    case constants.GET_REFERENCE_DATA_LIST_SUCCESS:
      return state.merge({
        list: fromJS(action.payload),
        lastRefresh: new Date().getTime(),
        loading: false,
        error: null,
      });
    case constants.GET_REFERENCE_DATA_LIST_FAILURE:
      return state.merge({
        loading: false,
        lastRefresh: new Date().getTime(),
        error: action.payload ? action.payload.error : null,
      });

    case constants.REFERENCE_DATA_EXPORT_REQUEST:
      return state.set('isExporting', true);
    case constants.REFERENCE_DATA_EXPORT_SUCCESS:
      return state.merge({
        data: fromJS(action.payload),
        lastRequest: new Date().getTime(),
        error: null,
        isExporting: false,
      });
    case constants.REFERENCE_DATA_EXPORT_FAILURE:
      return state.merge({
        data: null,
        lastRequest: -1,
        isExporting: false,
        error: action.payload ? action.payload.error : null,
      });
    case constants.REFERENCE_DATA_EXPORT_CLEAR:
      return state.merge(initialState);

    case constants.GET_REFERENCE_CURRENCIES:
    case constants.GET_REFERENCE_PRODUCT_LINES:
      return state;
    case constants.GET_REFERENCE_CURRENCIES_SUCCESS:
    case constants.GET_REFERENCE_PRODUCT_LINES_SUCCESS:
      return state.merge({
        reference: fromJS({
          ...state.get('reference').toJS(),
          [action.reference]: action.payload,
        }),
        error: null,
      });
    case constants.GET_REFERENCE_CURRENCIES_FAILURE:
    case constants.GET_REFERENCE_PRODUCT_LINES_FAILURE:
      return state.merge({
        error: action.payload ? action.payload.error : null,
      });

    case constants.CLEAR_SESSION:
    case constants.LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};
