import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const DashboardCardWrapper = styled.div`
  padding: 15px;
`;
export default function DashboardCard({ title, children, ...other }) {
  return (
    <DashboardCardWrapper {...other}>
      <h2>{title}</h2>
      <hr />
      {children}
    </DashboardCardWrapper>
  );
}

DashboardCard.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
};

DashboardCard.defaultProps = {
  children: null,
};
