/* eslint-disable no-console */

import React from 'react';
import { render, fireEvent } from '@testing-library/react';

import { ThemeProvider } from 'styled-components';

import TimeFilter from '../index';

const theme = {
  variables: {
    pageTopFooter: '#000',
    breakpoints: {
      lg: 1200,
    },
  },
};
const stateStart = new Date().setDate(-3);
const stateEnd = new Date();
const defaultState = {
  start: stateStart,
  end: stateEnd,
  selection: 3,
};
const defaultOptionMap = ['1H', 'Today', '24H', '3 Days', 'All', 'Custom'];

const renderComponent = props =>
  render(
    <ThemeProvider theme={theme}>
      <TimeFilter state={{ ...defaultState }} setFilterState={props.setFilterState} />
    </ThemeProvider>,
  );

describe.skip('Unit: [RTL] Time Filter', () => {
  let timeFilter;
  let globalConsoleError;

  let consoleErrorSpy;
  let setFilterStateSpy;

  beforeEach(() => {
    // spies
    consoleErrorSpy = jest.fn();
    setFilterStateSpy = jest.fn();

    // Override console error with spy
    globalConsoleError = console.error;
    console.error = consoleErrorSpy;

    timeFilter = renderComponent({
      setFilterState: setFilterStateSpy,
    });
  });

  afterEach(() => {
    // Reset console.error to global
    console.error = globalConsoleError;
  });

  it('time filter to render correctly', () => {
    expect(consoleErrorSpy).not.toBeCalled();
    expect(timeFilter.container.getElementsByClassName('button')).toHaveLength(6);
  });

  it('time filter has correct defaults set', () => {
    const options = timeFilter.container.querySelectorAll('.ui.button');
    options.forEach((opt, index) => expect(opt.textContent).toEqual(defaultOptionMap[index]));
  });

  it('time filter calls setFilterState when clicking a button', () => {
    const button = timeFilter.container.querySelectorAll('.ui.button')[1]; // Today option
    fireEvent.click(button, {});

    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).toISOString();
    const end = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59,
    ).toISOString();

    expect(setFilterStateSpy).toBeCalledTimes(1);
    expect(setFilterStateSpy).toBeCalledWith(start, end, 1);
  });

  it('time filter shows datepicker when custom option selected', () => {
    const buttons = timeFilter.container.querySelectorAll('.ui.button');
    const customButton = buttons[buttons.length - 1];
    fireEvent.click(customButton, {});

    const datePickers = timeFilter.container.querySelectorAll('.datePickers > div');
    expect(datePickers).toHaveLength(2);
    expect(setFilterStateSpy).toBeCalledTimes(0);

    fireEvent.click(customButton, {});
    const datePickersAfterClose = timeFilter.container.querySelectorAll('.datePickers > div');
    expect(datePickersAfterClose).toHaveLength(0);
    expect(setFilterStateSpy).toBeCalledTimes(1);
  });
});
