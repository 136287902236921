/* eslint-disable import/prefer-default-export */
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, catchError } from 'rxjs/operators';

import {
  fetchCurrencies,
  fetchProductLines,
  fetchReferenceDataList,
  performReferenceDataCSVExport,
} from '../../services/clientAPI/reference.service';

import * as constants from '../constants';

const handleError = type => error =>
  of({
    type,
    error: error.message,
  });

export const getReferenceDataListEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_REFERENCE_DATA_LIST),
    mergeMap(() =>
      fetchReferenceDataList().pipe(
        mergeMap(response => [
          {
            type: constants.GET_REFERENCE_DATA_LIST_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.GET_REFERENCE_DATA_LIST_FAILURE)),
      ),
    ),
    catchError(handleError(constants.GET_REFERENCE_DATA_LIST_FAILURE)),
  );

export const getReferenceDataExportEpic = actions$ =>
  actions$.pipe(
    ofType(constants.REFERENCE_DATA_EXPORT_REQUEST),
    mergeMap(action =>
      performReferenceDataCSVExport(action.json).pipe(
        mergeMap(response => [
          {
            type: constants.REFERENCE_DATA_EXPORT_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.REFERENCE_DATA_EXPORT_FAILURE)),
      ),
    ),
    catchError(handleError(constants.REFERENCE_DATA_EXPORT_FAILURE)),
  );

export const getReferenceDataProductLinesEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_REFERENCE_PRODUCT_LINES),
    mergeMap(action =>
      fetchProductLines(action.json).pipe(
        mergeMap(response => [
          {
            type: constants.GET_REFERENCE_PRODUCT_LINES_SUCCESS,
            payload: response,
            reference: 'productLines',
          },
        ]),
        catchError(handleError(constants.GET_REFERENCE_PRODUCT_LINES_FAILURE)),
      ),
    ),
    catchError(handleError(constants.GET_REFERENCE_PRODUCT_LINES_FAILURE)),
  );

export const getReferenceDataCurrenciesEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_REFERENCE_CURRENCIES),
    mergeMap(action =>
      fetchCurrencies(action.json).pipe(
        mergeMap(response => [
          {
            type: constants.GET_REFERENCE_CURRENCIES_SUCCESS,
            payload: response,
            reference: 'currencies',
          },
        ]),
        catchError(handleError(constants.GET_REFERENCE_CURRENCIES_FAILURE)),
      ),
    ),
    catchError(handleError(constants.GET_REFERENCE_CURRENCIES_FAILURE)),
  );
