import { UserGroup, User, UserGroupSearchProps } from './DistributionGroups.types';

const getTableSortableHeaders = () => [
  {
    name: 'Name',
    property: 'name',
    enabled: true,
  },
  {
    name: 'Members',
    property: 'users',
    enabled: true,
  },
  {
    name: 'Created',
    property: 'created',
    enabled: true,
  },
  {
    name: 'Action',
    property: 'action',
    enabled: true,
  },
];

export const userGroupsSearch = (userGroups: UserGroup[], searchTerm: string) => {
  const flattenedSearchElements = userGroups.map((element: UserGroup) => ({
    ...element,
    searchProperties: {
      name: element.name,
      users: element.users.map((user: User) => `${user.firstName} ${user.lastName}`).join(' '),
      created: `${element.created.firstName} ${element.created.lastName}`,
    },
  }));

  const search = (arr: UserGroupSearchProps[], str: string) =>
    arr.filter((x: UserGroupSearchProps) => {
      if (x.searchProperties) {
        return Object.values(x.searchProperties)
          .join(' ')
          .toLowerCase()
          .includes(str.toLowerCase());
      }
      return true;
    });
  const filtered: UserGroupSearchProps[] = search(flattenedSearchElements, searchTerm);

  return filtered.map((element: UserGroupSearchProps) => {
    delete element.searchProperties;
    return element;
  });
};

export default getTableSortableHeaders;
