import React from 'react';
import { storiesOf } from '@storybook/react';

import TableDefault from './index';

const defaultProps = {
  data: [],
  name: 'funds',
};

storiesOf('TableDefault', module).add('default', () => <TableDefault {...defaultProps} />);
