import { baseUrl } from './clientAPI.config';
import { apiCall$ } from './lib/apiCall';

export const getStrategiesDashboardMonitoring$ = (timeZoneOffset: number) => {
  return apiCall$(
    `${baseUrl}/strategies-monitoring/dashboard?offset=${timeZoneOffset}`,
  );
};

export const getStrategiesAlertsMonitoring$ = (date: Date, timeZoneOffset: number) => {
  return apiCall$(
    `${baseUrl}/strategies-monitoring/alerts?date=${new Intl.DateTimeFormat('en-GB').format(
      date,
    )}&offset=${timeZoneOffset}`,
  );
};
