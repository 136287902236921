import React from 'react';
import { storiesOf } from '@storybook/react';
import StoryRouter from 'storybook-react-router';

import AppWrapper from './index';
import { NavBar } from '../../molecules/NavBar';

const defaultProps = {
  showThemeSelector: false,
  theme: 'Alpima',
  isAuthenticated: true,
  user: {
    firstName: 'Joe',
    lastName: 'Doe',
  },
  handleLogout: () => {},
  onSelectTheme: () => {},
  links: [
    {
      linkText: 'Founds',
      url: '/founds',
    },
    {
      linkText: 'MAC',
      url: '/mac',
    },
    {
      linkText: 'My Founds',
      url: '/my-founds',
    },
  ],
};

storiesOf('AppWrapper', module)
  .addDecorator(StoryRouter())
  .add('default', () => <AppWrapper showBackground />)
  .add('with navbar', () => (
    <AppWrapper showBackground>
      <NavBar menuFixed {...defaultProps} />
    </AppWrapper>
  ));
