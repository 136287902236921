import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { fetchLatestSplunkTimestamps } from '../../../store/actions/monitoring.actions';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import {
  selectLoadingSplunkTimestamps,
  selectSplunkTimestamps,
} from '../../../store/selectors/monitoring.selector';

const SplunkTimestamps = styled.div`
  padding: 15px;
`;

const SplunkTimestampWidget = ({ loading, fetchSplunkTimestamps, timestamps }) => {
  useEffect(() => {
    fetchSplunkTimestamps();
  }, [fetchSplunkTimestamps]);

  const _timestamps = [];
  if (
    timestamps &&
    timestamps.length &&
    timestamps[0].timestampBalSal &&
    timestamps[0].timestampBalSal.length
  ) {
    _timestamps.push(
      <p>
        BAL/SAL:{' '}
        <b>{moment(timestamps[0].timestampBalSal[0].started).format('DD-MM-YYYY HH:mm:ss')}</b>
      </p>,
    );
  }
  if (
    timestamps &&
    timestamps.length &&
    timestamps[0].timestampException &&
    timestamps[0].timestampException.length
  ) {
    _timestamps.push(
      <p>
        Exception:{' '}
        <b>{moment(timestamps[0].timestampException[0].started).format('DD-MM-YYYY HH:mm:ss')}</b>
      </p>,
    );
  }

  return (
    <LoadingBoundary isLoading={loading}>
      <SplunkTimestamps className="dashboard-splunk-timestamps" style={{ minHeight: '125px' }}>
        <h2>Latest Splunk Exports:</h2>
        <hr />
        {_timestamps}
      </SplunkTimestamps>
    </LoadingBoundary>
  );
};

SplunkTimestampWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  timestamps: PropTypes.object.isRequired,
  fetchSplunkTimestamps: PropTypes.func.isRequired,
};

SplunkTimestampWidget.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  loading: state => selectLoadingSplunkTimestamps(state),
  timestamps: state => selectSplunkTimestamps(state),
});

const mapDispatchToProps = dispatch => ({
  fetchSplunkTimestamps: () => dispatch(fetchLatestSplunkTimestamps()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SplunkTimestampWidget);
