import { iif, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, mergeMap, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import {
  fetchUserList,
  fetchUserDetails,
  fetchOnlineUsers,
  putUserEnabledState,
  putUserUpdatedState,
  fetchPendingUsers,
  setUserOnboardingState,
  fetchUsersWithUpdatedContractRoles,
  setUserUpdatedContractRole,
  setUserObjectRoles,
  getUserObjectRoles,
  performUserCsvExport,
} from '../../services/clientAPI/user.service';
import { fetchFunctionalRolesList } from '../../services/clientAPI/roles.service';

import * as constants from '../constants';
import { makeSelectCurrentTheme } from '../selectors/themes.selector';

const handleListError = error =>
  of({
    type: constants.GET_USERS_LIST_FAILURE,
    error: error.message,
  });
const handleDetailsError = error =>
  of({
    type: constants.GET_USER_DETAILS_FAILURE,
    error: error.message,
  });
const handleUserUpdateError = error =>
  of({
    type: constants.SET_USER_DETAILS_FAILURE,
    error: error.message,
  });
const handleOnlineUsersError = error =>
  of({
    type: constants.GET_ONLINE_USERS_FAILURE,
    error: error.message,
  });
const handlePendingUsersError = error =>
  of({
    type: constants.GET_PENDING_USERS_FAILURE,
    error: error.message,
  });
const handleUserOnboardingStateError = error =>
  of({
    type: constants.SET_USER_ONBOARDING_STATE_FAILURE,
    error: error.message,
  });
const handleContractRoleUsersUsersError = error =>
  of({
    type: constants.GET_UPDATED_CONTRACT_ROLE_USERS_FAILURE,
    error: error.message,
  });
const handleContractRoleUsersUsersUpdateError = error =>
  of({
    type: constants.SET_USER_NEW_CONTRACT_ROLE_FAILURE,
    error: error.message,
  });

export const getUserListEpic = (actions$, state$) =>
  actions$.pipe(
    ofType(constants.GET_USERS_LIST),
    switchMap(roleList =>
      fetchFunctionalRolesList().pipe(
        map(functionalRoleList => ({
          ...roleList.roles,
          functionRoles: functionalRoleList.map(r => r.role),
        })),
      ),
    ),
    withLatestFrom(state$),
    mergeMap(([roles, state]) =>
      iif(
        () => state.getIn(['auth', 'user', 'company']) || false,
        (() => {
          const companyId = state.getIn(['auth', 'user', 'company']) || '';
          return fetchUserList(companyId);
        })().pipe(
          mergeMap(response => [
            {
              type: constants.GET_USERS_LIST_SUCCESS,
              payload: response,
            },
            {
              type: constants.GET_ROLES_LIST_SUCCESS,
              payload: roles,
            },
            {
              type: constants.GET_ONLINE_USERS,
            },
          ]),
          catchError(handleListError),
        ),
        of({ type: constants.GET_ONLINE_USERS_CANCELLED }),
      ),
    ),
    catchError(handleOnlineUsersError),
  );

export const getUserDetailsEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_USER_DETAILS),
    mergeMap(action =>
      fetchUserDetails(action.id).pipe(
        mergeMap(response =>
          getUserObjectRoles(action.id).pipe(
            map(objectRoles => ({ ...response, objectRoles })),
            mergeMap(user => [
              {
                type: constants.GET_USER_DETAILS_SUCCESS,
                payload: user,
              },
              {
                type: constants.GET_ONLINE_USERS,
              },
              {
                type: constants.GET_COMPANIES_LIST,
              },
              {
                type: constants.GET_ROLES_LIST,
              },
            ]),
            catchError(handleDetailsError),
          ),
        ),
      ),
    ),
    catchError(handleDetailsError),
  );

export const getOnlineUsersEpic = (action$, state$) =>
  action$.pipe(
    ofType(constants.GET_ONLINE_USERS),
    withLatestFrom(state$),
    map(([, state]) => state),
    map(makeSelectCurrentTheme),
    switchMap(fetchOnlineUsers),
    map(response => ({
      type: constants.GET_ONLINE_USERS_SUCCESS,
      payload: response,
    })),
    catchError(handleOnlineUsersError),
  );

export const getPendingUsersEpic = (action$, state$) =>
  action$.pipe(
    ofType(constants.GET_PENDING_USERS),
    withLatestFrom(state$),
    mergeMap(([, state]) =>
      iif(
        () => {
          // cancel/debounce if within 60s
          const s = state.getIn(['user', 'pending']);
          return (
            (s && s.lastRefresh && s.lastRefresh < new Date().getTime() - 30000) || !s.lastRefresh
          );
        },
        fetchPendingUsers().pipe(
          map(response => ({
            type: constants.GET_PENDING_USERS_SUCCESS,
            payload: response,
          })),
          catchError(handlePendingUsersError),
        ),
        of({ type: constants.GET_PENDING_USERS_CANCELLED }),
      ),
    ),
    catchError(handlePendingUsersError),
  );

export const getUpdatedUserContractRolesEpic = action$ =>
  action$.pipe(
    ofType(constants.GET_UPDATED_CONTRACT_ROLE_USERS),
    mergeMap(() =>
      fetchUsersWithUpdatedContractRoles().pipe(
        map(response => ({
          type: constants.GET_UPDATED_CONTRACT_ROLE_USERS_SUCCESS,
          payload: response,
        })),
        catchError(handleContractRoleUsersUsersError),
      ),
    ),
    catchError(handleContractRoleUsersUsersError),
  );

const redirectToUserProfileIfApproved = (response, approve, history = []) => {
  if (response && approve) {
    if (response && response.length > 1) {
      history.push(`/user/${response[0]._id}`);
    }
  }
  return response;
};

export const setUserOnboardingStateEpic = action$ =>
  action$.pipe(
    ofType(constants.SET_USER_ONBOARDING_STATE),
    mergeMap(action =>
      setUserOnboardingState(action.id, action.approve).pipe(
        map(response => redirectToUserProfileIfApproved(response, action.approve, action.history)),
        mergeMap(response => [
          {
            type: constants.SET_USER_ONBOARDING_STATE_SUCCESS,
            payload: response,
          },
          {
            type: constants.GET_PENDING_USERS,
          },
        ]),
        catchError(handleUserOnboardingStateError),
      ),
    ),
    catchError(handleUserOnboardingStateError),
  );

export const setNewContractRoleForUserEpic = action$ =>
  action$.pipe(
    ofType(constants.SET_USER_NEW_CONTRACT_ROLE),
    mergeMap(action =>
      setUserUpdatedContractRole(action.id, action.approve).pipe(
        map(response => redirectToUserProfileIfApproved(response, action.approve, action.history)),
        mergeMap(response => [
          {
            type: constants.SET_USER_NEW_CONTRACT_ROLE_SUCCESS,
            payload: response,
          },
          {
            type: constants.GET_UPDATED_CONTRACT_ROLE_USERS,
          },
        ]),
        catchError(handleContractRoleUsersUsersUpdateError),
      ),
    ),
    catchError(handleContractRoleUsersUsersUpdateError),
  );

const isEnableDisableUserRequest = state => () => {
  const active = state.getIn(['user', 'details', 'active']).toJS();
  const cache = state.getIn(['user', 'details', 'cache']).toJS();
  return active && cache && active.enabled !== cache.enabled;
};
const handleUserUpdateSuccess = state =>
  mergeMap(response => [
    {
      type: constants.SET_USER_DETAILS_SUCCESS,
      payload: response,
    },
    {
      type: constants.GET_USER_DETAILS,
      id: state._id,
    },
  ]);

const updateUserEnabledState = state => {
  const active = state.getIn(['user', 'details', 'active']).toJS();
  return putUserEnabledState(active._id, active.enabled).pipe(
    handleUserUpdateSuccess(active),
    catchError(handleUserUpdateError),
  );
};
const updateUserDetails = state => {
  const active = state.getIn(['user', 'details', 'active']).toJS();
  const payload = {
    firstName: active.firstName,
    lastName: active.lastName,
    email: active.email,
    contactPhoneNumber: active.contactPhoneNumber,
    bjbLocation: active.bjbLocation,
    functionRoles: active.functionRoles,
    companyRoles: active.companyRoles,
    featureRoles: active.featureRoles,
  };
  return putUserUpdatedState(active._id, payload).pipe(
    handleUserUpdateSuccess(active),
    catchError(handleUserUpdateError),
  );
};

export const requestUserDetailsUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(constants.REQUEST_USER_DETAILS_UPDATE),
    withLatestFrom(state$),
    mergeMap(([, state]) =>
      iif(
        isEnableDisableUserRequest(state),
        updateUserEnabledState(state),
        of({ type: constants.SET_USER_DETAILS }),
      ),
    ),
    catchError(handleUserUpdateError),
  );

export const setUserDetailsEpic = (action$, state$) =>
  action$.pipe(
    ofType(constants.SET_USER_DETAILS),
    withLatestFrom(state$),
    mergeMap(([, state]) => updateUserDetails(state)),
    catchError(handleUserUpdateError),
  );

export const updateUserObjectRoles = (action$, state$) =>
  action$.pipe(
    ofType(constants.SET_USER_DETAILS),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const activeUser = state.getIn(['user', 'details', 'active']).toJS();
      const { objectRoles } = activeUser;
      return iif(
        () => objectRoles != null,
        setUserObjectRoles(activeUser._id, objectRoles).pipe(
          map(() => ({ type: constants.SET_OBJECT_ROLES_SUCCESS, payload: objectRoles })),
          catchError(error =>
            of({ type: constants.SET_OBJECT_ROLES_FAILURE, error: error.message }),
          ),
        ),
      );
    }),
  );

export const getUsersExportEpic = actions$ =>
  actions$.pipe(
    ofType(constants.USERS_EXPORT_REQUEST),
    mergeMap(action =>
      performUserCsvExport(action.payload).pipe(
        mergeMap(response => [
          {
            type: constants.USERS_EXPORT_REQUEST_SUCCESS,
            payload: response,
          },
        ]),
        catchError(error =>
          of({ type: constants.USERS_EXPORT_REQUEST_FAILURE, error: error.message }),
        ),
      ),
    ),
    catchError(error => of({ type: constants.USERS_EXPORT_REQUEST_FAILURE, error: error.message })),
  );
