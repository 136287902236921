import React, { useEffect, useMemo, useState, ChangeEvent } from 'react';
import {
  Layout,
  PageHeader,
  Back,
  Section,
  Title,
  TextInput,
  TextInputType,
  TextInputSize,
  Button,
  ButtonStates,
} from '@alpima/picasso';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import TransferList from '../../../../molecules/List/TransferList';
import useTriggeredService from '../../../../../helpers/useTriggeredService';

import {
  updateUserGroup,
  fetchAllCompanyUsers,
  getUserGroup,
} from '../../../../../services/clientAPI/distributionGroups.service';
import { makeSelectUser } from '../../../../../store/selectors/auth.selector';
import { ListAsset } from '../DistributionGroups.types';

const EditDistributionGroups = () => {
  const currentUser = useSelector(makeSelectUser);
  const [groupName, setGroupName] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [leftUniverse, setLeftUniverse] = useState<ListAsset[] | []>([]);
  const [rightUniverse, setRightUniverse] = useState<ListAsset[] | []>([]);
  const [selectedLeftAssets, setSelectedLeftAssets] = useState<ListAsset[] | []>([]);
  const [selectedRightAssets, setSelectedRightAssets] = useState<ListAsset[] | []>([]);
  const [getUserGroupLoading, getUserGroupError, getUserGroupResponse, getUserGroupTrigger] =
    useTriggeredService(getUserGroup);

  const [, updateUserGroupError, updateUserGroupResponse, updateUserGroupTrigger] =
    useTriggeredService(updateUserGroup);

  const [loadingAllUsers, errorAllUsers, allCompanyUsers, fetchAllCompanyUsersTrigger] =
    useTriggeredService<ListAsset[]>(fetchAllCompanyUsers);

  useEffect(() => {
    if (currentUser) {
      fetchAllCompanyUsersTrigger(currentUser.company);
    }
  }, [currentUser, fetchAllCompanyUsersTrigger]);

  useEffect(() => {
    if (allCompanyUsers && getUserGroupResponse) {
      const groupUsers = getUserGroupResponse?.userGroup.users;
      const uniqueUsers: ListAsset[] = [];
      const enabledUsers = allCompanyUsers.filter(user => user.enabled);
      enabledUsers.forEach((user: ListAsset) => {
        const foundUser = groupUsers.find((u: ListAsset) => u._id === user._id);
        if (!foundUser) {
          uniqueUsers.push(user);
        }
      });
      setLeftUniverse(uniqueUsers);
    }
  }, [allCompanyUsers, getUserGroupResponse]);

  useEffect(() => {
    if (getUserGroupResponse) {
      const allGroupUsers = getUserGroupResponse.userGroup.users;
      const uniqueUsers: ListAsset[] = [];
      allGroupUsers.forEach((user: ListAsset) => {
        const foundUser = uniqueUsers.find((u: ListAsset) => u._id === user._id);
        if (!foundUser) {
          uniqueUsers.push(user);
        }
      });
      setRightUniverse(uniqueUsers);
      setGroupName(getUserGroupResponse.userGroup.name);
    }
  }, [getUserGroupResponse]);

  const filteredLeftUniverse = useMemo(() => {
    const searchedUserGroups = leftUniverse.filter((item: ListAsset) => {
      const joinNames = `${item.username} ${item.firstName} ${item.lastName}`;
      const formattedSearchName = joinNames.toLowerCase?.() || '';
      return formattedSearchName.includes(searchQuery.toLowerCase());
    });
    return searchedUserGroups;
  }, [leftUniverse, searchQuery]);

  useEffect(() => {
    const userGroupId = window.location.href.split('/').pop();
    if (userGroupId) {
      getUserGroupTrigger(userGroupId);
    }
  }, [getUserGroupTrigger]);

  useEffect(() => {
    if (errorAllUsers) {
      toast.error('Error loading users');
    }
  }, [errorAllUsers]);

  useEffect(() => {
    if (getUserGroupError) {
      toast.error('Error fetching Distribution Group');
    }
  }, [getUserGroupError]);

  useEffect(() => {
    if (updateUserGroupResponse) {
      toast.success('Successfully updated Distribution Group');
    }
  }, [updateUserGroupResponse]);

  useEffect(() => {
    if (updateUserGroupError) {
      toast.error('Error updating Distribution Group');
    }
  }, [updateUserGroupError]);

  const handleUpdateUserGroup = () => {
    const userGroupId = window.location.href.split('/').pop();
    updateUserGroupTrigger(userGroupId, {
      name: groupName,
      users: rightUniverse.map(user => user._id),
    });
  };

  return (
    <div className="min-h-screen">
      <Layout noMargins>
        <div className="w-full px-8 mb-2">
          <PageHeader>
            <Back />
            <Section>
              <Title>Edit Distribution Group</Title>
            </Section>
          </PageHeader>
        </div>
        <div className="flex flex-col w-10/12 mt-6">
          <div className="w-full bg-gray-100 flex flex-grow flex-col p-6">
            <textarea
              className="appearance-none text-black text-3xl border-none bg-gray-100 outline-none ring-0 placeholder-gray-500 w-full h-28 border"
              value={groupName}
              placeholder="Add Group name"
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                setGroupName(event.target.value)
              }
            />
          </div>
          <div className="w-full mt-10">
            <div className="float-left">
              <TextInput
                customSize={TextInputSize.Large}
                type={TextInputType.IconLeft}
                value={searchQuery}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchQuery(e.target.value)
                }
                className="w-96 outline-none float-left"
                placeholder="Search..."
              />
            </div>
            <Button
              disabled={!groupName.length || !rightUniverse.length}
              className="float-right"
              state={ButtonStates.Solid}
              onClick={handleUpdateUserGroup}
            >
              Update Group
            </Button>
          </div>
          <div className="mt-6">
            <TransferList<ListAsset>
              loading={loadingAllUsers || getUserGroupLoading}
              leftUniverse={{
                data: leftUniverse,
                setData: setLeftUniverse,
                selected: selectedLeftAssets,
                setSelected: setSelectedLeftAssets,
                filteredData: filteredLeftUniverse,
                title: 'All Users',
              }}
              rightUniverse={{
                data: rightUniverse,
                setData: setRightUniverse,
                selected: selectedRightAssets,
                setSelected: setSelectedRightAssets,
                title: 'Group Users',
              }}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default EditDistributionGroups;
