/* eslint-disable no-console */

import React from 'react';
import { render, fireEvent } from '@testing-library/react';
import { shallow, mount } from 'enzyme';

import { ThemeProvider } from 'styled-components';
import { Dropdown, Checkbox, Loader } from 'semantic-ui-react';

import FilterMenu from '../index';

const theme = {
  variables: {
    pageTopFooter: '#000',
  },
};
const defaults = {
  test1: true,
};
const options = [
  {
    key: 'test1',
    text: 'Test1',
    value: '0',
  },
  {
    key: 'test2',
    text: 'Test2',
    value: '1',
  },
];

const renderComponent = props =>
  render(
    <ThemeProvider theme={theme}>
      <FilterMenu
        config={{
          identifier: 'test',
          name: 'Test Menu',
        }}
        options={options}
        defaults={defaults}
        state={{ ...defaults }}
        setFilterState={props.setFilterState}
      />
    </ThemeProvider>,
  );

describe('Unit: [RTL] Filter Menu', () => {
  let filterMenu;
  let globalConsoleError;

  let consoleErrorSpy;
  let setFilterStateSpy;

  beforeEach(() => {
    // spies
    consoleErrorSpy = jest.fn();
    setFilterStateSpy = jest.fn();

    // Override console error with spy
    globalConsoleError = console.error;
    console.error = consoleErrorSpy;

    filterMenu = renderComponent({
      setFilterState: setFilterStateSpy,
    });
  });

  afterEach(() => {
    // Reset console.error to global
    console.error = globalConsoleError;
  });

  it('filter menu to render correctly', () => {
    expect(consoleErrorSpy).not.toBeCalled();
    expect(filterMenu.container.getElementsByClassName('checkbox')).toHaveLength(2);
  });

  it('filter menu has correct defaults set', () => {
    const checkbox1 = filterMenu.container.querySelectorAll('.ui.checkbox label')[0];
    const checkbox2 = filterMenu.container.querySelectorAll('.ui.checkbox label')[1];
    const checkbox1Checked = !!~checkbox1.parentElement.className.indexOf('checked');
    const checkbox2Checked = !!~checkbox2.parentElement.className.indexOf('checked');
    expect(checkbox1Checked).toBe(true);
    expect(checkbox2Checked).toBe(false);
  });

  it('filter menu calls setFilterState when clicking a checkbox', () => {
    // This sets up correct Aria attributes inside of semantic-ui component (annoyingly not already done)
    const labelElement = filterMenu.container.querySelectorAll('.ui.checkbox label')[1];
    labelElement.setAttribute('for', 'formControl-1');
    labelElement.previousElementSibling.setAttribute('id', 'formControl-1');

    const checkbox = filterMenu.getByLabelText('Test2');
    fireEvent.click(checkbox, options[1]);

    expect(setFilterStateSpy).toBeCalledTimes(1);
    expect(setFilterStateSpy).toBeCalledWith({ [options[0].key]: true, [options[1].key]: true });
  });
});
