import { createSelector } from 'reselect';
import { makeSelectCurrentTheme } from './themes.selector';

const selectMonitoringData = state => state.get('monitoring');

const selectLoginMonitoringDataFetching = createSelector(selectMonitoringData, data =>
  data.get('fetching'),
);
const selectLoginMonitoringDataLoading = createSelector(selectMonitoringData, data =>
  data.get('loading'),
);
const selectMonitoringTableLoading = createSelector(selectMonitoringData, data =>
  data.get('loadingTable'),
);
const selectLoadingSplunkTimestamps = createSelector(selectMonitoringData, data =>
  data.get('loadingSplunk'),
);

const selectLastRefresh = createSelector(selectMonitoringData, data => data.get('lastRefresh'));
const selectLastRefreshTable = createSelector(selectMonitoringData, data =>
  data.get('lastRefreshTable'),
);

const selectCacheTimestampChart = createSelector(selectMonitoringData, data =>
  data.get('cacheTimestampChart'),
);
const selectCacheTimestampTable = createSelector(selectMonitoringData, data =>
  data.get('cacheTimestampTable'),
);

const selectLoginMonitoringData = createSelector(selectMonitoringData, data => data.get('login'));

function getDefaultName(theme) {
  if (theme !== 'Natixis') return 'Other';
  return 'Natixis Users';
}

function addSeriesName(dataForType, theme) {
  return dataForType.map(data => ({
    ...data,
    name: data.name === 'other' ? getDefaultName(theme) : data.name,
  }));
}

const selectLoginsDataByRole = createSelector(
  selectMonitoringData,
  makeSelectCurrentTheme,
  (data, theme) => {
    const chartType = data.getIn(['filter', 'chartType']);
    const dataForType = chartType && data.getIn(['data', chartType])?.toJS();
    return dataForType ? addSeriesName(dataForType, theme) : [];
  },
);

const selectLoginDataTable = createSelector(
  selectMonitoringData,
  data => data.getIn(['loginTable', 'data']).toJS() || {},
);

const selectStrategyDataTable = createSelector(
  selectMonitoringData,
  data => data.getIn(['strategyTable', 'data']).toJS() || {},
);

const selectStrategyCreationSeries = createSelector(
  selectMonitoringData,
  data => data.getIn(['data', 'strategyCreation']).toJS() || [],
);

const selectMonitoringChartType = createSelector(
  selectMonitoringData,
  data => data.getIn(['filter', 'chartType']) || 'aggregate',
);
const selectMonitoringIncludeUnknowns = createSelector(
  selectMonitoringData,
  data => data.getIn(['filter', 'includeUnknowns']) || false,
);

const selectMonitoringFilter = createSelector(
  selectMonitoringData,
  data => data.get('filter').toJS() || {},
);

const selectSplunkTimestamps = createSelector(selectMonitoringData, data =>
  data.get('splunk').toJS(),
);

const selectBloombergImportStatus = createSelector(selectMonitoringData, data =>
  data.get('bloombergImportStatus').toJS(),
);

const selectBloombergImportStatusLoading = createSelector(selectMonitoringData, data =>
  data.get('bloombergImportStatusLoading'),
);

const selectBloombergImportStatusError = createSelector(selectMonitoringData, data =>
  data.get('bloombergImportStatusError'),
);

const selectOvernightCalculationsStatus = createSelector(selectMonitoringData, data =>
  data.get('overnightCalculationsStatus').toJS(),
);

const selectOvernightCalculationsStatusLoading = createSelector(selectMonitoringData, data =>
  data.get('loadingOvernightCalculationsStatus'),
);

const selectOvernightCalculationsStatusError = createSelector(selectMonitoringData, data =>
  data.get('overnightCalculationsStatusError'),
);

const selectICDecisionPreconditions = createSelector(selectMonitoringData, data => {
  const icDecisionPreconditions = data.get('icDecisionPreconditions');
  if (icDecisionPreconditions) {
    return icDecisionPreconditions.toJS();
  }
  return null;
});

export {
  selectMonitoringData,
  selectLoginMonitoringDataFetching,
  selectLoginMonitoringDataLoading,
  selectLoadingSplunkTimestamps,
  selectMonitoringTableLoading,
  selectLoginMonitoringData,
  selectLoginsDataByRole,
  selectLoginDataTable,
  selectStrategyCreationSeries,
  selectStrategyDataTable,
  selectMonitoringChartType,
  selectMonitoringIncludeUnknowns,
  selectMonitoringFilter,
  selectSplunkTimestamps,
  selectLastRefresh,
  selectLastRefreshTable,
  selectCacheTimestampChart,
  selectCacheTimestampTable,
  selectBloombergImportStatus,
  selectBloombergImportStatusLoading,
  selectBloombergImportStatusError,
  selectOvernightCalculationsStatus,
  selectOvernightCalculationsStatusLoading,
  selectOvernightCalculationsStatusError,
  selectICDecisionPreconditions,
};
