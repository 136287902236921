/* eslint-disable @typescript-eslint/no-unused-vars */
import useService from 'helpers/useService';
import useTriggeredService from 'helpers/useTriggeredService';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchLogFilterTypeContent } from 'services/clientAPI/log.service';
import { fetchNewUserList } from 'services/clientAPI/user.service';
import { makeSelectUser } from 'store/selectors/auth.selector';

type User = {
  firstName: string;
  lastName: string;
  _id: string;
  enabled: boolean;
};

export default function useRemoteFilterData() {
  const currentUser = useSelector(makeSelectUser);
  const userCompany = currentUser?.company;

  const [loadingTypes, errorTypes, rawTypes] =
    useService<{ key: string; value: string }[]>(fetchLogFilterTypeContent);
  const [loadingUsers, errorUsers, rawUsers, getUsers] =
    useTriggeredService<User[]>(fetchNewUserList);

  useEffect(() => {
    if (userCompany) getUsers(userCompany);
  }, [getUsers, userCompany]);

  const users = useMemo(
    () =>
      rawUsers
        ?.filter(a => a.enabled)
        .map(a => ({ ...a, displayName: `${a.firstName} ${a.lastName}` })),
    [rawUsers],
  );
  const types = useMemo(() => rawTypes, [rawTypes]);

  // Parse types list into filter options and remove the all option from raw data
  // Create initialSet for selected state
  const TYPE_OPTIONS = types
    ?.map((e: { key: string; value: string }) => ({
      title: e.value,
      id: e.key,
    }))
    .filter((e: { title: string; id: string }) => e.title !== 'All');
  const typeOptionsInitialSet = TYPE_OPTIONS?.map((e: { title: string; id: string }) => e.id);

  // Parse and sort alphabetically list of users
  type option = { title: string; id: string };
  const USER_OPTIONS = users
    ?.map((e: { _id: string; displayName: string }) => ({
      title: e.displayName,
      id: e._id,
    }))
    .sort((a: option, b: option) => {
      const c = a?.title?.toLowerCase();
      const d = b?.title?.toLowerCase();
      if (c > d) return 1;
      if (c < d) return -1;
      return 0;
    });
  const userOptionsInitialSet = USER_OPTIONS?.map((e: { title: string; id: string }) => e.id);

  const [typeFilters, setTypeFilters] = useState(new Set(typeOptionsInitialSet));
  const [userFilters, setUserFilters] = useState(new Set(userOptionsInitialSet));

  useEffect(() => {
    setTypeFilters(new Set(typeOptionsInitialSet));
    setUserFilters(new Set(userOptionsInitialSet));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types, users]);

  return {
    types: {
      options: TYPE_OPTIONS,
      initial: typeOptionsInitialSet,
      filters: typeFilters,
      setFilters: setTypeFilters,
    },
    users: {
      options: USER_OPTIONS,
      initial: userOptionsInitialSet,
      filters: userFilters,
      setFilters: setUserFilters,
    },
  };
}
