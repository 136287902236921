import { TimePeriods, Times } from './shared.types';

export function mapTimeToTimePeriod(time: Times): TimePeriods {
  if (time === 4 || time === 6 || time === 8 || time === 10) return `${time}am`;
  if (time === 12) return '12pm';
  if (time === 14) return '2pm';
  if (time === 16) return '4pm';
  if (time === 18) return '6pm';
  if (time === 20) return '8pm';
  return '8am';
  // throw new Error('API sent the wron value');
}

export const getUTCHours = (isoString: string) => new Date(isoString).getUTCHours();
