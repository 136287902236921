import React from 'react';

import { storiesOf } from '@storybook/react';
import StoryRouter from 'storybook-react-router';

import AppFooter from './index';

storiesOf('AppFooter', module)
  .addDecorator(StoryRouter())
  .add('default', () => <AppFooter />);
