import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getOvernightCalculationsStatus } from '../../../store/actions/monitoring.actions';
import { selectOvernightCalculationsStatus } from '../../../store/selectors/monitoring.selector';
import DashboardCard from '../../atoms/DashboardCard';
import StatusDate from '../../atoms/StatusDate';

function OvernightCalculationsWidget({
  // eslint-disable-next-line @typescript-eslint/no-shadow
  getOvernightCalculationsStatus,
  overnightCalculationsStatus,
}) {
  useEffect(() => {
    getOvernightCalculationsStatus();
  }, [getOvernightCalculationsStatus]);
  return (
    <DashboardCard title="Overnight Calculations Status">
      <div className="ui list">
        {overnightCalculationsStatus.map(({ date, success, failedStrategyIds }, index) => (
          <StatusDate key={index} date={date} success={success}>
            {failedStrategyIds ? failedStrategyIds.join(', ') : null}
          </StatusDate>
        ))}
      </div>
    </DashboardCard>
  );
}

OvernightCalculationsWidget.propTypes = {
  overnightCalculationsStatus: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      success: PropTypes.bool.isRequired,
      failedStrategyIds: PropTypes.array,
    }),
  ).isRequired,
  getOvernightCalculationsStatus: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  overnightCalculationsStatus: selectOvernightCalculationsStatus,
});

const mapDispatchToProps = {
  getOvernightCalculationsStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(OvernightCalculationsWidget);
