import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Accordion,
  Back,
  Button,
  ButtonTypes,
  FootNotes,
  Icons,
  Layout,
  Loader,
  PageHeader,
  Section,
  Title,
} from '@alpima/picasso';
import { getStrategiesDashboardMonitoring$ } from '../../../../../services/clientAPI/strategyAlerts.service';
import DataProvider from './DataProvider';
import AvailableStrategies from './AvailableStrategies';
import StrategyAlerts from './StrategyAlertsSection';
import useTriggeredService from '../../../../../helpers/useTriggeredService';
import { choices, capitalizeFirstLetter } from './helper';
import { StrategiesDashboardMonitoring } from './DataMonitoringDashboard.types';
import { parseDashboardData } from './parseData';

/**
 * Main monitoring page to show a live dashboard of strategy calculations.
 */
export default function StrategyCalculationDashboard() {
  const [refreshTime, setRefreshTime] = useState<number>(30_000);
  const [refreshLabel, setRefreshLabel] = useState<string>('Off');
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const now = new Date();
  const timeZoneOffset = now.getTimezoneOffset() * -1;

  const [loadingDashboard, errorDashboard, dataDashboard, refetchDashboard] =
    useTriggeredService<StrategiesDashboardMonitoring>(getStrategiesDashboardMonitoring$);
  const parsedDashboardData = useMemo(
    () => dataDashboard && parseDashboardData(dataDashboard),
    [dataDashboard],
  );

  const clearIntervalFn = () => {
    if (interval.current) clearInterval(interval.current);
  };

  const pingAndSetupInterval = useCallback(() => {
    clearIntervalFn();

    if (refreshLabel === 'Off') {
      refetchDashboard(timeZoneOffset);
    } else {
      interval.current = setInterval(() => {
        refetchDashboard(timeZoneOffset);
      }, refreshTime);
    }
  }, [refreshTime, refetchDashboard, timeZoneOffset]);

  const onChangeRefreshInterval = (choice: string) => {
    if (choice === 'Off') {
      setRefreshLabel('Off');
      clearIntervalFn();
    } else {
      setRefreshLabel(choice);
      const ms = choices[choice];
      setRefreshTime(ms);
    }
  };

  useEffect(() => {
    pingAndSetupInterval();
    return () => {
      clearIntervalFn();
    };
  }, [pingAndSetupInterval]);

  useEffect(() => {
    if (!dataDashboard) refetchDashboard(timeZoneOffset);
  }, [dataDashboard, refetchDashboard, timeZoneOffset]);

  if (loadingDashboard) {
    return (
      <div className="my-12 w-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (errorDashboard) {
    return (
      <FootNotes className="border-none py-24 text-center">
        Failed to fetch strategies monitoring data, please try again!
      </FootNotes>
    );
  }

  const mergedAlertsInfo = parsedDashboardData?.alertsByTime.map((item, i) =>
    Object.assign(
      {},
      item,
      parsedDashboardData?.availableStrategies.strategiesByTime[i] || {
        totalScheduledStrategies: 0,
      },
    ),
  );

  const headerSection = (
    <div className="w-full px-8 mb-6 border-b-1 border-gray-200">
      <PageHeader>
        <Back />
        <Section>
          <Title>Data Monitoring Dashboard</Title>
          <div className="flex flex-col">
            <Button
              type={ButtonTypes.IconLeft}
              icon={Icons.REFRESH}
              onClick={() => refetchDashboard(timeZoneOffset)}
              disabled={loadingDashboard}
              className={loadingDashboard ? 'cursor-not-allowed' : ''}
            >
              Refresh
            </Button>
            <label htmlFor="auto-refresh">
              Auto refresh:
              <select
                disabled={loadingDashboard}
                defaultValue={refreshLabel}
                id="auto-refresh"
                onInput={e => onChangeRefreshInterval((e.target as HTMLSelectElement).value)}
              >
                <option>Off</option>
                {Object.keys(choices).map(entry => (
                  <option key={entry}>{entry}</option>
                ))}
              </select>
            </label>
          </div>
        </Section>
      </PageHeader>
    </div>
  );

  return (
    <div className={`min-h-screen ${loadingDashboard ? 'opacity-50' : ''}`}>
      <Layout noMargins>
        {headerSection}

        <section id="data-providers" className="flex w-full gap-3 px-10">
          {parsedDashboardData?.providers &&
            Object.entries(parsedDashboardData.providers).map(([name, providerError]) => (
              <DataProvider
                key={name}
                name={capitalizeFirstLetter(name)}
                connected={providerError}
              />
            ))}
        </section>

        <div className="w-full px-6 mt-10">
          <Accordion title="Available Strategies">
            {parsedDashboardData ? (
              <AvailableStrategies
                availableStrategies={parsedDashboardData?.availableStrategies}
                liveStrategies={parsedDashboardData?.countedRunningAlerts}
              />
            ) : (
              'Loading...'
            )}
          </Accordion>
        </div>

        <div className="w-full px-6 mt-10">
          <Accordion title="Strategies Alerts">
            {!mergedAlertsInfo?.length ? (
              <FootNotes className="w-full text-center">No strategies alerts found</FootNotes>
            ) : (
              <StrategyAlerts alertsInfo={mergedAlertsInfo} />
            )}
          </Accordion>
        </div>
      </Layout>
    </div>
  );
}
