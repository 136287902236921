import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { Pagination, Search } from 'semantic-ui-react';
import styled from 'styled-components';
import renderThemeConfig from 'helpers/renderThemeConfig';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';
import {
  clearSearchResults as clearSearchResultsAction,
  getInstruments,
  setSearchResults as setSearchResultsAction,
} from '../../../store/actions/instruments.actions';
import {
  selectInstrumentList,
  selectInstrumentSearchList,
  selectInstrumentListPage,
  selectInstrumentLoading,
  selectInstrumentSearchResults,
  selectLastRefresh,
} from '../../../store/selectors/instruments.selector';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import TableDefault from '../../molecules/TableDefault';

const WidgetHeaderWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
`;

const TableOptionWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;

  @media screen and (min-width: ${props => props.theme.variables.breakpoints.md}px) {
    flex-direction: row;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1 0 auto;
  margin-bottom: 10px;
`;

const SearchBox = styled(Search)`
  width: 350px;
  & input {
    border-radius: 5px !important;
  }
  & .results.transition {
    width: 100%;
    display: none !important;
  }
`;
const ResultCount = styled.span`
  position: relative;
  top: 0;
  padding: 5px 15px;
  text-align: center;
  line-height: 25px;
`;

const ClearResults = styled.button`
  position: relative;
  top: 0;
  padding: 0 15px;
  background: transparent;
  border: none;
  height: 35px;
  border-left: solid 1px #666;
  color: #4c8ed6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
  &::after {
    content: 'Clear';
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0 0 auto;
  margin-bottom: 15px;

  & .csv-export {
    margin: 5px 15px;
    &:hover {
      & span {
        text-decoration: underline;
      }
    }

    & span {
      padding: 5px 10px;
    }
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;
  flex-direction: column;

  &.hidden {
    display: none;
  }
`;

const InstrumentsTableWidget = props => {
  const {
    theme,
    match,
    history,
    loading,
    lastRefresh,
    getInstrumentsList,
    instrumentList,
    instrumentSearchList,
    paginatedInstrumentList,
    paginatedSearchResults,
    setSortedInstruments,
    setSearchResults,
    clearSearchResults,
  } = props;
  const [search, setSearch] = useState({
    searching: false,
    value: '',
    results: null,
  });
  const { formatTime } = useDateTimeFormatter();

  useEffect(() => {
    getInstrumentsList();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [getInstrumentsList]);

  const handlePaginationChange = (e, { activePage }) =>
    history.push(match.path.replace(':page', activePage));

  const handleClearSearch = () => {
    setSearch({ searching: false, value: '', results: null });
    clearSearchResults();
  };

  const handleSearch = value => {
    if (value === '') {
      return handleClearSearch();
    }

    const re = new RegExp(_.escapeRegExp(value || ''), 'i');
    const isMatch = result =>
      re.test(result.name) ||
      re.test(result.idISIN) ||
      re.test(result.bbgticker) ||
      re.test(result.assetClassPrimary);
    const flattenedInstrumentList = instrumentList.reduce((a, b) => a.concat(b));
    const filteredResults = _.filter(flattenedInstrumentList, isMatch);

    setSearch({
      ...search,
      value,
      searching: false,
      results: filteredResults,
    });

    history.push(match.path.replace(':page', 1));

    return setSearchResults(filteredResults);
  };

  const handleSearchInput = (e, { value }) => {
    setSearch({
      ...search,
      value,
      searching: true,
    });

    handleSearch(value);
  };

  const paginationSearchPageCount = instrumentSearchList.length;
  const paginationInstrumentListPageCount = instrumentList.length;
  const paginationPageCount = (paginationSearchPageCount || paginationInstrumentListPageCount) || 0;
  const pageData = (paginationSearchPageCount ? paginatedSearchResults : paginatedInstrumentList) || [];

  return (
    <LoadingBoundary isLoading={loading}>
      <div style={{ minHeight: '850px', overflowX: 'scroll', overflowY: 'hidden' }}>
        <WidgetHeaderWrapper theme={theme}>
          <TableOptionWrapper>
            <SearchWrapper>
              <SearchBox
                loading={search.searching}
                onSearchChange={handleSearchInput}
                placeholder="Search"
                value={search.value}
                input={{
                  style: {
                    width: '100%',
                  },
                }}
              />
              {search.results ? (
                <Fragment>
                  <ResultCount>Results: {search.results.length}</ResultCount>
                  <ClearResults onClick={handleClearSearch} />
                </Fragment>
              ) : null}
            </SearchWrapper>
            <OptionsWrapper>
              <PaginationWrapper className={paginationPageCount < 2 ? 'hidden' : ''}>
                <Pagination
                  boundaryRange={0}
                  siblingRange={1}
                  totalPages={paginationPageCount}
                  activePage={match.params.page}
                  onPageChange={handlePaginationChange}
                />
                <sub style={{ marginTop: 14, fontSize: '1rem', textAlign: 'right' }}>
                  Last refresh: {formatTime(lastRefresh)}
                </sub>
              </PaginationWrapper>
            </OptionsWrapper>
          </TableOptionWrapper>
        </WidgetHeaderWrapper>
        <TableDefault
          striped
          selectable
          name="Instrument List"
          data={pageData}
          fullData={instrumentList.reduce((a, b) => a.concat(b), []) || []}
          onClickLink={instrument => {
            history.push(`/instruments/${instrument._id}`);
          }}
          onSort={setSortedInstruments}
          columns={renderThemeConfig.default.instrumentColumns}
        />
      </div>
    </LoadingBoundary>
  );
};

InstrumentsTableWidget.propTypes = {
  theme: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  lastRefresh: PropTypes.number.isRequired,
  getInstrumentsList: PropTypes.func.isRequired,
  instrumentList: PropTypes.object.isRequired,
  instrumentSearchList: PropTypes.object.isRequired,
  paginatedInstrumentList: PropTypes.array,
  paginatedSearchResults: PropTypes.array,
  setSortedInstruments: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
  clearSearchResults: PropTypes.func.isRequired,
};

InstrumentsTableWidget.defaultProps = {
  theme: {},
  paginatedInstrumentList: [],
  paginatedSearchResults: [],
};

const mapStateToProps = createStructuredSelector({
  instrumentList: state => selectInstrumentList(state),
  instrumentSearchList: state => selectInstrumentSearchList(state),
  paginatedInstrumentList: state => selectInstrumentListPage(state), 
  paginatedSearchResults: state => selectInstrumentSearchResults(state),
  loading: state => selectInstrumentLoading(state),
  lastRefresh: state => selectLastRefresh(state),
});

const mapDispatchToProps = dispatch => ({
  getInstrumentsList: () => dispatch(getInstruments()),
  setSearchResults: results => dispatch(setSearchResultsAction(results)),
  clearSearchResults: () => dispatch(clearSearchResultsAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InstrumentsTableWidget));
