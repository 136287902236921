import Cookies from 'js-cookie';

/**
 * Retrieve a refresh token robustly, either from the initial
 * state in the URL searchbar, or from cookies if it's already
 * been set.
 */
export function getRefreshToken(): string | null {
  const search = window.location.search;
  if (!search) return Cookies.get('cognito-refresh-token') || null;
  const token = new URLSearchParams(search).get('token');
  if (token) {
    Cookies.set('cognito-refresh-token', token);
  }
  return token;
}
