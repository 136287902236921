import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { getICDecisionPreconditions } from '../../../store/actions/monitoring.actions';
import { selectICDecisionPreconditions } from '../../../store/selectors/monitoring.selector';
import DashboardCard from '../../atoms/DashboardCard';

const Icon = styled.i`
  margin-right: 8px;
`;

function ICDecisionPreconditionsBody({ icDecisionPreconditions }) {
  const isICDecisionAvailable = icDecisionPreconditions
    ? icDecisionPreconditions.isICDecisionAvailable
    : false;
  const className = isICDecisionAvailable ? 'ui green message' : 'ui red message';
  const errorsExist = icDecisionPreconditions?.errors?.length > 0;

  // This shouldn't ever be displayed as if ic decision isn't available there should be error messages to display.
  const fallbackMessage = 'Not possible to do the IC decision at the moment.';

  return (
    <div className={className}>
      {isICDecisionAvailable ? (
        <Fragment>
          <Icon className="icon check circle" />
          OK
        </Fragment>
      ) : (
        <Fragment>
          {errorsExist ? (
            <div className="ui list">
              {icDecisionPreconditions.errors?.map((error, index) => (
                <div className="item" key={index}>
                  <Icon className="icon exclamation circle" />
                  <div className="content">{error}</div>
                </div>
              ))}
            </div>
          ) : (
            fallbackMessage
          )}
        </Fragment>
      )}
    </div>
  );
}

ICDecisionPreconditionsBody.propTypes = {
  icDecisionPreconditions: PropTypes.shape({
    isICDecisionAvailable: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

// eslint-disable-next-line @typescript-eslint/no-shadow
function ICDecisionPreconditions({ icDecisionPreconditions, getICDecisionPreconditions }) {
  useEffect(() => {
    getICDecisionPreconditions();
  }, [getICDecisionPreconditions]);

  return (
    <DashboardCard title="IC Decision Preconditions">
      {icDecisionPreconditions ? (
        <ICDecisionPreconditionsBody icDecisionPreconditions={icDecisionPreconditions} />
      ) : null}
    </DashboardCard>
  );
}

ICDecisionPreconditions.propTypes = {
  icDecisionPreconditions: PropTypes.shape({
    isICDecisionAvailable: PropTypes.bool.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  getICDecisionPreconditions: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  icDecisionPreconditions: selectICDecisionPreconditions,
});

const mapDispatchToProps = {
  getICDecisionPreconditions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ICDecisionPreconditions);
