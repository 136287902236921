import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import Highcharts from 'highcharts/highstock';
import renderConfig from 'helpers/renderConfig';
import renderThemeConfig from 'helpers/renderThemeConfig';
import AppWrapper from '../components/atoms/AppWrapper';
import { loadValidSession } from '../store/actions/auth.actions';
import DistributionGroups from '../components/pages/v2/DistributionGroups';
import EditDistributionGroups from '../components/pages/v2/DistributionGroups/EditDistributionGroups';
import CreateDistributionGroups from '../components/pages/v2/DistributionGroups/CreateDistributionGorups';
import LoginPage from '../components/pages/common/LoginPage';

// NEW
import NewLogs from '../components/pages/v2/Logs';
import NewNavbar from '../components/molecules/NewNavbar';
import NewHomepage from '../components/pages/v2/Homepage';
import NewDashboard from '../components/pages/v2/Dashboard';
import { UserList } from '../components/pages/common/Users';
import PrivateRoute from '../PrivateRoute';
import useCognitoSSO from '../helpers/useCognitoSSO';
import { validSession } from '../helpers/session.helper';
import { makeSelectAuthenticated, makeSelectInvalidRoles } from '../store/selectors/auth.selector';
import UserDetailsNew from '../components/pages/common/Users/UserDetails/UserDetailsNew';
import UserDetailsFull from '../components/pages/common/Users/UserDetails/UserDetails';
import DataMonitoringDashboard from '../components/pages/v2/DataMonitoring/DataMonitoringDashboard/DataMonitoringDashboard';
import StrategyAlerts from '../components/pages/v2/DataMonitoring/StrategyAlerts/StrategyAlerts';
import releaseToggles from '../releaseToggles';
import AuthorizationLogsPage from '../components/pages/v2/AuthorizationLogsPage/AuthorizationLogsPage';
import RoleMismatchPage from '../components/pages/common/RoleMismatchPage/RoleMismatchPage';

export const AuthContext = React.createContext(false);

export default function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(makeSelectAuthenticated);
  const location = useLocation();
  const isValidSession = validSession();

  Highcharts.setOptions({
    colors: renderThemeConfig.default.variables.highchartColors,
  });

  useEffect(() => {
    if (isValidSession && !isAuthenticated) {
      dispatch(loadValidSession());
    }
  }, [location, isValidSession, isAuthenticated, dispatch]);

  useCognitoSSO();

  const invalidRoles = useSelector(makeSelectInvalidRoles);
  if (invalidRoles) {
    return <RoleMismatchPage />;
  }

  return (
    <AuthContext.Provider value={isAuthenticated}>
      <ThemeProvider theme={renderThemeConfig.default}>
        <AppWrapper showBackground={location.pathname === '/login'}>
          <title>{renderConfig.displayName} - Alpima: Management Application </title>
          <NewNavbar />
          <Switch>
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/distribution-groups"
              component={DistributionGroups}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/distribution-groups/create"
              component={CreateDistributionGroups}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/distribution-groups/edit/*"
              component={EditDistributionGroups}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/"
              component={NewHomepage}
            />
            {renderConfig.userDetailsV2 && (
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                exact
                path="/user/:id"
                component={UserDetailsNew}
              />
            )}
            {renderConfig.userDetailsFull && (
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                exact
                path="/user/:id"
                component={UserDetailsFull}
              />
            )}
            {releaseToggles.dataMonitoring && (
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                exact
                path="/data-monitoring"
                component={DataMonitoringDashboard}
              />
            )}
            {releaseToggles.dataMonitoring && (
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                exact
                path="/strategy-alerts"
                component={StrategyAlerts}
              />
            )}
            {releaseToggles.cognitoAuthentication && (
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                exact
                path="/authorization-logs"
                component={AuthorizationLogsPage}
              />
            )}
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/user"
              component={UserList}
            />
            {renderConfig.showLogsPage && (
              <PrivateRoute
                isAuthenticated={isAuthenticated}
                exact
                path="/logs"
                component={NewLogs}
              />
            )}
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path="/dashboard"
              component={NewDashboard}
            />
            <Route exact path="/login" component={LoginPage} />
          </Switch>
          <ToastContainer autoClose={8000} />
        </AppWrapper>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}
