import { displayUserURL } from './clientAPI.config';
import { apiCall$ } from './lib/apiCall';

export type DisplayUser = {
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  enabled: boolean;
  roles: string[];
  username: string;
  company: string;
  displayName: string;
  provider: string;
  created: string;
  sessionTimeoutMinutes: number;
  apiToken: {
    token: string;
    expires: string; // ISO Timestamp
  };
  impersonatingUser: string | null;
  companySessionTimeoutMinutes: number;
  csvDelimiter: string;
  decimalSeparator: {
    default: string;
  };
  uniqueOperationId: string;
};

export const fetchDisplayUser$ = () => apiCall$<DisplayUser>(displayUserURL);
