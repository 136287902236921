import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { FootNotes } from '@alpima/picasso';
import { isEmpty } from 'lodash';
import liveStrategiesChart from './liveStrategiesChart';
import { ArrowDown, ArrowUp } from './ArrowIcons';
import { ParsedAvailableStrategies, RunningAlertsCount } from './DataMonitoringDashboard.types';

function PastDay({ number, diff }: { number: number; diff: number }) {
  const textStyle = `${
    diff >= 0 ? 'text-green-600' : 'text-red-600'
  } m-0 text-5xl ml-3 h-full font-bold`;

  return (
    <div className="flex flex-col items-center justify-center h-full py-5">
      <h2 className="text-gray-500 text-xl text-center">Past 24hr</h2>
      <div className="flex items-center">
        {diff === 0 ? null : diff > 0 ? <ArrowUp /> : <ArrowDown />}
        <p className="font-bold m-0" style={{ fontSize: '12rem' }}>
          {number}
        </p>
        <p className={textStyle}>{diff >= 0 ? `+${diff}` : diff}</p>
      </div>
    </div>
  );
}

function LiveStrategies({ liveStrategies }: { liveStrategies: RunningAlertsCount[] }) {
  const chartOptions = liveStrategiesChart(liveStrategies);

  return (
    <div className="flex flex-col py-5 h-96 items-center">
      <h2 className="text-gray-500 text-xl text-center">Live Strategies</h2>
      {isEmpty(liveStrategies) ? (
        <FootNotes className="border-none py-24">No live strategies found</FootNotes>
      ) : (
        <div className="max-w-lg">
          {/* 
          // @ts-ignore */}
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            title="Live Strategies Breakdown by Category"
          />
        </div>
      )}
    </div>
  );
}

export default function AvailableStrategiesSection({
  availableStrategies,
  liveStrategies,
}: {
  availableStrategies: ParsedAvailableStrategies;
  liveStrategies: RunningAlertsCount[];
}) {
  return (
    <section className="flex flex-col justify-around w-full  md:flex-row">
      <PastDay
        number={availableStrategies.currentTotal}
        diff={availableStrategies.currentTotal - availableStrategies.yesterdayTotal}
      />
      {liveStrategies && liveStrategies.length !== 0 ? (
        <LiveStrategies liveStrategies={liveStrategies} />
      ) : (
        <h2 className="text-gray-500 text-xl text-center">No Live Strategies</h2>
      )}
    </section>
  );
}
