import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import renderThemeConfig from 'helpers/renderThemeConfig';
import { makeSelectCurrentTheme } from '../../../store/selectors/themes.selector';

const LineChart = ({ data, xAxis, yAxis }) => {
  Highcharts.setOptions({
    colors: renderThemeConfig.default.variables.highchartColors,
  });

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            zoomType: 'x',
          },
          credits: {
            enabled: false,
          },
          navigator: {
            series: {
              dataGrouping: {
                smoothed: true,
              },
            },
          },
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
          },
          title: {
            text: data.name,
          },
          tooltip: data.tooltip || {},
          xAxis,
          yAxis,
          series: data.series,
        }}
      />
    </div>
  );
};

LineChart.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tooltip: PropTypes.any,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        data: PropTypes.array.isRequired,
      }).isRequired,
    ),
  }).isRequired,
  xAxis: PropTypes.any,
  yAxis: PropTypes.any,
};
LineChart.defaultProps = {
  xAxis: {},
  yAxis: {},
};

const mapStateToProps = createStructuredSelector({
  theme: state => makeSelectCurrentTheme(state),
});

export default connect(mapStateToProps)(LineChart);
