/* eslint import/no-dynamic-require: 0 */
/* eslint @typescript-eslint/no-var-requires: 0 */
/* eslint global-require: 0 */
/* eslint no-console: 0 */
import React from 'react';
import getDynamicUrlThemeSelection from '../config/themesConfig';

const client = getDynamicUrlThemeSelection();

const tryRequire = modulePath => {
  try {
    return require(`${modulePath}`).default;
  } catch (error) {
    return false;
  }
};

const BaseApp = tryRequire('./App');
const AppComponent = tryRequire(`./${client}/App`) || BaseApp;

export default function App() {
  return <AppComponent />;
}
