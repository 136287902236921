/* eslint-disable @typescript-eslint/no-shadow */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Confirm } from 'semantic-ui-react';
import {
  archiveInformationBanner,
  getInformationBanners,
  saveInformationBanner,
  openInformationBannerModal,
  closeInformationBannerModal,
} from '../../../store/actions/informationBanner.actions';
import {
  selectInformationBanners,
  selectInformationBannersLoader,
  selectInformationBannerUpdater,
  selectInformationBannerModalStatus,
  selectInformationBannerError,
} from '../../../store/selectors/informationBanner.selector';
import SaveInformationBannerModal from './SaveInformationBannerModal';
import InformationBanner from './InformationBanner';
import Button from '../../atoms/Button/Button';

function InformationBannerWidget({
  error,
  informationBanners,
  archiveInformationBanner,
  saveInformationBanner,
  getInformationBanners,
  isInformationBannerModalOpen,
  openInformationBannerModal,
  closeInformationBannerModal,
}) {
  const [isConfirmArchiveDialogOpen, setIsConfirmArchiveDialogOpen] = useState(false);
  const [archiveInformationBannerId, setArchiveInformationBannerId] = useState(null);
  const [informationBannerData, setInformationBannerData] = useState(null);

  useEffect(() => {
    getInformationBanners();
  }, [getInformationBanners]);

  return (
    <div>
      <h2>Information Banners</h2>
      <hr />
      <Button
        primary
        type="button"
        onClick={() => {
          setInformationBannerData(null);
          openInformationBannerModal();
        }}
      >
        Add New
      </Button>
      {informationBanners.map((informationBanner, key) => (
        <InformationBanner
          key={key}
          informationBanner={informationBanner}
          archive={informationBannerId => {
            setArchiveInformationBannerId(informationBannerId);
            setIsConfirmArchiveDialogOpen(true);
          }}
          // eslint-disable-next-line @typescript-eslint/no-shadow
          edit={({ displayFrom, displayUntil, ...informationBannerData }) => {
            setInformationBannerData({
              ...informationBannerData,
              displayFrom: new Date(displayFrom),
              displayUntil: new Date(displayUntil),
            });
            openInformationBannerModal();
          }}
        />
      ))}
      <Confirm
        open={isConfirmArchiveDialogOpen}
        content="Are you sure you want to delete information banner?"
        onCancel={() => {
          setIsConfirmArchiveDialogOpen(false);
          setArchiveInformationBannerId(null);
        }}
        onConfirm={() => {
          archiveInformationBanner(archiveInformationBannerId);
          setIsConfirmArchiveDialogOpen(false);
          setArchiveInformationBannerId(null);
        }}
      />
      <SaveInformationBannerModal
        initialData={informationBannerData}
        isOpen={isInformationBannerModalOpen}
        closeModal={closeInformationBannerModal}
        saveInformationBanner={saveInformationBanner}
        error={error}
      />
    </div>
  );
}

InformationBannerWidget.propTypes = {
  archiveInformationBanner: PropTypes.func.isRequired,
  saveInformationBanner: PropTypes.func.isRequired,
  getInformationBanners: PropTypes.func.isRequired,
  informationBanners: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      displayFrom: PropTypes.string.isRequired,
      displayUntil: PropTypes.string.isRequired,
      severityLevel: PropTypes.number.isRequired,
      isDismissable: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  isInformationBannerModalOpen: PropTypes.bool.isRequired,
  openInformationBannerModal: PropTypes.func.isRequired,
  closeInformationBannerModal: PropTypes.func.isRequired,
  error: PropTypes.object,
};

InformationBannerWidget.defaultProps = {
  error: null,
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectInformationBannersLoader,
  informationBanners: selectInformationBanners,
  isUpdating: selectInformationBannerUpdater,
  isInformationBannerModalOpen: selectInformationBannerModalStatus,
  error: selectInformationBannerError,
});

const mapDispatchToProps = {
  saveInformationBanner,
  archiveInformationBanner,
  getInformationBanners,
  openInformationBannerModal,
  closeInformationBannerModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(InformationBannerWidget);
