import { of } from 'rxjs';
import { mergeMap, switchMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { omit } from 'lodash';

import * as constants from '../constants';
import { login$, logout$ } from '../../services/clientAPI/auth.service';
import { fetchDisplayUser$ } from '../../services/clientAPI/auth.service-typed.ts';
import { setCookieExpiry, setCookie, removeCookie, getCookie } from '../../helpers/cookie.helper';

const sanitizeCookieObject = cookieObj => {
  const blacklistedProperties = [
    '_id',
    'email',
    'firstName',
    'lastName',
    'roles',
    'username',
    'uniqueOperationId',
    'provider',
  ];
  return omit(cookieObj, blacklistedProperties);
};

const handleLoginResponse = response => {
  setCookieExpiry(response.token);
  return [
    {
      type: constants.LOGIN_SUCCEEDED,
      payload: response,
    },
    {
      type: constants.GET_DISPLAY_USER_STARTED,
    },
  ];
};

const handleLogoutResponse = () => {
  removeCookie('alp-auth');
  removeCookie('display-user');
  return [
    {
      type: constants.LOGOUT_SUCCEEDED,
    },
  ];
};

const handleFetchDisplayUserResponse = response => {
  const sanitizedResponse = sanitizeCookieObject(response);
  setCookie('display-user', sanitizedResponse);
  return [
    {
      type: constants.GET_DISPLAY_USER_SUCCEEDED,
      payload: response,
    },
    {
      type: constants.GET_LAYOUT_STARTED,
    },
  ];
};

const handleError = error =>
  of({
    type: constants.LOGIN_FAILED,
    error: error.message,
  });

const handleDisplayUserError = error =>
  of({
    type: constants.LOGOUT_STARTED,
    error: error.message,
  });

export const loginEpic = actions$ =>
  actions$.pipe(
    ofType(constants.LOGIN_STARTED),
    mergeMap(action =>
      login$(action.payload).pipe(
        mergeMap(response => handleLoginResponse(response.response)),
        catchError(handleError),
      ),
    ),
  );

export const getDisplayUserEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_DISPLAY_USER_STARTED),
    switchMap(fetchDisplayUser$),
    mergeMap(handleFetchDisplayUserResponse),
    catchError(handleDisplayUserError),
  );

export const logoutEpic = actions$ =>
  actions$.pipe(
    ofType(constants.LOGOUT_STARTED),
    mergeMap(action =>
      logout$(action.payload).pipe(
        mergeMap(response => handleLogoutResponse(response.response)),
        catchError(() => handleLogoutResponse()),
      ),
    ),
  );

export const loadValidSessionEpic = actions$ =>
  actions$.pipe(
    ofType(constants.LOAD_VALID_SESSION),
    mergeMap(() => {
      const session = getCookie('alp-auth');
      return [
        {
          type: constants.LOGIN_SUCCEEDED,
          payload: session,
        },
        {
          type: constants.GET_DISPLAY_USER_STARTED,
        },
      ];
    }),
  );
