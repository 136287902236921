import React from 'react';
import { Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';
import moment from 'moment';
import InstrumentsTableWidget from '../InstrumentsTableWidget';
import {
  selectInstrumentList,
  selectInstrumentLoading,
} from '../../../store/selectors/instruments.selector';

const csvHeaderFields = {
  Enabled: 'visible',
  'Instrument ID': 'instrumentId',
  Name: 'displayName',
  ISIN: 'idISIN',
  'Bloomberg Ticker': 'bbgticker',
  Currency: 'refCurrency',
  'Instrument Type': 'type',
  'Asset Class': 'assetClass',
  Region: 'region',
  'Performance Inception': 'dataStartDate',
  'Performance Last Updated': 'dataEndDate',
};

export default function NatixisInstrumentsList(props) {
  const instrumentPagedList = useSelector(selectInstrumentList);
  const loading = useSelector(selectInstrumentLoading);

  const instruments = instrumentPagedList.flat();
  const instrumentJSON =
    instrumentPagedList &&
    `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(instruments))}`;

  const csvInstrumentData = instruments.map(instrument =>
    Object.values(csvHeaderFields).map(key => instrument[key]),
  );
  const instrumentCSV =
    instrumentPagedList &&
    `data:text/csv;charset=utf-8,${encodeURIComponent(
      Papa.unparse({ fields: Object.keys(csvHeaderFields), data: csvInstrumentData }),
    )}`;

  const datetime = moment().format('YYYY-MM-DD.HHmm');
  const jsonFile = `instrumentExport-${datetime}.json`;
  const csvFile = `instrumentExport-${datetime}.csv`;

  return (
    <>
      <Button
        type="button"
        content="Export as JSON"
        icon="download"
        href={instrumentJSON}
        download={jsonFile}
        disabled={loading}
        loading={loading}
      />
      <Button
        type="button"
        content="Export as CSV"
        icon="table"
        href={instrumentCSV}
        download={csvFile}
        disabled={loading}
        loading={loading}
      />
      <InstrumentsTableWidget {...props} />
    </>
  );
}
