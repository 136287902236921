import { StrategiesAlertsMonitoring } from '../../StrategyAlerts.types';

export default function matchesSearch(
  row: StrategiesAlertsMonitoring,
  searchText: string,
): boolean {
  return `${row.alertId} ${row.strategy} ${row.assetClass} ${row.status}`
    .toLowerCase()
    .includes(searchText.toLowerCase());
}
