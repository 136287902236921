import { ofType } from 'redux-observable';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as constants from '../constants';
import fetchRegionsList from '../../services/clientAPI/regions.service';

const handleError = type => error =>
  of({
    type,
    error: error.message,
  });

// eslint-disable-next-line import/prefer-default-export
export const getRegionsEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_REGIONS_LIST),
    mergeMap(action =>
      fetchRegionsList(action.json).pipe(
        mergeMap(response => [
          {
            type: constants.GET_REGIONS_LIST_SUCCESS,
            payload: response,
          },
        ]),
        catchError(handleError(constants.GET_REGIONS_LIST_FAILURE)),
      ),
    ),
    catchError(handleError(constants.GET_REGIONS_LIST_FAILURE)),
  );
