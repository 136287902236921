import { DateTime } from 'luxon';
import { getUTCHours, mapTimeToTimePeriod } from './../../shared.utils';
import { Times } from '../../shared.types';
import {
  ParsedStrategiesAlertsMonitoring,
  StrategiesAlertsMonitoring,
} from '../StrategyAlerts.types';

const timeFormat = 'HH:mm:ss';
const dateFormat = 'dd LLL yyyy';

export function parseAlertsData(
  data: StrategiesAlertsMonitoring[],
): ParsedStrategiesAlertsMonitoring[] {
  return data.map(item => {
    const groupHourKey = getUTCHours(item.groupTimestamp) as Times;

    return {
      ...item,
      formattedTime: mapTimeToTimePeriod(groupHourKey),
      startedAt: DateTime.fromISO(item.startedAt).toFormat(timeFormat),
      endedAt: DateTime.fromISO(item.endedAt).toFormat(timeFormat),
      scheduledAtTime: DateTime.fromISO(item.scheduledAt).toFormat(timeFormat),
      scheduledAtDate: DateTime.fromISO(item.scheduledAt).toFormat(dateFormat),
      time: groupHourKey,
    };
  });
}
