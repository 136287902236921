/* eslint import/no-dynamic-require: 0 */
/* eslint @typescript-eslint/no-var-requires: 0 */
/* eslint global-require: 0 */
/* eslint no-console: 0 */
const tryRequire = modulePath => {
  try {
    return require(`${modulePath}`).default;
  } catch (error) {
    return false;
  }
};

const BaseThemesConfig = tryRequire('./themesBaseConfig') || [];

const urlThemeMapping = [
  ...BaseThemesConfig,
  [/localhost/, 'BJB'],
  [/adminappv2-bjb\.dev\.quantifeed\.com/, 'BJB'],
  [/juliusbaer-admin\.dev\.quantifeed\.com/, 'BJB'],
  [/juliusbaer-admin\.test\.quantifeed\.com/, 'BJB'],
  [/juliusbaer-admin-2\.test\.quantifeed\.com/, 'BJB'],
  [/juliusbaer-admin-3\.uat\.quantifeed\.com/, 'BJB'],
  [/juliusbaer-admin\.uat\.quantifeed\.com/, 'BJB'],
  [/juliusbaer-admin\.prod\.quantifeed\.com/, 'BJB'],
  [/juliusbaer-admin\.quantifeed\.com/, 'BJB'],
  [/juliusbaer-admin\.uat\.alpima\.net/, 'BJB'],
  [/juliusbaer-admin-uat3\.uat\.alpima\.net/, 'BJB'],
  [/juliusbaer-admin\.alpima\.net/, 'BJB'],
  [/juliusbaer-admin\..*\.alpima\.net/, 'BJB'], // Match 'juliusbaer-admin.<any environment>.alpima.net'
];

const getDynamicUrlThemeSelection = () => {
  const location = window.location.hostname;
  const theme = urlThemeMapping.reduce((a, b) => {
    if (a) return a;
    if (b && b[0].test(location)) return b;
    return null;
  }, false);
  return theme ? theme[1] : false;
};

export default getDynamicUrlThemeSelection;
