import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Icon } from 'semantic-ui-react';
import renderThemeConfig from '../../../helpers/renderThemeConfig';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';
import 'react-datepicker/dist/react-datepicker.css';
import { makeSelectCurrentTheme } from '../../../store/selectors/themes.selector';
import Button from '../Button';

const TextReadOut = styled.div`
  position: absolute;
  bottom: 0;
`;
const Filter = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 250px;
  margin-bottom: 1rem;
  padding-bottom: 3em;

  @media screen and (min-width: ${props => props.theme.variables.breakpoints.lg}px) {
  }

  > div {
    display: flex;
    flex-direction: row;

    span {
      display: block;
      margin-top: 0.5rem;
      padding: 0 0.5rem;
      font-size: 0.8rem;
    }
  }
`;
const CustomDatePickers = styled.div`
  position: absolute;
  top: 40px;
  z-index: 9999;
  width: auto;
  padding: 15px;
  margin-top: 10px;
  border: solid 1px #333;
  border-radius: 3px;
  background: white;

  &.withTime {
    min-width: 665px;
  }

  > div {
    display: inline-block;
    padding: 10px;
    &:first-child {
      padding-left: 0;
    }
  }
`;

const useRangeAll = setFilterState => {
  const { startOfTime } = renderThemeConfig.default;

  return id => () => {
    const start = moment(startOfTime).startOf('day');
    const end = moment().endOf('day');
    setFilterState(start.toISOString(), end.toISOString(), id);
  };
};

const TimeFilter = ({
  state,
  setFilterState,
  displayTimeRangeText,
  timeInput,
  customDateTimeOption,
  customDateTimeOptions,
  style,
}) => {
  const client = useSelector(makeSelectCurrentTheme);
  const { dateFormat, dateTimeFormat } = useDateTimeFormatter();
  let stateFrom = null;
  if (state.start && moment(state.start).isValid()) {
    stateFrom = moment(state.start);
  }
  let stateTo = null;
  if (state.end && moment(state.end).isValid()) {
    stateTo = moment(state.end);
  }

  const { selection = 2021 } = state;
  const [customDateTimeState, setCustomDateTimeState] = useState({
    start: new Date(),
    end: new Date(),
    active: false,
  });

  const range1Hour = id => () => {
    const now = moment();
    const start = moment(now).add(-1, 'hour');
    setFilterState(start.toISOString(), now.toISOString(), id);
  };
  const rangeToday = id => () => {
    const start = moment().startOf('day');
    const end = moment().endOf('day');
    setFilterState(start.toISOString(), end.toISOString(), id);
  };
  const range24Hours = id => () => {
    const now = moment();
    const start = moment(now).add(-1, 'day');
    setFilterState(start.toISOString(), now.toISOString(), id);
  };
  const range3Days = id => () => {
    const now = moment();
    const start = moment(now).add(-3, 'days');
    setFilterState(start.toISOString(), now.toISOString(), id);
  };
  const rangeAll = useRangeAll(setFilterState);

  const handleCustomButtonClick = () => {
    if (customDateTimeState.active) {
      // performing close operation
      setCustomDateTimeState({
        ...customDateTimeState,
        active: false,
      });
      // Set the filter state
      setFilterState(customDateTimeState.start, customDateTimeState.end, -1);
    } else {
      setCustomDateTimeState({
        ...customDateTimeState,
        active: true,
      });
    }
  };

  const defaultTimeOptions = [
    { id: 0, label: '1H', func: range1Hour },
    { id: 1, label: 'Today', func: rangeToday },
    { id: 2, label: '24H', func: range24Hours },
    { id: 3, label: '3 Days', func: range3Days },
    { id: 4, label: 'All', func: rangeAll },
  ];

  const timeOptions = customDateTimeOptions.length ? customDateTimeOptions : defaultTimeOptions;
  const readOutFormat = timeInput
    ? dateTimeFormat || 'DD.MM.YYYY (HH:mm)'
    : dateFormat || 'DD.MM.YYYY';
  const textReadOut =
    (displayTimeRangeText && !customDateTimeState.active) ||
    (selection === -1 && !customDateTimeState.active) ? (
      <TextReadOut>
        <p>
          Period:
          <b>{stateFrom ? stateFrom.format(readOutFormat) : null}</b>
          &rarr;
          <b>{stateTo ? stateTo.format(readOutFormat) : null}</b>
        </p>
      </TextReadOut>
    ) : null;

  const customDatePickers = customDateTimeState.active ? (
    <CustomDatePickers className={`datePickers ${timeInput ? 'withTime' : ''}`}>
      <div>
        <h4>Start Date:</h4>
        <DatePicker
          className="dt-start"
          selected={customDateTimeState.start}
          onChange={date =>
            setCustomDateTimeState({
              ...customDateTimeState,
              start: timeInput ? date : moment(date).startOf('day').toDate(),
            })
          }
          selectsStart
          inline
          showTimeSelect={timeInput}
          timeFormat="HH:mm"
          timeIntervals={30}
          timeCaption="time"
          dateFormat="dd MMM, yyyy HH:mm"
          startDate={customDateTimeState.start}
          endDate={customDateTimeState.end}
        />
      </div>
      <div>
        <h4>End Date:</h4>
        <DatePicker
          className="dt-end"
          selected={customDateTimeState.end}
          onChange={date =>
            setCustomDateTimeState({
              ...customDateTimeState,
              end: timeInput ? date : moment(date).endOf('day').toDate(),
            })
          }
          selectsEnd
          inline
          showTimeSelect={timeInput}
          timeFormat="HH:mm"
          timeIntervals={30}
          timeCaption="time"
          dateFormat="dd MMM, yyyy HH:mm"
          startDate={customDateTimeState.start}
          endDate={customDateTimeState.end}
          minDate={customDateTimeState.start}
        />
      </div>
    </CustomDatePickers>
  ) : null;
  const customOption = customDateTimeOption ? (
    <Button
      key="custom"
      primary={customDateTimeState.active}
      className={selection === -1 && !customDateTimeState.active ? 'active' : ''}
      onClick={handleCustomButtonClick}
      color={client === 'BJB' && customDateTimeState.active ? 'green' : null}
    >
      {!customDateTimeState.active ? (
        'Custom'
      ) : (
        <p>
          Set Custom Date <Icon name="save outline" />
        </p>
      )}
    </Button>
  ) : null;

  return (
    <Filter style={style}>
      <div>
        {timeOptions.map(option => {
          // Override the callback to set state of custom date pickers to false
          const callbackFunction = event => {
            const func = option.func(option.id);
            setCustomDateTimeState({ ...customDateTimeState, active: false }); // Close custom
            func(event);
          };
          return (
            <Button
              key={option.id}
              className={selection === option.id ? 'active' : ''}
              onClick={callbackFunction}
            >
              {option.label}
            </Button>
          );
        })}
        {customOption}
      </div>
      {customDatePickers}
      {textReadOut}
    </Filter>
  );
};

TimeFilter.propTypes = {
  state: PropTypes.object.isRequired,
  setFilterState: PropTypes.func.isRequired,
  displayTimeRangeText: PropTypes.bool,
  timeInput: PropTypes.bool,
  customDateTimeOption: PropTypes.bool,
  customDateTimeOptions: PropTypes.array,
  style: PropTypes.any,
};
TimeFilter.defaultProps = {
  displayTimeRangeText: true,
  timeInput: true,
  customDateTimeOption: true,
  customDateTimeOptions: [],
  style: {
    position: 'relative',
    display: 'inline-block',
    paddingLeft: '1.5em',
  },
};

export default TimeFilter;
