import React from 'react';
import { Link } from 'react-router-dom';
import renderThemeConfig from 'helpers/renderThemeConfig';

const LogoWrapper = () => (
  <Link to="/">
    <img
      className="h-14"
      alt={`${renderThemeConfig.default.name}-logo`}
      src={renderThemeConfig.default.logo}
      height={54}
    />
  </Link>
);

export default LogoWrapper;
