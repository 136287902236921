import renderConfig from 'helpers/renderConfig';

type ReleaseToggle = 'cognitoAuthentication' | 'dataMonitoring' | 'mockDataMonitoring';

type ReleaseToggles = Record<ReleaseToggle, boolean>;

/**
 * Release toggles to be version-controlled along with the rest of
 * the codebase. The intent is to be able to remove each `if (!useCognito)`
 * branch very easily, once this release toggle is true in production.
 *
 * Feature toggles in `.env.X` should be of the format:
 * REACT_APP_TOGGLE_<featurename>=on
 * REACT_APP_TOGGLE_<otherfeature>=off
 *
 * Why on/off? Our `.env` files load everything as strings, so `false`
 * becomes `'false'`, which tests as truthy. To avoid this bug waiting
 * to happen, we use values which are *clearly* strings. If in future we
 * use JSON config files, these should be rewritten as booleans.
 */

export default {
  cognitoAuthentication: process.env.REACT_APP_TOGGLE_COGNITO === 'on',
  dataMonitoring: process.env.REACT_APP_TOGGLE_DATA_MONITORING === 'on',
  mockDataMonitoring: process.env.REACT_APP_REAL_DATA_MONITORING !== 'on',
} as ReleaseToggles;
