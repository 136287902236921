import { SortColumn } from 'react-data-grid';
import { StrategiesAlertsMonitoring } from '../StrategyAlerts.types';

/**
 * General purpose compare function to compare two objects by a given key.
 */
function compareBy(
  key: string,
): (a: Record<string, unknown>, b: Record<string, unknown>) => number {
  return (a: Record<string, unknown>, b: Record<string, unknown>) => {
    const one = a[key];
    const two = b[key];
    if (typeof one === 'string' && typeof two === 'string') {
      return one < two ? -1 : 1;
    }
    if (typeof one === 'number' && typeof two === 'number') {
      return one - two;
    }
    if (typeof one === 'boolean' && typeof two === 'boolean') {
      return Number(one) - Number(two);
    }
    return 0;
  };
}

/**
 * Given a list of rows and a list of columns to sort by, returns a sorted list of rows.
 */
export default function sortRows(
  visibleAlerts: StrategiesAlertsMonitoring[],
  sortColumns: readonly SortColumn[],
): StrategiesAlertsMonitoring[] {
  if (!visibleAlerts) return [];
  if (sortColumns.length === 0) return visibleAlerts;

  const newAlertsList = visibleAlerts.slice();
  return newAlertsList.sort((a, b) => {
    for (const sort of sortColumns) {
      const comparator = compareBy(sort.columnKey);
      const compResult = comparator(a, b);
      if (compResult !== 0) {
        return sort.direction === 'ASC' ? compResult : -compResult;
      }
    }
    return 0;
  });
}
