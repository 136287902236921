import React, { useEffect, useMemo, useState, ChangeEvent } from 'react';
import {
  Layout,
  PageHeader,
  Back,
  Section,
  Title,
  TextInput,
  TextInputType,
  TextInputSize,
  Button,
  ButtonStates,
} from '@alpima/picasso';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import TransferList from '../../../../molecules/List/TransferList';
import useTriggeredService from '../../../../../helpers/useTriggeredService';
import { makeSelectUser } from '../../../../../store/selectors/auth.selector';
import { ListAsset } from '../DistributionGroups.types';
import {
  fetchAllCompanyUsers,
  createUserGroup,
} from '../../../../../services/clientAPI/distributionGroups.service';

const CreateDistributionGroups = () => {
  const history = useHistory();
  const currentUser = useSelector(makeSelectUser);
  const [groupName, setGroupName] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [leftUniverse, setLeftUniverse] = useState<ListAsset[] | []>([]);
  const [rightUniverse, setRightUniverse] = useState<ListAsset[] | []>([]);
  const [selectedLeftAssets, setSelectedLeftAssets] = useState<ListAsset[] | []>([]);
  const [selectedRightAssets, setSelectedRightAssets] = useState<ListAsset[] | []>([]);
  const [, createUserGroupError, createUserGroupResponse, createUserGroupTrigger] =
    useTriggeredService(createUserGroup);

  const [loading, error, allCompanyUsers, fetchAllCompanyUsersTrigger] =
    useTriggeredService<ListAsset[]>(fetchAllCompanyUsers);

  useEffect(() => {
    if (currentUser) {
      fetchAllCompanyUsersTrigger(currentUser.company);
    }
  }, [currentUser, fetchAllCompanyUsersTrigger]);

  useEffect(() => {
    if (allCompanyUsers) {
      const enabledUsers = allCompanyUsers.filter(user => user.enabled);
      setLeftUniverse(enabledUsers);
    }
  }, [allCompanyUsers]);

  const filteredLeftUniverse = useMemo(() => {
    const searchedUserGroups = leftUniverse.filter((item: ListAsset) => {
      const joinNames = `${item.username} ${item.firstName} ${item.lastName}`;
      const formattedSearchName = joinNames.toLowerCase?.() || '';
      return formattedSearchName.includes(searchQuery.toLowerCase());
    });
    return searchedUserGroups;
  }, [leftUniverse, searchQuery]);

  const handleCreateUserGroup = () => {
    const payload = {
      users: rightUniverse.map(user => user._id),
      strategies: [],
      name: groupName,
    };

    createUserGroupTrigger(payload);
  };

  useEffect(() => {
    if (createUserGroupResponse) {
      toast.success('Distribution Group Created');
      history.push('/distribution-groups');
    }
  }, [createUserGroupResponse, history]);

  useEffect(() => {
    if (createUserGroupError) {
      toast.error('Error creating Distribution Group');
    }
  }, [createUserGroupError]);

  useEffect(() => {
    if (error) {
      toast.error('Error loading company users');
    }
  }, [error]);

  return (
    <div className="min-h-screen">
      <Layout noMargins>
        <div className="w-full px-8 mb-2">
          <PageHeader>
            <Back />
            <Section>
              <Title>Create Distribution Group</Title>
            </Section>
          </PageHeader>
        </div>
        <div className="flex flex-col w-10/12 mt-6">
          <div className="w-full bg-gray-100 flex flex-grow flex-col p-6">
            <textarea
              className="appearance-none text-black text-3xl border-none bg-gray-100 outline-none ring-0 placeholder-gray-500 w-full h-28 border"
              value={groupName}
              placeholder="Add Group name"
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                setGroupName(event.target.value)
              }
            />
          </div>
          <div className="w-full mt-10">
            <div className="float-left">
              <TextInput
                customSize={TextInputSize.Large}
                type={TextInputType.IconLeft}
                value={searchQuery}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearchQuery(e.target.value)
                }
                className="w-96 outline-none float-left"
                placeholder="Search..."
              />
            </div>
            <Button
              disabled={!groupName.length || !rightUniverse.length}
              className="float-right"
              state={ButtonStates.Solid}
              onClick={handleCreateUserGroup}
            >
              Create
            </Button>
          </div>
          <div className="mt-6">
            <TransferList<ListAsset>
              loading={loading}
              leftUniverse={{
                data: leftUniverse,
                setData: setLeftUniverse,
                selected: selectedLeftAssets,
                setSelected: setSelectedLeftAssets,
                filteredData: filteredLeftUniverse,
                title: 'All Users',
              }}
              rightUniverse={{
                data: rightUniverse,
                setData: setRightUniverse,
                selected: selectedRightAssets,
                setSelected: setSelectedRightAssets,
                title: 'Group Users',
              }}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default CreateDistributionGroups;
