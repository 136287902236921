import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const UIMessage = ({ type, message, list }) => (
  <Message
    error={type === 'error'}
    warning={type === 'warning'}
    positive={type === 'success'}
    info={type === 'info'}
    header={message}
    list={list && list.length ? list : null}
    style={{
      display: 'block',
    }}
  />
);

UIMessage.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
  message: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.string),
};

UIMessage.defaultProps = {
  type: 'default',
  list: [],
};

export default UIMessage;
