/* eslint-disable import/prefer-default-export */
export const filter = {
  config: {
    identifier: 'pt',
    name: 'Portfolio Types',
  },
  defaults: {},
  filters: [
    {
      key: 0,
      text: 'All',
      value: 0,
    },
    {
      key: 1,
      text: 'Building Blocks',
      value: 1,
    },
    {
      key: 2,
      text: 'SAA',
      value: 2,
    },
    {
      key: 3,
      text: 'TAA',
      value: 3,
    },
    {
      key: 4,
      text: 'MOPO',
      value: 4,
    },
    {
      key: 4,
      text: 'ASTRAT',
      value: 4,
    },
  ],
};
