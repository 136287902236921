import React from 'react';
import styled from 'styled-components';

const CardDefaultWrapper = styled.div`
  background: rgba(128, 128, 128, 0.5);
  padding: 20px;
  max-width: 250px;
  text-align: center;
  height: 200px;
  margin: 0 auto;
`;

// eslint-disable-next-line react/prop-types
const CardDefault = ({ children }) => <CardDefaultWrapper>{children}</CardDefaultWrapper>;

export default CardDefault;
