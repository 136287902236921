import React from 'react';
import { storiesOf } from '@storybook/react';

import { DashboardHeader } from './index';

const defaultProps = {
  title: 'Dashboard Header',
  subtitle: 'Demo subtitle',
  icon: 'cog',
};

storiesOf('DashboardHeader', module).add('default', () => <DashboardHeader {...defaultProps} />);
