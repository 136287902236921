import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Item, List } from 'semantic-ui-react';

import { makeSelectCurrentTheme } from '../../../store/selectors/themes.selector';

import DashboardHeaderWrapper from '../../atoms/DashboardHeaderWrapper';

const headerTitleStyle = {
  fontSize: '1.2em',
};
const headerIconStyle = {
  marginRight: '10px',
};

const subtitleStyle = {
  fontSize: '0.9em',
  marginTop: '6px',
};

export const DashboardHeader = ({ title, subtitle, icon }) => (
  <DashboardHeaderWrapper>
    <Item.Group divided>
      <Item>
        <List.Icon name={icon.name} size={icon.size} style={headerIconStyle} />
        <Item.Content verticalAlign="top">
          <span style={headerTitleStyle}>{title}</span>
          <br />
          <p style={subtitleStyle}>{subtitle}</p>
        </Item.Content>
      </Item>
    </Item.Group>
  </DashboardHeaderWrapper>
);

DashboardHeader.defaultProps = {
  title: '',
  subtitle: '',
  icon: {},
};

DashboardHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  theme: state => makeSelectCurrentTheme(state),
});

export default connect(mapStateToProps, null)(DashboardHeader);
