import { pick, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Checkbox, Icon } from 'semantic-ui-react';

import {
  CheckboxLabel,
  CheckboxWrapper,
  DataRefreshContainer,
  DropdownInput,
  FormContainer,
  Horizontal,
  InvisibleButton,
  SaveButtonWrapper,
  Title,
} from './atoms';
import inputs from './inputs';
import { addNewTask$ } from '../../../../../services/clientAPI/dataRefresh.service';

function useEnvironment() {
  const domain = window.location.href.toLowerCase();
  if (domain.includes('dev')) return 'Dev';
  if (domain.includes('localhost')) return 'Dev';
  if (domain.includes('qa')) return 'QA';
  if (domain.includes('uat2')) return 'UAT2';
  if (domain.includes('uat')) return 'UAT';
  if (domain.includes('sit')) return 'SIT';
  return 'Prod';
}

export default function AddNewDataRefreshTask({ onDone }) {
  const environment = useEnvironment();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    syncOperation: 'bjb-core-sync',
    sourceEnvironment: environment,
    targetEnvironment: 'Dev',
    clearTargetQueryMatches: true,
    targetFoundAction: 'Skip',
    targetNotFoundAction: 'Insert',
  });
  const formValid = formData.targetEnvironment !== formData.sourceEnvironment;

  // Helper method to set one of the keys in state.
  const setValue = key => value => setFormData(old => ({ ...old, [key]: value }));

  // Create our own dropdown component preconfigured with all the options.
  const Dropdown = ({ name, ...props }) => (
    <DropdownInput config={inputs[name]} value={formData[name]} setValue={setValue} {...props} />
  );
  Dropdown.propTypes = { name: PropTypes.string.isRequired };

  const clearTarget = formData.clearTargetQueryMatches;
  const postData = () => {
    setLoading(true);
    const dataToSend = pick(
      formData,
      'syncOperation',
      'targetEnvironment',
      'clearTargetQueryMatches',
      'targetFoundAction',
      'targetNotFoundAction',
    );
    addNewTask$(dataToSend).subscribe(
      () => {
        setLoading(false);
        onDone();
      },
      errorData => {
        setLoading(false);
        console.error(
          `An error occurred: ${get(
            errorData,
            'response.data.message',
            'No internet connection.',
          )}`,
        );
      },
    );
  };

  const saveButtonDisabled = loading || !formValid;

  return (
    <DataRefreshContainer>
      <InvisibleButton type="button" onClick={onDone}>
        <Icon name="arrow left" />
        Back
      </InvisibleButton>
      <Title>Add New Data Refresh Task</Title>
      <FormContainer>
        <Dropdown name="syncOperation" />
        <Horizontal>
          <Dropdown name="sourceEnvironment" disabled />
          <Dropdown name="targetEnvironment" />
        </Horizontal>

        <CheckboxWrapper>
          <CheckboxLabel htmlFor="clearTarget">Clear Target Query Matches</CheckboxLabel>
          <Checkbox
            toggle
            id="clearTarget"
            checked={clearTarget}
            onChange={() => setValue('clearTargetQueryMatches')(!clearTarget)}
          />
        </CheckboxWrapper>

        <Horizontal>
          <Dropdown name="targetFoundAction" />
          <Dropdown name="targetNotFoundAction" />
        </Horizontal>

        <SaveButtonWrapper>
          <Button
            style={{ flexGrow: 1 }}
            primary
            loading={loading}
            onClick={postData}
            disabled={saveButtonDisabled}
          >
            <Icon name="save" />
            Save
          </Button>
        </SaveButtonWrapper>
      </FormContainer>
    </DataRefreshContainer>
  );
}

AddNewDataRefreshTask.propTypes = {
  onDone: PropTypes.func.isRequired,
};
