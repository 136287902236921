import { ajax } from 'rxjs/ajax';
import { EMPTY } from 'rxjs';
import renderConfig from 'helpers/renderConfig';
import { getAjaxConfig, loginURL, ssoLoginURL, logoutURL } from './clientAPI.config';
import { apiCall$ } from './lib/apiCall';
import { ID_APP_URL } from './lib/cognitoConfig';
import releaseToggles from '../../releaseToggles';
import { removeCookie } from '../../helpers/cookie.helper';

export const login$ = credentials => {
  const ajaxConfig = getAjaxConfig();
  ajaxConfig.url = loginURL;
  ajaxConfig.method = 'POST';
  ajaxConfig.body = credentials;
  return ajax(ajaxConfig);
};

export const ssoLogin = () => {
  setTimeout(() => {
    window.location = `${ssoLoginURL}/${renderConfig.ssoLoginUrl}`;
  }, 500);
};

export const logout$ = () => {
  if (!releaseToggles.cognitoAuthentication) {
    return apiCall$(logoutURL);
  }
  removeCookie('cognito-refresh-token');
  removeCookie('cognito-access-token');
  window.location.href = `${ID_APP_URL}/logout`;
  return EMPTY;
};
