import styled from 'styled-components';

const WidgetActions = styled.div`
  display: flex;
  flex: 1 0 auto;
  & .widgetHeaderGroup {
    flex: 1 0 auto;
    justify-content: flex-start;

    & h1 {
      font-size: 2rem;
      margin: 10px 15px;
      border-bottom: solid 1px;
      padding-bottom: 15px;
    }
    & h2 {
      display: inline-flex;
      font-size: 1.2rem;
      margin: 5px 15px 10px;

      & div.ui.checkbox {
        margin: 3px 10px;
      }
      & i {
        margin: 3px 10px;
        &.fa-check-circle {
          color: green;
        }
        &.fa-times-circle {
          color: red;
        }
      }
    }
  }

  & .ui.buttons {
    flex: 0 0 auto;
    justify-content: flex-end;
    margin: 0 5px;
    padding: 10px;
    height: 60px;
  }
`;

export default WidgetActions;
