import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { QueryParamProvider } from 'use-query-params';
import { Route } from 'react-router-dom';
import 'react-app-polyfill/ie11';
import '@alpima/picasso/build/index.css';
import 'semantic-ui-css/semantic.min.css';

import App from './App';
import history from './history';
import store from './store/store';
import './index.css';

const dateFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};

const MOUNT_POINT = document.getElementById('root');

const provider = (
  <IntlProvider formats={dateFormat} locale="en-GB">
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <App />
        </QueryParamProvider>
      </ConnectedRouter>
    </Provider>
  </IntlProvider>
);

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}

(async () => {
  ReactDOM.render(provider, MOUNT_POINT);
})();
