import * as constants from '../constants';

export const getUsers = () => ({
  type: constants.GET_USERS_LIST,
});

export const fetchUserDetails = id => ({
  type: constants.GET_USER_DETAILS,
  id,
});

export const setSearchResults = payload => ({
  type: constants.SET_USER_SEARCH_RESULTS,
  payload,
});

export const clearSearchResults = () => ({
  type: constants.CLEAR_USER_SEARCH_RESULTS,
});

export const getOnlineUsers = () => ({
  type: constants.GET_ONLINE_USERS,
});

export const getPendingUsers = () => ({
  type: constants.GET_PENDING_USERS,
});

export const getUpdatedContractRoleUsers = () => ({
  type: constants.GET_UPDATED_CONTRACT_ROLE_USERS,
});

export const stampUserAttribute = (key, value, data, compiler = args => args) => ({
  type: constants.STAMP_USER_DETAILS,
  compiler,
  data,
  key,
  value,
});

export const stampEnableUser = () => ({
  type: constants.STAMP_ENABLE_USER,
});
export const stampDisableUser = () => ({
  type: constants.STAMP_DISABLE_USER,
});

export const setUserUpdate = () => ({
  type: constants.REQUEST_USER_DETAILS_UPDATE,
});

export const setUserApproval = (id, approve, history) => ({
  type: constants.SET_USER_ONBOARDING_STATE,
  id,
  approve,
  history,
});

export const setUserContractRoleApproval = (id, approve, history) => ({
  type: constants.SET_USER_NEW_CONTRACT_ROLE,
  id,
  approve,
  history,
});

export const cancelUserUpdate = () => ({
  type: constants.SET_USER_DETAILS_CANCEL,
});

export const sortUsersByColumn = (column, direction, sortedData) => ({
  type: constants.SET_USER_COLUMN_SORT,
  payload: {
    direction,
    column,
    sortedData,
  },
});

export const requestUsersExport = json => ({
  type: constants.USERS_EXPORT_REQUEST,
  payload: json,
});

export const clearExportUsers = () => ({
  type: constants.USERS_EXPORT_CLEAR,
});
