import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';

import {
  requestStrategyCalculation,
  setRecalculationError,
} from '../../../store/actions/strategy.actions';
import {
  selectStrategyCalculationLoading,
  selectStrategyCalculationResponse,
  selectStrategyCalculationError,
} from '../../../store/selectors/strategy.selector';

import TextInput from '../../atoms/TextInput';
import Button from '../../atoms/Button';

const StrategyCalculation = styled.div`
  padding: 15px;
`;
const RecalculationResponse = styled.div`
  padding: 15px;
  p::before {
    content: 'Response: ';
    font-weight: 700;
  }
  p.error {
    color: red;
  }
  p.success {
    color: green;
  }
`;

const StrategyCalculationWidget = ({
  loading,
  submitCalculationRequest,
  handleRecalculationError,
  calculationResponse,
  calculationError,
}) => {
  const [strategyName, setStrategyName] = useState('');
  let response;

  if (
    !loading &&
    (calculationError ||
      (calculationResponse &&
        calculationResponse.save_to_db &&
        calculationResponse.save_to_db === true))
  ) {
    response = (
      <RecalculationResponse
        className="dashboard-strategy-recalculation"
        style={{ minHeight: '125px' }}
      >
        <p className={calculationError ? 'error' : 'success'}>
          {calculationError || 'Successfully Re-Calculated Strategy'}
        </p>
      </RecalculationResponse>
    );
  }

  const submit = () => {
    if (strategyName && strategyName !== '') {
      submitCalculationRequest(strategyName);
    } else {
      handleRecalculationError('No Strategy Name provided.');
    }
  };

  return (
    <StrategyCalculation className="dashboard-pending-users" style={{ minHeight: '125px' }}>
      <h2>Strategy Re-Calculation:</h2>
      <hr />
      <TextInput
        name="Enter Strategy ID or Unique ID:"
        type="text"
        data={{ strategyName }}
        resolver={data => data.strategyName}
        onChange={setStrategyName}
      />
      <Button primary onClick={submit}>
        Submit
      </Button>
      <hr />
      <Loader inline size="mini" active={loading} />
      {response}
    </StrategyCalculation>
  );
};

StrategyCalculationWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  submitCalculationRequest: PropTypes.func.isRequired,
  handleRecalculationError: PropTypes.func.isRequired,
  calculationResponse: PropTypes.string,
  calculationError: PropTypes.string,
};

StrategyCalculationWidget.defaultProps = {
  calculationResponse: null,
  calculationError: null,
};

const mapStateToProps = createStructuredSelector({
  loading: state => selectStrategyCalculationLoading(state),
  calculationResponse: state => selectStrategyCalculationResponse(state),
  calculationError: state => selectStrategyCalculationError(state),
});

const mapDispatchToProps = dispatch => ({
  submitCalculationRequest: name => dispatch(requestStrategyCalculation(name)),
  handleRecalculationError: error => dispatch(setRecalculationError(error)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StrategyCalculationWidget));
