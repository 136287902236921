import { createSelector } from 'reselect';
import releaseToggles from '../../releaseToggles';

const selectAuth = state => state.get('auth');

const makeSelectAuthenticated = createSelector(
  selectAuth,
  authState => releaseToggles.cognitoAuthentication || authState.get('isAuthenticated'),
);

const makeSelectInvalidRoles = createSelector(selectAuth, authState =>
  authState.get('invalidRoles'),
);

const makeSelectUser = createSelector(selectAuth, authState => authState.get('user'));

const makeSelectAuthLoading = createSelector(selectAuth, authState => authState.get('loading'));

const selectLoginMessage = state => {
  const reasons = {
    0: 'Sorry, something went wrong logging in.',
    1: 'Unable to login via Company Credentials (Error: 0x001)',
    2: 'Unable to login via Company Credentials (Error: 0x002)',
  };
  let reason;
  // eslint-disable-next-line consistent-return
  window.location.search
    .substring(1)
    .split('&')
    // eslint-disable-next-line consistent-return
    .forEach(param => {
      const [key, value] = param.split('=');
      if (key === 'reason') {
        reason = reasons[value] || decodeURIComponent(value);
        return false;
      }
    });

  return createSelector(selectAuth, authState => reason || authState.get('error'))(state);
};

export {
  selectAuth,
  makeSelectAuthenticated,
  makeSelectInvalidRoles,
  makeSelectAuthLoading,
  makeSelectUser,
  selectLoginMessage,
};
