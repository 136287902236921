import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react';
import moment from 'moment';

import { getInstruments } from '../../../store/actions/instruments.actions';
import {
  selectInstrumentList,
  selectInstrumentLoading,
  selectLastRefresh,
} from '../../../store/selectors/instruments.selector';

import LoadingBoundary from '../../atoms/LoadingBoundary';

const InstrumentPerformanceMonitoring = styled.div`
  padding: 15px;

  .rowWrapper {
    max-height: 415px;
    overflow-y: scroll;
  }

  a {
    color: ${props => props.theme.variables.highlightedText};
  }
`;

const InstrumentsPerfMonitoringWidget = props => {
  const { loading, lastRefresh, getInstrumentsList, instrumentList } = props;

  useEffect(() => {
    const now = new Date().getTime();
    if (!lastRefresh || now > lastRefresh + 60000) getInstrumentsList(); // 1 min timeout for bounce requests
  }, [getInstrumentsList, lastRefresh]);

  const getNumWorkDays = (startDate, endDate) => {
    let numWorkDays = 0;
    let currentDate = moment(new Date(startDate));
    while (currentDate <= endDate) {
      if (currentDate.day() !== 0 && currentDate.day() !== 6) {
        // eslint-disable-next-line no-plusplus
        numWorkDays++;
      }
      currentDate = currentDate.add(1, 'days');
    }
    return numWorkDays - 1; // -1 because we want to check yesterday, not today
  };

  const today = moment(new Date());
  const breachingInstruments = (instrumentList.reduce((a, b) => a.concat(b), []) || [])
    .filter(i => i && i.visible)
    .sort((a, b) => {
      const aDate = a && a.dataEndDate;
      const bDate = b && b.dataEndDate;
      if (!aDate) {
        return 1;
      }
      if (!bDate) {
        return -1;
      }
      return moment(aDate).diff(moment(bDate));
    })
    .map(instrument => {
      const performanceLastUpdated = moment(instrument.dataEndDate);
      const delta = getNumWorkDays(performanceLastUpdated, today);
      // eslint-disable-next-line no-restricted-globals
      const isOk = isNaN(delta) || delta < 0;

      const col2Format = performanceLastUpdated.isValid()
        ? performanceLastUpdated.format('ddd DD/MM/YYYY')
        : 'Unknown';
      const col3Format = isOk ? '' : `${delta} day${delta > 1 ? 's' : ''} old`;

      if (isOk) {
        return undefined; // will be removed in next filter
      }

      return !!delta || delta > 0 ? (
        <Segment vertical key={`instrumentBreach-${instrument._id}`}>
          <Grid>
            <GridRow>
              <GridColumn width={8}>
                <span>
                  <a href={`/instruments/${instrument._id}`}>{instrument.name}</a>
                </span>
              </GridColumn>
              <GridColumn width={4}>
                <strong>{col2Format}</strong>
              </GridColumn>
              <GridColumn width={4}>{col3Format}</GridColumn>
            </GridRow>
          </Grid>
        </Segment>
      ) : null;
    })
    .filter(i => i);

  const instruments =
    breachingInstruments && breachingInstruments.length ? (
      breachingInstruments
    ) : (
      <p>No instruments to report</p>
    );

  return (
    <LoadingBoundary isLoading={loading}>
      <InstrumentPerformanceMonitoring style={{ minHeight: '125px' }}>
        <h2>{`Instruments Performance Monitoring${
          breachingInstruments.length ? `: ${breachingInstruments.length}` : ''
        }`}</h2>
        <hr />
        <Grid>
          <GridRow>
            <GridColumn className="rowWrapper" width={16}>
              {instruments}
            </GridColumn>
          </GridRow>
        </Grid>
      </InstrumentPerformanceMonitoring>
    </LoadingBoundary>
  );
};

InstrumentsPerfMonitoringWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  lastRefresh: PropTypes.number.isRequired,
  getInstrumentsList: PropTypes.func.isRequired,
  instrumentList: PropTypes.object.isRequired,
};

InstrumentsPerfMonitoringWidget.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  instrumentList: state => selectInstrumentList(state),
  loading: state => selectInstrumentLoading(state),
  lastRefresh: state => selectLastRefresh(state),
});

const mapDispatchToProps = dispatch => ({
  getInstrumentsList: () => dispatch(getInstruments()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InstrumentsPerfMonitoringWidget);
