import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PageWrapper from '../../../molecules/PageWrapper';
import Dashboard from '../../../organisms/Dashboard';

const StrategiesPage = ({ match }) => (
  <PageWrapper>
    <div style={{ minHeight: '850px', background: '#F6F8FA' }}>
      <Switch>
        <Route
          exact
          path="/strategy/list/:page"
          render={() => (
            <Dashboard
              title="Strategies List"
              icon={{ name: 'th list', size: 'large' }}
              name="strategyList"
            />
          )}
        />
        <Route exact path="/strategy/list" render={() => <Redirect to={`${match.url}/1`} />} />
        <Route
          path="/strategy/:id"
          render={() => (
            <Dashboard title="Strategy" icon={{ name: 'th list', size: 'large' }} name="strategy" />
          )}
        />
        <Route
          exact
          path={`${match.url}`}
          render={() => (
            <Fragment>
              <p>Redirecting...</p>
              <Redirect to={`${match.url}/list`} />
            </Fragment>
          )}
        />
      </Switch>
    </div>
  </PageWrapper>
);

StrategiesPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(connect()(StrategiesPage));
