export const GET_INFORMATION_BANNERS = 'GET_INFORMATION_BANNERS';
export const GET_INFORMATION_BANNERS_SUCCESS = 'GET_INFORMATION_BANNERS_SUCCESS';
export const GET_INFORMATION_BANNERS_FAILURE = 'GET_INFORMATION_BANNERS_FAILURE';

export const TOGGLE_INFORMATION_BANNER_MODAL = 'TOGGLE_INFORMATION_BANNER_MODAL';
export const SAVE_INFORMATION_BANNER = 'SAVE_INFORMATION_BANNER';
export const SAVE_INFORMATION_BANNER_SUCCESS = 'SAVE_INFORMATION_BANNER_SUCCESS';
export const SAVE_INFORMATION_BANNER_FAILURE = 'SAVE_INFORMATION_BANNER_FAILURE';

export const ARCHIVE_INFORMATION_BANNER = 'ARCHIVE_INFORMATION_BANNER';
export const ARCHIVE_INFORMATION_BANNER_SUCCESS = 'ARCHIVE_INFORMATION_BANNER_SUCCESS';
export const ARCHIVE_INFORMATION_BANNER_FAILURE = 'ARCHIVE_INFORMATION_BANNER_FAILURE';
