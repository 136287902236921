import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Button, Search, Pagination } from 'semantic-ui-react';
import Papa from 'papaparse';
import moment from 'moment';
import renderThemeConfig from 'helpers/renderThemeConfig';
import renderConfig from 'helpers/renderConfig';
import {
  selectLastRefresh,
  selectUserList,
  selectOnlineUsers,
  selectUserListPage,
  selectUserLoading,
  selectUserSearchResults,
} from '../../../store/selectors/user.selector';
import { makeSelectUser } from '../../../store/selectors/auth.selector';
import {
  getUsers,
  sortUsersByColumn,
  setSearchResults as setSearchResultsAction,
  clearSearchResults as clearSearchResultsAction,
} from '../../../store/actions/user.actions';
import TableDefault from '../../molecules/TableDefault';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import { selectFunctionRolesList } from '../../../store/selectors/roles.selector';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';

const DownloadButton = styled(Button)`
  align-self: flex-start;
  padding: 14px 30px !important;
  margin-right: 20px !important;
`;

const WidgetHeaderWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
`;

const TableOptionWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;

  @media screen and (min-width: ${props => props.theme.variables.breakpoints.md}px) {
    flex-direction: row;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1 0 auto;
  margin-bottom: 10px;
`;

const SearchBox = styled(Search)`
  width: 350px;
  & input {
    border-radius: 5px !important;
  }
  & .results.transition {
    width: 100%;
    display: none !important;
  }
`;
const ResultCount = styled.span`
  position: relative;
  top: 0;
  padding: 5px 15px;
  text-align: center;
  line-height: 25px;
`;

const ClearResults = styled.button`
  position: relative;
  top: 0;
  padding: 0 15px;
  background: transparent;
  border: none;
  height: 35px;
  border-left: solid 1px #666;
  color: #4c8ed6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
  &::after {
    content: 'Clear';
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0 0 auto;
  margin-bottom: 15px;

  & .csv-export {
    margin: 5px 15px;
    &:hover {
      & span {
        text-decoration: underline;
      }
    }

    & span {
      padding: 5px 10px;
    }
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 auto;
  flex-direction: column;

  &.hidden {
    display: none;
  }
`;

function getCSVforExport(data) {
  const csvHeaderFields = {
    Enabled: 'enabled',
    Name: 'displayName',
    Username: 'username',
    Company: 'company.name',
    Email: 'email',
    Created: 'created',
  };
  const csvUserData = data.map(instrument =>
    Object.values(csvHeaderFields).map(key => _.get(instrument, key)),
  );
  const csvDataURI =
    data &&
    `data:text/csv;charset=utf-8,${encodeURIComponent(
      Papa.unparse(
        { fields: Object.keys(csvHeaderFields), data: csvUserData },
        { escapeFormulae: true },
      ),
    )}`;

  const datetime = moment().format('YYYY-MM-DD.HHmm');
  const csvFilename = `usersExport-${datetime}.csv`;
  return { csvDataURI, csvFilename };
}

const UserTableWidget = props => {
  const {
    theme,
    match,
    history,
    authenticatedUser,
    loading,
    lastRefresh,
    getUserList,
    userList,
    onlineUsers,
    functionRoles,
    paginatedUserList,
    paginatedSearchResults,
    setSortedUsers,
    setSearchResults,
    clearSearchResults,
  } = props;
  const [search, setSearch] = useState({
    searching: false,
    value: '',
    results: null,
  });

  const { formatTime } = useDateTimeFormatter();

  useEffect(() => {
    const now = new Date().getTime();
    if (!lastRefresh || now > lastRefresh + 10000) getUserList(); // 10s timeout for bounce requests

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [authenticatedUser, getUserList, lastRefresh, onlineUsers]);

  const handlePaginationChange = (e, { activePage }) =>
    history.push(match.path.replace(':page', activePage));

  const handleClearSearch = () => {
    setSearch({ searching: false, value: '', results: null });
    clearSearchResults();
  };

  const handleSearch = value => {
    if (value === '') {
      return handleClearSearch();
    }

    const re = new RegExp(_.escapeRegExp(value || ''), 'i');
    const isMatch = result => re.test(result.displayName);
    const flattenedUserList = userList.reduce((a, b) => a.concat(b));
    const filteredResults = _.filter(flattenedUserList, isMatch);

    setSearch({
      ...search,
      value,
      searching: false,
      results: filteredResults,
    });

    return setSearchResults(filteredResults);
  };

  // const handleSearchDebounced = _.debounce(handleSearch, 500, { leading: true });

  const handleSearchInput = (e, { value }) => {
    setSearch({
      ...search,
      value,
      searching: true,
    });

    handleSearch(value);
    // setTimeout(() => handleSearchDebounced(value), 500);
  };

  const paginationPageCount =
    (paginatedSearchResults && paginatedSearchResults.length
      ? paginatedSearchResults.length
      : userList.length) || 0;
  const data = (search.results || paginatedUserList || []).map(user => {
    const functionalRoles =
      functionRoles &&
      functionRoles.length &&
      user.roles.filter(role => ~functionRoles.indexOf(role));
    return {
      ...user,
      functionalRoles,
    };
  });
  const { csvDataURI, csvFilename } = getCSVforExport(userList.flat());

  return (
    <LoadingBoundary isLoading={loading}>
      <div style={{ minHeight: '850px', overflowX: 'scroll', overflowY: 'hidden' }}>
        <WidgetHeaderWrapper theme={theme}>
          <TableOptionWrapper>
            <SearchWrapper>
              <SearchBox
                loading={search.searching}
                onSearchChange={handleSearchInput}
                placeholder="Search"
                value={search.value}
                input={{
                  style: {
                    width: '100%',
                  },
                }}
              />
              {search.results ? (
                <Fragment>
                  <ResultCount>Results: {search.results.length}</ResultCount>
                  <ClearResults onClick={handleClearSearch} />
                </Fragment>
              ) : null}
            </SearchWrapper>
            {renderConfig.showSvgDownloadButton && (
              <DownloadButton
                type="button"
                download={csvFilename}
                href={csvDataURI}
                icon="table"
                content="Download CSV"
              />
            )}
            <OptionsWrapper>
              <PaginationWrapper className={paginationPageCount < 2 ? 'hidden' : ''}>
                <Pagination
                  boundaryRange={0}
                  siblingRange={1}
                  totalPages={paginationPageCount}
                  activePage={match.params.page}
                  onPageChange={handlePaginationChange}
                />
                <sub style={{ marginTop: 14, fontSize: '1rem', textAlign: 'right' }}>
                  Last refresh: {formatTime(lastRefresh)}
                </sub>
              </PaginationWrapper>
            </OptionsWrapper>
          </TableOptionWrapper>
        </WidgetHeaderWrapper>
        <TableDefault
          striped
          selectable
          name="User List"
          data={data}
          fullData={userList.reduce((a, b) => a.concat(b), []) || []}
          onClickLink={user => {
            history.push(`/user/${user._id}`);
          }}
          onSort={setSortedUsers}
          columns={renderThemeConfig.default.userColumns}
        />
      </div>
    </LoadingBoundary>
  );
};

UserTableWidget.propTypes = {
  theme: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  lastRefresh: PropTypes.number.isRequired,
  authenticatedUser: PropTypes.object,
  getUserList: PropTypes.func.isRequired,
  userList: PropTypes.object.isRequired,
  onlineUsers: PropTypes.array.isRequired,
  functionRoles: PropTypes.array.isRequired,
  paginatedUserList: PropTypes.array,
  paginatedSearchResults: PropTypes.array,
  setSortedUsers: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
  clearSearchResults: PropTypes.func.isRequired,
};

UserTableWidget.defaultProps = {
  theme: {},
  authenticatedUser: {},
  paginatedUserList: [],
  paginatedSearchResults: [],
};

const mapStateToProps = createStructuredSelector({
  authenticatedUser: state => makeSelectUser(state),
  userList: state => selectUserList(state),
  onlineUsers: state => selectOnlineUsers(state),
  functionRoles: state => selectFunctionRolesList(state),
  paginatedUserList: state => selectUserListPage(state),
  paginatedSearchResults: state => selectUserSearchResults(state),
  loading: state => selectUserLoading(state),
  lastRefresh: state => selectLastRefresh(state),
});

const mapDispatchToProps = dispatch => ({
  getUserList: () => dispatch(getUsers()),
  setSearchResults: results => dispatch(setSearchResultsAction(results)),
  clearSearchResults: () => dispatch(clearSearchResultsAction()),
  setSortedUsers: (column, direction, sortedData) =>
    dispatch(sortUsersByColumn(column, direction, sortedData)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserTableWidget));
