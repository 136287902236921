import { Options } from 'highcharts';
import { RunningAlertsCount } from './DataMonitoringDashboard.types';

export default function liveStrategiesChart(liveStrategies: RunningAlertsCount[]): Options {
  return {
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: 'pie',
      height: 250,
      width: 250 + 200,
    },
    legend: {
      enabled: true,
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'middle',
      labelFormat: '{name} ({y})',
    },
    series: [
      {
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
        name: 'Live Strategies',
        type: 'pie',
        data: liveStrategies.map(
          ({ assetClass, count }: { assetClass: string; count: number }) => ({
            name: assetClass,
            y: count,
          }),
        ),
      },
    ],
  };
}
