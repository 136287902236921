import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

const UIModal = ({ children, active, onClose, data }) => (
  <Modal open={active} onClose={onClose} closeIcon dimmer="blurring">
    <Modal.Header>{data.header}</Modal.Header>
    <Modal.Content scrolling>
      <Modal.Description>{children}</Modal.Description>
    </Modal.Content>
    <Modal.Actions>{data.actions}</Modal.Actions>
  </Modal>
);

UIModal.defaultProps = {};
UIModal.propTypes = {
  children: PropTypes.any.isRequired,
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    header: PropTypes.string.isRequired,
    actions: PropTypes.array,
  }).isRequired,
};

export default UIModal;
