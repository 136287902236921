import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Icon, Label, Loader, Table } from 'semantic-ui-react';
import { getTasks$ } from '../../../../../services/clientAPI/dataRefresh.service';
import { ExistingTasksWrapper, TitleRow } from './atoms';

const formatDate = date => moment(date).format('D MMM, YYYY HH:MM');

const Aggregate = () => <Label style={{ marginLeft: '5px' }}>Aggregate</Label>;

function SubtasksTable({ queries }) {
  return (
    <Table style={{ marginLeft: '30px', width: '870px' }} basic color="blue">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ padding: '10px' }}>Source</Table.HeaderCell>
          <Table.HeaderCell style={{ padding: '10px' }}>Target</Table.HeaderCell>
          <Table.HeaderCell style={{ padding: '10px' }}>Status</Table.HeaderCell>
          <Table.HeaderCell style={{ padding: '10px' }}>Date</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {queries.map(({ source, target, status, created, type }) => (
          <Table.Row key={`${source}-${target}-${created}`}>
            <Table.Cell style={{ padding: '10px' }}>
              {source}
              {type === 'AGGREGATE' ? <Aggregate /> : null}
            </Table.Cell>
            <Table.Cell style={{ padding: '10px' }}>{target}</Table.Cell>
            <Table.Cell style={{ padding: '10px' }}>{status}</Table.Cell>
            <Table.Cell style={{ padding: '10px' }}>
              {created ? formatDate(created) : '-'}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

SubtasksTable.propTypes = {
  queries: PropTypes.array.isRequired,
};

function ExistingTask({
  operation,
  requestor,
  status,
  created,
  queries,
  collapsed,
  toggleCollapsed,
}) {
  const collapseText = queries.length ? (collapsed ? '+' : '–') : '';
  return (
    <>
      <Table.Row>
        <Table.Cell>
          {/* eslint-disable-next-line */}
          <span
            style={{ fontWeight: 'bold', cursor: 'pointer', userSelect: 'none' }}
            onClick={toggleCollapsed}
          >
            <span style={{ width: '1em', display: 'inline-block' }}>{collapseText}</span>
            {operation}
          </span>
        </Table.Cell>
        <Table.Cell style={{ display: 'flex', alignItems: 'center' }}>
          <Icon name="user" size="small" />
          {requestor}
        </Table.Cell>
        <Table.Cell style={{ textTransform: 'capitalize' }}>{status.toLocaleString()}</Table.Cell>
        <Table.Cell textAlign="right">{created ? formatDate(new Date(created)) : '–'}</Table.Cell>
      </Table.Row>
      <Table.Row>
        {queries && queries.length !== 0 && !collapsed && (
          <Table.Cell colSpan={4}>
            <SubtasksTable queries={queries} />
          </Table.Cell>
        )}
      </Table.Row>
    </>
  );
}

ExistingTask.propTypes = {
  operation: PropTypes.string.isRequired,
  requestor: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  queries: PropTypes.array.isRequired,
  collapsed: PropTypes.bool.isRequired,
  toggleCollapsed: PropTypes.func.isRequired,
};

export default function ViewExistingTasks({ onCreateNew }) {
  const [data, setData] = useState(null);
  const [operationsCollapsed, setOperationsCollapsed] = useState({});
  const toggleCollapsed = operation => () =>
    setOperationsCollapsed(old => ({ ...old, [operation]: !old[operation] }));
  const [loadStatus, setLoadStatus] = useState('load');

  const refresh = useCallback((loadingType = 'refresh') => {
    setLoadStatus(loadingType);
    getTasks$().subscribe(loadedData => {
      setData(loadedData);
      setLoadStatus('done');
    });
  }, []);

  useEffect(() => {
    refresh('load');
  }, [refresh]);

  return (
    <ExistingTasksWrapper>
      <TitleRow>
        <h1>Data Refresh Tasks</h1>
        <span>
          <Button
            secondary
            disabled={loadStatus !== 'done'}
            onClick={() => refresh('refresh')}
            style={{ marginRight: '10px' }}
          >
            Refresh Tasks
            <Icon
              name="refresh"
              loading={loadStatus === 'refresh'}
              style={{ marginLeft: '7px', marginRight: '-5px' }}
            />
          </Button>
          <Button primary onClick={onCreateNew}>
            Add New
            <Icon name="plus" style={{ marginLeft: '7px', marginRight: '-5px' }} />
          </Button>
        </span>
      </TitleRow>
      {loadStatus === 'load' && <Loader active inline="centered" />}

      {data && (
        <Table basic="very" style={{ maxWidth: '900px' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Operation</Table.HeaderCell>
              <Table.HeaderCell>Requestor</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Created</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data.map(op => {
              const key = `${op.operation}-${op.created}`;
              return (
                <ExistingTask
                  key={key}
                  {...op}
                  collapsed={!!operationsCollapsed[key]}
                  toggleCollapsed={toggleCollapsed(key)}
                />
              );
            })}
          </Table.Body>
        </Table>
      )}
    </ExistingTasksWrapper>
  );
}

ViewExistingTasks.propTypes = {
  onCreateNew: PropTypes.func.isRequired,
};
