import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  setFluidLayout,
  setTabsLayout,
  setLinkPageLayout,
} from '../../../store/actions/layout.actions';
import {
  makeSelectFluidLayout,
  makeSelectTabsLayout,
  makeSelectLinkPageLayout,
} from '../../../store/selectors/layout.selector';

const Switcher = styled.div`
  height: 80px;
  width: 30px;
  position: absolute;
  right: 10px;
  top: 110px;
  cursor: pointer;
`;

const FluidSwitcher = ({ setFluid, setTabs, setLinkPage, fluid, tabs, linkPage }) => (
  <Switcher>
    <Icon
      bordered
      name="expand arrows alternate"
      inverted={fluid}
      color="blue"
      onClick={setFluid}
    />
    <Icon bordered name="th" color="blue" inverted={!tabs} onClick={setTabs} />
    <Icon
      bordered
      name="external alternate"
      color="blue"
      inverted={linkPage}
      onClick={setLinkPage}
    />
  </Switcher>
);

FluidSwitcher.propTypes = {
  fluid: PropTypes.bool.isRequired,
  tabs: PropTypes.bool.isRequired,
  linkPage: PropTypes.bool.isRequired,
  setFluid: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired,
  setLinkPage: PropTypes.func.isRequired,
};

const PageWrapper = ({ children, fluid, tabs, linkPage, setFluid, setTabs, setLinkPage }) => (
  <Fragment>
    <Grid style={{ padding: 0 }}>
      <Grid.Row style={{ padding: 0, marginTop: 90 }}>
        {!fluid && <Grid.Column width="3" only="computer" />}
        <Grid.Column mobile={16} tablet={16} computer={fluid ? 16 : 10}>
          {children}
        </Grid.Column>
        {!fluid && <Grid.Column width="3" only="computer" />}
      </Grid.Row>
    </Grid>
    {!fluid && (
      <FluidSwitcher
        setFluid={setFluid}
        setTabs={setTabs}
        setLinkPage={setLinkPage}
        fluid={fluid}
        tabs={tabs}
        linkPage={linkPage}
      />
    )}
  </Fragment>
);

PageWrapper.defaultProps = {
  fluid: false,
  tabs: false,
  linkPage: false,
};

PageWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  tabs: PropTypes.bool,
  linkPage: PropTypes.bool,
  setFluid: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired,
  setLinkPage: PropTypes.func.isRequired,
};

export const mapDispatchToProps = dispatch => ({
  setFluid: () => dispatch(setFluidLayout()),
  setTabs: () => dispatch(setTabsLayout()),
  setLinkPage: () => dispatch(setLinkPageLayout()),
});

const mapStateToProps = createStructuredSelector({
  fluid: state => makeSelectFluidLayout(state),
  tabs: state => makeSelectTabsLayout(state),
  linkPage: state => makeSelectLinkPageLayout(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageWrapper);
