import { DateTime } from 'luxon';

// This is needed to cast a value to string because an API sends us a number value from both endpoints
export const timeOptions = [
  { text: '4am', key: '4am', value: '4am' },
  { text: '6am', key: '6am', value: '6am' },
  { text: '8am', key: '8am', value: '8am' },
  { text: '10am', key: '10am', value: '10am' },
  { text: '12pm', key: '12pm', value: '12pm' },
  { text: '2pm', key: '2pm', value: '2pm' },
  { text: '4pm', key: '4pm', value: '4pm' },
  { text: '6pm', key: '6pm', value: '6pm' },
  { text: '8pm', key: '8pm', value: '8pm' },
];

export const today = DateTime.now();
export const yesterday = DateTime.now().minus({ days: 1 });

export const oneMonthAgo = yesterday.minus({ months: 1 });
