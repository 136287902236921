import { Icons } from '@alpima/picasso';

type Company = {
  _id: string;
  name: string;
};

export enum Order {
  Ascending = 'ascending',
  Descending = 'descending',
}

export type UserTableHeader<T> = {
  name: string;
  property: keyof T;
  sortOrderProperty?: string;
  secondSortOrderProperty?: string;
  icon?: Icons;
  className?: string;
  enabled: boolean;
};

export type User = {
  enabled: boolean;
  lastActivity?: string;
  accountType: string;
  contractRole?: string;
  created: string;
  displayName: string;
  bjbLocation?: string;
  online: boolean;
  username: string;
  _id: string;
  bjbCountry?: string;
  email: string;
  company: Company;
  roles: string[];
};

export type Employment = {
  address: {
    postCode: string;
    street1: string;
  };
  business: string;
  occupation: string;
  _id: string;
};

export type Client = {
  _id: string;
  enabled: boolean;
  firstName: string;
  lastName: string;
  employment: Employment[];
  email: string;
  username: string;
  created: string;
  contractRole: string;
  lastActivity?: string;
  logs?: LogEntry[];
  company?: string;
  jobTitle?: string;
  firstNameDisplayText?: string;
  usernameDisplayText?: string;
  companyDisplayText?: string;
  emailDisplayText?: string;
  jobTitleDisplayText?: string;
  contractRoleDisplayText?: string;
};

export type LogEntry = {
  details: string;
  date: string;
  user: {
    _id: string;
    displayName: string;
  };
};
