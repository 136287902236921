import React from 'react';
import { useSelector } from 'react-redux';
import { Illustrations, Popover, PopoverLink } from '@alpima/picasso';
import renderConfig from 'helpers/renderConfig';
import toggleFeatureByRole from 'helpers/toggleFeatueByRole';
import { makeSelectUser } from '../../../store/selectors/auth.selector';
import releaseToggles from '../../../releaseToggles';

type Props = {
  hidePopover: () => void;
};

const AppsPopover = ({ hidePopover }: Props) => {
  const currentUser = useSelector(makeSelectUser);
  const appSections = [
    {
      title: 'Dashboard',
      description: 'Get a quick overview of the most important metrics.',
      illustration: Illustrations.BUILD,
      link: '/dashboard',
      visible: renderConfig.showDashboardPage,
    },
    {
      title: 'Users',
      description: 'Access and manage all your users and clients.',
      illustration: Illustrations.EXPLORE,
      link: '/user',
      visible: toggleFeatureByRole(currentUser, renderConfig.showUsersPage),
    },
    {
      title: 'Logs',
      description: 'Overview of all the events all your users triggered.',
      illustration: Illustrations.REQUEST,
      link: '/logs',
      visible: toggleFeatureByRole(currentUser, renderConfig.showLogsPage),
    },
    {
      title: 'Data Monitoring',
      description: "Live dashboard of today's strategy calculations",
      illustration: Illustrations.COMPARE,
      link: '/data-monitoring',
      visible:
        toggleFeatureByRole(currentUser, renderConfig.dataMonitoring) &&
        releaseToggles.dataMonitoring,
    },
  ].filter(i => i.visible === true);

  return (
    <Popover hidePopover={hidePopover} classNames="top-16 right-48 w-96 p-4">
      <div className="flex flex-col">
        {appSections.map(section => (
          <PopoverLink
            title={section.title}
            key={section.title}
            description={section.description}
            illustration={section.illustration}
            to={section.link}
            onClick={hidePopover}
          />
        ))}
      </div>
    </Popover>
  );
};

export default AppsPopover;
