export type ListAsset = {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
};

export enum Order {
  Ascending = 'ascending',
  Descending = 'descending',
}

export type User = {
  _id: string;
  firstName: string;
  lastName: string;
};

export type UserGroup = {
  _id: string;
  createdUser: User;
  name: string;
  strategies: [];
  users: [User];
  created: User;
};

export interface UserGroupSearchProps extends UserGroup {
  searchProperties?: {
    name: string;
    users: string;
    created: string;
  };
}
