import { fromJS } from 'immutable';

import * as constants from '../constants';

const now = new Date();
const initialState = fromJS({
  loading: false,
  fetching: false,
  lastRefresh: -1,
  error: null,
  dailyErrorCount: 0,
  dailyErrorCountLoading: false,
  dailyErrorCountError: null,
  list: [],
  metadata: {
    totalPages: 1,
    currentPage: 1,
  },
  filterContent: {},
  filter: {
    companies: ['583ed887b144df9066973f8f'], // BJB
    levels: [],
    loadTotalPages: true,
    onlyEnabledUsers: false,
    pageNumber: 1,
    pageSize: 40,
    rangeFrom: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0).toISOString(),
    rangeTo: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59).toISOString(),
    timeFilterSelection: 2021,
    types: [],
    groups: [],
    users: [],
    sortDirection: 'desc',
    sortField: 'created',
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_LOGS_LIST:
      return state.mergeDeep({
        fetching: true,
        filter: {
          pageNumber: 1,
        },
      });

    case constants.GET_LOGS_LIST_SUCCESS:
      return state.merge({
        list: action.payload.logs,
        metadata: action.payload.meta,
        fetching: false,
        lastRefresh: new Date().getTime(),
        error: null,
      });

    case constants.GET_LOGS_LIST_FAILURE:
      return state.merge({
        fetching: false,
        error: action.payload ? action.payload.error : action.error,
      });

    case constants.GET_LOG_FILTERS_SUCCESS:
      return state.merge({
        filterContent: action.payload,
      });
    case constants.GET_LOG_FILTERS_FAILURE:
      return state.merge({
        filterContent: {},
        error: action.payload ? action.payload.error : action.error,
      });

    case constants.GET_LOG_DAILY_ERROR_COUNT:
      return state.set('dailyErrorCountLoading', true);
    case constants.GET_LOG_DAILY_ERROR_COUNT_SUCCESS:
      return state.merge({
        dailyErrorCount: action.payload,
        dailyErrorCountLoading: false,
        dailyErrorCountError: null,
      });
    case constants.GET_LOG_DAILY_ERROR_COUNT_FAILURE:
      return state.merge({
        dailyErrorCount: 0,
        dailyErrorCountLoading: false,
        dailyErrorCountError: action.payload ? action.payload.error : action.error,
      });

    case constants.RESET_LOG_FILTERS:
      return state.set('filter', initialState.get('filter'));

    case constants.SET_LOG_FILTER_STATE:
      return state.setIn(['filter', action.key], fromJS(action.payload));

    case constants.SET_LOG_TIME_FILTER_STATE:
      return state.mergeIn(['filter'], {
        rangeFrom: new Date(action.payload.start).toISOString(),
        rangeTo: new Date(action.payload.end).toISOString(),
        timeFilterSelection: action.payload.selection,
      });

    case constants.SET_LOG_COLUMN_SORT:
      return state.mergeDeep({
        fetching: true,
        filter: {
          sortDirection: action.payload.direction,
          sortField: action.payload.column,
          pageNumber: 1,
        },
      });

    case constants.LOGS_LIST_NAVIGATION:
      return state.mergeDeep({
        fetching: true,
        filter: {
          pageNumber: action.payload,
        },
      });

    case constants.CLEAR_SESSION:
    case constants.LOGOUT_SUCCEEDED:
      return initialState;

    default:
      return state;
  }
};
