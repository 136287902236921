export const HIGH_SEVERITY = 0;
export const MEDIUM_SEVERITY = 1;
export const INFO_SEVERITY = 2;

export function getSeverityLevelColor(severityLevel) {
  switch (severityLevel) {
    case HIGH_SEVERITY:
      return 'red';
    case MEDIUM_SEVERITY:
      return 'orange';
    case INFO_SEVERITY:
      return 'blue';
    default:
      return undefined;
  }
}
