/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

const WidgetDefaultTitleWrapper = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: inherit;
  font-family: 'Open Sans', sans-serif;
`;

const WidgetDefaultTitle = ({ children }) => (
  <WidgetDefaultTitleWrapper>{children}</WidgetDefaultTitleWrapper>
);

export default WidgetDefaultTitle;
