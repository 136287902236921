import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { getUserLoginTableData } from '../../../store/actions/monitoring.actions';
import { getUsers } from '../../../store/actions/user.actions';
import {
  selectLastRefreshTable as selectLastRefresh,
  selectCacheTimestampTable,
  selectLoginDataTable,
  selectMonitoringTableLoading,
} from '../../../store/selectors/monitoring.selector';
import {
  selectLastRefresh as selectLastUserRefresh,
  selectUserList,
} from '../../../store/selectors/user.selector';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';
import { makeSelectCurrentTheme } from '../../../store/selectors/themes.selector';

const ChartLink = styled.span`
  padding: 0 15px 0;
  border-right: solid 1px #999;
  color: black;
  cursor: pointer;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    border-right: none;
  }
  &.active {
    color: ${props => props.theme.variables.primary};
    font-weight: 700;
  }
`;

const Table = styled.table`
  border: solid 1px #333;
  width: 100%;
  text-align: center;
  border-collapse: collapse;

  tr,
  th,
  td {
    border: solid 1px #333;
  }
  th {
    background: #ccc;
  }
  td {
    padding: 5px;
  }
`;

const compileTableContent = loginData => {
  const parsedLoginData = {
    overall: {},
    roles: {},
  };
  Object.entries(loginData).forEach(([parameter, logins]) => {
    if (parameter && parameter === 'monthly') {
      Object.entries(logins).forEach(([month, monthData]) =>
        Object.entries(monthData).forEach(([role, roleData]) => {
          // Initialise
          parsedLoginData.overall = parsedLoginData.overall || {};
          parsedLoginData.overall[month] = parsedLoginData.overall[month] || 0;
          parsedLoginData.roles[role] = parsedLoginData.roles[role] || {};
          parsedLoginData.roles[role][month] = parsedLoginData.roles[role][month] || 0;

          // Increment
          if (role.toLowerCase() !== 'unknown' && role.toLowerCase() !== 'bjbsupport') {
            parsedLoginData.overall[month] += roleData;
          }
          parsedLoginData.roles[role][month] += roleData;
        }),
      );
    } else {
      Object.entries(logins).forEach(([role, roleData]) => {
        // Initialise
        parsedLoginData.overall = parsedLoginData.overall || {};
        parsedLoginData.overall[parameter] = parsedLoginData.overall[parameter] || 0;
        parsedLoginData.roles[role] = parsedLoginData.roles[role] || {};
        parsedLoginData.roles[role][parameter] = parsedLoginData.roles[role][parameter] || 0;

        // Increment
        if (role.toLowerCase() !== 'unknown' && role.toLowerCase() !== 'bjbsupport') {
          parsedLoginData.overall[parameter] += roleData;
        }
        parsedLoginData.roles[role][parameter] += roleData;
      });
    }
  });
  return parsedLoginData;
};

const MonitorLoginsTableWidget = ({
  loading,
  // lastRefresh,
  cacheTimestamp,
  loginData,
  lastUserRefresh,
  userList,
  getLoginTableData,
  getUserList,
}) => {
  const client = useSelector(makeSelectCurrentTheme);
  const [activeYear, setYear] = useState(2022);
  const { formatDateTime } = useDateTimeFormatter();

  // Get login logs for table
  useEffect(() => {
    getLoginTableData(activeYear);
  }, [getLoginTableData, activeYear]);

  // Get company users for active licence count
  useEffect(() => {
    const now = new Date().getTime();
    if (!lastUserRefresh || now > lastUserRefresh + 60000) getUserList(); // 1m timeout for bounce requests

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [getUserList, lastUserRefresh]);

  const parsedLoginData = compileTableContent(loginData);
  const LICENCES =
    client === 'Natixis'
      ? { overall: 20 }
      : {
          overall: 210,
          unknown: 0,
          itso: 5,
          poweruser: 10,
          quantuser: 5,
          imuser: 180,
          readonly: 10,
        };
  const overallLabel = client === 'Natixis' ? 'Natixis Users' : 'Overall';
  const layout = [{ identifier: 'overall', label: overallLabel, data: parsedLoginData.overall }];
  const columns = [
    { identifier: 'today', label: 'Today' },
    { identifier: 'yesterday', label: 'Yesterday' },
    { identifier: 'ytd', label: 'YTD' },
    { identifier: '0', label: 'Jan' },
    { identifier: '1', label: 'Feb' },
    { identifier: '2', label: 'Mar' },
    { identifier: '3', label: 'Apr' },
    { identifier: '4', label: 'May' },
    { identifier: '5', label: 'Jun' },
    { identifier: '6', label: 'Jul' },
    { identifier: '7', label: 'Aug' },
    { identifier: '8', label: 'Sept' },
    { identifier: '9', label: 'Oct' },
    { identifier: '10', label: 'Nov' },
    { identifier: '11', label: 'Dec' },
    { identifier: 'sinceInception', label: 'S.I.' },
  ];

  const activeUsers = (userList || [])
    .reduce((a, b) => a.concat(b), [])
    .filter(user => user.enabled)
    .map(user => user.contractRole || 'unknown')
    .reduce((counts, contractRole) => {
      const role = contractRole.toLowerCase();
      let roleCount = counts[role] || 0;
      roleCount += 1;
      return {
        ...counts,
        [role]: roleCount,
      };
    }, {});
  const totalActiveCount = Object.entries(activeUsers).reduce(
    (total, [role, count]) =>
      role.toLowerCase() !== 'unknown' && role.toLowerCase() !== 'bjbsupport'
        ? total + count
        : total,
    0,
  );

  const renderTableContent = () => (
    <Fragment>
      {layout.map((entry, index) => (
        <tr key={`login-layout-entry-${index}`}>
          <td>{entry.label}</td>
          <td>{LICENCES[entry.identifier && entry.identifier.toLowerCase()]}</td>
          <td>{totalActiveCount}</td>
          {columns.map((column, colIndex) => (
            <td key={`login-layout-column-entry-${colIndex}`}>
              {entry.data && entry.data[column.identifier]}
            </td>
          ))}
        </tr>
      ))}
      {client !== 'Natixis' &&
        Object.entries(parsedLoginData.roles).map(([role, roleData], index) =>
          role.toLowerCase() !== 'unknown' && role.toLowerCase() !== 'bjbsupport' ? (
            <tr key={`login-role-entry-${index}`}>
              <td>{role}</td>
              <td>{LICENCES[role && role.toLowerCase()]}</td>
              <td>{activeUsers[role.toLowerCase()]}</td>
              {columns.map((column, colIndex) => (
                <td key={`login-role-column-entry-${colIndex}`}>
                  {roleData && roleData[column.identifier]}
                </td>
              ))}
            </tr>
          ) : null,
        )}
      {parsedLoginData.roles && parsedLoginData.roles.BJBSupport ? (
        <tr key="login-role-entry-BJBSupport" style={{ background: '#e5e5e5' }}>
          <td>BJB Support</td>
          <td>-</td>
          <td>{activeUsers.bjbsupport || 0}</td>
          {columns.map((column, colIndex) => (
            <td key={`login-role-column-entry-${colIndex}`}>
              {parsedLoginData.roles.BJBSupport &&
                parsedLoginData.roles.BJBSupport[column.identifier]}
            </td>
          ))}
        </tr>
      ) : null}
      {client !== 'Natixis' && parsedLoginData.roles && parsedLoginData.roles.unknown ? (
        <tr key="login-role-entry-unknown" style={{ background: '#e5e5e5' }}>
          <td>Unknown</td>
          <td>-</td>
          <td>{activeUsers.unknown || 0}</td>
          {columns.map((column, colIndex) => (
            <td key={`login-role-column-entry-${colIndex}`}>
              {parsedLoginData.roles.unknown && parsedLoginData.roles.unknown[column.identifier]}
            </td>
          ))}
        </tr>
      ) : null}
    </Fragment>
  );

  return (
    <LoadingBoundary isLoading={loading}>
      <Fragment>
        <div style={{ marginBottom: 10 }}>
          {client !== 'Natixis' && (
            <ChartLink
              className={activeYear === 2019 ? 'active' : ''}
              onClick={() => setYear(2019)}
            >
              2019
            </ChartLink>
          )}
          <ChartLink className={activeYear === 2020 ? 'active' : ''} onClick={() => setYear(2020)}>
            2020
          </ChartLink>
          <ChartLink className={activeYear === 2021 ? 'active' : ''} onClick={() => setYear(2021)}>
            2021
          </ChartLink>
          <ChartLink className={activeYear === 2022 ? 'active' : ''} onClick={() => setYear(2022)}>
            2022
          </ChartLink>
          <span style={{ margin: '0 15px', fontSize: '1rem', textAlign: 'right' }}>
            Cached since: {formatDateTime(cacheTimestamp)}
          </span>
        </div>
        <Table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>User Licences</th>
              <th>Active Users</th>
              {columns.map(column => (
                <th key={`column-header-${column.label}`}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>{renderTableContent()}</tbody>
        </Table>
        <h5>* Please note that the table above is compiled using log data for users</h5>
      </Fragment>
    </LoadingBoundary>
  );
};

MonitorLoginsTableWidget.propTypes = {
  loading: PropTypes.bool.isRequired,
  // lastRefresh: PropTypes.number.isRequired,
  cacheTimestamp: PropTypes.number.isRequired,
  loginData: PropTypes.object.isRequired,
  lastUserRefresh: PropTypes.number.isRequired,
  getLoginTableData: PropTypes.func.isRequired,
  userList: PropTypes.array.isRequired,
  getUserList: PropTypes.func.isRequired,
};

MonitorLoginsTableWidget.defaultProps = {};

const mapStateToProps = createStructuredSelector({
  loading: state => selectMonitoringTableLoading(state),
  lastRefresh: state => selectLastRefresh(state),
  cacheTimestamp: state => selectCacheTimestampTable(state),
  loginData: state => selectLoginDataTable(state),
  userList: state => selectUserList(state),
  lastUserRefresh: state => selectLastUserRefresh(state),
});

const mapDispatchToProps = dispatch => ({
  getLoginTableData: year => dispatch(getUserLoginTableData(year)),
  getUserList: () => dispatch(getUsers()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitorLoginsTableWidget);
