import React, { ChangeEventHandler } from 'react';

const Checkbox = ({
  disabled,
  checked = false,
  onChange,
  className = '',
  id = '',
}: {
  disabled?: boolean;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  id?: string;
}) => (
  <input
    type="checkbox"
    id={`checkbox-${id}`}
    checked={checked}
    disabled={disabled}
    onChange={onChange}
    className={`form-checkbox border-2 rounded w-6 h-6 focus:ring-0 
    focus-within:ring-0 cursor-pointer ${className}`}
  />
);

export default Checkbox;
