import { Button, ButtonTypes, Popover, TextInput, TextInputType } from '@alpima/picasso';
import React, { ChangeEvent, Dispatch, SetStateAction, useRef, useState } from 'react';
import KeyListener from './KeyListener';
import SearchIcon from './SearchIcon';

type SearchProps = {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
};

export default function Search({ searchText, setSearchText }: SearchProps) {
  const [showSearch, setShowSearch] = useState(false);
  const searchRef = useRef<HTMLInputElement>(null);

  return (
    <div className="relative">
      <Button
        icon={SearchIcon}
        type={ButtonTypes.IconLeft}
        className={`font-bold text-sm border-none ${showSearch ? 'text-primary-main' : ''}`}
        onClick={() =>
          setShowSearch(prev => {
            if (!prev) {
              setTimeout(() => {
                searchRef.current?.focus();
              }, 0);
            }
            return !prev;
          })
        }
      >
        Search
      </Button>
      {showSearch && (
        <Popover
          hidePopover={() => setShowSearch(false)}
          classNames="text-black pt-4 right-2 pb-2 px-8 shadow-lg whitespace-nowrap"
        >
          <KeyListener
            listenForKey="Escape"
            action={() => {
              setShowSearch(false);
              setSearchText('');
            }}
          />
          <TextInput
            customType={TextInputType.Default}
            ref={searchRef}
            value={searchText}
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value)}
            className="w-96 outline-none"
            placeholder="Search for strategy name or ID..."
          />
        </Popover>
      )}
    </div>
  );
}
