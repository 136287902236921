import React, { useState } from 'react';
import { storiesOf } from '@storybook/react';
import StoryRouter from 'storybook-react-router';

import FilterMenu from './index';

const defaults = {
  error: true,
  warn: true,
  info: true,
};
const options = [
  {
    key: 'error',
    text: 'Error',
    value: '0',
    label: { color: 'red', empty: true, circular: true },
  },
  {
    key: 'warn',
    text: 'Warn',
    value: '1',
    label: { color: 'yellow', empty: true, circular: true },
  },
  {
    key: 'info',
    text: 'Info',
    value: '2',
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 'verbose',
    text: 'Verbose',
    value: '3',
    label: { color: 'black', empty: true, circular: true },
  },
];
const Driver = story => {
  const [state, setFilterState] = useState(defaults);
  return <div style={{ margin: 50 }}>{story({ state, setFilterState })}</div>;
};

const defaultProps = {
  config: {
    identifier: '_filter',
    name: 'Filter',
  },
  defaults,
  options,
};
const loadingProps = {
  config: {
    identifier: '_filter',
    name: 'Filter',
  },
  defaults,
  options: [],
};

storiesOf('FilterMenu', module)
  .addDecorator(StoryRouter())
  .addDecorator(Driver)
  .add('default', props => <FilterMenu {...defaultProps} {...props} />)
  .add('loading', props => <FilterMenu {...loadingProps} {...props} />);
