/* eslint-disable @typescript-eslint/no-explicit-any */
// ^ Strictly typing service parameters would honestly make it WAY harder to read and use.

import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Observable, Subscription } from 'rxjs';

type Service = (...params: any[]) => Observable<any>;

type InnerServiceState = {
  loading: boolean;
  error: Error | null;
  data: any;
};

export type ServiceState<T> = Readonly<[boolean, null | Error, null | T]>;

/**
 * Reusable hook to express using a service to load data.
 * @param observableCreator: The service to call, e.g. fetchHistoricalRisk$
 * @param params Each subsequent parameter is passed to the service.
 * Returns: [loading: Boolean, error: String?, data: Object?]
 */
export default function useService<T>(
  observableCreator: Service,
  ...params: any[]
): ServiceState<T> {
  const [{ loading, error, data }, setState] = useState<InnerServiceState>({
    loading: true,
    error: null,
    data: null,
  });

  const subscription: MutableRefObject<Subscription | null> = useRef(null);

  useEffect(() => {
    const onData = (newData: any) => {
      setState({ data: newData, loading: false, error: null });
    };

    const onError = (errorValue: Error) => {
      setState(({ data: oldData }) => ({
        loading: false,
        error: errorValue,
        data: oldData,
      }));
    };

    if (!error) {
      // Note: in a lot of cases, we'd want to keep the data in-place until it updates.
      // So we don't remove the data yet – but we set "loading" so we can show a spinner.
      setState(state => ({ ...state, loading: true }));
      subscription.current = observableCreator(...params).subscribe(onData, onError);
    }

    return function cleanup() {
      if (subscription.current) subscription.current.unsubscribe();
    };

    // We disable this warning because we want the flexibility of passing in our own parameter list here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, observableCreator, ...params]);

  return [loading, error, data] as const;
}
