import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';

export const numberWithCommas = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const padZero = int => (int < 10 ? `0${int}` : `${int}`);

export const FormatNumberFilter = ({ value, style }) => {
  if (value || value === 0) {
    return (
      <FormattedNumber
        value={value}
        style={style}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />
    );
  }
  return 'n/a';
};

FormatNumberFilter.propTypes = {
  value: PropTypes.number.isRequired,
  style: PropTypes.object,
};

FormatNumberFilter.defaultProps = {
  style: {},
};
