import React from 'react';
import { useHistory } from 'react-router-dom';
import { BodyCell, Icon, Icons, TableRow } from '@alpima/picasso';
import renderConfig from 'helpers/renderConfig';
import { Client, Order, UserTableHeader, LogEntry } from './UserList.types';

export const filterUnauthorisedContractRoles = (contractRole?: string) =>
  ['architect pro', 'architect pro it', 'visualiser', 'meta', 'meta-biam', 'architect'].includes(contractRole || '');

export const getMostRecentLogByDate = (array: LogEntry[] | undefined) =>
  array
    ?.map((log: LogEntry) => log.date)
    .sort()
    .reverse()[0];

export const getTableSortableHeaders = (): UserTableHeader<Client>[] => [
  {
    name: 'Name',
    property: 'firstNameDisplayText',
    sortOrderProperty: 'firstName',
    secondSortOrderProperty: 'lastName',
    enabled: true,
  },
  {
    name: 'Username',
    property: 'usernameDisplayText',
    sortOrderProperty: 'username',
    enabled: true,
  },
  {
    name: 'Email',
    property: 'emailDisplayText',
    sortOrderProperty: 'email',
    enabled: true,
  },
  {
    name: 'Job Title',
    property: 'jobTitleDisplayText',
    sortOrderProperty: 'jobTitle',
    enabled: renderConfig.showUserJobTitle,
  },
  {
    name: 'Contract Role',
    property: 'contractRoleDisplayText',
    sortOrderProperty: 'contractRole',
    enabled: renderConfig.showUserContractRole,
  },
  {
    name: 'Created',
    property: 'created',
    enabled: true,
  },
  {
    name: 'Last Activity',
    property: 'lastActivity',
    enabled: true,
  },
];

const compare = <T extends Record<string | number, unknown>>(
  x: T,
  y: T,
  order: Order = Order.Ascending,
  prop: keyof T,
) => {
  const a = x[prop];
  const b = y[prop];

  if (a === b) return 0;

  if (order === 'ascending') {
    return a > b ? 1 : -1;
  }
  return a > b ? -1 : 1;
};

export const sortingByProp =
  (prop: any, order: Order = Order.Ascending) =>
  (x: any, y: any) =>
    compare(x, y, order, prop);

export function searchFn(item: Client, query: string) {
  if (item?.email.toLowerCase().includes(query.toLowerCase())) return true;
  if (item?.employment?.[0]?.business.toLowerCase().includes(query.toLowerCase())) return true;
  if (item?.employment?.[0]?.occupation.toLowerCase().includes(query.toLowerCase())) return true;
  if (item?.firstName.toLowerCase().includes(query.toLowerCase())) return true;
  if (item?.lastName.toLowerCase().includes(query.toLowerCase())) return true;
  if (item?.username.toLowerCase().includes(query.toLowerCase())) return true;
  return false;
}

export const ClientRow = (
  props: Client & {
    formatDate: (i: Date) => void;
    formatDateTime: (i: Date) => void;
  },
) => {
  const {
    _id,
    enabled,
    firstName,
    lastName,
    employment,
    email,
    username,
    contractRole,
    created,
    lastActivity,
    formatDate,
    formatDateTime,
  } = props;
  const history = useHistory();
  const company = employment?.[0]?.business;
  const jobTitle = employment?.[0]?.occupation;

  const lastDate = lastActivity
    ? `${formatDate(new Date(lastActivity))} - 
  ${formatDateTime(new Date(lastActivity))}`
    : '---';

  return (
    <TableRow className="text-center cursor-pointer" onClick={() => history.push(`/user/${_id}`)}>
      <BodyCell className="py-2 text-primary-800">
        <span className="w-full flex justify-center">
          <Icon
            name={enabled ? Icons.DONE : Icons.CLOSE}
            className={`fill-current ${enabled ? 'text-success-800' : 'text-danger-800'}`}
          />
        </span>
      </BodyCell>
      <BodyCell className="py-4">
        {firstName && lastName ? `${firstName} ${lastName}` : 'Unknown'}
      </BodyCell>
      <BodyCell className="py-2">{username}</BodyCell>
      {renderConfig.showUserCompany && <BodyCell className="py-4">{company}</BodyCell>}
      <BodyCell className="py-2">{email}</BodyCell>
      {renderConfig.showUserJobTitle && <BodyCell className="py-4">{jobTitle}</BodyCell>}
      {renderConfig.showUserContractRole && (
        <BodyCell className="py-4">{contractRole || '---'}</BodyCell>
      )}
      <BodyCell className="py-2">
        {formatDate(new Date(created))} - {formatDateTime(new Date(created))}
      </BodyCell>
      <BodyCell className="py-2">{lastDate}</BodyCell>
    </TableRow>
  );
};
