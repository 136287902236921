import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ButtonTypes,
  Icons,
  Icon,
  Table,
  TableBody,
  TableRow,
  HeaderCell,
  Layout,
  PageHeader,
  Back,
  Section,
  Title,
  TextInput,
  TextInputType,
  TextInputSize,
  TableHeader,
} from '@alpima/picasso';
import renderConfig from 'helpers/renderConfig';
import { Client, Order } from './UserList.types';
import TablePagination from '../helpers/TablePagination';
import useSortAndPagination from './useSortAndPagination';
import useTriggeredService from '../../../../../helpers/useTriggeredService';
import useSortOrder from './useSortOrder';
import { makeSelectUser } from '../../../../../store/selectors/auth.selector';
import useDateTimeFormatter from '../../../../../helpers/useDateTimeFormatter';
import { fetchNewUserList } from '../../../../../services/clientAPI/user.service';
import {
  ClientRow,
  searchFn,
  sortingByProp,
  getTableSortableHeaders,
  getMostRecentLogByDate,
  filterUnauthorisedContractRoles,
} from './UserList.helpers';
import { FilterOutline, FilterSolid } from './UserList.icons';

export default function UserList() {
  const currentUser = useSelector(makeSelectUser);
  const { formatDate, formatDateTime } = useDateTimeFormatter();
  const [loading, error, rawUsers, getUserList] = useTriggeredService<Client[]>(fetchNewUserList);
  const [query, setQuery] = useState<string>('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [filterActive, setFilterActive] = useState(false);

  const users = useMemo(() => {
    const flattenUserProperties = rawUsers
      ?.map(user => ({
        ...user,
        firstNameDisplayText: user.firstName,
        firstName: user.firstName.toLocaleLowerCase(),
        lastNameDisplayText: user.lastName,
        lastName: user.lastName.toLocaleLowerCase(),
        usernameDisplayText: user.username,
        username: user.username.toLocaleLowerCase(),
        companyDisplayText: user?.employment?.[0]?.business,
        company: user?.employment?.[0]?.business.toLocaleLowerCase(),
        jobTitleDisplayText: user?.employment?.[0]?.occupation,
        jobTitle: user?.employment?.[0]?.occupation.toLocaleLowerCase(),
        contractRoleDisplayText: user.contractRole,
        contractRole: user.contractRole?.toLocaleLowerCase(),
        lastActivity: getMostRecentLogByDate(user.logs),
      }))
      .filter(user => (filterActive ? user.enabled : true));

    return renderConfig.userPropertiesFilter
      ? (flattenUserProperties || [])
          .filter(i => filterUnauthorisedContractRoles(i.contractRole))
          .sort(i => (i.enabled ? -1 : 1))
          .filter(i => searchFn(i, query))
      : (flattenUserProperties || []).filter(i => searchFn(i, query));
  }, [query, rawUsers, filterActive]);

  useEffect(() => {
    if (!rawUsers && currentUser?.company) getUserList(currentUser.company);
  }, [currentUser?.company, getUserList, rawUsers]);

  const { order, sortingProperty, changeSortOrder } = useSortOrder<Client>(
    'firstName',
    Order.Ascending,
  );

  const changeSortByProperty = (property: keyof Client) => {
    const headerConfig = getTableSortableHeaders();
    const headerObject = headerConfig.find(item => item.property === property);

    if (headerObject?.sortOrderProperty) {
      changeSortOrder(headerObject.sortOrderProperty as keyof Client);
    } else if (headerObject?.secondSortOrderProperty) {
      changeSortOrder(
        `${headerObject.sortOrderProperty} ${headerObject.secondSortOrderProperty}` as keyof Client,
      );
    } else {
      changeSortOrder(property);
    }
  };

  const sortingFunction = useCallback(
    () => sortingByProp(sortingProperty, order),
    [sortingProperty, order],
  );

  const paginatedUsers = useSortAndPagination(itemsPerPage, page, users, setPage, sortingFunction);

  return (
    <div className="min-h-screen">
      <Layout noMargins>
        <div className="w-full px-8 mb-2">
          <PageHeader>
            <Back />
            <Section>
              <Title>User Dashboard</Title>
            </Section>
          </PageHeader>
        </div>
        <div className="w-full border-b border-gray-200" />
        <div className="flex flex-row w-full py-5 px-8 items-center justify-between">
          <TextInput
            customType={TextInputType.IconLeft}
            customSize={TextInputSize.Large}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
            placeholder="Search by name, email, username ..."
            value={query}
            wrapperClassName="w-full flex-grow mr-6"
          />
          <div className="flex space-x-2 flex-grow-0">
            <Button
              type={ButtonTypes.IconLeft}
              icon={Icons.CLOSE}
              onClick={() => setQuery('')}
              disabled={!query.length}
            >
              Clear
            </Button>
          </div>
        </div>
        {!error && (
          <Table>
            <TableHeader>
              <TableRow>
                <HeaderCell
                  className="p-4 text-left cursor-pointer"
                  onClick={() => setFilterActive(!filterActive)}
                >
                  Enabled
                  <div className="float-right">
                    {filterActive ? <FilterSolid /> : <FilterOutline />}
                  </div>
                </HeaderCell>
                {getTableSortableHeaders().map(
                  header =>
                    header.enabled && (
                      <HeaderCell
                        key={header.sortOrderProperty}
                        className="p-4 text-left cursor-pointer"
                        onClick={() => changeSortByProperty(header.property as keyof Client)}
                      >
                        <div className="justify-between flex flex-row items-center">
                          {header.name}
                          <Icon name={Icons.SORT} className="float-right" />
                        </div>
                      </HeaderCell>
                    ),
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {paginatedUsers?.map(row => (
                <ClientRow
                  key={row._id}
                  email={row.email}
                  _id={row._id}
                  username={row.username}
                  firstName={row.firstNameDisplayText}
                  lastName={row.lastNameDisplayText}
                  created={row.created}
                  contractRole={row.contractRole}
                  lastActivity={row.lastActivity}
                  employment={row.employment}
                  enabled={row.enabled}
                  formatDate={formatDate}
                  formatDateTime={formatDateTime}
                />
              ))}
            </TableBody>
          </Table>
        )}
        {!paginatedUsers.length && (
          <div className="text-center w-full border-b border-l border-r border-gray-400 py-4">
            {loading ? 'Loading users...' : 'No users found for this filter selection'}
          </div>
        )}
        {!!paginatedUsers.length && (
          <TablePagination
            itemsPerPage={itemsPerPage}
            itemsPerPageHandler={setItemsPerPage}
            page={page}
            pageHandler={setPage}
            numberOfRows={users.length}
          />
        )}
      </Layout>
    </div>
  );
}
