import React from 'react';
import { Link } from '@alpima/picasso';

import { ArrowRight } from './ArrowIcons';
import { TimePeriods } from '../shared.types';

type CategoryProps = {
  name: 'high' | 'medium';
  number: number;
  formattedTime: TimePeriods;
};

export default function Category({ name, number, formattedTime }: CategoryProps) {
  const zeroBG = name === 'high' ? 'bg-red-50' : 'bg-yellow-50';
  const nonZeroBG = name === 'high' ? 'bg-red-500' : 'bg-yellow-500';

  const headingText =
    number !== 0 ? 'text-white' : name === 'high' ? 'text-red-600' : 'text-orange-600';

  return (
    <div className={`${number === 0 ? zeroBG : nonZeroBG} rounded-lg p-3 relative`}>
      <h3 className={`${headingText} text-2xl w-36`}>{name}</h3>
      <p className={`${headingText} font-bold text-5xl m-0`}>{number}</p>
      <Link to={`/strategy-alerts?time=${formattedTime}&severity=${name}`} className="contents">
        <ArrowRight className={`${headingText} absolute right-3 bottom-3`} />
      </Link>
    </div>
  );
}
