import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropdownInput from '../../atoms/DropdownInput';
import { selectRegionList } from '../../../store/selectors/regions.selector';
import { getRegions } from '../../../store/actions/regions.actions';

const RegionSelector = props => {
  const dispatch = useDispatch();

  const regions = useSelector(selectRegionList);

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  return <DropdownInput {...props} values={regions} />;
};

export default RegionSelector;
