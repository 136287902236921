import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Illustrations, Layout } from '@alpima/picasso';
import renderConfig from 'helpers/renderConfig';
import toggleFeatureByRole from 'helpers/toggleFeatueByRole';
import releaseToggles from '../../../../releaseToggles';
import { makeSelectUser } from '../../../../store/selectors/auth.selector';

export default function Homepage() {
  const currentUser = useSelector(makeSelectUser);
  return (
    <Layout>
      <h1 className="text-4xl font-inter w-full text-left font-bold text-black pt-0 pb-4">
        What do you want to do?
      </h1>
      <div className="flex flex-wrap">
        {renderConfig.showDashboardPage && (
          <Card
            link="/dashboard"
            illustration={Illustrations.BUILD_BG}
            title="Dashboard"
            subtitle="Get a quick overview of the most important metrics."
          />
        )}
        {toggleFeatureByRole(currentUser, renderConfig.showUsersPage) && (
          <Card
            link="/user"
            illustration={Illustrations.EXPLORE_BG}
            title="Users"
            subtitle="Access and manage all your users and clients."
          />
        )}
        {toggleFeatureByRole(currentUser, renderConfig.showLogsPage) && (
          <Card
            link="/logs"
            illustration={Illustrations.REQUEST_BG}
            title="Logs"
            subtitle="Overview of all the events all your users triggered."
          />
        )}
        {toggleFeatureByRole(currentUser, renderConfig.dataMonitoring) &&
          releaseToggles.dataMonitoring && (
            <Card
              link="/data-monitoring"
              illustration={Illustrations.COMPARE_BG}
              title="Data Monitoring"
              subtitle="Live dashboard of today's strategy calculation."
            />
          )}
        {renderConfig.showDistributionGroups && (
          <Card
            link="/distribution-groups"
            illustration={Illustrations.COMPARE_BG}
            title="Distribution groups"
            subtitle="Create and manage all your distribution groups"
          />
        )}
      </div>
    </Layout>
  );
}
