/* eslint-disable import/prefer-default-export */
export const filter = {
  config: {
    identifier: 'curr',
    name: 'Currencies',
  },
  defaults: {
    CHF: true,
    EUR: true,
    GBP: true,
    USD: true,
    NON: true,
  },
  filters: [
    {
      key: 'EUR',
      text: 'EUR',
      value: 'EUR',
    },
    {
      key: 'CHF',
      text: 'CHF',
      value: 'CHF',
    },
    {
      key: 'USD',
      text: 'USD',
      value: 'USD',
    },
    {
      key: 'GBP',
      text: 'GBP',
      value: 'GBP',
    },
    {
      key: 'NON',
      text: 'XXX',
      value: 'NON',
    },
  ],
};
