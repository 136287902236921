/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { pick } from 'lodash';
import { Form, Grid, GridRow, GridColumn, Button } from 'semantic-ui-react';
import renderThemeConfig from 'helpers/renderThemeConfig';
import {
  selectHasDetailsStateChanged,
  selectStrategyDetails,
  selectStrategyDetailsError,
  selectStrategyLoading,
} from '../../../store/selectors/strategy.selector';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import {
  cancelStrategyUpdate,
  fetchStrategyDetails,
  setStrategyUpdate,
  updateStrategyDetails,
} from '../../../store/actions/strategy.actions';
import WidgetActions from '../../atoms/WidgetActions';
import { getRegions } from '../../../store/actions/regions.actions';
import UIMessage from '../../atoms/UIMessage';

const StrategyDetailsWidget = ({ match, loading, strategyDetails }) => {
  const dispatch = useDispatch();
  const hasStateChanged = useSelector(selectHasDetailsStateChanged);
  const strategyDetailsError = useSelector(selectStrategyDetailsError);

  const { strategyConfig } = renderThemeConfig.default;

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchStrategyDetails(match.params.id));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getUpdatePayload = (strategyDetails, strategyConfig) => {
    const editableAttributes = strategyConfig.attributes
      .filter(a => a.readOnly === false)
      .map(a => a.type);
    const payload = pick(strategyDetails, editableAttributes);
    payload._id = strategyDetails._id;
    return payload;
  };

  const handlePersistUpdate = () =>
    dispatch(setStrategyUpdate(getUpdatePayload(strategyDetails, strategyConfig)));
  const handleCancelUpdate = () => dispatch(cancelStrategyUpdate());
  const handleStrategyChange = update => {
    dispatch(updateStrategyDetails({ strategyDetails, update }));
  };

  const renderAttribute = attribute => {
    // eslint-disable-next-line
    const ComponentAtom = attribute.atom && require(`../../atoms/${attribute.atom}`).default;
    const ComponentMolecule =
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      attribute.molecule && require(`../../molecules/${attribute.molecule}`).default;
    const data = {
      strategy: strategyDetails || {},
    };

    const onChange = value => {
      const update = {};
      update[attribute.type] = value;
      return handleStrategyChange(update);
    };

    return ComponentAtom ? (
      <ComponentAtom {...attribute} data={data} disabled={false} onChange={onChange} />
    ) : (
      <ComponentMolecule {...attribute} data={data} disabled={false} onChange={onChange} />
    );
  };

  return (
    <LoadingBoundary isLoading={loading}>
      <div style={{ minHeight: '850px' }}>
        <WidgetActions>
          <div className="widgetHeaderGroup">
            <h1>{strategyDetails?.uid}</h1>
          </div>
          {strategyDetailsError && <UIMessage type="error" message={strategyDetailsError} />}
          <Button.Group>
            <Button positive disabled={!hasStateChanged} onClick={handlePersistUpdate}>
              Save
            </Button>
            <Button.Or />
            <Button disabled={!hasStateChanged} onClick={handleCancelUpdate}>
              Cancel
            </Button>
          </Button.Group>
        </WidgetActions>
        <Form>
          <Grid columns={2} divided>
            <GridRow>
              <GridColumn>
                {strategyConfig.attributes
                  .filter(attribute => attribute.column === 1)
                  .map(renderAttribute)}
              </GridColumn>
              <GridColumn>
                {strategyConfig.attributes
                  .filter(attribute => attribute.column === 2)
                  .map(renderAttribute)}
              </GridColumn>
            </GridRow>
          </Grid>
        </Form>
      </div>
    </LoadingBoundary>
  );
};

StrategyDetailsWidget.defaultProps = {
  hasStateChanged: false,
};

StrategyDetailsWidget.propTypes = {
  hasStateChanged: PropTypes.bool,
  getStrategyDetails: PropTypes.func.isRequired,
  strategyDetails: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  strategyDetails: state => selectStrategyDetails(state),
  loading: state => selectStrategyLoading(state),
});

export default withRouter(connect(mapStateToProps)(StrategyDetailsWidget));
