import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getUserLoginChartData,
  setMonitoringLoginChartType,
} from '../../../store/actions/monitoring.actions';
import {
  selectLoginMonitoringDataLoading,
  selectLoginsDataByRole,
} from '../../../store/selectors/monitoring.selector';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import LineChart from '../../atoms/LineChart';
import MonitorLoginsControls from './MonitorLoginsControls';
import getChartConfig from './MonitorLoginsChart.config';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export default function MonitorLoginsWidget() {
  const dispatch = useDispatch();
  const { type: chartType } = useParams();

  // Load data for the chart.
  const loading = useSelector(selectLoginMonitoringDataLoading);
  const loginsData = useSelector(selectLoginsDataByRole);

  // Synchronise the chart type with the URL.
  useEffect(() => {
    dispatch(setMonitoringLoginChartType(chartType || 'aggregate'));
    dispatch(getUserLoginChartData());
    scrollToTop();
  }, [dispatch, chartType]);

  const chartConfig = getChartConfig(loginsData);

  return (
    <LoadingBoundary isLoading={loading}>
      <MonitorLoginsControls />
      {loginsData.length > 0 && <LineChart {...chartConfig} />}
    </LoadingBoundary>
  );
}
