/* eslint-disable import/prefer-default-export */
import { of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';

import * as constants from '../constants';

import { fetchCompaniesList } from '../../services/clientAPI/companies.service';

const handleListError = error =>
  of({
    type: constants.GET_COMPANIES_LIST_FAILURE,
    error: error.message,
  });

export const getCompaniesListEpic = actions$ =>
  actions$.pipe(
    ofType(constants.GET_COMPANIES_LIST),
    mergeMap(() =>
      fetchCompaniesList().pipe(
        mergeMap(response => [
          {
            type: constants.GET_COMPANIES_LIST_SUCCESS,
            payload: response.response,
          },
        ]),
        catchError(handleListError),
      ),
    ),
    catchError(handleListError),
  );
