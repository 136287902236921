import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container, Dropdown, Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import renderThemeConfig from 'helpers/renderThemeConfig';
import { logout } from '../../../store/actions/auth.actions';
import { selectTheme } from '../../../store/actions/themes.actions';
import { makeSelectNavigation } from '../../../store/selectors/layout.selector';
import { makeSelectCurrentTheme } from '../../../store/selectors/themes.selector';
import { makeSelectAuthenticated, makeSelectUser } from '../../../store/selectors/auth.selector';
import ThemeSelector from '../ThemeSelector';
import LogoWrapper from '../LogoWrapper';

const MenuFixed = styled(Menu)`
  height: 75px;
  background-color: #fff;
  border-bottom: 1px solid #ccc !important;
  box-shadow: none !important;
`;

const MenuItemStyled = styled(Menu.Item)`
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background: 0 0;
  padding: 0.92857143em 1.14285714em;
  text-transform: none;
  margin-top: 18px;
  transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
  font-weight: 600;
  color: ${props => props.theme.variables.highlightedText};
`;

const DropdownStyled = styled(Dropdown)`
  font-weight: 600 !important;
  color: ${props => props.theme.variables.highlightedText} !important;
`;

export const NavBar = ({
  isAuthenticated,
  user,
  handleLogout,
  onSelectTheme,
  showThemeSelector,
}) => {
  let displayedLinks = useSelector(makeSelectNavigation);
  const hasRequiredRoles =
    user && user.roles && user.roles.includes('bjb-ims') && user.roles.includes('admin');
  if (hasRequiredRoles) {
    displayedLinks = [
      ...displayedLinks,
      {
        linkText: 'Data Refresh',
        url: '/data-refresh',
      },
    ];
  }

  return (
    <MenuFixed borderless fixed="top">
      <Container fluid>
        <Menu.Item>
          <LogoWrapper />
        </Menu.Item>
        <Menu.Item>
          {showThemeSelector && (
            <ThemeSelector themes={renderThemeConfig.default} selectTheme={onSelectTheme} />
          )}
        </Menu.Item>
        {isAuthenticated && (
          <Menu.Menu position="right">
            {displayedLinks.map(link => (
              <MenuItemStyled key={`nav-link-${link.linkText}`} as={Link} to={link.url}>
                {link.linkText}
              </MenuItemStyled>
            ))}
            <DropdownStyled
              text={`${user?.firstName || ''} ${user?.lastName || ''}`}
              className="link item"
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </DropdownStyled>
          </Menu.Menu>
        )}
      </Container>
    </MenuFixed>
  );
};

NavBar.defaultProps = {
  showThemeSelector: false,
};

NavBar.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  onSelectTheme: PropTypes.func.isRequired,
  showThemeSelector: PropTypes.bool,
};

export const mapDispatchToProps = dispatch => ({
  handleLogout: () => dispatch(logout()),
  onSelectTheme: payload => dispatch(selectTheme(payload)),
});

const mapStateToProps = createStructuredSelector({
  isAuthenticated: state => makeSelectAuthenticated(state),
  user: state => makeSelectUser(state),
  theme: state => makeSelectCurrentTheme(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
