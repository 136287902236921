import React from 'react';

import PageWrapper from '../../../molecules/PageWrapper';
import Dashboard from '../../../organisms/Dashboard';
// import CardDefault from '../../atoms/CardDefault';
// import buildImage from '../../../images/build.png';
// import monitorImage from '../../../images/monitor.png';
// import Disclaimer from '../../atoms/Disclaimer';
// import exploreImage from '../../../images/explore.png';

const HomePage = () => (
  <PageWrapper>
    <div style={{ minHeight: '850px', background: '#F6F8FA' }}>
      <Dashboard title={'Dashboard' || ''} icon={{ name: 'globe', size: 'large' }} name="home" />
    </div>
  </PageWrapper>
);

export default HomePage;
