import React from 'react';
import { Link } from '@alpima/picasso';
import { DateTime } from 'luxon';
import { ArrowRight } from './ArrowIcons';
import Category from './Category';
import { ParsedAlertByTime } from './DataMonitoringDashboard.types';
import { roundToPreviousNearestEven } from './helper';
import { Times } from '../shared.types';
import { mapTimeToTimePeriod } from '../shared.utils';

function StrategyAlert({
  formattedTime,
  highAlerts,
  mediumAlerts,
  totalScheduledStrategies,
}: ParsedAlertByTime) {
  return (
    <div className="border border-gray-200 rounded-lg p-4 flex items-center">
      <div className="px-5 w-52">
        <h2 className="m-0 text-6xl">{formattedTime}</h2>
        <h3 className="m-0 mt-1 font-normal text-normal">
          <span className="font-bold">{totalScheduledStrategies}</span> scheduled strategies
        </h3>
        <Link to={`/strategy-alerts?time=${formattedTime}`} className="mt-2 block">
          View more <ArrowRight />
        </Link>
      </div>
      <div className="space-y-3 ml-5">
        <Category name="high" number={highAlerts} formattedTime={formattedTime} />
        <Category name="medium" number={mediumAlerts} formattedTime={formattedTime} />
      </div>
    </div>
  );
}

export default function StrategyAlertsSection({ alertsInfo }: { alertsInfo: ParsedAlertByTime[] }) {
  const currentHour = DateTime.now().toFormat('HH');
  const currentHourKey = roundToPreviousNearestEven(Number(currentHour)) as Times;
  const addCurrentHourGroup = !alertsInfo.find(item => item.time === currentHourKey);

  if (addCurrentHourGroup) {
    alertsInfo.push({
      time: currentHourKey,
      highAlerts: 0,
      mediumAlerts: 0,
      formattedTime: mapTimeToTimePeriod(currentHourKey as Times),
      totalScheduledStrategies: 0,
    });
  }

  return (
    <div className="py-8 flex gap-3 overflow-x-auto">
      {alertsInfo.map(info => (
        <StrategyAlert
          key={info.formattedTime}
          formattedTime={info.formattedTime}
          mediumAlerts={info.mediumAlerts}
          highAlerts={info.highAlerts}
          totalScheduledStrategies={info.totalScheduledStrategies}
        />
      ))}
    </div>
  );
}
