import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Form, Grid, GridRow, GridColumn, Checkbox } from 'semantic-ui-react';
import renderThemeConfig from 'helpers/renderThemeConfig';
import renderConfig from 'helpers/renderConfig';
import {
  selectInstrumentDetails,
  selectInstrumentLoading,
  selectHasDetailsStateChanged,
} from '../../../store/selectors/instruments.selector';
import {
  fetchInstrumentDetails,
  stampInstrumentAttribute,
  setInstrumentUpdate,
  cancelInstrumentUpdate,
  stampEnableInstrument,
  stampDisableInstrument,
} from '../../../store/actions/instruments.actions';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import WidgetActions from '../../atoms/WidgetActions';
import ButtonTwoChoice from '../../atoms/ButtonTwoChoice';

const InstrumentDetailsWidget = ({
  match,
  loading,
  getInstrumentDetails,
  instrumentDetails,
  hasStateChanged,
  setInstrumentAttribute,
  setUpdate,
  cancelUpdate,
  enableInstrument,
  disableInstrument,
}) => {
  const { instrumentConfig } = renderThemeConfig.default;

  useEffect(() => {
    getInstrumentDetails(match.params.id);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderAttribute = attribute => {
    // eslint-disable-next-line
    const ComponentAtom = require(`../../atoms/${attribute.atom}`).default;
    const data = {
      instrument: instrumentDetails || {},
    };

    return (
      <ComponentAtom
        {...attribute}
        data={data}
        onChange={value => {
          setInstrumentAttribute(attribute.type, value, data, attribute.compiler);
        }}
        disabled={instrumentDetails && !instrumentDetails.visible}
      />
    );
  };

  const handleEnableInstrument = () => enableInstrument();
  const handleDisableInstrument = () => disableInstrument();

  const handlePersistUpdate = () => setUpdate();
  const handleCancelUpdate = () => cancelUpdate();
  const isEnabled = instrumentDetails && renderConfig.instrumentDetailsEnabled && (
    <h2>
      Enabled:
      <Checkbox
        checked={instrumentDetails.visible}
        onClick={() =>
          instrumentDetails.visible ? handleDisableInstrument() : handleEnableInstrument()
        }
      />
    </h2>
  );

  return (
    <LoadingBoundary isLoading={loading}>
      <div style={{ minHeight: '850px' }}>
        <WidgetActions>
          <div className="widgetHeaderGroup">
            <h1>{instrumentDetails && instrumentDetails.name}</h1>
            {isEnabled}
          </div>
          <ButtonTwoChoice
            disabled={!hasStateChanged}
            onClickLeft={handlePersistUpdate}
            onClickRight={handleCancelUpdate}
            left="Save"
            right="Cancel"
          />
        </WidgetActions>
        <Form>
          <Grid columns={2} divided>
            <GridRow>
              <GridColumn>
                {instrumentConfig.attributes
                  .filter(attribute => attribute.column === 1)
                  .map(renderAttribute)}
              </GridColumn>
              <GridColumn>
                {instrumentConfig.attributes
                  .filter(attribute => attribute.column === 2)
                  .map(renderAttribute)}
              </GridColumn>
            </GridRow>
          </Grid>
        </Form>
      </div>
    </LoadingBoundary>
  );
};

InstrumentDetailsWidget.propTypes = {
  getInstrumentDetails: PropTypes.func.isRequired,
  instrumentDetails: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  hasStateChanged: PropTypes.bool,
  setInstrumentAttribute: PropTypes.func.isRequired,
  setUpdate: PropTypes.func.isRequired,
  cancelUpdate: PropTypes.func.isRequired,
  enableInstrument: PropTypes.func.isRequired,
  disableInstrument: PropTypes.func.isRequired,
};

InstrumentDetailsWidget.defaultProps = {
  hasStateChanged: false,
};

const mapStateToProps = createStructuredSelector({
  instrumentDetails: state => selectInstrumentDetails(state),
  loading: state => selectInstrumentLoading(state),
  hasStateChanged: state => selectHasDetailsStateChanged(state),
});

const mapDispatchToProps = dispatch => ({
  getInstrumentDetails: id => dispatch(fetchInstrumentDetails(id)),
  setInstrumentAttribute: (key, value, data, compiler) =>
    dispatch(stampInstrumentAttribute(key, value, data, compiler)),
  setUpdate: () => dispatch(setInstrumentUpdate()),
  cancelUpdate: () => dispatch(cancelInstrumentUpdate()),
  enableInstrument: () => dispatch(stampEnableInstrument()),
  disableInstrument: () => dispatch(stampDisableInstrument()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InstrumentDetailsWidget));
