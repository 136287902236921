import { useDispatch } from 'react-redux';
import * as moment from 'moment';
import { setMonitoringFilterTimeState } from '../../../store/actions/monitoring.actions';

export default function useTimeFilter() {
  const dispatch = useDispatch();

  const initialFilter = {
    rangeStart: moment().startOf('year').toISOString(),
    rangeEnd: moment().endOf('year').toISOString(),
    selection: moment().get('year'),
  };

  function setTimeFilter(timeFilter) {
    dispatch(
      setMonitoringFilterTimeState(
        timeFilter.rangeStart,
        timeFilter.rangeEnd,
        timeFilter.rangeName,
        timeFilter.selection,
      ),
    );
  }

  function resetFilters() {
    setTimeFilter(initialFilter);
  }

  return {
    setTimeFilter,
    resetFilters,
  };
}
