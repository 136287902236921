import { sanitize } from 'dompurify';
import MarkdownToJsx from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledMarkdown = styled(MarkdownToJsx)`
  word-break: break-word;
`;

export default function Markdown({ children, ...props }) {
  return <StyledMarkdown {...props}>{sanitize(children)}</StyledMarkdown>;
}

Markdown.propTypes = {
  children: PropTypes.node.isRequired,
};
