import { informationBannersUrl } from './clientAPI.config';
import { apiCall$ } from './lib/apiCall';

export const fetchInformationBanners = () => apiCall$(informationBannersUrl);

export const archiveInformationBanner = id =>
  apiCall$(`${informationBannersUrl}/${id}/archive`, 'POST');

export const saveInformationBanner = informationBanner =>
  apiCall$(informationBannersUrl, 'POST', informationBanner);
