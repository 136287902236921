import React from 'react';
import { Icon, Icons, Tooltip } from '@alpima/picasso';

function Check() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-8 w-8 text-green-600"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
    </svg>
  );
}
export default function DataProvider({ name, connected }: { name: string; connected: boolean }) {
  const iconBackground = `
    ${connected ? 'bg-green-100' : 'bg-red-100'}
    rounded-md flex items-center w-16 h-16 justify-center
  `;
  const textColor = connected ? 'text-green-600' : 'text-red-600';

  return (
    <div className="border-1 border-gray-300 rounded-lg p-3 flex-1 flex items-center">
      <div className={iconBackground}>
        {connected ? <Check /> : <Icon name={Icons.CLOSE} className="text-red-500 fill-current" />}
      </div>
      <section className="ml-5 text-2xl font-bold">
        {name}
        <h3 className={`${textColor} m-0 text-sm flex items-center`}>
          {connected ? (
            'Connected'
          ) : (
            <>
              Not Connected
              <Tooltip>Error: Timed out while waiting for response from provider</Tooltip>
            </>
          )}
        </h3>
      </section>
    </div>
  );
}
