const layout = {
  navbar: {
    navigationLinks: [
      {
        linkText: 'Dashboard',
        url: '/',
      },
      {
        linkText: 'Monitor',
        url: '/monitoring',
      },
      {
        linkText: 'Instruments',
        url: '/instruments',
      },
      {
        linkText: 'Strategies',
        url: '/strategy/list',
      },
      {
        linkText: 'Users',
        url: '/user/list/1',
      },
      {
        linkText: 'Logs',
        url: '/logs',
      },
    ],
  },
};

export default layout;
