/* eslint-disable react/display-name */
/* eslint-disable max-len */
import React, { CSSProperties, ReactNode } from 'react';
import { FixedSizeList } from 'react-window';
import ListItem from './ListItem';

export type Item = {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
};

const List = ({
  items,
  selected,
  handleSelection,
  fallback,
}: {
  items: Item[];
  selected: Item[];
  handleSelection: (item: Item) => void;
  fallback?: ReactNode;
}) => {
  const Row = ({ index, style }: { index: number; style: CSSProperties | undefined }) => (
    <ListItem
      data={items[index]}
      key={items[index]._id}
      selected={!!selected.find(e => e._id === items[index]._id)}
      onClick={() => handleSelection(items[index])}
      style={style}
    />
  );

  return (
    <div className="w-136 h-full bg-gray-200 flex flex-col overflow-y-auto">
      {items.length ? (
        <FixedSizeList height={500} itemCount={items.length} itemSize={80} width="100%">
          {Row}
        </FixedSizeList>
      ) : (
        fallback
      )}
    </div>
  );
};

export default List;
