import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { createStructuredSelector } from 'reselect';

import { login, ssoLogin } from '../../../../store/actions/auth.actions';
import { makeSelectAuthenticated } from '../../../../store/selectors/auth.selector';
import LoginForm from '../../../molecules/LoginForm';
import PageWrapper from '../../../molecules/PageWrapper';

const LoginPage = ({ handleLogin, handleSSOLogin, isAuthenticated, location }) => {
  if (isAuthenticated) {
    if (location?.state?.from?.pathname) {
      const loc = location.state.from;
      if (loc.pathname === '/login') {
        loc.pathname = '/';
      }
      return <Redirect to={loc} />;
    }
    return <Redirect to="/" />;
  }
  return (
    <PageWrapper>
      <Grid textAlign="center" style={{ minHeight: '850px' }} verticalAlign="top">
        <Grid.Column style={{ maxWidth: 450, paddingTop: '250px' }}>
          <LoginForm onLogin={handleLogin} onSSOLogin={handleSSOLogin} />
        </Grid.Column>
      </Grid>
    </PageWrapper>
  );
};

LoginPage.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  handleSSOLogin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  handleLogin: data => dispatch(login(data)),
  handleSSOLogin: () => dispatch(ssoLogin()),
});

const mapStateToProps = createStructuredSelector({
  isAuthenticated: state => makeSelectAuthenticated(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
