const dashboards = {
  home: {
    Natixis: {
      toolbar: null,
      column1: {
        width: 5,
        widgets: [
          {
            title: 'Online Users',
            type: 'onlineUsersWidget',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Daily Errors:',
            type: 'logErrorMonitoring',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Users Pending Approval',
            type: 'samlUserApproval',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          // {
          //     title: 'Users with Updated Contract Roles',
          //     type: 'samlContractRoleApproval',
          //     noPadding: true,
          //     noBorder: false,
          //     hideHeader: true
          // },
          // {
          //     title: 'Splunk Exports',
          //     type: 'splunkTimestampWidget',
          //     noPadding: true,
          //     noBorder: false,
          //     hideHeader: true
          // },
          {
            title: 'Strategy ReCalculation',
            type: 'strategyCalculationWidget',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
        ],
      },
      column2: {
        width: 11,
        widgets: [
          {
            title: 'Instruments Performance Monitoring',
            type: 'instrumentsPerfMonitoringWidget',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Instruments Market Performance Monitoring',
            type: 'instrumentsMarketPerfMonitoringWidget',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          // {
          //     title: 'Instruments TST Uploads Monitoring',
          //     type: 'instrumentsUploadMonitoringWidget',
          //     noPadding: true,
          //     noBorder: false,
          //     hideHeader: true
          // },
          {
            column1: {
              width: 8,
              widgets: [
                //                                {
                //                                    title: 'Strategy Attribute Export',
                //                                    type: 'strategyExportWidget',
                //                                    noPadding: true,
                //                                    noBorder: false,
                //                                    hideHeader: true
                //                                },
                {
                  title: 'Reference Data Export',
                  type: 'referenceDataExportWidget',
                  noPadding: true,
                  noBorder: false,
                  hideHeader: true,
                },
              ],
            },
            column2: {
              width: 8,
              widgets: [
                {
                  title: 'Instrument Attribute Export',
                  type: 'instrumentExportWidget',
                  noPadding: true,
                  noBorder: false,
                  hideHeader: true,
                },
                // {
                //     title: 'Building Block Export',
                //     type: 'bbExportWidget',
                //     noPadding: true,
                //     noBorder: false,
                //     hideHeader: true
                // }
              ],
            },
          },
          // {
          //     title: 'Information Banner',
          //     type: 'informationBanner',
          //     noPadding: false,
          //     noBorder: false,
          //     hideHeader: true
          // }
        ],
      },
    },
    default: {
      toolbar: null,
      column1: {
        width: 5,
        widgets: [
          {
            title: 'Online Users',
            type: 'onlineUsersWidget',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Daily Errors:',
            type: 'logErrorMonitoring',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Users Pending Approval',
            type: 'samlUserApproval',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Users with Updated Contract Roles',
            type: 'samlContractRoleApproval',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Splunk Exports',
            type: 'splunkTimestampWidget',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Strategy ReCalculation',
            type: 'strategyCalculationWidget',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Bloomberg Feed Imports',
            type: 'bloombergFeedImport',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Overnight Calculations',
            type: 'overnightCalculations',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'IC Decision pre-conditions',
            type: 'icDecisionPreconditions',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
        ],
      },
      column2: {
        width: 11,
        widgets: [
          {
            title: 'Instruments Performance Monitoring',
            type: 'instrumentsPerfMonitoringWidget',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Instruments Market Performance Monitoring',
            type: 'instrumentsMarketPerfMonitoringWidget',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Instruments TST Uploads Monitoring',
            type: 'instrumentsUploadMonitoringWidget',
            noPadding: true,
            noBorder: false,
            hideHeader: true,
          },
          {
            column1: {
              width: 8,
              widgets: [
                {
                  title: 'Strategy Attribute Export',
                  type: 'strategyExportWidget',
                  noPadding: true,
                  noBorder: false,
                  hideHeader: true,
                },
                {
                  title: 'Reference Data Export',
                  type: 'referenceDataExportWidget',
                  noPadding: true,
                  noBorder: false,
                  hideHeader: true,
                },
                {
                  title: 'Users export',
                  type: 'usersExportWidget',
                  noPadding: true,
                  noBorder: false,
                  hideHeader: true,
                },
              ],
            },
            column2: {
              width: 8,
              widgets: [
                {
                  title: 'Instrument Attribute Export',
                  type: 'instrumentExportWidget',
                  noPadding: true,
                  noBorder: false,
                  hideHeader: true,
                },
                {
                  title: 'Building Block Export',
                  type: 'bbExportWidget',
                  noPadding: true,
                  noBorder: false,
                  hideHeader: true,
                },
              ],
            },
          },
          {
            title: 'Information Banner',
            type: 'informationBanner',
            noPadding: false,
            noBorder: false,
            hideHeader: true,
          },
        ],
      },
    },
  },
  monitoringLogins: {
    default: {
      toolbar: {
        back: true,
        actions: [
          { label: 'Monitor Objects', template: 'monitoringStrategies', icon: 'chart area' },
        ],
      },
      column1: {
        width: 16,
        widgets: [
          {
            title: 'Monitor: User Logins',
            type: 'monitorLogins',
            noPadding: false,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Monitor: Unique User Logins by Type',
            type: 'monitorLoginsTable',
            noPadding: false,
            noBorder: false,
            hideHeader: true,
          },
        ],
      },
      column2: null,
    },
    Natixis: {
      toolbar: {
        back: true,
        actions: [
          { label: 'Monitor Strategies', template: 'monitoringStrategies', icon: 'chart area' },
        ],
      },
      column1: {
        width: 16,
        widgets: [
          {
            title: 'Monitor: User Logins',
            type: 'monitorLogins',
            noPadding: false,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Monitor: Unique User Logins by Type',
            type: 'monitorLoginsTable',
            noPadding: false,
            noBorder: false,
            hideHeader: true,
          },
        ],
      },
      column2: null,
    },
  },
  monitoringStrategies: {
    default: {
      toolbar: {
        back: true,
        actions: [{ label: 'Monitor Users', template: 'monitoringLogins', icon: 'chart area' }],
      },
      column1: {
        width: 16,
        widgets: [
          {
            title: 'Monitor: Strategies',
            type: 'monitorStrategies',
            noPadding: false,
            noBorder: false,
            hideHeader: true,
          },
          {
            title: 'Monitor: Strategies Statistics',
            type: 'monitorStrategiesTable',
            noPadding: false,
            noBorder: false,
            hideHeader: true,
          },
        ],
      },
      column2: null,
    },
  },
  userList: {
    toolbar: {
      back: true,
      actions: [],
    },
    column1: {
      width: 16,
      widgets: [
        {
          title: 'List of Users',
          type: 'userTable',
          noPadding: true,
          noBorder: true,
          hideHeader: true,
        },
      ],
    },
    column2: null,
  },
  user: {
    toolbar: {
      back: true,
      actions: [],
    },
    column1: {
      width: 16,
      widgets: [
        {
          title: 'User Details',
          type: 'userDetails',
          noPadding: true,
          noBorder: true,
          hideHeader: true,
        },
      ],
    },
    column2: null,
  },
  instrumentList: {
    Natixis: {
      toolbar: {
        back: true,
        actions: [],
      },
      column1: {
        width: 16,
        widgets: [
          {
            title: 'Instruments List',
            type: 'natixisInstrumentList',
            noPadding: true,
            noBorder: true,
            hideHeader: true,
          },
        ],
      },
      column2: null,
    },
    default: {
      toolbar: {
        back: true,
        actions: [],
      },
      column1: {
        width: 16,
        widgets: [
          {
            title: 'Instruments List',
            type: 'instrumentTable',
            noPadding: true,
            noBorder: true,
            hideHeader: true,
          },
        ],
      },
      column2: null,
    },
  },
  instrument: {
    toolbar: {
      back: true,
      actions: [],
    },
    column1: {
      width: 16,
      widgets: [
        {
          title: 'Instrument Details',
          type: 'instrumentDetails',
          noPadding: true,
          noBorder: true,
          hideHeader: true,
        },
      ],
    },
    column2: null,
  },
  logList: {
    toolbar: {
      back: true,
      actions: [],
    },
    column1: {
      width: 16,
      widgets: [
        {
          title: 'Log Stream',
          type: 'logTable',
          noPadding: true,
          noBorder: true,
          hideHeader: true,
        },
      ],
    },
    column2: null,
  },
  strategyList: {
    toolbar: {
      back: true,
      actions: [],
    },
    column1: {
      width: 16,
      widgets: [
        {
          title: 'Strategy List',
          type: 'strategyTable',
          noPadding: true,
          noBorder: true,
          hideHeader: true,
        },
      ],
    },
    column2: null,
  },
  strategy: {
    toolbar: {
      back: true,
      actions: [],
    },
    column1: {
      width: 16,
      widgets: [
        {
          title: 'Strategy Details',
          type: 'strategyDetails',
          noPadding: true,
          noBorder: true,
          hideHeader: true,
        },
      ],
    },
    column2: null,
  },
};

export default dashboards;
