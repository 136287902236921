import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import Highcharts from 'highcharts/highstock';
import renderConfig from 'helpers/renderConfig';
import renderThemeConfig from 'helpers/renderThemeConfig';
import NavBarCmp from '../../components/molecules/NavBar';
import AppWrapper from '../../components/atoms/AppWrapper';
import AppFooter from '../../components/molecules/AppFooter';
import { loadValidSession } from '../../store/actions/auth.actions';
import HomePage from '../../components/pages/v1/HomePage';
import LogsPage from '../../components/pages/v1/LogsPage';
import UsersPage from '../../components/pages/v1/UsersPage';
import LoginPage from '../../components/pages/common/LoginPage';
import MonitoringPage from '../../components/pages/v1/Monitoring';
import StrategiesPage from '../../components/pages/v1/StrategiesPage';
import InstrumentsPage from '../../components/pages/v1/InstrumentsPage';
import DataRefreshPage from '../../components/pages/v1/DataRefreshPage';

import PrivateRoute from '../../PrivateRoute';
import useCognitoSSO from '../../helpers/useCognitoSSO';
import { validSession } from '../../helpers/session.helper';
import {
  makeSelectAuthenticated,
  makeSelectInvalidRoles,
} from '../../store/selectors/auth.selector';
import RoleMismatchPage from '../../components/pages/common/RoleMismatchPage/RoleMismatchPage';

export const AuthContext = React.createContext(false);

export default function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(makeSelectAuthenticated);
  const location = useLocation();
  const isValidSession = validSession();

  Highcharts.setOptions({
    colors: renderThemeConfig.default.variables.highchartColors,
  });

  useEffect(() => {
    if (isValidSession && !isAuthenticated) {
      dispatch(loadValidSession());
    }
  }, [location, isValidSession, isAuthenticated, dispatch]);

  useCognitoSSO();

  const invalidRoles = useSelector(makeSelectInvalidRoles);
  if (invalidRoles) {
    return <RoleMismatchPage />;
  }

  return (
    <AuthContext.Provider value={isAuthenticated}>
      <ThemeProvider theme={renderThemeConfig.default}>
        <AppWrapper showBackground={location.pathname === '/login'}>
          <title>{renderConfig.displayName} - Alpima: Management Application </title>
          <NavBarCmp />
          <Switch>
            <PrivateRoute isAuthenticated={isAuthenticated} exact path="/" component={HomePage} />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path="/monitoring"
              component={MonitoringPage}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path="/monitoring/*"
              component={MonitoringPage}
            />
            <PrivateRoute isAuthenticated={isAuthenticated} path="/user/*" component={UsersPage} />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/logs"
              component={LogsPage}
            />
            <PrivateRoute isAuthenticated={isAuthenticated} path="/logs/*" component={LogsPage} />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/instruments"
              component={InstrumentsPage}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path="/instruments/*"
              component={InstrumentsPage}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path="/data-refresh"
              component={DataRefreshPage}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              exact
              path="/strategy"
              component={StrategiesPage}
            />
            <PrivateRoute
              isAuthenticated={isAuthenticated}
              path="/strategy/*"
              component={StrategiesPage}
            />
            <Route exact path="/login" component={LoginPage} />
          </Switch>
          <AppFooter />
          <ToastContainer autoClose={8000} />
        </AppWrapper>
      </ThemeProvider>
    </AuthContext.Provider>
  );
}
