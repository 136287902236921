import {
  ARCHIVE_INFORMATION_BANNER,
  GET_INFORMATION_BANNERS,
  SAVE_INFORMATION_BANNER,
  TOGGLE_INFORMATION_BANNER_MODAL,
} from '../constants/informationBanner.constants';

export const getInformationBanners = () => ({ type: GET_INFORMATION_BANNERS });

export const archiveInformationBanner = informationBannerId => ({
  type: ARCHIVE_INFORMATION_BANNER,
  payload: informationBannerId,
});

export const openInformationBannerModal = () => ({
  type: TOGGLE_INFORMATION_BANNER_MODAL,
  payload: true,
});

export const closeInformationBannerModal = () => ({
  type: TOGGLE_INFORMATION_BANNER_MODAL,
  payload: false,
});

export const saveInformationBanner = informationBanner => ({
  type: SAVE_INFORMATION_BANNER,
  payload: informationBanner,
});
