import Cookies from 'js-cookie';

export const setCookieExpiry = tokenParam => {
  const token = tokenParam || Cookies.get('alp-auth');

  const displayUser = Cookies.get('display-user');

  const cookieExpiry = new Date();
  cookieExpiry.setHours(cookieExpiry.getHours() + 1);

  Cookies.set('alp-auth', token, {
    secure: !window.location.hostname.includes('localhost'),
    expires: cookieExpiry,
  });

  if (displayUser) {
    Cookies.set('display-user', displayUser, {
      secure: !window.location.hostname.includes('localhost'),
      expires: cookieExpiry,
    });
  }
};

export const setCookie = (key, value) => Cookies.set(key, value);

export const getCookie = key => Cookies.get(key);

export const removeCookie = key => Cookies.remove(key);
