import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';

import { Pagination, Loader } from 'semantic-ui-react';

import Button from '../../atoms/Button';
import {
  selectLastRefresh,
  selectLogList,
  selectLogMeta,
  selectLogLoading,
  selectFilter,
  selectFilterContent,
  selectLogFetching,
} from '../../../store/selectors/log.selector';

import {
  getLogStream,
  getLogFilterContent,
  setLogFilterState,
  setLogFilterTimeState,
  sortLogsByColumn,
  paginationChange,
  resetLogFilters,
} from '../../../store/actions/log.actions';

import { columns } from '../../../config/appConfig/tables/log.columns';

import UIModal from '../../molecules/Modal';
import TableDefault from '../../molecules/TableDefault';
import LoadingBoundary from '../../atoms/LoadingBoundary';
import FilterMenu from '../../atoms/FilterMenu';
import TimeFilter from '../../atoms/TimeFilter';

import { filters } from '../../../config/appConfig';
import useDateTimeFormatter from '../../../helpers/useDateTimeFormatter';

const Modal = styled(UIModal)``;

const WidgetHeaderWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
`;

const TableOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;

  > div {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${props => props.theme.variables.breakpoints.lg}px) {
      flex-direction: row;
    }
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (min-width: ${props => props.theme.variables.breakpoints.lg}px) {
    flex-direction: row;
  }

  & > div {
    display: flex;
    justify-content: flex-end;
    flex: 0 0 auto;
    flex-direction: column;
  }
`;

const FilterLoader = styled(Loader)`
  padding: 5px 0 0 30px !important;
  margin: 0 30px !important;

  &::before,
  &::after {
    top: 0 !important;
    left: 0 !important;
  }
`;

const SubmissionGroup = styled.div`
  @media screen and (min-width: ${props => props.theme.variables.breakpoints.lg}px) {
    margin-left: 1rem;
    padding-left: 1rem;
  }
`;
const ResultsCount = styled.div`
  @media screen and (min-width: ${props => props.theme.variables.breakpoints.lg}px) {
    margin-left: 1rem;
    padding-left: 1rem;
  }
`;

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const LogsTableWidget = ({
  theme,
  history,
  loading,
  filtering,
  lastRefresh,
  logList,
  logMeta,
  resetFilters,
  getLogs,
  getLogFilters,
  filter,
  sortLogs,
  filterContent,
  setFilterState,
  setTimeFilterState,
  navigatePagination,
}) => {
  const { formatTime } = useDateTimeFormatter();

  // console.log({
  //   filtering,
  //   lastRefresh,
  //   logList,
  //   logMeta,
  //   resetFilters,
  //   getLogs,
  //   getLogFilters,
  //   filter,
  //   sortLogs,
  //   filterContent,
  //   setFilterState,
  //   setTimeFilterState,
  //   navigatePagination,
  // });

  useEffect(() => {
    getLogFilters();
    scrollToTop();

    // Auto fetches daily errors if url param is set
    if (history && history.location && history.location.search && history.location.search !== '') {
      const fetchDailyErrors = history.location.search
        .substr(1) // remove "?"
        .split('&')
        .filter(parameter => !!~parameter.indexOf('submit=errors')).length;
      if (fetchDailyErrors > 0) {
        setFilterState(filters.LogLevelFilter.config.identifier, [0]);
        getLogs();
      }
    }
  }, [getLogFilters, getLogs, setFilterState, history]);

  const [modalData, setModal] = useState({
    active: false,
    content: '',
  });

  const handlePaginationChange = (e, { activePage }) =>
    navigatePagination(activePage) && scrollToTop();
  const pagination = (
    <PaginationWrapper>
      <div>
        <Pagination
          boundaryRange={0}
          siblingRange={2}
          totalPages={logMeta.totalPages}
          activePage={logMeta.currentPage}
          onPageChange={handlePaginationChange}
        />
        <sub style={{ marginTop: 14, fontSize: '1rem', textAlign: 'right' }}>
          Last refresh: {lastRefresh ? formatTime(lastRefresh) : 'N/A'}
        </sub>
      </div>
    </PaginationWrapper>
  );

  const handleInfoDisplay = log => () => {
    setModal({
      active: true,
      content: (log && log.details && JSON.stringify(log.details, ' ', 2)) || {},
    });
  };

  const logCount =
    logMeta && typeof logMeta.currentPageItems === 'number' ? (
      logMeta.currentPageItems !== 0 && logMeta.totalItems ? (
        <div>
          <span style={{ fontSize: '0.8rem' }}>Total Log Entries: {logMeta.totalItems}</span>
        </div>
      ) : (
        <div>
          <span style={{ fontSize: '0.8rem' }}>No results found</span>
        </div>
      )
    ) : null;

  const submission = (
    <div>
      <SubmissionGroup>
        <Button primary onClick={getLogs}>
          Filter Logs
        </Button>
        <Button className="btn btn-info" onClick={resetFilters}>
          Reset Filters
        </Button>
        {filtering ? (
          <FilterLoader indeterminate active inline>
            Filtering Logs
          </FilterLoader>
        ) : null}
      </SubmissionGroup>
      <ResultsCount>{logCount}</ResultsCount>
    </div>
  );

  const data = (logList || []).map(log => ({
    ...log,
    actionInfoCallback: handleInfoDisplay(log),
  }));

  // console.log('filter', filter);

  return (
    <LoadingBoundary isLoading={loading}>
      <div style={{ minHeight: '850px', overflowX: 'scroll', overflowY: 'hidden' }}>
        <WidgetHeaderWrapper theme={theme}>
          <TableOptionsWrapper>
            <div>
              <FilterMenu
                config={filters.LogLevelFilter.config}
                defaults={filters.LogLevelFilter.defaults}
                options={filters.LogLevelFilter.filters}
                state={filter[filters.LogLevelFilter.config.identifier].reduce(
                  (a, b) => ({ ...a, [b]: true }),
                  [],
                )}
                setFilterState={state => {
                  // levels are submitted as array not object, this converts to array, state is then reduced from array above
                  const levelStateArray = Object.entries(state).map(([key]) => key);
                  setFilterState(filters.LogLevelFilter.config.identifier, levelStateArray);
                }}
              />
              <FilterMenu
                config={filters.LogTypeFilter.config}
                defaults={filters.LogTypeFilter.defaults}
                options={
                  (filterContent.types &&
                    filterContent.types.map(type => ({
                      key: type.key,
                      text: type.value,
                      value: type.value,
                    }))) ||
                  filters.LogTypeFilter.filters
                }
                state={filter[filters.LogTypeFilter.config.identifier].reduce(
                  (a, b) => ({ ...a, [b]: true }),
                  [],
                )}
                setFilterState={state => {
                  // levels are submitted as array not object, this converts to array, state is then reduced from array above
                  const typeStateArray = Object.entries(state).map(([key]) => key);
                  setFilterState(filters.LogTypeFilter.config.identifier, typeStateArray);
                }}
              />
              <FilterMenu
                config={filters.LogGroupsFilter.config}
                defaults={filters.LogGroupsFilter.defaults}
                options={filters.LogGroupsFilter.filters}
                state={filter[filters.LogGroupsFilter.config.identifier].reduce(
                  (a, b) => ({ ...a, [b]: true }),
                  [],
                )}
                setFilterState={state => {
                  // levels are submitted as array not object, this converts to array, state is then reduced from array above
                  const groupsStateArray = Object.entries(state).map(([key]) => parseInt(key, 10));
                  setFilterState(filters.LogGroupsFilter.config.identifier, groupsStateArray);
                }}
              />
              <FilterMenu
                config={filters.LogUsersFilter.config}
                defaults={filters.LogUsersFilter.defaults}
                options={
                  (filterContent.users &&
                    filterContent.users
                      .map(user => ({
                        key: user._id,
                        text: user.displayName,
                        value: user.displayName,
                      }))
                      .filter(a => a.value)
                      .sort((a, b) => {
                        const aValue = a.value || '';
                        const bValue = b.value || '';
                        return aValue.localeCompare(bValue);
                      })) ||
                  filters.LogUsersFilter.filters
                }
                state={filter[filters.LogUsersFilter.config.identifier].reduce(
                  (a, b) => ({ ...a, [b]: true }),
                  [],
                )}
                setFilterState={state => {
                  // users are submitted as array not object, this converts to array, state is then reduced from array above
                  const userStateArray = Object.entries(state).map(([key]) => key);
                  setFilterState(filters.LogUsersFilter.config.identifier, userStateArray);
                }}
              />
            </div>
            <div>
              <TimeFilter
                state={{
                  start: filter.rangeFrom,
                  end: filter.rangeTo,
                  selection: filter.timeFilterSelection,
                }}
                setFilterState={setTimeFilterState}
                style={{
                  position: 'relative',
                  paddingBottom: '2em',
                }}
              />
              {submission}
            </div>
          </TableOptionsWrapper>
        </WidgetHeaderWrapper>
        {pagination}
        <TableDefault
          striped
          selectable
          name="Logs"
          data={data}
          columns={columns}
          onClickLink={log => handleInfoDisplay(log)()}
          onSort={sortLogs}
        />
        {pagination}
      </div>
      <Modal
        active={modalData.active}
        data={{
          header: 'Log Details',
        }}
        onClose={() =>
          setModal({
            active: false,
          })
        }
      >
        <pre>{modalData.content}</pre>
      </Modal>
    </LoadingBoundary>
  );
};

LogsTableWidget.propTypes = {
  theme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  filtering: PropTypes.bool.isRequired,
  lastRefresh: PropTypes.string.isRequired,
  logList: PropTypes.array.isRequired,
  logMeta: PropTypes.object,
  getLogs: PropTypes.func.isRequired,
  getLogFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  sortLogs: PropTypes.func.isRequired,
  filterContent: PropTypes.object.isRequired,
  setFilterState: PropTypes.func.isRequired,
  setTimeFilterState: PropTypes.func.isRequired,
  navigatePagination: PropTypes.func.isRequired,
};

LogsTableWidget.defaultProps = {
  logMeta: {
    totalPages: 1,
    currentPage: 1,
  },
};

const mapStateToProps = createStructuredSelector({
  loading: state => selectLogLoading(state),
  filtering: state => selectLogFetching(state),
  lastRefresh: state => selectLastRefresh(state),
  logList: state => selectLogList(state),
  logMeta: state => selectLogMeta(state),
  filter: state => selectFilter(state),
  filterContent: state => selectFilterContent(state),
});

const mapDispatchToProps = dispatch => ({
  getLogs: () => dispatch(getLogStream()),
  getLogFilters: () => dispatch(getLogFilterContent()),
  resetFilters: () => dispatch(resetLogFilters()),
  setFilterState: (key, valueArray) => dispatch(setLogFilterState(key, valueArray)),
  setTimeFilterState: (rangeStart, rangeEnd, selection) =>
    dispatch(setLogFilterTimeState(rangeStart, rangeEnd, selection)),
  sortLogs: (column, direction) => dispatch(sortLogsByColumn(column, direction)),
  navigatePagination: page => dispatch(paginationChange(page)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogsTableWidget));
