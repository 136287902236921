import { pluck } from 'rxjs/operators';
import { apiCall$, apiCallWithRequest$ } from './lib/apiCall';
import {
  strategyExportUrl,
  strategyCalculationUrl,
  strategyListURL,
  strategyDetailsURL,
} from './clientAPI.config';

export const performStrategyCSVExport = (json = false) =>
  apiCallWithRequest$({
    url: `${strategyExportUrl}?json=${json}`,
    method: 'GET',
    responseType: json ? 'json' : 'text',
  }).pipe(pluck('response'));

export const requestStrategyCalculation = name => apiCall$(`${strategyCalculationUrl}/${name}`);

export const fetchStrategyList = () => apiCall$(strategyListURL);

export const fetchStrategyDetails = strategyId =>
  apiCall$(strategyDetailsURL.replace(/:strategyId/, strategyId));

export const setStrategyUpdate = updatedStrategy =>
  apiCall$(
    strategyDetailsURL.replace(/:strategyId/, updatedStrategy._id),
    'PATCH',
    updatedStrategy,
  );
