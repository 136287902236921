// eslint-disable-next-line
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import find from 'lodash/find';
import {
  Table,
  TableHeader,
  HeaderCell,
  TableBody,
  TableRow,
  BodyCell,
  PageHeader,
  Back,
  Title,
  Link,
  Layout,
  Section,
  Modal,
} from '@alpima/picasso';
import renderConfig from 'helpers/renderConfig';
import { fetchLogList } from '../../../../services/clientAPI/log.service';
import useTriggeredService from '../../../../helpers/useTriggeredService';
import { makeSelectUser } from '../../../../store/selectors/auth.selector';
import useDateTimeFormatter from '../../../../helpers/useDateTimeFormatter';
import { filter as LogGroupFilters } from '../../../../config/appConfig/filters/logGroups.filters';
import releaseToggles from '../../../../releaseToggles';
import Toolbar from './Toolbar';
import useFilters from './useFilters';
import { LogItem } from './Logs.types';
import TablePagination from './TablePagination';
import DownloadLogsButton from './DownloadLogs';

function LinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6 mr-2"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
      />
    </svg>
  );
}

const Logs = () => {
  const currentUser = useSelector(makeSelectUser);
  const userCompany = currentUser?.company;
  const { formatDate, formatDateTime } = useDateTimeFormatter();

  const [loading, , rawLogList, getLogs] = useTriggeredService(fetchLogList);
  const memoLogList = useMemo(() => rawLogList?.logs || [], [rawLogList]);

  const filters = useFilters();
  const { filter } = filters;

  const requestPayload = useMemo(
    () => ({
      companies: [userCompany],
      levels: [...filter.level.selected] || [0, 1, 2],
      loadTotalPages: true,
      onlyEnabledUsers: false,
      pageNumber: filter.pagination.page.value,
      pageSize: filter.pagination.pageSize.value,
      rangeFrom: filter.dates.start,
      rangeTo: filter.dates.end,
      sortDirection: 'desc',
      sortField: 'created',
      types: [...filter.type.selected] || [],
      users: [...filter.user.selected] || [],
    }),
    [
      filter.dates.end,
      filter.dates.start,
      filter.level.selected,
      filter.pagination.page.value,
      filter.pagination.pageSize.value,
      filter.type.selected,
      filter.user.selected,
      userCompany,
    ],
  );

  const remoteGetLogs = useCallback(() => {
    const { level, user, type } = filters.filter;
    const validateFields = [level, user, type];
    let isValidRequest = true;
    validateFields.forEach(({ initial, selected }) => {
      if (initial && selected) {
        // Ensure the selected values do not exceed the initial API values.
        if (selected.length > initial.length) {
          isValidRequest = false;
        }
        // Ensure the selected item exists in the initial API values.
        selected.forEach(value => {
          if (!initial?.includes(value)) {
            isValidRequest = false;
          }
        });
      }
    });
    if (isValidRequest) {
      getLogs(requestPayload);
    }
    // Don't re-register when filters change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLogs, requestPayload]);

  useEffect(() => {
    if (!rawLogList && userCompany && [...filter.user.selected].length > 0) getLogs(requestPayload);
  }, [getLogs, rawLogList, requestPayload, userCompany, filter.user.selected]);

  useEffect(() => {
    if (userCompany && [...filter.user.selected].length > 0) getLogs(requestPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.pagination.pageSize.value, filter.pagination.page.value]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const openLog = (content: string) => {
    setIsModalOpen(true);
    setModalContent(content);
  };

  const LogElement = (props: LogItem & { content: string }) => {
    const { user, type, content, detailsSummary, created, group } = props;
    return (
      <TableRow className="text-center cursor-pointer" onClick={() => openLog(content)}>
        <BodyCell className="py-4 text-primary-800">{user.displayName}</BodyCell>
        {renderConfig.showUserCompany && <BodyCell className="py-4">{user.company.name}</BodyCell>}
        <BodyCell className="py-4">{type.name}</BodyCell>
        <BodyCell className="py-4">
          {find(LogGroupFilters.filters, { key: group })?.text || 'N/A'}
        </BodyCell>
        <BodyCell className="py-4">{detailsSummary}</BodyCell>
        <BodyCell className="py-4">
          {formatDate(new Date(created))} - {formatDateTime(new Date(created))}
        </BodyCell>
      </TableRow>
    );
  };

  return (
    /* External div should be removes when page layout is sorted */
    <div className="min-h-screen">
      <Layout noMargins>
        <div className="w-full px-8 mb-2">
          <PageHeader>
            <Back />
            <Section>
              <Title>Logs Dashboard</Title>
              <div className="flex gap-x-2">
                {releaseToggles.cognitoAuthentication ? (
                  <Link
                    to="/authorization-logs"
                    className="border border-gray-400 rounded-full text-black text-sm md:text-lg px-4 m-0 h-10 flex items-center hover:text-black"
                  >
                    <LinkIcon />
                    Authentication Logs
                  </Link>
                ) : null}
                <DownloadLogsButton
                  userCompany={userCompany}
                  filter={filter}
                  rawLogList={rawLogList}
                  loading={loading}
                />
              </div>
            </Section>
          </PageHeader>
        </div>
        <div className="w-full border-b border-gray-200" />
        <Toolbar getLogs={remoteGetLogs} filters={filters} />
        <Table>
          <TableHeader>
            <TableRow className="text-md">
              <HeaderCell className="py-4 w-1/6">User</HeaderCell>
              {renderConfig.showUserCompany && (
                <HeaderCell className="py-4 w-1/6">Company</HeaderCell>
              )}
              <HeaderCell className="py-4 w-1/6">Event Type</HeaderCell>
              <HeaderCell className="py-4 w-1/6">Group</HeaderCell>
              <HeaderCell className="py-4 w-1/6">Details</HeaderCell>
              <HeaderCell className="py-4 w-1/6">Created</HeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {memoLogList?.map((log: LogItem) => (
              <LogElement key={log._id} content={JSON.stringify(log.details, null, 2)} {...log} />
            ))}
          </TableBody>
        </Table>
        {!memoLogList.length && (
          <div className="text-center w-full border-b border-l border-r border-gray-400 py-4">
            No logs found for the current selection.
          </div>
        )}
        {!!memoLogList.length && (
          <TablePagination
            itemsPerPage={filter.pagination.pageSize.value}
            itemsPerPageHandler={newSize => filter.pagination.pageSize.setValue(newSize)}
            page={filter.pagination.page.value}
            pageHandler={newPage => filter.pagination.page.setValue(newPage)}
            numberOfRows={rawLogList?.pagination?.totalItems}
          />
        )}
      </Layout>
      <Modal open={isModalOpen} outsideClickToClose onClose={() => setIsModalOpen(false)}>
        <div className="px-64">
          <pre>{modalContent}</pre>
        </div>
      </Modal>
    </div>
  );
};

export default Logs;
