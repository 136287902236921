import React from 'react';
import { SeverityLevel } from '../StrategyAlerts.types';

function High() {
  return <span className="inline-block bg-red-500 rounded-md text-white px-2 m-3 py-2">High</span>;
}

function Medium() {
  return (
    <span className="inline-block bg-yellow-600 rounded-md text-white px-2 m-3 py-2">Medium</span>
  );
}

function Low() {
  return <span className="inline-block bg-green-600 rounded-md text-white px-2 m-3 py-2">Low</span>;
}

export default function SeverityTag({ severity }: { severity: SeverityLevel }) {
  return {
    high: <High />,
    medium: <Medium />,
    low: <Low />,
  }[severity];
}
