/* eslint-disable no-case-declarations */
import { fromJS } from 'immutable';

import * as constants from '../constants';

const initialState = fromJS({
  loading: false,
  error: null,
  lastRefresh: -1,
  list: [],
  search: [],
  online: {
    users: [],
    error: null,
    loading: false,
    lastRefresh: -1,
  },
  pending: {
    users: [],
    error: null,
    loading: false,
    lastRefresh: -1,
  },
  contractRoles: {
    users: [],
    error: null,
    loading: false,
    lastRefresh: -1,
  },
  details: {
    active: {},
    cache: {},
  },
  isExporting: false,
  exportData: null,
});

function paginateList(list = [], maxPerPage = 50) {
  const paginatedList = [];
  let count = 0;
  let currentPage = 0;
  list.forEach(item => {
    currentPage = count < maxPerPage - 1 ? currentPage : currentPage + 1;
    count = count < maxPerPage - 1 ? count + 1 : 0;

    paginatedList[currentPage] = paginatedList[currentPage] || [];
    paginatedList[currentPage].push(item);
  });
  return paginatedList;
}

function flattenUserDetails(user = {}) {
  return {
    ...user,
    contactPhoneNumber:
      (user.phones &&
        user.phones.filter(phone => phone.type === 'contact').length &&
        user.phones.filter(phone => phone.type === 'contact')[0].number) ||
      undefined,
    sessionTimeoutMinutes: user.sessionTimeoutMinutes || 60,
  };
}

function mergeOnlineIntoUserList(userList = [], onlineUsers = []) {
  return userList
    .reduce((a, b) => a.concat(b), [])
    .map(user => {
      const userIsOnline = onlineUsers.find(online => online._id === user._id);
      return {
        ...user,
        online: Boolean(userIsOnline),
      };
    });
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.GET_USERS_LIST:
      return state.set('loading', true);
    case constants.GET_USERS_LIST_SUCCESS:
      const onlineUsers =
        state.getIn(['online', 'users']) &&
        ((state.getIn(['online', 'users']).toJS && state.getIn(['online', 'users']).toJS()) ||
          state.getIn(['online', 'users']));
      return state.merge({
        list: fromJS(paginateList(mergeOnlineIntoUserList(action.payload, onlineUsers), 20)),
        lastRefresh: new Date().getTime(),
        loading: false,
        error: null,
      });

    case constants.GET_USER_DETAILS:
      return state.set('loading', true);
    case constants.GET_USER_DETAILS_SUCCESS:
      const payload = flattenUserDetails(action.payload);
      return state.merge({
        details: {
          active: fromJS(payload),
          cache: fromJS(payload),
        },
        loading: false,
        error: null,
      });

    case constants.GET_USERS_LIST_FAILURE:
    case constants.GET_USER_DETAILS_FAILURE:
      return state.merge({
        loading: false,
        lastRefresh: new Date().getTime(),
        error: action.payload ? action.payload.error : null,
      });
    case constants.SET_USER_DETAILS_CANCEL:
      return state.setIn(['details', 'active'], state.getIn(['details', 'cache']));

    case constants.SET_USER_SEARCH_RESULTS:
      return state.set('search', fromJS(paginateList(action.payload, 20)));
    case constants.CLEAR_USER_SEARCH_RESULTS:
      return state.set('search', fromJS([]));

    case constants.SET_USER_COLUMN_SORT:
      return state.merge({
        list: fromJS(paginateList(action.payload.sortedData, 20)),
      });

    case constants.STAMP_USER_DETAILS:
      return state.mergeIn(['details', 'active'], {
        [action.key]: action.compiler(
          action.value,
          state && state.getIn(['details', 'active']),
          action.data,
        ),
      });
    case constants.STAMP_INITIAL_USER_DETAILS_ROLES:
      const userRoles = state.getIn(['details', 'cache', 'roles']); // eslint-disable-line no-case-declarations
      // eslint-disable-next-line no-case-declarations
      const roles = {
        functionRoles: userRoles.filter(role => ~action.payload.functionRoles.indexOf(role)),
        featureRoles: userRoles.filter(role => ~action.payload.featureRoles.indexOf(role)),
        companyRoles: userRoles.filter(role => ~action.payload.companyRoles.indexOf(role)),
        accountTypeRoles: userRoles.filter(role => ~action.payload.accountTypeRoles.indexOf(role)),
      };
      return state
        .setIn(['details', 'active', 'functionRoles'], roles.functionRoles)
        .setIn(['details', 'active', 'featureRoles'], roles.featureRoles)
        .setIn(['details', 'active', 'companyRoles'], roles.companyRoles)
        .setIn(['details', 'active', 'accountTypeRoles'], roles.accountTypeRoles)
        .setIn(['details', 'cache', 'functionRoles'], roles.functionRoles)
        .setIn(['details', 'cache', 'featureRoles'], roles.featureRoles)
        .setIn(['details', 'cache', 'companyRoles'], roles.companyRoles)
        .setIn(['details', 'cache', 'accountTypeRoles'], roles.accountTypeRoles);

    case constants.STAMP_ENABLE_USER:
      return state.setIn(['details', 'active', 'enabled'], true);
    case constants.STAMP_DISABLE_USER:
      return state.setIn(['details', 'active', 'enabled'], false);

    case constants.GET_ONLINE_USERS:
      return state.mergeDeepIn(['online'], {
        loading: true,
      });
    case constants.GET_ONLINE_USERS_CANCELLED:
      return state.mergeDeepIn(['online'], {
        loading: false,
      });
    case constants.GET_ONLINE_USERS_SUCCESS:
      const userList =
        state.get('list') &&
        ((state.get('list').toJS && state.get('list').toJS()) || state.get('list'));
      return state.merge({
        list: fromJS(paginateList(mergeOnlineIntoUserList(userList, action.payload), 20)),
        online: {
          users: fromJS(action.payload),
          error: null,
          loading: false,
          lastRefresh: new Date().getTime(),
        },
      });
    case constants.GET_ONLINE_USERS_FAILURE:
      return state.merge({
        online: {
          error: action.payload,
          users: fromJS([]),
          loading: false,
          lastRefresh: new Date().getTime(),
        },
      });

    case constants.GET_PENDING_USERS:
      return state.mergeDeepIn(['pending'], {
        loading: true,
      });
    case constants.GET_PENDING_USERS_CANCELLED:
      return state.mergeDeepIn(['pending'], {
        loading: false,
      });
    case constants.GET_PENDING_USERS_SUCCESS:
      return state.merge({
        pending: {
          users: fromJS(action.payload),
          error: null,
          loading: false,
          lastRefresh: new Date().getTime(),
        },
      });
    case constants.GET_PENDING_USERS_FAILURE:
      return state.merge({
        pending: {
          error: action.payload,
          users: fromJS([]),
          loading: false,
          lastRefresh: new Date().getTime(),
        },
      });

    case constants.SET_USER_ONBOARDING_STATE:
      return state.mergeDeepIn(['pending'], {
        loading: true,
      });
    case constants.SET_USER_ONBOARDING_STATE_CANCELLED:
      return state.mergeDeepIn(['pending'], {
        loading: false,
      });
    case constants.SET_USER_ONBOARDING_STATE_SUCCESS:
      return state.merge({
        pending: {
          users: fromJS([]),
          error: null,
          loading: false,
        },
      });
    case constants.SET_USER_ONBOARDING_STATE_FAILURE:
      return state.merge({
        pending: {
          users: fromJS([]),
          error: `${action.error} - ${action.error.message}`,
          loading: false,
        },
      });

    case constants.GET_UPDATED_CONTRACT_ROLE_USERS:
      return state.setIn(['contractRoles', 'loading'], true);
    case constants.GET_UPDATED_CONTRACT_ROLE_USERS_CANCELLED:
      return state.setIn(['contractRoles', 'loading'], false);
    case constants.GET_UPDATED_CONTRACT_ROLE_USERS_SUCCESS:
      return state.merge({
        contractRoles: {
          users: fromJS(action.payload),
          error: null,
          loading: false,
          lastRefresh: new Date().getTime(),
        },
      });
    case constants.GET_UPDATED_CONTRACT_ROLE_USERS_FAILURE:
      return state.merge({
        contractRoles: {
          error: action.payload || action.error,
          users: fromJS([]),
          loading: false,
          lastRefresh: new Date().getTime(),
        },
      });

    case constants.SET_USER_NEW_CONTRACT_ROLE:
      return state.setIn(['contractRoles', 'loading'], true);
    case constants.SET_USER_NEW_CONTRACT_ROLE_CANCELLED:
      return state.setIn(['contractRoles', 'loading'], false);
    case constants.SET_USER_NEW_CONTRACT_ROLE_SUCCESS:
      return state.merge({
        contractRoles: {
          users: fromJS([]),
          error: null,
          loading: false,
          lastRefresh: new Date().getTime(),
        },
      });
    case constants.SET_USER_NEW_CONTRACT_ROLE_FAILURE:
      return state.merge({
        contractRoles: {
          users: fromJS([]),
          error: `${action.error} - ${action.error.message}`,
          loading: false,
          lastRefresh: new Date().getTime(),
        },
      });

    case constants.CLEAR_SESSION:
    case constants.LOGOUT_SUCCEEDED:
      return initialState;

    case constants.USERS_EXPORT_REQUEST:
      return state.merge({
        isExporting: true,
      });
    case constants.USERS_EXPORT_REQUEST_SUCCESS:
      return state.merge({
        isExporting: false,
        exportData: action.payload,
      });
    case constants.USERS_EXPORT_REQUEST_FAILURE:
      return state.merge({
        isExporting: false,
        exportData: null,
      });

    case constants.USERS_EXPORT_CLEAR:
      return state.merge({
        isExporting: false,
        exportData: null,
      });

    default:
      return state;
  }
};
