export default {
  syncOperation: {
    key: 'syncOperation',
    name: 'Sync Operation',
    options: ['bjb-core-sync', 'bjb-adv-sync', 'bjb-imc-sync'],
  },
  sourceEnvironment: {
    key: 'sourceEnvironment',
    name: 'Source Environment',
    options: ['Dev', 'SIT', 'UAT', 'UAT2', 'Prod'],
  },
  targetEnvironment: {
    key: 'targetEnvironment',
    name: 'Target Environment',
    options: ['Local', 'Dev', 'QA', 'SIT', 'UAT', 'UAT2'],
  },
  targetFoundAction: {
    key: 'targetFoundAction',
    name: 'Target Found Action',
    options: ['Replace', 'Skip', 'Merge'],
  },
  targetNotFoundAction: {
    key: 'targetNotFoundAction',
    name: 'Target Not Found Action',
    options: ['Insert', 'Skip'],
  },
};
