import { Button } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import ThemedButton from '../Button';

export default function ButtonTwoChoice({ disabled, onClickLeft, onClickRight, left, right }) {
  return (
    <Button.Group>
      <ThemedButton positive disabled={disabled} onClick={onClickLeft}>
        {left}
      </ThemedButton>
      <Button.Or />
      <ThemedButton disabled={disabled} onClick={onClickRight}>
        {right}
      </ThemedButton>
    </Button.Group>
  );
}

ButtonTwoChoice.defaultProps = {
  disabled: false,
};

ButtonTwoChoice.propTypes = {
  disabled: PropTypes.bool,
  onClickLeft: PropTypes.func.isRequired,
  onClickRight: PropTypes.func.isRequired,
  left: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
};
