import { pluck } from 'rxjs/operators';
import { apiCallWithRequest$, apiCall$ } from './lib/apiCall';

import {
  userListURL,
  newUserListURL,
  newUserDetailsURL,
  userDetailsURL,
  usersOnlineURL,
  usersPendingURL,
  userOnboardingUpdateURL,
  userEnableURL,
  userDisableURL,
  userUpdateURL,
  usersWithUpdatedContractRolesURL,
  usersWithUpdatedContractRolesUpdateURL,
  setUserObjectRolesURL,
  getUserObjectRolesURL,
  usersExportURL,
  newEditUserURL,
} from './clientAPI.config';

export const fetchUserList = companyId => apiCall$(userListURL.replace(/:companyId/i, companyId));
export const fetchNewUserList = companyId =>
  apiCall$(newUserListURL.replace(/:companyId/i, companyId));

export const fetchUserDetails = id => apiCall$(userDetailsURL.replace(/:id/i, id));

export const fetchNewUserDetails = id => apiCall$(newUserDetailsURL.replace(/:id/i, id));

export const putNewUserDetails = user => apiCall$(newEditUserURL, 'POST', user);

export const fetchUserObjectRoles = userId => apiCall$(fetchUserObjectRoles.replace(/:id/, userId));

export const fetchOnlineUsers = whitelabel =>
  apiCall$(usersOnlineURL[whitelabel] || usersOnlineURL.default);

export const fetchPendingUsers = () => apiCall$(usersPendingURL);

export const fetchUsersWithUpdatedContractRoles = () => apiCall$(usersWithUpdatedContractRolesURL);

export const putUserEnabledState = (id, enabled) => {
  if (enabled) {
    return apiCall$(userEnableURL.replace(/:id/i, id));
  }
  return apiCall$(userDisableURL.replace(/:id/i, id));
};

export const putUserUpdatedState = (id, userState) =>
  apiCall$(userUpdateURL.replace(/:id/i, id), 'PATCH', userState);

export const setUserOnboardingState = (id, approve) =>
  apiCall$(userOnboardingUpdateURL.replace(/:id/i, id), 'PATCH', { approve });

export const setUserUpdatedContractRole = (id, approve) =>
  apiCall$(usersWithUpdatedContractRolesUpdateURL.replace(/:id/i, id), 'PATCH', { approve });

export const getUserObjectRoles = userId =>
  apiCall$(getUserObjectRolesURL.replace(/:userId/, userId));

export const setUserObjectRoles = (userId, objectRoles) =>
  apiCall$(setUserObjectRolesURL.replace(/:userId/, userId), 'PUT', {
    objectRoles,
  });

export const performUserCsvExport = (json = false) =>
  apiCallWithRequest$({
    url: `${usersExportURL}?json=${json}`,
    method: 'GET',
    responseType: json ? 'json' : 'text',
  }).pipe(pluck('response'));
